$c1: #56a9e8;
$c2: #4a4a4a;
$c3: #bababb;
$c4: #1e5f91;
$c5: #f7f7f7;
$c6: #343a40;
$c7: #0056b3;
$c8: #212529;
$universe-text-color: #494949;

.set-default-tooltip-discover {
   font-weight: 600;
   font-size: 14px;
   color: #1e5f91;
}

.dashboard-container {
   width: 100%;
   height: 100%;
   margin: 0;

   color: $charcoal-black !important;

   .sidebar-container {
      height: 100%;
      border-right: 1px solid #fff;
      // .sideBarWrapper {
      // 	height: 100%;
      // 	padding: 22px 5px;
      // 	// padding: 22px 0px;
      // 	.loader {
      // 		font-size: 8px;
      // 	}
      // 	.show {
      // 		opacity: 1 !important;
      // 		visibility: visible !important;
      // 	}
      // 	.hide {
      // 		opacity: 0;
      // 		visibility: hidden;
      // 	}
      // 	.element {
      // 	  padding-bottom: 20px;
      // 	  transition: all 0.3s;
      //
      // 	  h2 {
      // 	    padding-bottom: 15px;
      // 	    margin: 0;
      // 	    font-family: Roboto;
      // 	    font-size: 20px !important;
      // 	    font-weight: bold;
      // 	    color: inherit;
      // 	    // line-height: 1.5;
      // 	  }
      // 	  .label {
      // 			font-family: Roboto;
      // 	    font-size: 18px;
      // 	    color: inherit;
      // 	    margin-bottom: 7px;
      // 	  }
      // 	  .searchInput {
      // 	    input {
      // 	      height: 40px;
      // 	      padding: 5px 40px 5px 10px;
      // 	      font-size: 15px;
      // 	      font-weight: normal;
      // 	      transition: all 0.3s;
      // 	      &::-ms-clear {
      // 	        display: none !important;
      // 	      }
      // 	    }
      // 	    .search-icon {
      // 	      font-size: 20px;
      // 	      right: 8px;
      // 	    }
      // 	    .clear-icon {
      // 	      position: absolute;
      // 	      right: 40px;
      // 	      line-height: 35px;
      // 	      width: 15px;
      // 	      text-align: center;
      // 	      z-index: 9;
      // 	      opacity: 0.2;
      // 	      font-size: 15px;
      // 	      transition: line-height .5s, width .5s, font-size .5s;
      // 	      &.active {
      // 	        opacity: 1;
      // 	        cursor: pointer;
      // 	      }
      // 	    }
      // 	    // @include respond(phone) {
      // 	    //   .clear-icon {
      // 	    //     line-height: 35px;
      // 	    //     width: 15px;
      // 	    //     text-align: center;
      // 	    //     right: 35px;
      // 	    //   }
      // 	    // }
      // 	    //
      // 	    // @include respond(tab){
      // 	    //   .clear-icon {
      // 	    //     line-height: 35px;
      // 	    //     width: 15px;
      // 	    //     text-align: center;
      // 	    //     right: 35px;
      // 	    //   }
      // 	    // }
      //
      // 	    // @include respond(desktop){
      // 	    //   .clear-icon {
      // 	    //     line-height: 35px;
      // 	    //     width: 15px;
      // 	    //     text-align: center;
      // 	    //     right: 35px;
      // 	    //   }
      // 	    // }
      // 	  }
      // 	  .tagWrapper {
      // 	    display: flex;
      // 	    flex-direction: row;
      // 	    flex-wrap: wrap;
      // 	    overflow: hidden;
      // 	    font-size: 16px;
      // 	    max-height: 122px;
      // 	    color: inherit!important;
      // 	    .tag {
      // 	      // min-width: max-content;
      // 	      max-width: 290px;
      // 	      overflow: hidden;
      // 	      margin-right: 10px;
      // 	      margin-bottom: 10px;
      // 	      padding: 6px 15px;
      // 	      color: $tag-blue;
      // 	      font-size: 12px;
      // 	      border: 1px solid $tag-blue;
      // 	      background: #fff;
      // 	      border-radius: 30px;
      // 	      transition: all .3s;
      // 	      white-space: nowrap;
      // 	      cursor: pointer;
      // 	      text-overflow: ellipsis;
      //
      // 	      &:hover {
      // 	        background: $tag-blue;
      // 	        color: #fff;
      // 	      }
      // 	    }
      // 	    .tag-question {
      // 	      color: #55a9e8;
      // 	      margin-right: 10px;
      //
      // 	      &:hover {
      // 	        cursor: pointer;
      // 	      }
      // 	    }
      // 	  }
      // 	  .cardWrapper {
      // 	    .card {
      //
      // 	    }
      // 	  }
      // 	}
      //
      // 	.account-nav-header {
      // 		flex-direction: column;
      //
      // 		.dropdown-menu {
      // 			margin-left: 0 !important;
      // 			width: 100%;
      // 			text-align: center;
      // 			height: 100%;
      // 			min-height: 300px;
      // 			overflow-y: scroll;
      // 		}
      //
      // 		ul {
      // 			margin: 5px 0;
      //
      // 			a {
      // 				font-size: 12px;
      // 				padding: 7px !important;
      // 				border-radius: 30px !important;
      // 			}
      // 		}
      // 	}
      // }
      .sideBarWrapper {
         padding: 22px 5px;
         .loader {
            font-size: 8px;
         }
         .show {
            opacity: 1 !important;
            visibility: visible !important;
         }
         .hide {
            opacity: 0;
            visibility: hidden;
         }
         .element {
            padding-bottom: 20px;
            transition: all 0.3s;

            h2 {
               padding-bottom: 15px;
               margin: 0;
               font-family: Roboto;
               font-size: 20px !important;
               font-weight: bold;
               color: inherit;
               // line-height: 1.5;
            }
            .label {
               line-height: 1.5;
               font-family: Roboto;
               font-size: 18px;
               color: inherit;
               margin-bottom: 7px;
            }
            .sub-label {
               font-size: 0.7rem;
            }
            .searchInput {
               input {
                  height: 40px;
                  padding: 5px 40px 5px 10px;
                  font-size: 15px;
                  font-weight: normal;
                  transition: all 0.3s;
                  &::-ms-clear {
                     display: none !important;
                  }
               }
               .search-icon {
                  font-size: 20px;
                  right: 8px;
               }
               .clear-icon {
                  position: absolute;
                  right: 40px;
                  line-height: 35px;
                  width: 15px;
                  text-align: center;
                  z-index: 9;
                  opacity: 0.2;
                  font-size: 15px;
                  transition: line-height 0.5s, width 0.5s, font-size 0.5s;
                  &.active {
                     opacity: 1;
                     cursor: pointer;
                  }
               }
               // @include respond(phone) {
               //   .clear-icon {
               //     line-height: 35px;
               //     width: 15px;
               //     text-align: center;
               //     right: 35px;
               //   }
               // }
               //
               // @include respond(tab){
               //   .clear-icon {
               //     line-height: 35px;
               //     width: 15px;
               //     text-align: center;
               //     right: 35px;
               //   }
               // }

               // @include respond(desktop){
               //   .clear-icon {
               //     line-height: 35px;
               //     width: 15px;
               //     text-align: center;
               //     right: 35px;
               //   }
               // }
            }
            .tagWrapper {
               display: flex;
               flex-direction: row;
               flex-wrap: wrap;
               overflow: hidden;
               font-size: 16px;
               max-height: 122px;
               color: inherit !important;
               .tag {
                  // min-width: max-content;
                  max-width: 290px;
                  overflow: hidden;
                  margin-right: 10px;
                  margin-bottom: 10px;
                  padding: 6px 15px;
                  color: $tag-blue;
                  font-size: 12px;
                  border: 1px solid $tag-blue;
                  background: #fff;
                  border-radius: 30px;
                  transition: all 0.3s;
                  white-space: nowrap;
                  cursor: pointer;
                  text-overflow: ellipsis;

                  &:hover {
                     background: $tag-blue;
                     color: #fff;
                  }
               }
               .tag-question {
                  color: #55a9e8;
                  margin-right: 10px;

                  &:hover {
                     cursor: pointer;
                  }
               }
            }
            .cardWrapper {
               .card {
               }
            }
         }
         .portfolio-class-dropdown-container {
            line-height: 1;
         }
         .sidebar-dropdown-wrapper {
            display: flex;
            align-items: center;
            .sidebar-selector-span {
               display: flex;
               align-items: center;
               margin-bottom: 5px;
            }
            .portfolio-class-dropdown-text {
               font-family: Roboto;
               font-size: 14px;
               color: inherit;
               margin-bottom: 7px;
               .anticon-caret-down {
                  position: absolute;
                  top: 0;
                  right: -14px;
                  height: 100%;
                  font-size: 12px;
                  line-height: 1.4;
                  vertical-align: 0;
                  font-size: 10px;
               }
            }
         }
         .discover-dropdown {
            .multiple-calculator {
               .view-btn {
                  font-family: Roboto !important;
                  font-size: 14px !important;
                  color: inherit;
                  .anticon-caret-down {
                     font-size: 12px;
                  }
               }
            }
         }
         .sidebar-scroll-content {
            height: calc(100vh - (340px));
            margin-right: -12px !important;
            padding-left: 0 !important;
            padding-right: 8px !important;
         }
         .universe-container {
            .enhancer-positions-universe-wrapper {
            }
            .enhancer-universe-divider-wrapper {
               position: relative;
               .enhancer-universe-divider {
                  height: 38px;
                  &:before {
                     content: "";
                     position: absolute;
                     top: 30px;
                     display: inline-block;
                     height: 40px;
                     margin: 0 8px;
                     vertical-align: middle;
                     border-top: 0;
                     border-left: 1px solid rgba(0, 0, 0, 0.06);
                  }
               }
            }
            .enhancer-portfolio-universe-wrapper {
            }
            .universe-section {
               position: relative;
               display: flex;
               flex-wrap: wrap;
               justify-content: space-between;
               padding: 0 2px;
               .switch-wrapper {
                  display: flex;
                  .ant-switch-small {
                     min-width: 20px !important;
                     height: 8px;
                  }
                  .ant-switch-checked {
                     &:after {
                        top: -4px !important;
                        width: 14px !important;
                        height: 14px !important;
                     }
                  }
                  .ant-switch::after {
                     top: -4px !important;
                     width: 14px !important;
                     height: 14px !important;
                  }
               }
               &__item {
                  min-width: auto;
                  margin-bottom: 5px;
                  .check-label {
                     font-family: Roboto;
                     font-size: 12px;
                     font-weight: normal;
                     font-stretch: normal;
                     font-style: normal;
                     letter-spacing: -0.12px;
                     text-align: left;
                     color: $universe-text-color;
                     #mfu,
                     #etf {
                        font-size: 10px;
                     }
                  }
                  .switch {
                     width: 25px;
                     height: 8px;
                     display: inline-block;
                     position: relative;
                     margin: 0;
                  }
                  .switch input {
                     display: none;
                  }
               }
            }
            .check-error {
               font-size: 12px;
            }
         }
      }
   }
   .dashboard-content-container {
      position: relative;

      .show {
         display: block !important;
         visibility: visible !important;
         opacity: 1 !important;
      }
      .hide {
         display: none !important;
         visibility: hidden !important;
         opacity: 0 !important;
      }
      .spinner-wrapper {
         position: absolute;
         width: 100%;
         height: 100%;
         background: #f7f7f7;
      }
      .spinnerMessage {
         margin: 50px;
         display: flex;
         flex-direction: column;
         vertical-align: middle;
         height: 55%;
         align-items: center;
         justify-content: center;
      }

      .custom-scroll {
         height: calc(100vh - 180px) !important;
      }

      .account-page-home-wrapper {
         height: calc(100vh - 164px) !important;
         margin-bottom: 0;
         padding-right: 8px !important;

         &::-webkit-scrollbar {
            width: 4px !important;
            border-radius: 6px;
            background-color: transparent;
         }

         &::-webkit-scrollbar-thumb {
            width: 4px !important;
            border-radius: 6px;
            background-color: $in-active-color;
         }
      }

      .itemListContainer {
         padding: 0 15px 0 15px;
         padding-left: 0;
         padding-right: 0;

         .itemListHeaderWrapper {
            .cardListToolbarWrapper .container {
               max-width: unset;
               padding: unset;
            }
            .cardListToolbarWrapper {
               .container {
                  max-width: unset;
                  padding: unset;
                  .toolbar-wrapper-flex-center {
                     align-items: center;
                     display: flex;
                     .fees-returns-risk-header-container {
                        .FRR-Label {
                           height: 22px;
                        }
                     }
                  }
                  .viewChartsColumn {
                     display: flex;
                     flex-wrap: wrap;
                     align-items: center;

                     .viewDropdownContainer {
                        width: 100%;
                        height: 100%;

                        .viewDropdownWrapper {
                           height: 22px;
                           margin-bottom: 2px;
                           .viewBtn {
                              // margin-top: -10px !important;
                              line-height: normal !important;
                           }
                           .viewDropDownList {
                              min-width: unset !important;
                              // padding: 2px !important;
                              .viewDropDownList-Child {
                                 // font-size: 10px !important;
                              }
                           }
                        }
                        .view-text-container {
                           margin-bottom: 0;
                           // margin-top: 3px;
                           .view-text-label {
                              // font-size: 8px !important;
                           }
                        }
                        .view-sub-title-container {
                           min-height: 18px;
                           h6 {
                              // font-size: 8px !important;
                              line-height: normal;
                           }
                           .dropdown-menu {
                              min-width: unset !important;
                           }
                        }
                     }
                  }

                  .date-range-slider {
                     // padding-right: 0 !important;
                     .irs {
                        .irs-from,
                        .irs-to,
                        .irs-single {
                           // font-size: 8px !important;
                        }
                     }
                     ul {
                        li {
                           // font-size: 10px !important;
                           line-height: 1 !important;
                           button {
                              // font-size: 10px !important;
                           }
                        }
                     }
                  }
               }
            }
         }
         .itemListArea {
            // height: calc(100vh - (280px))!important;
            // height: calc(100vh - (250px))!important;
            padding-top: 1rem;
            height: calc(100vh - (215px)) !important;
            margin-right: -12px;
            padding-left: 0 !important;
            padding-right: 8px !important;
            overflow: hidden;
            overflow-y: scroll;
            position: relative;
            //padding-bottom:68px;

            .itemCardWrapper {
               .border-transparent {
                  border-color: transparent !important;
               }
               .border-bg-filter-bg-color {
                  border-color: $filter-bg-color !important;
               }
               .itemCard {
                  height: 120px;
                  border-color: transparent;
                  border-radius: 8px !important;
                  background: transparent !important;
                  .app-card-left {
                     border-top-left-radius: 8px;
                     border-bottom-left-radius: 8px;
                  }
                  .app-card-right {
                     border-top-right-radius: 8px;
                     border-bottom-right-radius: 8px;
                  }
                  .card-body {
                     .row {
                        height: 100%;
                     }
                  }
                  .card-background {
                     background: #efefef !important;
                  }
                  .feesReturnRiskWrapper {
                     display: flex;
                     align-items: center;
                     .search-fees-return-stats-padding {
                        height: 100%;
                        padding: 28px 0px 28px;
                     }
                     .search-risk-stats-padding {
                        height: 100%;
                        padding: 35px 0px;
                     }
                     .search-risk-stats-highlighting {
                        .fees-flag-color,
                        .returns-flag-color,
                        .risk-flag-color {
                           color: $tag-blue;
                        }
                     }
                     .w-100 {
                        display: grid;
                        height: 100%;

                        .fees-flag-color,
                        .returns-flag-color,
                        .risk-flag-color {
                           height: 100%;
                           img {
                              // width: 20px;
                           }
                           i {
                              // font-size: 14px;
                           }
                           .__val {
                              // font-size: 9px !important;
                           }
                           .__state {
                              // font-size: 8px !important;
                           }
                        }
                     }
                  }

                  .paddingTpBtLt {
                     padding-top: 0 !important;
                     padding-bottom: 0 !important;
                     padding-left: 0 !important;
                  }
                  .paddingTpBt {
                     padding-top: 0 !important;
                     padding-bottom: 0 !important;
                  }
                  .paddingTpBtRt {
                     padding-top: 0 !important;
                     padding-bottom: 0 !important;
                     padding-right: 0 !important;
                  }

                  .selectBtn {
                     padding: 5px;
                     padding: 8px 15px !important;
                     font-size: 12px !important;
                     border-radius: 30px;
                     line-height: 1.4;
                     background: transparent;
                     border-color: $ssf-orange-d1;
                     color: $ssf-orange-d1;
                     white-space: nowrap;
                     &:hover {
                        background: $ssf-orange-d1;
                        color: #fff;
                     }
                     &.btn-unselect {
                        color: #fff;
                        background-color: #807f7f;
                        border-color: #807f7f;
                     }
                  }

                  .chart-block {
                     .primaryTextStyle {
                        left: 50%;
                     }
                     .highlightTooltip {
                        // font-size: 10px;
                     }
                     .guage-chart-wrapper {
                        .guage-chart-details {
                           .col {
                              // font-size: 9px !important;
                           }
                        }
                     }
                     .flip-items {
                        li {
                           text-align: center;
                           h6 {
                              // font-size: 10px !important;
                           }
                        }
                     }
                  }
               }
               &:last-child {
                  // margin-bottom: 48px;
               }
            }

            &::-webkit-scrollbar {
               // display: none !important;
               // width: 0 !important;
            }
         }
      }

      #investment {
         .nav-justified .nav-link {
            cursor: pointer;
            &:not(.active) {
               color: inherit;
               background-color: rgba(255, 255, 255, 0.5);
            }
            &.active {
               color: #fff;
            }
         }
      }
      .row.compact {
         margin-left: -0.25rem;
         margin-right: -0.25rem;
         & > [class*="col-"] {
            padding-left: 0.25rem;
            padding-right: 0.25rem;
         }
      }

      .bg-wave1 {
         background: no-repeat bottom url("/assets/images/wave-1.png")
            transparent;
         background-size: 100%;
      }
      .bg-wave2 {
         background: no-repeat bottom url("/assets/images/wave-2.png")
            transparent;
         background-size: 100%;
      }

      .tx-c1 {
         color: $c1;
      }
      .tx-c2 {
         color: $c2;
      }
      .tx-c3 {
         color: $c3;
      }
      .fw-b {
         font-weight: bold;
      }
      .fw-n {
         font-weight: normal;
      }

      .fs-l1 {
         font-size: 1.75rem !important;
      }
      .fs-m1 {
         font-size: 1rem !important;
      }
      .fs-s1 {
         font-size: 0.875rem !important;
      }
      .nav-pills .nav-link.active {
         background-color: $c4;
      }
      .header.dropdown,
      .btn-link {
         font-size: 0.875rem !important;
         color: $c1;
         font-family: "Roboto", sans-serif !important;
      }
      .sfmodal-comb-drpdwn {
         font-size: 1rem !important;
         color: $c8;
         font-family: inherit !important;

         &:hover {
            color: $c7 !important;
         }
      }
      .sfmodal-date,
      .sfmodal-unit {
         font-size: 0.75rem !important;
         color: $c6;
         font-family: inherit !important;

         &:hover {
            color: $c7 !important;
         }
      }
      .mx-0p5 {
         margin-left: 0.125rem !important;
         margin-right: 0.125rem !important;
      }

      .bg-c5 {
         background-color: $c5 !important;
      }

      .ps-wrap {
         margin-right: -10px;
         padding-right: 10px;
         position: relative;
         height: 511px;
         .ps__rail-y {
            width: 4px;
         }
         .ps__thumb-y {
            right: 0px;
         }
      }
   }

   .summaryDrawerWrapper {
      z-index: 10;
      .ant-drawer-header {
         padding-bottom: 0;
      }
      .ant-drawer-right {
         .ant-drawer-content-wrapper {
            right: -14px !important;
         }
      }
      .summaryDrawerContainer {
         height: calc(100vh - 72px) !important;
      }

      .ant-drawer-title {
         padding-left: 5px;
         padding-right: 5px;
         font-size: 18px;
         line-height: 30px;
         @media only screen and (min-width: 1024px) and (max-width: 1439px) {
            font-size: 1rem !important;
         }

         @include respond(tab) {
            font-size: 1rem !important;
         }
      }
      .ant-drawer-body {
         padding-top: 0;
         padding-bottom: 0;
      }
      .content-head-row {
         margin: 0;
         padding: 0px 15px;
         @media only screen and (min-width: 1024px) and (max-width: 1439px) {
            // margin: 0px 0px 5px !important;
         }

         @include respond(tab) {
            // margin: 0px 0px 5px !important;
         }
         .col-header-wrap {
            font-family: Roboto;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            // line-height: 1.33;
            letter-spacing: -0.14px;
            text-align: right;
            color: #bababb;
            @media only screen and (min-width: 1024px) and (max-width: 1439px) {
               font-size: 0.6rem !important;
            }
            @include respond(tab) {
               font-size: 0.6rem !important;
            }
         }
         .col-header-current-stats {
            position: relative;
            padding-left: 10px;
            padding-right: 5px;
            &:before {
               content: "";
               position: absolute;
               left: 0;
               top: 12px;
               bottom: 0;
               width: 6px;
               height: 6px;
               margin: 0 auto;
               background: $c1;
            }
         }
         .col-header-enhanced-stats {
            position: relative;
            padding-left: 10px;
            &:before {
               content: "";
               position: absolute;
               left: 0;
               top: 12px;
               bottom: 0;
               width: 6px;
               height: 6px;
               margin: 0 auto;
               background: $c4;
            }
         }
      }
      .portfolio-impact-card-footer-wrapper {
         // position: absolute;
         // left: 0;
         // right: 0;
         // width: 100%;
         // padding: 12px 24px;
         .portfolio-impact-card-footer-text {
            margin: 0;
            font-family: "Open Sans";
            font-size: 12px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.42;
            letter-spacing: -0.14px;
            text-align: left;
            color: #bababb;
         }
      }
   }
   .summary-block {
      padding: 1rem 0 1rem;
      border-bottom: 1px solid $border-color-opacity;
      &:last-child {
         border-bottom: 0px;
      }
      &:first-child {
         padding-top: 0px;
      }

      .dark-active-color {
         color: $dark-active-color;
      }

      .element-wrap {
         .label {
            margin-bottom: 1rem;
            font-family: Roboto;
            font-size: 15px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.22;
            letter-spacing: -0.22px;
            text-align: left;
            color: $primary-color;
         }
         .content-wrap {
            margin-bottom: 1rem;
            line-height: 1;
            .year {
               margin-bottom: 1rem;
               padding-left: 20px;
               padding-right: 20px;
               font-family: Roboto;
               font-size: 11px;
               font-weight: normal;
               font-stretch: normal;
               font-style: normal;
               line-height: 1.17;
               letter-spacing: -0.14px;
               text-align: left;
               color: $dark-active-color;
            }
            .tag-year {
               height: 18px;
               font-family: Roboto;
               font-size: 14px;
               font-weight: normal;
               font-stretch: normal;
               font-style: normal;
               line-height: 1.19;
               letter-spacing: -0.19px;
               text-align: left;
               color: $sub-header-color;
            }
            .value-text {
               margin-top: -2px;
               margin-bottom: 0.6rem;
               font-family: Roboto;
               font-size: 14px;
               font-weight: normal;
               font-stretch: normal;
               font-style: normal;
               line-height: 1.19;
               letter-spacing: -0.19px;
               text-align: left;

               .ant-tag {
                  border-radius: 15px;
                  padding-left: 20px;
                  padding-right: 20px;
                  margin-right: 20px;
               }
            }
            .row-bg-left {
               border-top-left-radius: 15px;
               border-bottom-left-radius: 15px;
            }
            .row-bg-right {
               border-top-right-radius: 15px;
               border-bottom-right-radius: 15px;
            }
         }
      }
   }

   .portfolio-impact-card {
      margin-bottom: 30px;
      padding: 15px;
      border-radius: 10px;
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
      background-color: #fbfbfb;
      @media only screen and (min-width: 1024px) and (max-width: 1439px) {
         margin-bottom: 1rem !important;
         padding: 0.7rem !important;
      }

      @include respond(tab) {
         margin-bottom: 1rem !important;
         padding: 0.7rem !important;
      }

      .ant-row {
         margin: 0 0 20px 0;
         &:last-child {
            margin: 0 !important;
         }
         @media only screen and (min-width: 1024px) and (max-width: 1439px) {
            margin: 0 !important;
         }

         @include respond(tab) {
            margin: 0 !important;
         }
         .ant-col {
            display: flex;
            height: 30px;
            align-items: center;
            justify-content: flex-start;
            font-family: Roboto;
            font-size: 18px;
            font-weight: 500;
            line-height: 1.33;
            letter-spacing: -0.22px;
            text-align: left;
            @media only screen and (min-width: 1024px) and (max-width: 1439px) {
               font-size: 0.8rem !important;
            }

            @include respond(tab) {
               font-size: 0.8rem !important;
            }
            &:first-child {
               font-family: Roboto;
               font-size: 14px;
               font-weight: 500;
               line-height: 1.36;
               letter-spacing: -0.17px;
               text-align: left;
               color: $label-black-color;
               text-transform: uppercase;
               @media only screen and (min-width: 1024px) and (max-width: 1439px) {
                  font-size: 0.6rem !important;
               }

               @include respond(tab) {
                  font-size: 0.6rem !important;
               }
            }
         }
      }
      .up-down-chart-txt {
         margin-top: 30px !important;
      }
      .up-down-capture-dropdown {
         position: absolute;
         right: 0;
         width: 150px;
         text-align: right;
         border: none !important;
         .ant-select-selector {
            background: transparent;
            border: none !important;
            font-size: 12px !important;
            box-shadow: none !important;
         }
         .ant-select-arrow {
            font-size: 9px !important;
            top: 51% !important;
         }
      }
      .ant-select-dropdown {
         @media only screen and (min-width: 1024px) and (max-width: 1439px) {
            font-size: 0.6rem !important;
         }
         @include respond(tab) {
            font-size: 0.6rem !important;
         }
         .ant-select-item {
            @media only screen and (min-width: 1024px) and (max-width: 1439px) {
               padding: 5px 6px !important;
               font-size: 0.6rem !important;
            }
            @include respond(tab) {
               padding: 5px 6px !important;
               font-size: 0.6rem !important;
            }
         }
      }
      &.return-card {
         .ant-select {
            height: 100%;
            @media only screen and (min-width: 1024px) and (max-width: 1439px) {
               font-size: 0.8rem !important;
            }
            @include respond(tab) {
               font-size: 0.8rem !important;
            }

            .ant-select-selector {
               height: 100% !important;
               line-height: 1.33;
               padding-right: 20px;
               text-align: right;
               @media only screen and (min-width: 1024px) and (max-width: 1439px) {
                  padding-left: 0 !important;
                  font-size: 0.6rem !important;
               }
               @include respond(tab) {
                  padding-left: 0 !important;
                  font-size: 0.6rem !important;
               }

               .ant-select-selection-search {
                  line-height: 1.33 !important;
                  height: 100% !important;

                  .ant-select-selection-search-input {
                     height: inherit !important;
                     opacity: 0;
                  }
               }
               .ant-select-selection-item {
                  height: 100% !important;
                  padding: 0;
                  line-height: 18px !important;
                  @media only screen and (min-width: 1024px) and (max-width: 1439px) {
                     line-height: 18px !important;
                     padding: 0 !important;
                  }

                  @include respond(tab) {
                     line-height: 18px !important;
                     padding: 0 !important;
                  }
               }
               .ant-select-selection {
                  height: 100%;
                  border: 0;
                  background: transparent;
                  box-shadow: unset;
                  font-size: 12px;
                  border-bottom: 1.3px solid #bababb;
                  border-radius: 0;
                  .ant-select-selection__rendered {
                     height: 100%;
                     margin: 0;
                     padding: 0px 22px 0px 3px;
                     display: flex;
                     align-items: center;
                     justify-content: flex-start;
                     line-height: normal;
                     color: #1e5f91;
                  }
               }
            }
            .ant-select-arrow {
               top: 50%;
               right: 0;
               @media only screen and (min-width: 1024px) and (max-width: 1439px) {
                  top: 13px !important;
                  right: 0;
                  width: 8px;
                  height: 8px;
                  font-size: 0.45rem !important;
               }
               @include respond(tab) {
                  top: 13px !important;
                  right: 0;
                  width: 8px;
                  height: 8px;
                  font-size: 0.45rem !important;
               }
            }
         }
      }
   }
   .barchart-block {
      width: 100%;
      height: 150px;
   }

   .linechart-block {
      width: 100%;
      height: 100px;
      // margin: 15px 0 60px;
   }

   .up-down-capture-dropdown {
      width: 100%;
      text-align: right;
   }
   .up-down-capture-menu {
      border-radius: 10px !important;
      * {
         &::-webkit-scrollbar {
            width: 4px !important;
            border-radius: 6px;
            background-color: transparent;
         }

         &::-webkit-scrollbar-thumb {
            width: 4px !important;
            border-radius: 6px;
            background-color: $in-active-color;
         }
      }
   }
}
