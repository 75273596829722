.phone-input-container {
   .is-invalid {
      border-color: #dc3545 !important;
   }
   display: flex;
   justify-content: space-between;
   .dropdown-element .dropdown-wrapper .dropdown-toggle::after {
      top: 0.8em;
   }
   .dropdown-wrapper {
      width: 50%;
      div {
         max-height: 300px;
         overflow-y: auto;
      }
      .toggle {
         width: 100%;
         // background-color: #fff!important;
         border-radius: 0;
         color: #2f2f2f !important;
         border: none;
         text-align: start;
         border-bottom: 1px solid #2f2f2f;
         display: flex;
         box-shadow: none !important;
         align-items: center;
         justify-content: space-between;
         overflow: hidden;
         &:hover {
            outline: none;
         }
         &:focus {
            outline: none;
            box-shadow: none;
         }
      }
      .disabled {
         cursor: not-allowed;
      }
   }
   .input-area {
      height: auto;
      padding: 7px 0;
      font-size: 1rem !important;
      color: #4a4a4a;
      border: 0;
      border-radius: 0;
      border-bottom: 1px solid;
      border-bottom-color: #4a4a4a;
      transition: 0.2s ease all;
      &:hover {
         outline: none;
      }
      &:focus {
         outline: none;
         box-shadow: none;
      }
   }
   .phone-input-container input {
      position: absolute;
      left: 20px;
      width: auto;
      top: -2px;
   }
   .code-input {
      width: 29%;
      float: left;
   }
   .phone-input {
      width: 70% !important;
   }
   .typeahead-field {
      height: 20px;
      width: 100%;
      border: 0px;
   }
}

.phoneContainer {
   .phone-input-container {
      max-width: 200px;
      max-height: 24px;
      input {
         background: none !important;
      }
      input[type="text"] {
         background: none !important;
         height: 24px;
      }
      .code-input {
         height: 24px;
      }
      .dropdown-toggle::after {
         margin-left: -0.745em;
      }
      button {
         background: none !important;
         height: 24px;
         padding: 0px;
      }
   }
}
