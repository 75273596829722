.switch {
   position: relative;
   display: inline-block;
   width: 34px;
   height: 14px;
}

.switch input {
   opacity: 0;
   width: 0;
   height: 0;
}

.slider {
   position: absolute;
   cursor: pointer;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background-color: #ccc;
   -webkit-transition: 0.4s;
   transition: 0.4s;
}

.slider:before {
   position: absolute;
   content: "";
   height: 20px;
   width: 20px;
   left: -5px;
   bottom: -2px;
   background: #f1f1f1;
   box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
   -webkit-transition: 0.4s;
   transition: 0.4s;
}

input:checked + .slider:before {
   background: #f36300;
}

input:checked + .slider {
   opacity: 0.9;
   background: #f36300;
}

input:focus + .slider {
   box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
   -webkit-transform: translateX(26px);
   -ms-transform: translateX(26px);
   transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
   border-radius: 34px;
}

.slider.round:before {
   border-radius: 50%;
}
