.allocation-page-container {
   width: 100%;
   min-width: 994px;
   // height: 100%;
   // min-height: 80vh !important;
   .track-record-container {
      background: #fff;
      .track-record {
         width: 90%;
         max-width: 1440px;
         margin: 0 auto;
         padding: 0rem 1rem 3.5rem;
      }
   }

   .allocation-page-header-container {
      padding: 0 15px;
      background: #fff;
      .allocation-page-header-wrapper {
         // width: 85%;
         width: 95%;
         max-width: 1440px;
         margin: 0 auto;
         padding: 4rem 2rem 2rem 2rem;
         padding-top: 4%;
         .navigation-button {
            margin-bottom: 0;
            padding: 0;
            display: inline-flex;
            align-items: center;
            color: $navigation-text-color;
            span {
               padding-left: 2px;
            }
         }
         .allocation-page-header-title {
            padding: 15px 8px 0 8px;
            font-family: $secondary-font;
            font-style: normal;
            font-weight: 500;
            font-size: $font-size-30px;
            color: $black;
            letter-spacing: 0.01em;
         }
      }
   }

   .allocation-page-content-wrapper {
      // width: 85%;
      padding: 2rem;
      min-width: 994px;
      // width: 78%;
      max-width: 1330px;
      margin: 0 auto;
      justify-content: center;
      .allocation-page-content-menu-label {
         font-family: $secondary-font;
         font-style: normal;
         font-weight: 500;
         font-size: $font-lg-px;
         color: $black;
      }

      .allocation-page-content-menu-wrapper {
         width: 100%;
         .edit-portfolio-button {
            text-align: right;
            button {
               font-family: $primary-font;
               font-style: normal;
               font-weight: 600;
               font-size: $font-xs-m-px;
               border-radius: 30px;
               height: 40px !important;
               background: $primary-color;
               color: $white-color;
               i {
                  margin-right: 5px;
               }
            }
         }
      }
      .allocation-page-portfolio-list-header {
         width: 100%;
         .ant-col {
            font-family: $primary-font;
            font-style: normal;
            font-weight: 400;
            font-size: $font-lg-m;
            text-align: center;
            color: #797979;
         }
      }
      .allocation-page-portfolio-list-wrapper {
         width: 100%;
         .allocation-page-portfolio-list {
            height: 100%;
            .allocation-page-footer-note {
               font-style: italic;
               font-size: $font-size-16px;
               font-weight: 300;
            }
            .allocation-page-portfolio-card {
               height: 94px;
               margin-bottom: 10px;
               align-items: center;
               background: #ffffff;
               color: $black;
               .allocation-page-portfolio-id {
                  height: 100%;
                  padding-right: 2rem;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  font-family: $secondary-font;
                  font-style: normal;
                  font-weight: 600;
                  font-size: $font-size-15px;

                  label {
                     margin: 0;
                     // opacity: 48%;
                     font-family: $secondary-font;
                     font-style: normal;
                     font-weight: 600;
                     font-size: $font-size-15px;
                     line-height: 1;
                     color: #000;
                  }
                  span {
                     color: rgba(0, 0, 0, 0.48);
                     font-family: $secondary-font;
                     font-size: $font-size-14px;
                     font-weight: 500;
                     line-height: 16px;
                  }
               }
               .allocation-page-portfolio-name-block {
                  height: 100%;
                  padding-left: 2rem;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  justify-content: center;
                  .sponsor-logo {
                     width: auto;
                     height: 20px;
                  }
                  .sponsor-logo-name {
                     width: 90%;
                     white-space: nowrap;
                     text-overflow: ellipsis;
                     overflow: hidden;
                  }
                  .fund-name {
                     margin: 0;
                     padding: 3px 0;
                     font-family: $secondary-font;
                     font-style: normal;
                     font-weight: 400;
                     font-size: $font-size-14px;
                     line-height: 1.2;
                     overflow: hidden;
                     text-overflow: ellipsis;
                     white-space: nowrap;
                     color: $black;
                  }
               }
               .allocation-page-portfolio-rating-block {
                  align-items: center;
                  justify-content: center;
                  font-family: $secondary-font;
                  font-style: normal;
                  font-weight: 400;
                  font-size: $font-size-16px;
                  padding: 0px 5px;
                  .ant-row {
                     width: 100%;
                     justify-content: center;
                  }
                  .portfolio-rating {
                     padding: 0 8px;
                     // margin-top: -3px;
                     // margin-left: 15px;
                     // margin-right: 15px;
                  }
                  .ant-rate {
                     font-size: $font-size-14px;
                  }
                  .fa-pencil {
                     color: $primary-color;
                     &:hover {
                        cursor: pointer;
                     }
                  }
               }
               .allocation-page-portfolio-allocation-block {
                  height: 100%;
                  padding-right: 2rem;
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                  font-family: $secondary-font;
                  font-style: normal;
                  font-weight: 400;
                  font-size: $font-size-18px;
               }
               &:last-child {
                  margin: 0;
               }
               p {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  width: 80%;
               }
            }
         }
         .allocation-page-portfolio-chart-wrapper {
            height: 100%;
         }
         .custom-scroll {
            margin-top: 9px !important;
            margin-bottom: -9px !important;
            padding-right: 4px !important;
            padding-top: 0 !important;
            padding-bottom: 9px !important;
         }
      }
   }
}
