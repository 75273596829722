.body-bg-white {
   background-color: $bg-color-light !important;
}

.enhancer-onboard-screen-wrapper-loader-screen-overlay {
   width: 100% !important;
   height: inherit !important;
   background-color: $bg-color-light !important;
   .overlay {
      background-color: $bg-color-light !important;
   }
}

.enhancer-onboard-screen-dropdown {
   ul {
      &:first-child {
         max-height: 250px;
         overflow: hidden !important;
         overflow-y: scroll !important;
         border-radius: 10px !important;
      }
   }
}

.portfolioMenuListWrapper {
   .ant-dropdown-menu-item:hover,
   .ant-dropdown-menu-submenu-title:hover {
      background-color: transparent !important;
   }
   .ant-dropdown-menu {
      border-radius: 10px !important;
      max-height: 200px;
      .ant-dropdown-menu-item {
         padding: 0 !important;
         font-family: "Open Sans";
         font-size: $font-xs-m-px;
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         letter-spacing: normal;
         text-align: left;
         color: $black-color !important;
         font-size: $font-sm-s;
         display: flex;
         flex-direction: row;
         margin-bottom: 2px;

         .upload_btn {
            width: 100%;
            position: relative;
            padding: 5px 15px;
            color: $active-color;
            font-weight: $font-weight-600;
            &:hover {
               background-color: $bg-color-hover !important;
            }
         }

         .portfolio_name-label {
            width: 100%;
            position: relative;
            padding: 5px 15px;
            &:hover {
               background-color: $bg-color-hover !important;
            }
         }
         .delete-icon {
            padding: 5px 12px;
            color: $in-active-color;

            &:hover {
               color: $red-color;
               background: $red-color-opacity;
            }
         }
      }
   }
}

.enhancer-onboard-screen-wrapper {
   // position: relative;
   width: 78%;
   height: 100%;
   // min-height: calc(100vh - 60px);
   min-height: 80vh !important;
   max-width: 1200px;
   margin: 0 auto;
   display: flex;
   flex-direction: row;
   // justify-content: center;
   padding: 6rem 2rem;
   min-width: 994px;
   // min-height: 600px !important;

   .headline {
      margin-right: 8px;
      // margin-top: 7%;
      display: flex;
      flex-direction: column;
      margin-bottom: 4%;
      padding-left: 11px;
      // height: 162px;
      h1 {
         color: $label-black-color;
         margin-bottom: 4px;
         margin-left: -3px;
         font-family: $secondary-font;
         font-weight: $font-weight-light;
         font-size: 60px !important;
      }
      span {
         font-size: $font-size-20px;
         color: $label-black-color;
         font-family: $secondary-font;
      }
   }

   .content-body {
      width: 100%;
      margin: 0px 0 55px;
      padding: 0;
      border-radius: 10px;

      &:last-child {
         margin-bottom: 0;
         padding-top: 0;
      }

      .content-title {
         font-family: $secondary-font;
         font-size: $font-size-30px;
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.23;
         letter-spacing: -0.36px;
         color: $label-black-color;
         // text-align: center;
      }
      .sub-txt {
         font-family: $primary-font;
         margin-bottom: 20px;
         font-size: 13px;
         color: #545454;
         font-weight: 400;
      }

      .overview-items-wrapper {
         width: 100%;
         display: flex;
         // justify-content: center;

         .overview-item {
            width: 240px;
            // height: 250px;
            display: flex;
            // flex-direction: column;
            justify-content: center;
            // align-items: center;
            // margin-right: 40px;
            // border: solid 2px #5eade9;
            // border-radius: 10px;
            // background-color: #ffffff;
            margin-left: -12px;
            &:last-child {
               margin: 0;
               margin-left: 20px;
            }

            img {
               height: 72px;
               margin-bottom: 17px;
            }

            .overview-item-label {
               font-family: $secondary-font;
               font-size: 20px;
               font-weight: 500;
               font-stretch: normal;
               font-style: normal;
               line-height: 1.26;
               letter-spacing: normal;
               // text-align: center;
               // filter: blur(10px);
               color: #4a4a4a;
               white-space: nowrap;
               height: 17px;
               padding-left: 0;
            }
            .overview-item-count {
               font-family: $secondary-font;
               font-size: 48px;
               font-weight: 300;
               font-stretch: normal;
               font-style: normal;
               line-height: 1.16;
               letter-spacing: normal;
               // text-align: center;
               color: #4a4a4a;
               // filter: blur(10px);
               padding-left: 0;
            }
         }
      }

      .buttons-items-wrapper {
         margin: 0 auto;
         display: flex;
         //justify-content: space-evenly;
         .disabled {
            border-color: $indicator-color !important;
            cursor: not-allowed;
         }
         .buttons-item {
            width: 222px;
            height: 45px;
            margin: 0 15px 30px 0;
            padding: 2px 30px;
            display: flex;
            flex-direction: row;
            align-items: center;
            color: #5eade9;
            border: solid 2px #5eade9;
            border-color: #5eade9;
            border-radius: 30px;
            padding: 2px 0 2px 24px;
            cursor: pointer;
            transition: all 0.3s;

            img {
               width: 32px;
               height: 25px;
            }

            .buttons-item-label {
               width: 100%;
               position: relative;
               padding-right: 24px;

               font-family: $secondary-font;
               font-size: 17px;

               font-size: 15px;
               font-weight: bold;
               font-stretch: normal;
               font-style: normal;
               line-height: 1.14;
               letter-spacing: normal;
               text-align: left;
               color: #5eade9;

               .anticon-down {
                  position: absolute;
                  right: 18px;
                  bottom: 0;
                  vertical-align: 0 !important;
               }
            }

            &:last-child {
               // margin-right: 0;
               margin-right: auto;
            }
            // &:hover, &:focus {
            //   background-color: $dark-blue-color;
            //   .buttons-item-label {
            //     color: #fff;
            //   }
            // }
         }
         .active {
            color: #fff;
            background-color: $dark-blue-color;
         }
      }

      .cursor {
         cursor: pointer;
      }

      .tabs-block {
         display: flex;
         flex-direction: row;
         justify-content: space-evenly;

         .tabs {
            width: 292px;
            display: flex;
            flex-wrap: wrap;
            word-break: break-all;
            flex-direction: row;
            margin: 0 30px 5px;
            padding: 3rem;
            border-radius: 5px;
            background-color: $dark-active-color;
            .ant-col {
               &:last-child .block {
                  border: 0;
               }
            }

            .box {
               width: 100%;
               display: flex;
               flex-direction: column;
               justify-content: center;
               text-align: center;
               align-items: center;

               .divider {
                  width: 50px;
                  min-width: 50px;
               }

               .imgBlock {
                  text-align: center;

                  img {
                     width: 40px;
                     height: 40px;
                  }
               }
               .detailsBlock {
                  .head {
                     font-family: $secondary-font;
                     font-size: $font-size-14px;
                     font-weight: bold;
                     font-stretch: normal;
                     font-style: normal;
                     text-align: center;
                     color: white;
                     font-size: 16px;
                     cursor: pointer;
                  }
                  .sub-head {
                     font-family: $secondary-font;
                     font-stretch: normal;
                     font-style: normal;
                     text-align: center;
                     color: white;
                     font-size: 16px;
                     line-height: 19px;
                     cursor: pointer;
                     word-break: break-word;

                     .anticon-down {
                        vertical-align: 0;
                     }
                  }
                  .disable {
                     cursor: not-allowed;
                  }
                  .underline {
                     cursor: pointer;
                     text-decoration: underline;
                  }
               }
            }
         }
      }
   }
   .buttons-block {
      // position: relative;
      // bottom: 54px;
   }
}

.disabled {
   cursor: not-allowed !important;
}

.select-portfolio {
   font-size: 24px;
   font-weight: 500;
   padding-bottom: 17px;
   font-family: "Roboto";
   color: $dark-active-color;
   .select-underline {
      width: 102px;
      margin-top: 7px !important;
      height: 2px;
      color: $label-black-color;
      background-color: $dark-active-color;
   }
}

.market-place-dropdown {
   .active-label {
      font-family: $secondary-font;
      // font-size: $font-size-14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: $dark-blue-color;
   }

   .ant-dropdown-menu {
      border-radius: 10px !important;
      max-height: 220px !important;
   }
}

.default-label {
   // .ant-dropdown-menu-submenu-title {
   //   font-family: $primary-font;
   //   font-size: $font-size-14px;
   //   font-weight: normal;
   //   font-stretch: normal;
   //   font-style: normal;
   //   line-height: 1.36;
   //   letter-spacing: normal;
   //   text-align: left;
   //   color: $label-black-color;
   // }
}

.market-place-submenu-initial {
   margin: 0 !important;
   margin-left: 3px !important;
   padding: 0 !important;
   overflow: visible !important;
   ul:first-child {
      margin: 0 !important;
      margin-left: 2px !important;
      padding: 0 !important;
      overflow: visible !important;
   }
}

.market-popup {
   width: max-content;
   max-width: 350px;
   left: 45px !important;
   top: 33px !important;
   margin: 0 !important;
   padding: 0 !important;
   overflow: visible !important;

   .market-popup-wrapper {
      position: relative;
      max-height: 190px !important;
      overflow: hidden !important;
      overflow-y: auto !important;
      font-size: $font-size-14px !important;

      &::-webkit-scrollbar {
         width: 4px !important;
         border-radius: 6px;
         background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
         width: 4px !important;
         border-radius: 6px;
         background-color: $in-active-color;
      }
   }
}

.market_name-label {
   padding: 5px 10px;
   cursor: pointer;
   font-family: $primary-font !important;
   font-size: $font-size-14px !important;
   font-weight: normal;
   font-stretch: normal;
   font-style: normal;
   letter-spacing: normal;
   text-align: left;
   color: #4a4a4a !important;

   &:hover {
      background-color: #dff1ff !important;
   }
}

.dropdown-style {
   font-family: $primary-font !important;
   font-size: 14px !important;
   font-weight: 400;
   font-stretch: normal;
   font-style: normal;
   letter-spacing: normal;
   text-align: left;
   color: #4a4a4a !important;
   > div {
      color: #4a4a4a !important;
   }
}

// .market-popup-head {
//   overflow: visible !important;
//   ul:first-child {
//     margin: 0 !important;
//     // padding: 0 !important;
//     overflow: visible !important;
//     border-radius: 10px;
//     // max-height: 200px;
//     // overflow: hidden !important;
//     // overflow-y: scroll !important;
//     background: #fff;
//   }
// }

.dropdown-button-wrapper {
   .ant-dropdown-menu {
      max-height: 200px;
      overflow: hidden !important;
      overflow-y: scroll !important;
      background: #fff;
      border-radius: 10px !important;
   }
}

@media only screen and (min-width: 1440px) {
   .enhancer-onboard-screen-wrapper {
      .headline {
         span {
            // white-space: nowrap;
         }
      }
   }
}

@media only screen and (min-width: 1920px) {
   .enhancer-onboard-screen-wrapper {
   }
}

@media only screen and (min-width: 980px) and (max-width: 1212px) {
   .enhancer-onboard-screen-wrapper {
      .content-body.buttons-block {
         bottom: 0;
      }
   }
}

.client-name-edit-modal {
   .client-name-edit-form-wrapper {
      max-width: unset;
      input,
      .client-name-edit-input {
         height: auto;
         padding: 16px 0 6px 0;
         line-height: normal;
         border: 0;
         box-shadow: none;
         border-bottom: 1px solid #1e5f91;
      }
      .cta-wrapper {
         .btn-primary {
            color: #fff !important;
            background-color: #1e5f91 !important;
            border: 1px solid #1e5f91 !important;
         }
         .btn-secondary {
            color: #1e5f91 !important;
            background-color: #fff !important;
            border: 1px solid #1e5f91 !important;
         }
      }
   }
}

.community-hero-wrapper {
   padding: 4rem 8rem;
   background-color: $white-color !important;
   display: flex;
}

.top-rank-member-wrapper {
   background-color: $background-color-light-blue !important;
   height: fit-content !important;
}
.sponsored-searches-wrapper {
   width: 100%;
   margin: auto;
   max-width: 1322px;
   background-color: $background-color-light-blue !important;
   height: fit-content !important;

   @media (min-width: 991px) {
      padding: 1.8rem;
   }
}

.mobile-navbar-wrapper {
   display: none;
   @media (max-width: 991px) {
      display: block;
   }
}
.mobile-navbar-home {
   display: none;
   @media (max-width: 991px) {
      display: block;
   }
}
