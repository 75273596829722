@font-face {
   font-family: "Font Awesome 5 SSF";
   font-style: normal;
   font-weight: 400;
   src: url("/assets/css/fa-ssf-1.woff?5") format("woff");
}

$fassf-size: 32px;
.fassf {
   font-family: "Font Awesome 5 SSF";
   width: $fassf-size;
   height: $fassf-size;
   line-height: $fassf-size + 4;

   &.fa-hand-holding-usd-1:before {
      content: "\e000";
   }
   &.fa-hand-holding-usd-2:before {
      content: "\e001";
   }
   &.fa-hand-holding-usd-3:before {
      content: "\e002";
   }
   &.fa-tags-1:before {
      content: "\e004";
   }
   &.fa-tags-2:before {
      content: "\e005";
   }
   &.fa-tags-3:before {
      content: "\e006";
      margin-left: -3px;
   }
   &.fa-coins-1:before {
      content: "\e008";
   }
   &.fa-coins-2:before {
      content: "\e009";
   }
   &.fa-coins-3:before {
      content: "\e00a";
   }
   &.fa-chart-line-1:before {
      content: "\e00c";
   }
   &.fa-chart-line-2:before {
      content: "\e00d";
   }
   &.fa-chart-line-3:before {
      content: "\e00e";
   }
   &.fa-heart-rate-1:before {
      content: "\e010";
   }
   &.fa-heart-rate-2:before {
      content: "\e011";
   }
   &.fa-heart-rate-3:before {
      content: "\e012";
   }
   &.fa-heart-rate-alt-1:before {
      content: "\e014";
   }
   &.fa-heart-rate-alt-2:before {
      content: "\e015";
   }
   &.fa-heart-rate-alt-3:before {
      content: "\e016";
   }
}
.fassf-hw {
   width: $fassf-size;
   height: $fassf-size;
   line-height: $fassf-size + 4;
}
