$thead-gutter: 14px 8px 8px 8px;
$row-height: 58px;

.portfolio-table-view-container {
   padding: 20px 0 0 0;

   .portfolio-table-view-wrapper {
      .ant-table-wrapper {
         // margin-bottom: 20px;
      }
      .ant-table-placeholder {
         height: 0px !important;
      }
      .editable-row {
         position: relative;
      }
      .ant-table {
         .ant-table-column-sorter-up.active,
         .ant-table-column-sorter-down.active {
            color: #1d6091 !important;
         }
         .editable-enable-overflow {
            overflow: unset !important;
         }
         .td-disable-box {
            visibility: hidden !important;
         }
         .editable-enable-overflow + td > div {
            // visibility: hidden !important;
         }

         .ant-form-item {
            .ant-form-item-control {
               display: flex;
               align-items: center;
               justify-content: center;
               .ant-form-item-control-input {
                  width: 73%;

                  height: 100%;
                  min-height: auto;
                  position: relative;
                  .ant-form-item-control-input-content .ant-input {
                     // height: 28px;
                     height: 100%;
                     padding: 4px;
                     //color: $black;
                     color: #5488c9 !important;
                     text-align: center;
                     border-radius: 5px;
                     border: 1px solid #5488c9;
                     //border: 1px solid $black;
                     &:focus {
                        color: $navigation-text-color !important;
                        border: 1px solid $navigation-text-color;
                     }
                  }
               }
            }
            .ant-form-item-explain {
               position: absolute;
               bottom: -12px;
               font-size: 10px;
               line-height: 1;
               min-height: auto;
            }
         }

         .custom-ticker-search-box-wrapper {
            // height: 35px;
            height: 100%;
            position: absolute;
            top: 0px;
            left: 0;
            width: 325px;
            z-index: 9999;
            .ant-form-item-control {
               height: 100%;
               .ant-form-item-control-input-content {
                  height: 100%;
                  .ant-input {
                     height: 100%;
                  }
               }
            }
         }

         // .editable-box-container {
         //   // height: 35px;
         //   height: 100%;
         //   .ant-form-item-control {
         //     .ant-form-item-control-input {
         //       .ant-form-item-control-input-content {
         //         .ant-input {
         //           // height: 28px;
         //           height: 100%;
         //           color: $navigation-text-color !important;
         //           border: 1px solid $navigation-text-color;
         //         }
         //       }
         //     }
         //   }
         // }

         table {
            thead {
               background: $background-color-light-blue;
               tr {
                  th {
                     padding: $thead-gutter;
                     font-family: $secondary-font;
                     font-style: normal;
                     font-weight: 700;
                     font-size: 16px;
                     color: $black;
                     border: 0;
                     background: transparent;

                     .anticon-search {
                        font-size: 14px;
                     }
                     &:first-child {
                        text-align: left;
                     }
                  }
                  &:first-child {
                     th:last-child {
                        text-align: center;
                     }
                  }
                  th[title="Ticker"] {
                     text-align: left;
                  }
               }
            }
            tbody {
               tr {
                  height: 58px;
                  td,
                  span {
                     font-family: $primary-font;
                     font-size: 14px;
                     font-weight: 400;
                     font-stretch: normal;
                     font-style: normal;
                     line-height: 1.36;
                     letter-spacing: normal;
                     text-align: left;
                     color: $black;
                  }
                  td {
                     // border-bottom-color: #c5c5c5;
                     &:first-child {
                        padding-left: 25px;
                        text-align: left;
                     }
                     &:last-child {
                        padding-right: 25px;
                        text-align: right;
                        i {
                           cursor: pointer;
                        }
                     }
                     .ant-empty {
                        display: none !important;
                     }
                  }
                  td.ticker {
                     font-size: 15px;
                     font-weight: 600;
                     text-align: left;
                  }
                  td.weight,
                  td.values {
                     .input-placeholder-block {
                        padding: 4px 8px;
                        color: $black;
                        text-align: center;
                        border-radius: 5px;
                        border: 1px solid $black;
                     }
                  }
               }
               .ant-table-placeholder {
                  td {
                     padding: 0 !important;
                     border: 0 !important;
                  }
               }
            }
         }
      }
      .ant-pagination {
         position: absolute;
         top: -45px;
         right: 12px;
         .ant-pagination-item,
         .ant-pagination-prev,
         .ant-pagination-next,
         .ant-pagination-jump-prev,
         .ant-pagination-jump-next {
            min-width: 22px;
            height: 22px;
            font-size: 12px;
            line-height: 20px;
            &:hover,
            &:active,
            &:focus {
               .ant-pagination-item-link {
                  background: #fff;
                  color: #1d6091;
                  border-color: #1d6091;
                  box-shadow: 0 0 0 2px rgba(29, 96, 145, 0.1);
               }
            }
         }
         .ant-pagination-item-link {
            // margin-top: -1px;
         }
         .anticon {
            vertical-align: 0.2em;
            font-size: 10px;
         }
         .ant-pagination-item-active {
            border-color: #1d6091 !important;
            a {
               color: #1d6091 !important;
            }
         }
      }

      .custom-ticker-search-box-loader {
         position: absolute;
         z-index: 999;
         width: 100%;
         text-align: center;
         .anticon {
            line-height: 0 !important;
            color: $light-blue-color !important;
         }
      }

      .custom-ticker-search-box-wrapper {
         width: 100%;

         .ant-select {
            width: 100%;
            margin-bottom: 0px;
            box-shadow: none;
            border-radius: 0;
            .ant-select-selector {
               height: 35px;
               padding: 0 !important;
               border: 0 !important;
               background: transparent !important;
               box-shadow: none !important;
               border-radius: 0 !important;
               // border-bottom: 1px solid #c5c5c5 !important;
               .ant-select-selection-search {
                  width: 100%;
                  left: 0;
               }
               input {
                  height: 35px;
                  padding: 8px 8px !important;
               }
               .ant-select-selection-placeholder {
                  padding-left: 18%;
                  padding-right: 0;
                  text-align: center;
                  line-height: 35px;
               }
               .ant-select-selection-item {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding: 8px 8px;
                  .option-ticker {
                     width: 30%;
                     font-size: 15px;
                     font-weight: 600;
                     text-align: left;
                     color: $black;
                  }
                  .option-ticker-name {
                     width: 70%;
                     font-size: 14px;
                     font-weight: 600;
                     text-align: left;
                     font-weight: normal;
                     color: $black;
                  }
               }
            }
            .ant-select-arrow {
               right: 0 !important;
               .search-icon {
                  right: 0;
                  top: 5px;
                  font-size: 20px;
               }
            }
         }

         .custom-ticker-search-box-dropdown {
            // height: 120px;
            div[role="listbox"] + div {
               &::-webkit-scrollbar {
                  width: 4px !important;
                  border-radius: 6px;
                  background-color: transparent;
               }

               &::-webkit-scrollbar-thumb {
                  width: 4px !important;
                  border-radius: 6px;
                  background-color: $light-blue-color;
               }
            }
            .custom-ticker-search-list-option {
               .ant-select-item-option-content {
                  display: flex;
                  align-items: center;
                  .option-ticker {
                     font-size: 15px;
                     font-weight: 600;
                     text-align: left;
                     color: $black;
                  }
                  .option-ticker-name {
                     font-size: 14px;
                     text-align: left;
                     font-weight: normal;
                     color: $black;
                     white-space: nowrap;
                     text-overflow: ellipsis;
                     overflow: hidden;
                  }
               }
            }
         }
         .ant-select-dropdown {
            z-index: 999999;
         }
      }

      .add-new-ticker-label {
         height: $row-height;
         padding: 8px 8px 8px 40px;
         margin-bottom: 20px;
         span {
            font-size: 0.8rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            line-height: 1.5;
            color: $light-blue-color;
            cursor: pointer;
         }
      }

      .total-text {
         height: 100%;
         display: flex;
         align-items: center;
         font-style: normal;
         font-weight: 400;
         font-size: 16px;
         line-height: 19px;
         text-align: center;
         text-transform: uppercase;
         color: $total-blue-text;
         border: 0;
         background: transparent;
      }

      .add-new-ticker-container {
         width: 100%;
         height: $row-height;
         margin-bottom: 20px;
         display: block;
         .add-new-ticker-col {
            // height: $row-height;
            // padding: 4px 8px;
            // line-height: 2;
            // border-bottom: 1px solid #f0f0f0;
            .fa-plus {
               width: 100%;
               font-size: 16px;
               margin-right: 0;
               font-weight: 100;
               color: $light-blue-color;
               cursor: pointer;
            }
            .fa-times {
               font-weight: 500;
               color: $light-red-color;
               cursor: pointer;
            }
            .ant-select {
               width: 100%;

               .ant-select-selector {
                  height: 35px;

                  .ant-select-selection-search {
                     width: 100%;
                     left: 0;
                  }
                  input {
                     height: 100%;
                  }
                  .ant-select-selection-placeholder {
                     padding: 0 0 0 7px;
                     font-size: 16px;
                     text-align: left;
                     line-height: 35px;
                     color: $primary-color;
                     opacity: 1;
                  }
                  .ant-select-selection-item {
                     padding: 8px 8px;
                     .option-ticker {
                        width: 30%;
                        font-size: 15px;
                        font-weight: 600;
                        text-align: left;
                        color: $black;
                     }
                     .option-ticker-name {
                        width: 70%;
                        font-size: 14px;
                        text-align: left;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        font-weight: normal;
                        color: $black;
                     }
                  }
               }
               .ant-select-arrow {
                  right: 0 !important;
                  .search-icon {
                  }
               }
            }
            .input-box {
               height: 100%;
               display: flex;
               align-items: center;
               justify-content: center;
               padding: 5px 8px;
               margin-left: 3px;
               line-height: 2;
               text-align: center;
               color: $navigation-text-color !important;
               border-radius: 5px;
               border: 1px solid $navigation-text-color;
            }
            .error {
               height: 100%;
               line-height: 28px;
               //border: solid 1px $error-color;
               // animation: shake 0.2s ease-in-out 0s 2;
               // box-shadow: 0 0 0.5em $error-color;
            }
            .ant-form-item {
               height: 100%;
               margin: 0;
               .ant-form-item-control {
                  .ant-form-item-control-input {
                     height: 100%;
                     min-height: unset;
                     .ant-form-item-control-input-content {
                        button {
                           width: 100%;
                           height: 100%;
                           min-height: unset;
                           margin: 0px;
                           padding: 0;
                           text-align: center;
                           border: 0;
                           background: transparent;
                           box-shadow: unset;
                        }
                     }
                  }
               }
            }
            .ant-form-item-has-error {
               .ant-form-item-control {
                  .ant-form-item-control-input {
                     .ant-form-item-control-input-content {
                        input {
                           border: solid 1px $error-color;
                           animation: shake 0.2s ease-in-out 0s 2;
                           box-shadow: 0 0 0.5em $error-color;
                        }
                     }
                  }
                  .ant-form-item-explain {
                     display: none;
                  }
               }
            }
            .custom-ticker-search-select-dropdown-error {
               border: 0;
               border-bottom: solid 1px $error-color;
               animation: shake 0.2s ease-in-out 0s 2;
               box-shadow: 0 0 0.5em $error-color;
            }
            &:first-child {
               padding-left: 35px;
               text-align: left;
            }
            .ant-select-open {
               .ant-select-selector {
                  .ant-select-selection-placeholder {
                     color: inherit;
                     opacity: 0.4;
                  }
               }
            }
         }
      }

      .ant-tooltip {
         .ant-tooltip-content {
            .ant-tooltip-inner {
               font-size: 11px;
               font-weight: normal;
               font-stretch: normal;
               font-style: normal;
               letter-spacing: 0.01px;
               text-align: center;
               color: #fff;
               border-radius: 5px;
            }
         }
      }

      .allocation-header-container {
         position: relative;
         overflow: visible;
         .allocation-radio-button-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 99;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: $thead-gutter;
            .allocation-head-text {
               width: 100%;
               // margin-right: 5px;
            }
            .ant-radio-button-wrapper {
               width: 25px;
               height: 25px;
               padding: 0px;
               line-height: 25px;
               &:hover {
                  color: #1e5f91;
               }
            }
            .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
               color: #1e5f91;
               border-color: #1e5f91;
            }
         }
      }

      .disabled {
         color: #ababab;
      }

      .head-note {
         width: 100%;
         p {
            margin-bottom: 8px;
            padding-left: 70px;
            font-family: $secondary-font;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            letter-spacing: 0.01em;
            color: $black;
         }
      }

      .foot-note {
         width: 100%;
         display: flex;
         align-items: center;
         margin-bottom: 3rem;
         font-size: 12px;
         p {
            width: 85%;
            margin-top: 5px;
            margin-bottom: 0px;
            padding-left: 40px;
            color: $red-error-code;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            text-align: left;
         }
         span {
            width: 15%;
            padding: 0 25px 0 8px;
            text-align: right;
            color: $light-blue-color;
            text-decoration: underline;
            cursor: pointer;
         }
      }

      .row-has-error {
         background: transparent !important;
         td {
            background: transparent !important;
         }
      }
   }

   .portfolio-table-view-wrapper,
   .custom-ticker-search-box-wrapper {
      width: 100%;
      .ant-select {
         width: 100%;
         margin-bottom: 0px;
         box-shadow: none;
         border-radius: 0;
         .ant-select-selector {
            height: 35px;
            padding: 0 !important;
            border: 0 !important;
            background: transparent !important;
            box-shadow: none !important;
            border-radius: 0 !important;
            // border-bottom: 1px solid #c5c5c5 !important;
            .ant-select-selection-search {
               width: 100%;
               left: 0;
            }
            input {
               height: 100%;
               padding: 8px 8px !important;
            }
            .ant-select-selection-placeholder {
               padding-left: 18%;
               padding-right: 0;
               text-align: center;
               line-height: 35px;
            }
            .ant-select-selection-item {
               display: flex;
               align-items: center;
               justify-content: space-between;
               padding: 8px 8px;
               .option-ticker {
                  width: 30%;
                  font-size: 15px;
                  font-weight: 600;
                  text-align: left;
                  color: $black;
               }
               .option-ticker-name {
                  width: 70%;
                  font-size: 14px;
                  text-align: left;
                  font-weight: normal;
                  color: $black;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
               }
            }
         }
         .ant-select-arrow {
            right: 0 !important;
            .search-icon {
               right: 0;
               top: 5px;
               font-size: 20px;
            }
         }
      }

      .custom-ticker-search-box-dropdown {
         // height: 120px;
         div[role="listbox"] + div {
            &::-webkit-scrollbar {
               width: 4px !important;
               border-radius: 6px;
               background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
               width: 4px !important;
               border-radius: 6px;
               background-color: $light-blue-color;
            }
         }
         .custom-ticker-search-list-option {
            .ant-select-item-option-content {
               display: flex;
               align-items: center;
               .option-ticker {
                  font-size: 15px;
                  font-weight: 600;
                  text-align: left;
                  color: $black;
               }
               .option-ticker-name {
                  font-size: 14px;
                  text-align: left;
                  font-weight: normal;
                  color: $black;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
               }
            }
         }
      }
      .ant-select-dropdown {
         z-index: 999999;
      }

      .portfolio-table-view-total-wrapper {
         height: 37px;
         background: $background-color-light-blue;
         .ant-col {
            height: 37px;
         }
      }
   }

   #portfolio-table-view-total-weight {
      font-family: $secondary-font;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      text-align: center;
      color: $black;
   }

   .portfolio-table-view-uplaod-pencil-icon {
      color: $primary-color;
   }

   .warning-text {
      padding-left: 35px;
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: 14px;
      font-weight: 400;
      color: #db3f3e;
   }
}

@keyframes shake {
   0% {
      margin-left: 0rem;
   }
   25% {
      margin-left: 0.5rem;
   }
   75% {
      margin-left: -0.5rem;
   }
   100% {
      margin-left: 0rem;
   }
}
