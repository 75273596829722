.asset-allocation-screen-container {
   width: 100%;
   height: 100%;
   height: 93vh;
   min-height: 550px;
   max-width: 1200px;
   margin: 0 auto;
   display: flex;
   flex-direction: column;
   justify-content: center;
   padding: 1rem 2rem;
   padding: 6rem 2rem 6rem;

   .asset-allocation-screen-wraper {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;

      .title {
         font-family: $secondary-font;
         font-size: $font-size-18px;
         font-weight: 500;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.17;
         letter-spacing: normal;
         text-align: center;
         color: #424242;
      }
      .sub-title {
         font-family: $secondary-font;
         font-size: $font-size-26px;
         font-weight: 500;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.17;
         letter-spacing: normal;
         text-align: center;
         color: $dark-blue-color;
      }

      .section-mg {
         margin-top: -20px;
         // margin-bottom: 20px;
      }

      .section {
         width: 100%;
         max-width: 800px;
         display: flex;
         justify-content: space-evenly;
      }

      .chart-box {
         max-width: 250px;
         .chart-label {
            top: 36%;
            font-family: $secondary-font !important;
            font-size: $font-size-18px !important;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 3;
            letter-spacing: normal;
            text-align: center;
            color: $dark-blue-color !important;
         }
         .donut-chart {
         }
      }
      .action-btn {
         height: auto;
         padding: 16px 40px;
         font-family: $secondary-font;
         font-size: $font-size-18px;
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.17;
         letter-spacing: normal;
         text-align: center;
         color: $dark-blue-color;
         border: solid 1px $dark-blue-color;
         background-color: #ffffff;
      }
   }
}
