// Required
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

@import "./variables";

/*
.btn-warning, .btn-warning:hover, .btn-warning:active, .btn-warning.active, .btn-warning:active:focus, .btn-warning.active:focus,
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: maroon;
}
*/

.btn-warning {
   @include button-variant($ssf-orange, $ssf-orange);
}

.btn-outline-warning {
   @include button-outline-variant($ssf-orange);
}
.btn-select {
   @include button-variant($ssf-orange-d1, $ssf-orange-d1);
}
.btn-outline-select {
   @include button-outline-variant($ssf-orange-d1);
}
.btn-unselect {
   @include button-variant($ssf-green-d2, $ssf-green-d2);
}
.btn-outline-unselect {
   @include button-outline-variant($ssf-green-d2);
}
.btn-tools {
   @include button-variant($ssf-blue-d3, $ssf-blue-d3);
}
.btn-outline-tools {
   @include button-outline-variant($ssf-blue-d3);
}

.btn-outline-select:not(.comm) {
   background-color: $white;
   &:hover {
      background-color: $ssf-orange-d1;
   }
}
.btn-outline-tools:not(.comm) {
   background-color: $white;
   &:hover {
      background-color: $ssf-blue-d3;
   }
}

.btn.non-clickable {
   cursor: default !important;
   &:focus,
   &.focus,
   &:not(:disabled):not(.disabled):active:focus,
   &:not(:disabled):not(.disabled).active:focus {
      box-shadow: none;
   }
   &.btn-secondary:hover {
      background-color: $secondary;
      border-color: $secondary;
   }
}

.btn[disabled] {
   cursor: not-allowed;
}
/*
.h4.btn-select {
  color: $ssf-orange;
  background-color: $ssf-blue-d3;
  border-color: $ssf-orange; }
  .h4.btn-select:hover {
    color: #fff;
    background-color: $ssf-orange;
    border-color: $ssf-orange; }
  .h4.btn-select:focus, .h4.btn-select.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 83, 131, 0.5); }
  .h4.btn-select.disabled, .h4.btn-select:disabled {
    color: #fff;
    background-color: $ssf-blue-d3;
    border-color: $ssf-blue-d3; }
  .h4.btn-select:not(:disabled):not(.disabled):active, .h4.btn-select:not(:disabled):not(.disabled).active,
  .show > .btn-select.dropdown-toggle {
    color: #fff;
    background-color: $ssf-orange;
    border-color: $ssf-orange; }
    .h4.btn-select:not(:disabled):not(.disabled):active:focus, .h4.btn-select:not(:disabled):not(.disabled).active:focus,
    .show > .h4.btn-select.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 83, 131, 0.5); }
*/
