$blue: #1b8be0;
$red: #a36b6a;
$green: #3e7165;
$orange: #f66400;

#siw-2-2019 {
   font: 500 14px/1.2 "Open Sans", "Helvetica Neue", Helvetica, Arial,
      sans-serif;
   color: #333;
   width: 100%;

   * {
      box-sizing: border-box;
      user-select: none;
   }
   .siw-holder {
      position: relative;
      margin: 0 0 10px;
   }
   .siw-root {
      width: 780px;
      height: 375px;
      position: relative;
      margin: 0px auto;

      &.imported {
         max-width: 100%;
         margin: 0;
         overflow-x: hidden;
         overflow-y: auto;
         height: 100vh;
      }
   }
   .siw-results {
      z-index: 9999;
      top: 50px;
      left: 0;
      width: 100%;
      background: #f7f7f7;
      border: solid #dfdfdf;
      border-width: 0px;
      border-radius: 0px 0px 5px 5px;
      height: 278px;
      .slick-slide {
         height: 100%;
         width: 244px !important;
         margin-top: 12px;
         margin-bottom: 12px;
         margin-right: 12px;
         &:first-child {
            margin-left: 12px;
         }
      }

      .slick-track {
         display: flex;
      }
      @media (max-device-width: 639px) {
         top: 40px;
      }
   }

   .siw-empty-results {
      padding: 20px;
      text-align: center;
      background: #f7f7f7;

      .siw-empty-message {
         width: 100%;
         font-size: 14px;
         text-align: center;
         font-family: "Roboto";
      }
   }
   .siw-result-item {
      display: block;
      color: inherit;
      text-decoration: none;
      border: 1px solid #dfdfdf;
      background: inherit;
      position: relative;
      background-color: #fff;
      border-radius: 10px;
      height: 254px;
      width: 244px;
      .siw-result-item-holder {
         position: relative;
         padding: 0px;
         display: -webkit-flex;
         display: flex;
         -webkit-align-items: center;
         align-items: center;
         -webkit-flex-wrap: wrap;
         flex-wrap: wrap;
         height: 100%;
         width: 100%;
      }
      &:before {
         transition: transform 0.3s ease;
         transform-origin: 50% 50%;
         position: absolute;
         top: 0;
         left: 0;
         width: 100%;
         height: 100%;
         content: "";
         background: inherit;
         border-radius: 10px;
         @media (max-device-width: 639px) {
            display: none;
         }
      }
      &:hover {
         z-index: 2;
         cursor: pointer;

         &:before {
            transform: scale(1.04);
            border-color: #dfdfdf;
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4);
            border-radius: 5px;
         }
         .siw-sponsor-text-1,
         .siw-sponsor-text-2 {
            max-height: none;
         }
      }
   }
   .siw-price-col {
      width: 62px;
      padding: 0 10px;
      text-align: center;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      flex-direction: column;
      color: #4a4a4a;
      height: 66px;
      align-items: center;
      justify-content: center;
      border-bottom: solid 1px #efefef;
   }
   .siw-eem {
      font-size: 12px;
      text-transform: uppercase;
   }
   .siw-price {
      /*color: $blue;
    font-size: 22px;
    line-height: 1;*/
      font-size: 12px;
   }
   .siw-sponsor-col {
      width: calc(100% - 62px);
      padding-right: 10px;
      font-size: 12px;
      font-weight: normal;
      word-break: break-word;
      text-align: left;
      color: #4a4a4a;
      height: 66px;
      justify-content: center;
      display: flex;
      flex-direction: column;
      padding-top: 5px;
      border-bottom: solid 1px #efefef;
      letter-spacing: -0.24px;
   }
   .siw-sponsor-image {
      img {
         display: block;
         max-width: 100px;
         height: 12px;
      }
   }
   .siw-sponsor-text-1 {
      display: -webkit-box;
      max-height: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      line-height: 1.33;

      @media (max-device-width: 639px) {
         max-height: none;
      }
   }
   .siw-sponsor-text-2 {
      max-height: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      color: #979797;
      white-space: nowrap;
      width: 100%;
      line-height: 1.42;
   }
   .siw-item-col {
      width: 30%;
      flex-grow: 1;
      margin: 0px;
      padding: 0 10px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: $blue;
      height: 70px;
      border-bottom: solid 1px #efefef;
   }
   .siw-item-value {
      font-size: 10px;
      font-weight: bold;
   }
   .siw-item-rank {
      font-size: 10px;
      margin: 0 0 2px;
   }
   .siw-item-title {
      font-size: 10px;
      font-weight: normal;
      color: #56a9e8;
      padding-bottom: 5px;
   }

   .siw-meter-col {
      width: 100%;
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      height: 75px;
   }
   .siw-meter {
      position: relative;
      margin-top: -20px;
   }
   .siw-meter-title {
      position: absolute;
      left: 50%;
      bottom: 100%;
      margin-bottom: 2px;
      width: 120px;
      margin-left: -60px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 11px;
      text-align: center;
      color: #666;
   }
   .siw-meter-img {
      display: block;
   }
   // Editied CSS
   .siw-meter-title,
   .siw-meter-min,
   .siw-meter-max,
   .siw-meter-value {
      display: block;
   }
   // Editied CSS
   .siw-meter-min {
      font-size: 11px;
      color: #666;
      position: absolute;
      top: 100%;
      right: 100%;
      margin-top: 2px;
      margin-right: -24px;
      width: 32px;
      text-align: center;
   }
   .siw-meter-max {
      font-size: 11px;
      color: #666;
      position: absolute;
      top: 100%;
      left: 100%;
      margin-top: 2px;
      margin-left: -24px;
      width: 32px;
      text-align: center;
   }
   .siw-meter-value {
      position: absolute;
      width: 60px;
      margin-left: -30px;
      top: 100%;
      margin-top: 4px;
      left: 50%;
      font-weight: bold;
      text-align: center;
      font-size: 12px;
   }
   .siw-meter-arrow-holder {
      transform-origin: 50% 50%;
   }
   .siw-meter-arrow {
      width: 28px;
      height: 2px;
      border-radius: 1px;
      background: #888;
      position: absolute;
      top: 100%;
      right: 50%;
      margin-top: -1px;
      transform-origin: 100% 50%;
      &:after {
         position: absolute;
         left: 100%;
         margin-left: -3px;
         top: -2px;
         width: 6px;
         height: 6px;
         border-radius: 3px;
         background: #555;
         content: "";
      }
   }

   .siw-carouse-holder {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & .siw-pie-title {
         // position: absolute;
         // top: 5px;
         font-size: 11px;
         margin-bottom: -15px;
         // padding: 3px;
      }
   }

   .siw-blue {
      color: $blue;
   }
   .siw-red {
      color: $red;
   }
   .siw-green {
      color: $green;
   }
   .siw-orange {
      color: $orange;
   }
   .siw-fees-color {
      color: #4a4a4a;
   }
   .siw-return-color {
      color: $green;
   }
   .siw-risk-color {
      color: $red;
   }
   .siw-col-active {
      background: rgba(0, 0, 0, 0.08);
   }

   .siw-icon {
      width: 28px;
      height: 18px;
      margin: 0 0 2px;
      font: 400 16px/1 SIW;
   }
   .siw-carousel {
      width: 70%;
      height: 100%;
      position: relative;
      min-height: 95px;
      margin-top: -15px;
   }
   .siw-carousel > div {
      position: absolute;
      top: 50%;
      margin-top: -30px;
      margin-left: -30px;
      width: 60px;
      height: auto;
      transform-origin: 50% 50%;
      transition: all 0.5s ease;
      overflow: initial;
   }
   .siw-carousel img {
      margin: 0;
      padding: 0;
      display: block;
      width: 100%;
   }
   .siw-slide-m2 {
      left: 0;
      opacity: 0;
      transform: scale(0.5);
      z-index: 1;
   }
   .siw-slide-m1 {
      left: 25%;
      transform: scale(0.75);
      opacity: 0.5;
      z-index: 2;
   }
   .siw-slide-0 {
      left: 50%;
      z-index: 3;
   }
   .siw-slide-1 {
      left: 75%;
      opacity: 0.5;
      transform: scale(0.75);
      z-index: 2;
   }
   .siw-slide-2 {
      left: 100%;
      transform: scale(0.5);
      z-index: 1;
      opacity: 0;
   }
   .siw-slide-label {
      // position: absolute;
      // top: 100%;
      // left: 50%;
      // width: 120px;
      // margin-left: -60px;
      margin-top: 3px;
      font-size: 12px;
      line-height: 16px;
      color: #000;
      white-space: nowrap;
      text-align: center;
      align-items: center;
      display: flex;
      align-self: center;
      justify-content: center;
   }
   .siw-pie-holder {
      width: 100%;
      position: relative;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      .siw-pie-title {
         width: 100%;
         margin: 0 0 2px;
         overflow: hidden;
         text-overflow: ellipsis;
         white-space: nowrap;
         font-size: 11px;
         display: block;
         text-align: center;
         padding: 3px;
      }
   }
   .siw-link {
      display: -webkit-flex;
      display: flex;
      font-size: inherit;
      line-height: inherit;
      color: inherit;
      -webkit-align-items: center;
      align-items: center;
      text-decoration: none;
      font-size: 12px;
      font-family: Roboto;
      color: #56a9e8;
   }
   .siw-pie-center-text {
      position: absolute;
      top: 45%;
      left: 50%;
      width: 44px;
      height: 44px;
      margin: -16px 0 0 -22px;
      font-size: 10px;
      line-height: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
   }
   .siw-desktop-hidden {
      display: flex;
      flex-direction: column;
      @media (min-device-width: 640px) {
         display: none;
      }
   }

   .siw-simplePieChartWrapper {
      position: relative;

      & .primaryTextStyle {
         position: absolute;
         top: 50%;
         left: 54%;
         -webkit-transform: translate(-50%, -50%);
         transform: translate(-50%, -50%);
         margin: 0 auto;
         text-align: center;
         line-height: 1;
         font-size: 10px;
         font-weight: bold;
         z-index: 2;

         &:hover {
            .highlightTooltip {
               display: block;
            }
         }

         & .highlightTooltip {
            display: none;
            position: absolute;
            top: 15px;
            left: 10px;
            border: 1px solid #363a3e;
            width: 92px;
            font-weight: normal;
            padding: 4px 1px;
            font-size: 11px;
            background-color: #fff;
            box-shadow: 4px 3px 3px #888;
         }
      }

      & .recharts-tooltip-wrapper {
         z-index: 5 !important;
      }
   }

   .siw-notapp-status {
      display: flex;
      flex-direction: column;
      font-size: 11px;
      color: #979797;

      & img {
         width: 28px;
         margin-right: 3px;
      }

      &__text {
         margin: 0 auto;
      }
   }

   .siw-d3ChartWrapper {
      position: relative;

      & .primaryTextStyle {
         position: absolute;
         top: 49%;
         left: 51%;
         margin: 0 auto;
         text-align: center;
         line-height: 1;
         font-size: 10px;
         -webkit-transform: translate(-50%, -50%);
         transform: translate(-50%, -50%);

         &:hover .highlightTooltip {
            display: block;
         }

         & .highlightTooltip {
            display: none;
            position: absolute;
            top: 15px;
            left: 10px;
            border: 1px solid #363a3e;
            width: 92px;
            font-weight: normal;
            padding: 4px 1px;
            font-size: 11px;
            background-color: #fff;
            box-shadow: 4px 3px 3px #888;
         }
      }
   }
}
