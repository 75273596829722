$red-color: #a5685f;
$red-color-bgcolor: #f1e8e7;
$light-grey-color: #b7b7b7;
$text-color: #1e1e1e;

.portfolio-tbl-wrapper-advisor {
   display: flex;
   flex-direction: column;
   flex: 1;
   overflow: hidden;
   .cta-wrapper {
      .orange-btn {
         background-color: #fff;
         color: $orange-color;
         border: 1px solid $orange-color;
         padding: 0px;
         margin-bottom: 0px;
         font-size: 0.7rem;
      }
      .blue-btn {
         background-color: #fff;
         color: $primary-color;
         border: 1px solid $primary-color;
         padding: 2px 20px;
         margin-bottom: 0px;
         font-size: 0.7rem;
         width: auto;

         &:focus {
            box-shadow: none !important;
         }

         &:not(:disabled):not(.disabled):active,
         .disabled {
            background: $primary-color;
         }

         &.disabled,
         &:disabled {
            background: $primary-color;
         }

         &.el-grey {
            color: $other-grey-color !important;
            border: 1px solid $other-grey-color !important;
            &:active {
               background: #fff !important;
            }
         }
      }
   }
   .pf-tbl-options {
      flex-direction: row;
      display: flex;
      justify-content: center;
      margin: 10px 0px 10px;
      padding: 0px 15px;
      .pf-tbl-pagination {
         span {
            font-family: $secondary-font;
            color: #4a4a4a;
            font-size: $font-size-16px;
            font-weight: bold;
            padding: 0px 5px;
            cursor: pointer;
            &:first-child {
               color: #707070;
            }
            &:last-child {
               color: #707070;
            }
            &.active {
               color: $dark-blue-color;
               text-decoration: underline;
            }
         }
      }
      .avg-cash-allocation-margin {
         margin-right: 14%;
      }
      .tbl-actions {
         display: flex;
         flex-direction: row;
         .imp-exp-opt {
            padding-left: 15px;
         }
         .avg-cash-allocation {
            font-family: $secondary-font;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #919191;
         }
         .avg-cash-allocation-value {
            color: $label-black-color;
         }
      }
      .imp-exp-opt {
         font-family: $secondary-font;
         font-size: 14px;
         font-weight: 500;
         color: $dark-blue-color;
         //font-weight: normal;
         cursor: pointer;
         img {
            width: 12px;
            margin-right: 7px;
            margin-bottom: 3px;
         }
      }
      .not-allowed {
         cursor: not-allowed !important;
      }
   }
   .ant-custom-tbl-content {
      //padding: 0px 15px;
      .left-section {
         width: 50%;
         max-width: 50%;
         .imp-exp-opt:hover {
            background-color: #145b8a;
            .text {
               color: white;
            }
         }
         .imp-exp-opt {
            border: 1.6px solid #145b8a;
            border-radius: 4px;
            //width: 30%;
            width: 180px;
            margin-top: 14px;
            font-size: 12px;
            font-family: "Roboto";
            line-height: 1.18;
            padding-left: 12px;
            background-color: rgba(255, 255, 255, 0);
            cursor: pointer;
            .image1 {
               padding: 10px 2px;
            }
            .text {
               line-height: 1.18;
               padding: 10px 12px 10px 8px;
               font-stretch: normal;
               font-style: normal;
               font-weight: 500;
               font-size: 12px;
               letter-spacing: normal;
               text-align: center;
               color: #145b8a;
            }
         }
      }
      .ant-table-wrapper {
         width: 100%;
         max-width: 100%;
         padding-right: 0.5%;
         // padding-bottom: 10px;
      }
      &.show-client-det {
         display: flex;
         flex-direction: row;
         .ant-table-wrapper {
            // width: 50%;
            // max-width: 50%;
         }
         .client-detail-section {
            width: 50%;
            max-width: 50%;
            //padding-top: 56px;
            padding-top: 32px;
         }
      }
      &.no-table-content {
         .ant-table,
         .ant-table-wrapper,
         .ant-spin-nested-loading,
         .ant-spin-container,
         .ant-table-fixed-header,
         .ant-table-container,
         .ant-table-content,
         .ant-table-content table {
            height: 100%;
         }
      }
      .ant-table-content {
         overflow: hidden !important;
      }
      .ant-table table {
         border-collapse: collapse;
      }
      // Fixing table scroll issues -- starts
      .ant-table-container {
         // display: flex;
         // flex-direction: column;
         // .ant-table-header{
         //   overflow: unset !important;
         // }
         .ant-table-body {
            overflow-x: hidden;
            overflow-y: auto !important;
            height: 100%;
         }
         &::after {
            width: 0px !important;
         }
      }
      // Fixing table scroll issues -- ends
      td.search-highlight {
         //background: #e1effa !important;
      }
      .ant-table-tbody > tr.ant-table-row:hover > td {
         background: #f5f7f9;
      }
      .ant-table-tbody > tr:last-child.ant-table-row:hover > td {
         background: unset;
      }
      .ant-table-tbody > tr.ant-table-row.selectedClientRow {
         background: #f5f7f9;
      }

      // Table header styles
      .ant-table-thead {
         tr:first-child {
            th {
               background-color: unset;
            }
         }
         tr:last-child {
            th {
               background: #f6f7f9;
               font-family: "Roboto";
               font-size: 16px;
               color: $label-black-color;
               font-style: normal;
               font-weight: 500;
               padding: 8px 16px;
               &.ant-table-cell-scrollbar {
                  border-bottom: 1px solid transparent !important;
               }
            }
         }
         .acc-details-header,
         .model-selection-header {
            border-right: $clients-table-border-width solid #ffffff;
         }
         // Table header colors
         .acc-details-header,
         .model-selection-header,
         .tax-management-header {
            color: $dark-blue-color;
         }
         .acc-details-header {
            background: rgba(86, 169, 232, 0.15);
         }
         .model-selection-header {
            background: rgba(216, 216, 216, 0.2);
         }
         .tax-management-header {
            background: rgba(203, 225, 244, 0.25);
         }
      }

      // Table body styles
      .ant-table-tbody {
         tr {
            td {
               font-family: $primary-font;
               font-size: $font-size-16px;
               color: $label-black-color;
               padding: 8px 0px;
               border-bottom: 1px solid #cbcbcb;
               font-weight: normal;
            }
         }
         tr:last-child {
            td {
               border-bottom: unset;
            }
         }
         tr td:first-child {
            padding-left: 16px;
         }
      }

      // Table body colors
      .acc-details-content {
         border-bottom: 1px solid #cbcbcb;
         //background: rgba(221, 238, 251, 0.25) !important;
         // background: #f6fbfe !important;
      }
      .search-ticker {
         display: flex;
         flex-flow: row;
         justify-content: center;
      }
      .model-selection-content {
         background: rgba(216, 216, 216, 0.1) !important;
         // background: #fbfbfb !important;
      }
      .tax-management-content {
         background: rgba(217, 236, 242, 0.2) !important;
         // background: #f7fbfc !important;
      }

      // Table inner borders
      .light-left-border {
         border-left: $clients-table-border-width solid #ffffff;
      }
      .light-right-border {
         // border-right: $clients-table-border-width solid #ffffff;
      }

      .tbl-edit {
         color: $primary-color;
      }

      .highlight-ticker-c {
         flex-grow: 1;
      }
      .highlight {
         padding: 6px 20px;
         line-height: 1;
         margin-left: 8px;
         margin-right: 8px;
         background: #064676;
         padding: 4px 8px;
         border-radius: 30px;
         color: #ffffff;
         .content-highlight {
         }
      }

      .left-arrow,
      .right-arrow {
         flex-grow: 0.4;
         color: #5488c9;
      }
      .side-arrow:hover {
         cursor: pointer;
      }
      .custom-checkbox {
         input[type="checkbox"] {
            z-index: 100;
            position: absolute;
            left: 0;
            opacity: 0;
            width: inherit;
            &:hover {
               cursor: pointer;
            }
         }
      }
   }
   .col-title-filter {
      display: flex;
      flex-direction: row;
      align-items: center;
      align-self: center;
      i {
         font-size: 14px;
         margin-left: 5px;
         &.filter-highlight {
            color: $primary-color;
         }
         &.filter-reset {
            color: #ea5f4b;
            margin-right: -4px;
            margin-left: 3px;
         }
      }
   }
   .checkbox-align {
      align-self: center;
      hr {
         display: none;
      }
   }
   .sch-wrapper {
      width: 150px;
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      i {
         font-size: 12px;
         &.fa-search {
            position: absolute;
            top: 12px;
            right: 0;
         }
      }
   }
   .tbl-hdr-search {
      height: 100%;
      // padding-left: 16px;
      // padding-right: 16px;
      .search {
         border: none;
         border-bottom: 1px solid rgba(0, 0, 0, 0.12);
         width: 100%;
         margin-top: 5px;
         margin-bottom: 10px;
         text-align: left;
         padding-right: 25px;
         height: 25px;
      }
      p {
         height: 100%;
         margin-bottom: 0px;
         display: flex;
         justify-content: center;
         align-items: center;
         // font-weight: bold;
      }
      hr {
         margin-left: -16px;
         margin-right: -16px;
      }
   }
   .model-selector-edit {
      display: flex;
      flex-direction: row;
      overflow: hidden;
      & > span {
         text-overflow: ellipsis;
         white-space: nowrap;
         overflow: hidden;
         display: block !important;
      }
   }
   .taxtran-value {
      min-width: 65px;
      justify-content: center;
   }
   .sub-head-text-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 2px;
      img {
         width: auto;
         height: 20px;
      }
      label {
         margin: 0;
         margin-right: 2px;
         font-size: 14px;
         font-weight: normal;
         line-height: 1;
      }
   }
}
.sml-modal-wrapper {
   padding: 55px 40px;
   .confirm-text {
      font-family: $secondary-font;
      font-size: $font-size-26px;
      color: $dark-blue-color;
      margin-bottom: 10px;
   }
   .cta-wrapper {
      display: flex;
      flex-direction: row !important;
      margin-top: 25px;
      .btn-secondary {
         width: 140px;
         margin: 7px;
         color: $dark-blue-color !important;
         border: 1px solid $dark-blue-color !important;
         padding: 3px 15px;
         font-weight: 600;
         font-size: $font-size-20px;

         &:focus {
            box-shadow: none !important;
         }

         &:not(:disabled):not(.disabled):active,
         .disabled {
            color: $white-color !important;
            background: $dark-blue-color;
         }

         &.disabled,
         &:disabled {
            // background: $dark-blue-color;
         }
      }
   }
}
// Restricted Tickers Styles
.res-tickers-sm-light {
   max-width: 600px !important;
   .modal-content {
      border-radius: 20px;
   }
   h3 {
      font-family: $secondary-font;
      font-size: $font-size-30px !important;
      font-weight: normal;
      color: $dark-blue-color;
      margin-bottom: 50px;
   }
}
.mdl-form-content {
   .element-box {
      display: flex;
      flex-direction: column;
      padding-bottom: 25px;
      margin: 0;
      font-family: $secondary-font;
      font-weight: 600;
      font-size: $font-size-20px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.35;
      letter-spacing: normal;
      text-align: left;
      .input-area {
         width: 100%;
         height: auto;
         padding: 7px 0;
         font-size: $font-size-17px;
         color: #1e5f91 !important;
         background-color: transparent !important;
         border: 0;
         border-radius: 0;
         border-bottom: 1px solid;
         border-bottom-color: #1e5f91 !important;
         -webkit-transition: 0.2s ease all;
         transition: 0.2s ease all;
      }
   }
   .upload-error {
      color: #a5685f;
      padding: 10px 0;
   }
   .input-chips-wrapper {
      .cus-pointer {
         cursor: pointer;
         width: unset;
      }
   }
}
.res-tickers {
   display: flex;
   align-items: center;
   width: 100%;
   justify-content: center;
   i {
      padding-left: 10px;
   }
   span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
   }
   &.el-grey {
      color: $other-grey-color !important;
      i {
         color: $other-grey-color !important;
      }
   }
}
// General Styles
.cus-pointer {
   cursor: pointer;
   width: -webkit-fill-available;
}
.flex-row {
   display: flex;
   flex-direction: row;
}
.flex-column {
   display: flex;
   flex-direction: column;
}
// Input chips component styles
.input-chips-wrapper {
   display: flex;
   flex-wrap: wrap;
   border-bottom: 1px solid #ccc;
   flex-direction: row;
   align-items: center;
   margin-bottom: 50px;
   .chips-list {
      display: inline-flex;
      flex-wrap: wrap;
      list-style-type: none;
      width: calc(100% - 30px);
      padding: 0px 10px 0px 0px;
      margin: 0;
      li {
         display: flex;
         padding-bottom: 5px;
         max-width: 100%;
         .ant-tag {
            display: flex;
            justify-content: center;
            align-self: center;
            align-items: center;
            font-family: $secondary-font;
            font-size: $font-size-17px;
            text-transform: uppercase;
            background: none;
            border: none;
            padding-left: 0px;
            flex-direction: row;
            max-width: 100%;
            .tag-text {
               overflow: hidden;
               text-overflow: ellipsis;
               white-space: nowrap;
            }
            .anticon-close {
               font-size: 7px;
               margin-left: 5px;
            }
         }
      }
   }
   .input-chips {
      flex-grow: 1;
      input {
         width: 100%;
         border: none;
         font-family: $secondary-font;
         font-size: $font-size-17px;
         padding: 0;
         text-transform: uppercase;
         &:focus {
            border: none;
            box-shadow: none;
         }
         &::-webkit-input-placeholder {
            /* WebKit browsers */
            text-transform: none;
         }
         &:-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            text-transform: none;
         }
         &::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            text-transform: none;
         }
         &:-ms-input-placeholder {
            /* Internet Explorer 10+ */
            text-transform: none;
         }
         &::placeholder {
            /* Recent browsers */
            text-transform: none;
         }
      }
   }
}
// Tax Transition Styles
.tt-wrap {
   padding: 55px 100px;
   .reset-link {
      display: block;
      color: $dark-blue-color;
      text-decoration: underline;
   }
   .mdl-form-content {
      width: 300px;
      margin: 0 auto;
      .input-area {
         font-family: $primary-font;
         font-size: $font-size-23px;
         font-weight: 600;
      }
   }
}

.tax-trans-form-row {
   display: flex;
   flex-direction: row;
   width: 100%;
   font-family: $primary-font;
   font-size: $font-size-23px;
   font-weight: 600;
   align-items: baseline;
   .form-group {
      width: 100%;
   }
   span {
      white-space: nowrap;
      padding: 0px 10px;
   }
}

// Optimize Modal Styles
.optimize-sm-light {
   max-width: 80% !important;
   .modal-content {
      border-radius: 10px;
   }
   h3 {
      font-family: $secondary-font;
      font-size: $font-size-30px !important;
      font-weight: normal;
      color: $dark-blue-color;
      margin-bottom: 50px;
   }
   &.opt-min-wid {
      max-width: 600px !important;
   }
}

// Optimize Table Styles
.opt-mdl-wrap {
   padding: 35px 75px;
   .optimize-tbl-wrapper {
      font-family: $secondary-font;
      .optimize-tbl {
         tr {
            &:first-child {
               td {
                  padding: 0px;
               }
            }
            td {
               border-left: 1px solid #ccc !important;
               padding-bottom: 10px;
               padding-top: 10px;
               width: 14%;
               background-color: #fcfcfc !important;
               text-align: center;
               h5 {
                  margin-bottom: 0px;
                  font-size: $font-size-17px !important;
               }
            }
         }
         th,
         td {
            text-align: center;
            border: 1px solid #ccc !important;
         }
         th {
            h5 {
               font-size: $font-size-17px !important;
               color: #666666 !important;
               font-family: $primary-font;
               margin-bottom: 0px;
            }
         }
         .highlight {
            background-color: #d5edff !important;
            border-color: #d5edff !important;
         }
      }
      .tt_budget_heading {
         padding-top: 10px;
         padding-bottom: 10px;
         z-index: 1;
         position: relative;
         background-color: $dark-blue-color !important;
         color: #fff !important;
      }
      .pv {
         color: $dark-blue-color;
      }
      .tt-mdl-sub-text {
         font-size: $font-size-14px;
         font-weight: normal;
      }
      .skill-group-bar {
         /*display:inline;*/
         border: solid 1px #ccc;
         background-color: #ffffff;
         height: 7px;
         width: 100%;
         overflow: hidden;
      }
      .skill-bar {
         display: inline-block;
         float: left;
         position: relative;
         width: 20px;
         height: 5px;
         width: 20%;
         border-right: 1px solid #fff;
         &.active {
            background-color: $dark-blue-color !important;
         }
         &.disable {
            background-color: #f1f1f1;
            border-right: solid 1px #ccc;
            &:last-child {
               border-right: none;
            }
         }
         &:last-child {
            border-right: none;
         }
      }
   }
   .perc-chart {
      position: relative;
      padding-top: 10px;
      .guage-chart-details {
         position: absolute;
         bottom: 5px;
      }
   }
}

.tbl-actions {
   display: flex;
   flex-direction: row;
   justify-content: center;
   margin-bottom: 5px;
   .imp-exp-opt {
      padding-left: 15px;
   }
   .avg-cash-allocation {
      font-size: 14px;
      font-family: $secondary-font;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #919191;
   }
   .avg-cash-allocation-value {
      color: $label-black-color;
   }
}
// Responsive Styles
@media only screen and (max-width: 1550px) {
   .portfolio-tbl-wrapper-advisor .sch-wrapper {
      width: 120px !important;
   }
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
   .portfolio-tbl-wrapper-advisor {
      .rdt_TableHeadRow {
         .rdt_TableCol {
            font-size: 0.8rem !important;
         }
      }
   }
   .portfolio-tbl-wrapper-advisor {
      .rdt_TableRow {
         font-size: 0.7rem !important;
      }
   }
}

// New CSS - Table
.ant-custom-tbl-content {
   // display: flex;
   overflow: hidden;
   height: 100%;
   .container-left-width {
      width: 49.5% !important;
      padding-right: 0.5%;
   }
   .container-right-width {
      width: 50% !important;
   }
}

//no-account-css
.no-account {
   font-family: Roboto;
   font-size: 22px;
   font-weight: 500;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.32;
   letter-spacing: normal;
   text-align: center;
   color: #30608d;
}
.no-add-account {
   .imp-exp-opt:hover {
      background-color: #145b8a;
      .text {
         color: white;
      }
   }
   .imp-exp-opt {
      border: 1.6px solid #145b8a;
      border-radius: 4px;
      //width: 30%;
      width: 180px;
      margin-top: 14px;
      font-size: 12px;
      font-family: "Roboto";
      line-height: 1.18;
      padding-left: 12px;
      background-color: rgba(255, 255, 255, 0);
      cursor: pointer;
      .image1 {
         padding: 10px 2px;
      }
      .text {
         line-height: 1.18;
         padding: 10px 12px 10px 8px;
         font-stretch: normal;
         font-style: normal;
         font-weight: 500;
         font-size: 12px;
         letter-spacing: normal;
         text-align: center;
         color: #145b8a;
      }
   }
}

// Infinite List css
$infinite-list-col-padding: 8px 16px;
$infinite-list-header-font-szie: 1rem;
$infinite-list-row-font-szie: 0.875rem;
$infinite-list-row-hover-bg-color: #f5f7f9;
$infinite-list-row-selected-bg-color: #f5f7f9;

#infinite-list-wrapper {
   &::-webkit-scrollbar {
      width: 15px !important;
      background-color: transparent;
      border-left: 1px solid #fff;
   }
   &::-webkit-scrollbar-thumb {
      border-radius: 25px;
      background-color: #d2d2d2;
      border: 5px solid transparent;
      background-clip: content-box;
   }
}

.infinite-list-container {
   .infinite-list-header {
      padding-top: 16px;
      margin-right: 15px;
      align-items: center;
      border-bottom: 1px solid $border-color;
      .ant-col {
         display: flex;
         align-items: center;
         justify-content: center;
         padding: $infinite-list-col-padding;
         font-family: $primary-font;
         font-size: $infinite-list-header-font-szie;
         font-weight: 600;
         text-align: center;
         color: $label-black-color;
         i {
            font-size: 14px;
            margin-left: 5px;
            &.filter-highlight {
               color: $primary-color;
            }
            &.filter-reset {
               color: #ea5f4b;
               margin-right: -4px;
               margin-left: 3px;
            }
         }
         &:first-child {
            justify-content: flex-start;
            text-align: left;
         }
      }
      .query-var-header-col {
         padding: 8px 0;
         display: flex;
         flex-direction: column;
      }
   }
   .infinite-list-wrapper {
      .infinite-list-row {
         align-items: center;
         cursor: pointer;
         border-bottom: 1px solid $border-color;
         .ant-col {
            padding: $infinite-list-col-padding;
            font-family: $primary-font;
            font-size: $infinite-list-row-font-szie;
            font-weight: 400;
            text-align: center;
            color: $label-black-color;
            &:first-child {
               text-align: left;
            }
         }
         .client-name-col {
            position: relative;
            .iconWrapper {
               padding: 0;
               display: flex;
               align-items: center;
               i {
                  position: absolute;
                  right: 0;
                  z-index: 9;
                  height: 100%;
                  padding: 4px 0px 4px 8px;
                  display: flex;
                  line-height: normal;
                  justify-content: center;
                  align-items: center;
                  color: $light-grey-color;
               }
               .deleteIcon {
                  &:hover {
                     color: $red-color;
                     background: $red-color-bgcolor;
                     background-color: $red-color-bgcolor;
                  }
               }
               .editIcon {
                  &:hover {
                     color: $text-color;
                  }
               }
            }
         }
         &:hover {
            background: $infinite-list-row-hover-bg-color;
         }
         &:last-child {
            // border: 0;
         }
      }
      .selected-row {
         background: $infinite-list-row-selected-bg-color;
      }
   }
   .add-more-column {
      padding: 8px 10px 8px 0px;
      font-family: $secondary-font;
      font-size: 12px;
      font-weight: 400;
      font-style: normal;
      text-align: right;
      color: $dark-active-color;
      cursor: pointer;
      float: right;
   }
   .multiple-query-var-header-col {
      display: flex;
      align-items: center;
      .left-arrow {
         padding-right: 3px;
      }
      .right-arrow {
         padding-left: 3px;
      }
   }
}

.infinite-list-tooltip {
   .ant-tooltip-arrow {
      opacity: 0.8;
   }
   .ant-tooltip-inner {
      font-size: 0.7rem;
      text-align: center;
      opacity: 0.8;
      border-radius: 3px;
      background-color: $label-black-color;
   }
}
