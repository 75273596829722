.search-wrapper {
   // margin-bottom: 15px;
   .search-box {
      input {
         width: 100%;
         height: 45px;
         padding-right: 40px;
         font-size: 18px;
         font-weight: 500;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.35;
         letter-spacing: -0.24px;
         text-align: left;
         color: rgb(112, 112, 112);
         border: 2px solid $primary-color;
         padding-left: 25px;
         color: $primary-color !important;
      }
      .search-icon {
         right: 20px;
         font-size: 14px;
         font-weight: 500;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.35;
         letter-spacing: -0.24px;
         color: $primary-color !important;
         border: 1px solid $primary-color;
         border-radius: 50%;
         width: 27px;
         height: 27px;
         align-items: center;
         justify-content: center;
         display: flex;
         opacity: 1;
      }
      .rounded {
         border-radius: 50px !important;
      }
      ::placeholder {
         color: $primary-color !important;
         opacity: 1; /* Firefox */
         text-align: center;
      }
      :-ms-input-placeholder {
         /* Internet Explorer 10-11 */
         color: $primary-color !important;
         opacity: 1;
         text-align: center;
      }
      ::-ms-input-placeholder {
         /* Microsoft Edge */
         color: $primary-color !important;
         opacity: 1;
         text-align: center;
      }
   }
}

.type-writer {
   position: absolute;
   left: 0;
   right: 0;
   margin: 0 auto !important;
   width: max-content !important;
   min-width: auto !important;
   max-width: max-content !important;
   // margin: 0px 28px !important;
   padding-right: 3px;
   line-height: 1.5;
   font-size: 18px !important;
   font-weight: 500 !important;
   font-weight: normal;
   color: #56a9e8 !important;
}

.type-writer {
   border-right: solid 1px rgba(0, 255, 0, 0.75);
   white-space: nowrap;
   overflow: hidden;
}

/* Animation */
.type-writer {
   // animation: animated-text 4s steps(29,end) 1s 1 normal both,
   //            animated-cursor 800ms steps(29,end) infinite;
   animation: animated-cursor 800ms steps(29, end) infinite;
}

/* text animation */

@keyframes animated-text {
   from {
      width: 0;
   }
   to {
      width: 230px;
   }
}

/* cursor animations */

@keyframes animated-cursor {
   from {
      border-right-color: #56a9e8;
   }
   to {
      border-right-color: transparent;
   }
}
