// Stats component styles starts
.stats-wrapper {
   padding: 0px 16px;
   .sw-highlight-card {
      // background: $dark-blue-color;
      // box-shadow: 0 0 15px 0 rgba(34, 99, 147, 0.12);
      // border-radius: 10px;
      padding: 25px;
      height: 100%;
      padding-left: 0px !important;
      .sw-card-det {
         // color: #fff;
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.2;
         letter-spacing: normal;
         text-align: left;
         color: $label-black-color;
         // font-weight: 500;
         .sub-title {
            font-family: $secondary-font;
            font-size: $font-size-14px !important;
            margin-bottom: 0px;
         }
         .main-title {
            font-family: $secondary-font;
            font-size: 22px; //$font-size-25px;
            margin-bottom: 4px;
         }
      }
      .sw-card-icon {
         img {
            width: 50px !important;
            height: 50px !important;
         }
      }
   }
   .sw-card-wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      // background: #fff;
      // box-shadow: 0 0 15px 0 rgba(34, 99, 147, 0.12);
      // border: 1px solid #efefef;
      // border-radius: 10px;
      padding: 25px;
      // background: no-repeat bottom url(/assets/images/BackDrop.png) transparent;
      background-size: 100%;
      align-items: flex-end;
   }
   .sw-card {
      .sw-card-det {
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.2;
         letter-spacing: normal;
         text-align: left;
         color: $label-black-color;
         // font-weight: 500;
         .sub-title {
            font-family: $secondary-font;
            font-size: $font-size-25px;
         }
         .main-title {
            font-family: $secondary-font;
            font-size: $font-size-28px;
            // font-weight: 600;
         }
      }
   }
   .sw-gen-style {
      display: flex;
      flex-direction: row;
      // justify-content: center;
      align-items: center;
      padding-left: 30px;
      .sw-card-icon {
         padding-right: 10px;
         img {
            width: 35px;
            height: 35px;
         }
      }
      .sw-card-det {
         display: flex;
         flex-direction: column;
         align-items: center;
         align-self: center;
         .sub-title {
            width: 100%;
            display: inline-block;
            margin-bottom: 4px;
            font-size: 22px;
         }
         .main-title {
            width: 100%;
         }
      }
   }
}
// Stats component styles ends
