.modal-backdrop {
   display: none;
}

.vehicle-label {
   margin: 0 auto;
   line-height: 1.2;
   font-size: 10px;
   font-weight: 600;
   color: #57869c;
   text-align: center;
}

.media-vehicle-label {
   // margin: unset;
   // margin-top: 20px;
   // margin-right: -14px;
}

.share-component__tooltip {
   font-size: 19px;

   .tooltip-inner {
      background-color: #4a4a4a !important;
   }
   .arrow:before {
      border-bottom-color: #4a4a4a !important;
   }
}

.topSectionContainer {
   position: relative;
   padding: 22px 0;
   padding-bottom: 15px;
   border-bottom: 2px solid #efefef;

   .search-related-wrapper {
      font-size: 18px;
      .search-label {
         font-size: 18px;
      }
      .results-count-color {
         // padding-left: 0;
         // white-space: nowrap;
         // overflow: hidden;
         // text-overflow: ellipsis;
         // width: 600px;
      }
      .share-component {
         &__wrapper {
            height: 22px;
         }

         &__item {
            width: 16px;
            height: 16px;
            padding: 0px;
            font-size: 10px;
            color: #4a4a4a;
         }

         &__tootlip {
         }
      }
   }

   .toolbarWrapper {
      background: #f7f7f7 !important;
      // margin-top: 8px;
      .sort-filter-wrapper {
         justify-content: flex-end;
         position: absolute;
         top: 26px;
         right: 10px;
         z-index: 999;
      }
      .container .row .col-auto {
         margin: 0 !important;
      }
      .container {
         max-width: unset;
         padding: 0;
         .filter-items-wrapper {
            display: flex;
            flex-direction: row-reverse;
            padding: 5px 0px 0;
            margin: 0;
            .filter-items {
               position: relative;
               margin: 0;
               margin-right: 5px;
               margin-bottom: 5px;
               padding: 3px 20px 3px 10px;
               font-size: 12px;
               font-weight: normal;
               line-height: 1;
               letter-spacing: -0.17px;
               color: #4a4a4a;
               border-radius: 15px;
               border: solid 1px #efefef;
               background-color: #ffffff;

               .close-btn {
                  position: absolute;
                  right: 5px;
               }

               &:first-child {
                  margin-right: 0;
               }

               &:last-child {
                  margin-left: 0;
                  // margin-right: 0;
               }

               & .fa-times {
                  font-size: 10px;
                  transition: all 0.2s;

                  &:hover {
                     cursor: pointer;
                     transform: scale(1.3);
                  }
               }
            }
         }
      }
      .display-flex {
         display: flex !important;
         flex-direction: column;
         position: absolute;
         z-index: 9;
         background: $filter-bg-color;
         border-radius: 2px;
         li {
            color: $charcoal-black;
            font-size: 12px;
            line-height: 1.5;
         }
      }
      .filterButtonWrapper {
         border-radius: 15px !important;
         background: $filter-bg-color;
         color: $charcoal-black !important;
         border: 0 !important;
         .filterbutton {
            position: relative;
            font-size: 12px;
            font-weight: 600;
            color: $charcoal-black !important;
            // background: $filter-bg-color;
            border-radius: 15px !important;
            border: 0 !important;
            &:after {
               opacity: 0;
               display: none;
            }
            &:before {
               opacity: 0;
               display: none;
            }
            .dropdown-toggle:focus {
               box-shadow: none;
            }
            .dropdown-menu {
               .select-col {
                  background: #dff1ff;
               }
               .dropright,
               .dropleft {
                  &:first-child {
                     border-top-left-radius: 4px;
                     border-top-right-radius: 4px;
                  }
                  &:last-child {
                     border-bottom-left-radius: 4px;
                     border-bottom-right-radius: 4px;
                  }
                  .dropdown-button {
                     width: 100%;
                     color: $charcoal-black;
                     font-size: inherit;
                     text-align: left;
                     line-height: 1;
                     border: 0;
                     &:after {
                        margin-left: 0.255em;
                        content: "";
                        border-top: 0.3em solid transparent;
                        border-right: 0;
                        border-bottom: 0.3em solid transparent;
                        border-left: 0.3em solid;
                        float: right;
                        margin-top: 3px;
                     }
                  }
                  .select-col {
                     background: #dff1ff;
                  }
                  .second-level-dropdown {
                     min-width: 95px;
                     margin: 0;
                     padding: 0;
                     padding-right: 6px;
                     font-size: inherit;
                     background: transparent;
                     border: 0px;
                     z-index: 99;
                     // transform: translate3d(-93px, 0px, 0px)!important;
                     .dropdown-wrapper {
                        padding: 0.25rem 0;
                        // overflow: hidden;
                        // background: $filter-bg-color;
                        box-shadow: 0 0 30px 0 #dbdbdb;
                        background-color: #ffffff;
                        border-radius: 10px;
                        border: 1px solid rgb(239, 239, 239);

                        .dropdown-item {
                           &:active,
                           &:hover,
                           &:focus,
                           &:active {
                              color: $charcoal-black;
                              background-color: transparent;
                              background-color: #dff1ff !important;
                           }
                        }
                        .custom-control {
                           min-height: 16px !important;
                           padding-left: 18px;
                           line-height: 16px !important;
                           .custom-control-input {
                              left: 0;
                              width: 100%;
                              height: 100%;
                           }
                           .custom-control-input:checked
                              ~ .custom-control-label::before {
                              width: 0;
                              height: 0;
                              border-color: $tag-blue;
                              border: unset;
                              background-color: transparent;
                           }
                           .custom-control-input:checked
                              ~ .custom-control-label::after {
                              content: "\f14a";
                              font-family: "Font Awesome 5 Pro";
                              font-size: 12px;
                              text-align: center;
                              font-weight: bold;
                              color: $tag-blue;
                              background-image: unset;
                           }

                           .custom-control-label {
                              pointer-events: none;
                              &:before {
                                 top: 3px;
                                 left: -17px;
                                 width: 11px;
                                 height: 11px;
                                 border-radius: 2px;
                                 background-color: transparent;
                              }
                              &:after {
                                 top: 1.4px;
                                 left: -17px;
                                 width: 12px;
                                 height: 12px;
                              }
                           }
                        }
                     }
                  }
                  &:focus {
                     box-shadow: none;
                  }
                  &:active,
                  &:hover {
                     background-color: #f8f9fa;
                     // color: #1b8be0;
                  }
               }
            }
         }
      }
      .filterButtonWrapper.select-col {
         background: #dff1ff;
      }
      .unselect-col {
         .filterbutton {
            background: $filter-bg-color;
         }
      }
      .select-col {
         .filterbutton {
            background: #dff1ff;
         }
      }

      .sortButtonWrapper {
         border-radius: 15px !important;
         background: $filter-bg-color;
         color: $charcoal-black !important;
         border: 0 !important;
         .sortbutton {
            position: relative;
            font-size: 12px;
            font-weight: 600;
            color: $charcoal-black !important;
            background: $filter-bg-color;
            border-radius: 15px !important;
            border: 0 !important;
            &:after {
               opacity: 0;
               display: none;
            }
            .dropdown-toggle:focus {
               box-shadow: none;
            }
            .dropdown-item:active,
            .dropdown-item:hover {
               background-color: transparent;
               // color: #1b8be0;
            }
         }
         .dropdown-item {
            color: $charcoal-black;
            font-size: inherit;
            line-height: 1;
         }
      }
      .ghost-wrapper {
         position: absolute;
         z-index: 99;
         min-width: 95px;
         display: flex !important;
         flex-direction: column;
         margin-left: 0;
         // padding-top: 6px;
         font-size: inherit;
         background: transparent;
         border: 0px;
         top: 15px !important;
         right: -3px !important;
         left: auto !important;
         transform: unset !important;
         .dropdown-wrapper {
            padding: 0.25rem 0;
            box-shadow: 0 0 30px 0 #dbdbdb;
            background-color: #ffffff;
            border-radius: 10px;
            border: 1px solid rgb(239, 239, 239);
            .dropdown-button {
               &:after {
                  opacity: 0;
                  display: none;
               }
            }
            .dropdown-item {
               &:first-child {
                  border-top-left-radius: 4px;
                  border-top-right-radius: 4px;
               }
               &:last-child {
                  border-bottom-left-radius: 4px;
                  border-bottom-right-radius: 4px;
               }
               &:hover {
                  // background-color: #f8f9fa !important;
                  background-color: #dff1ff !important;
                  // color: #1b8be0;
               }
            }
         }
      }
   }
}

.media-mycard-desc {
   .sponsor-logo-placeholder {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
   }
}

.popoverContainer {
   .pagination-wrapper {
      width: inherit;
      text-align: center;
      .funds-pagination {
         display: inline-flex;
         .ant-pagination-item,
         .ant-pagination-prev,
         .ant-pagination-next {
            min-width: 24px;
            height: 24px;
            margin: 0;
            line-height: 22px;
         }
         .ant-pagination-prev,
         .ant-pagination-next {
            .ant-pagination-item-link {
               line-height: 19px;
            }
            &:hover {
               border: 1px solid #1d6091 !important;
            }
         }
         .ant-pagination-item-active {
            border: 1px solid #1d6091 !important;
         }
      }
      .jump-icn {
         width: 16px;
         position: relative;
         top: 2px;
         cursor: pointer;
      }
      .disabled {
         cursor: not-allowed;
      }
      .flip {
         transform: scaleX(-1);
      }
   }
}

// .filter-items{
//   line-height: 1;
//   font-size: 12px;
//   padding: 2px 6px;
//   margin: 0 5px 10px;
//   position: relative;
//   font-weight: 600;
//   border-radius: 15px;
//   border: 1px solid #dbdbdb;
//
//   // &:first-child {
//   //   margin-left: 0;
//   // }
//   //
//   // &:last-child {
//   //   margin-right: 0;
//   // }
//
//   & .fa-times {
//     font-size: 10px;
//     transition: all .2s;
//
//     &:hover {
//       cursor: pointer;
//       transform: scale(1.3);
//     }
//   }
// }
.go-back-section {
   width: 100%;
   display: inline-flex;
   font-family: $secondary-font;
   font-size: 14px;
   font-weight: 600;
   line-height: 1;
   color: $dark-active-color;
   span {
      display: inline-flex;
      line-height: 1;
      cursor: pointer;
   }
   img {
      padding-right: 8px;
   }
}

.itemListArea {
   .slick-next {
      right: 30px;
      top: 82px;
      z-index: 1;
   }

   .slick-slide {
      img {
         display: unset;
      }
   }

   .slick-prev {
      left: -24px;
      top: 82px;
      z-index: 1;
   }
   .slick-prev.slick-disabled:before,
   .slick-next.slick-disabled:before {
      display: none;
   }

   .slick-disabled {
      img {
         visibility: hidden;
      }
   }

   .slick-prev:before {
      content: unset;
   }
   .slick-next:before {
      content: unset;
   }

   .anticon::before {
      display: block;
   }

   .render-more-stocks-text {
      // position: absolute;
      width: 100%;
      display: inline-flex;
      justify-content: flex-end;
      padding: 4px 8px;
      font-family: $secondary-font;
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      line-height: 1;
      color: $primary-color-2;
      span {
         display: inline-flex;
         line-height: 1;
         cursor: pointer;
      }
      .fa-chevron-right {
         padding-left: 4px;
      }
   }
}
