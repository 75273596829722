.ssf-btn-primary {
   font-weight: $font-weight-bold;
   background: $primary-color;
   border: 0;

   &:hover {
      background: $primary-color-hover;
   }

   &:not(:disabled):not(.disabled):active,
   .disabled {
      background: $primary-color-hover;
   }

   &.disabled,
   &:disabled {
      background: $primary-color-hover;
   }
}

.ssf-btn-secondary {
   font-weight: $font-weight-bold;
   background: $secondary-color;
   border: 0;

   &:hover {
      background: $secondary-color-hover;
   }

   &:not(:disabled):not(.disabled):active,
   .disabled {
      background: $secondary-color-hover;
   }

   &.disabled,
   &:disabled {
      background: $secondary-color-hover;
   }
}

.ssf-btn-transparent {
   background-color: transparent;
   padding: 0;
   border-radius: 0;

   &:hover {
      background: transparent;
   }

   &:not(:disabled):not(.disabled):active,
   .disabled {
      background: transparent;
   }

   &.disabled,
   &:disabled {
      background: transparent;
   }
}

.ssf-btn-tertiary {
   font-weight: $font-weight-bold;
   background: $tertiary-color;
   border: 0;

   &:hover {
      background: $tertiary-color-hover;
   }

   &:not(:disabled):not(.disabled):active,
   .disabled {
      background: $tertiary-color-hover;
   }

   &.disabled,
   &:disabled {
      background: $tertiary-color-hover;
   }
}

.ssf-icon-button {
   cursor: pointer;
   color: #989a9c;
   width: 18px;
   height: 18px;
}
