.profile-container {
   font-family: "Roboto";
   margin-bottom: 20px;
}

.profile {
   padding-bottom: 40px;
   .vetted_status_row {
      border-top: 1px solid #dfe2e7;
   }
   .vetted_status_value {
      color: #5dacea;
      font-size: 14px;
      font-weight: 500;
      text-align: right;
   }
   .vetted_status_heading {
      color: #494a49;
      font-size: 14px;
      font-weight: 600;
      text-align: left;
   }

   .vetted_status_content {
      p {
         color: #494a49 !important;
         font-size: 12px !important;
         font-weight: 400;
         margin: 0;
      }
   }
}

.profile__section {
   background: #ffffff;
   box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
   max-width: 546px;
   margin: 0 auto;
   &.top {
      border-radius: 15px 15px 0px 0px;
   }
   &.bottom {
      border-radius: 0px 0px 15px 15px;
      padding-bottom: 15px;
   }
}

.profile__alert,
.profile__picture {
   max-width: 546px;
   margin: 0 auto;
}

.profile__user-info {
   padding: 15px 0px 10px;

   .form-group {
      margin-bottom: 5px;
      color: #202529;

      label {
         font-size: 14px;
      }
      input {
         font-size: 14px;
      }

      .field-value {
         font-weight: $font-weight-medium;
         font-size: 16px;
         color: #202529;
      }
   }
}

.change-password-form {
   font-weight: $font-weight-medium;
   width: 280px;

   .form-group {
      margin-bottom: 10px;
      color: #202529;

      label {
         font-size: 14px;
      }
      input {
         font-size: 14px;
      }

      .secondary-btn {
         padding: 6px 15px !important;
         font-size: 12px !important;
         border-radius: 30px;
         line-height: 1.4;
         background: #fff !important;
         color: #56a9e8 !important;
         height: 30px !important;
         border-color: #56a9e8 !important;
         transition: all 0.3s;
         &:hover {
            background: #56a9e8 !important;
            color: #fff !important;
         }
         &:focus {
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
         }
      }
   }
}

.change-password__footer {
   display: flex;
   justify-content: space-between;
   padding: 15px 15px 0 15px;
   margin-top: 10px;
   border-top: 1px solid #cdd4db;
}

.profile-editable-form {
   font-weight: $font-weight-medium;
   font-size: 14px;
   width: 280px;

   .form-group {
      margin-bottom: 10px;
      color: #202529;

      label {
         font-size: 14px;
         margin-bottom: 2px;
         padding-left: 2px;
      }
      input {
         font-size: 14px;
      }
   }
}

.profile-view-form {
   font-weight: $font-weight-medium;
   font-size: 14px;

   .form-group {
      margin-bottom: 10px;

      label {
         font-size: 14px;
         margin-bottom: 2px;
         padding-left: 2px;
         color: #878787;
      }

      .field-value {
         font-size: 16px;
         color: #202529;
         padding-left: 2px;
      }
   }
}

.profile__section-header {
   border-bottom: 1px solid #cdd4db;
   margin-bottom: 15px;
   padding-top: 15px;
}

.profile__section-title {
   font-weight: 600 !important;
   font-size: 14px !important;
   color: #878787;
   padding-bottom: 15px;
   margin: 0px;
   display: inline-flex;
   text-transform: uppercase;
}

.profile__section-title-required {
   font-weight: $font-weight-medium;
   padding-top: 14px;
   display: inline-flex;
   float: right;
   font-style: italic;
   color: #55a9e8;
   font-size: 10px !important;
}

.profile__section-title-required-label {
   font-weight: $font-weight-medium;
   display: inline-flex;
   font-style: italic;
   color: #55a9e8;
}

.profile__section-link {
   padding-top: 14px;
}

.profile__section-footer {
   display: flex;
   align-items: center;
   border-top: 1px solid #cdd4db;
   min-height: 60px;
   margin-left: -15px;
   margin-right: -15px;

   .form-group {
      margin: auto;

      .secondary-btn {
         padding: 6px 15px !important;
         font-size: 12px !important;
         border-radius: 30px;
         line-height: 1.4;
         background: #fff !important;
         color: #56a9e8 !important;
         height: 30px !important;
         border-color: #56a9e8 !important;
         transition: all 0.3s;
         &:hover {
            background: #56a9e8 !important;
            color: #fff !important;
         }
         &:focus {
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
         }
      }
   }
}

.profile-details-wrapper {
   .profile-element-wrapper {
      border-bottom: 1px solid #cdd4db;
      padding-bottom: 15px;
      margin-bottom: 15px;
      &:last-child {
         padding-bottom: 0px;
         margin-bottom: 0px;
         border-bottom: none;
      }
   }
}

.profile-description-heading {
   h4,
   h5 {
      display: inline-flex;
   }
   p,
   a {
      display: inline-flex;
      float: right;
   }
}

.profile-description-heading,
.profile-change-plan {
   h5 {
      font-size: 14px !important;
      font-weight: 600 !important;
   }
}

.profile-boxed-details {
   padding: 15px;
   border: 1px solid #cdd4db;
   border-radius: 5px;
}

.profile-change-plan {
   h6 {
      font-size: 17px !important;
      display: inline-flex;
      margin-bottom: 0px;
   }
   p {
      margin-bottom: 0px;
   }
   .basic-section,
   .premium-section {
      padding-top: 15px;
      padding-bottom: 15px;
   }
}

.profile__subscription-button {
   .form-group {
      margin: auto;
      .secondary-btn {
         padding: 6px 15px !important;
         font-size: 12px !important;
         border-radius: 30px;
         line-height: 1.4;
         background: #fff !important;
         color: #56a9e8 !important;
         height: 30px !important;
         border-color: #56a9e8 !important;
         transition: all 0.3s;
         @media only screen and (max-width: 760px) {
            font-size: 9px !important;
         }
         &:hover {
            background: #56a9e8 !important;
            color: #fff !important;
         }
         &:focus {
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
         }
      }
   }
}
$secondary-blue: #1e5f91;

.profile-description-list {
   font-size: 12px;
   margin: 0px;
}

.profile-description-list,
.premium-feature-list {
   ul {
      margin: 0px;
      padding-left: 20px;
      list-style: none;
      li {
         list-style: disc;
         color: #1e5f91;
         line-height: 2.08;
         font-size: 12px;
         p {
            display: inline-block;
            color: #8d8d8d;
            vertical-align: top;
            line-height: 2.08;
         }
         b {
            font-weight: 600 !important;
         }
      }
   }
}

.profile-plan-price {
   margin: 0px 0px 20px 0px;
   p {
      font-size: 12px;
   }
   b {
      font-size: 15px;
   }
   .profile-mute-text {
      font-size: 11px;
   }
}

.premium-feature-list {
   p {
      font-size: 12px;
      margin-bottom: 10px;

      &:last-child {
         margin-bottom: 0px;
      }
   }
}

.profile-border-right {
   border-right: 1px solid #eaeaea;
}

.profile-mute-text {
   font-size: 10px;
   color: #8d8d8d;
   font-family: "Open Sans";
}

.pcm__description p {
   margin-bottom: 0px;
}

.pcm__button-group {
   margin-top: 20px !important;
   .btn {
      min-width: 100px;
      margin-right: 10px;
   }
   .primary-btn {
      padding: 6px 15px !important;
      font-size: 12px !important;
      border-radius: 30px;
      line-height: 1.4;
      background: #56a9e8 !important;
      color: #fff !important;
      height: 30px !important;
      border-color: #56a9e8 !important;
      transition: all 0.3s;
      &:hover {
         background: #fff !important;
         color: #56a9e8 !important;
      }
      &:focus {
         box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
      }
   }
}

.profile-plan-details {
   margin-top: 10px;
   h6 {
      font-size: 14px !important;
   }
   p {
      margin-bottom: 0px;
   }
}

.profile-card-details {
   margin-top: 10px;
   h6,
   p {
      display: inline-flex;
   }
   p {
      float: right;
   }
}

.profile-billing-address {
   margin-top: 10px;
}

.profile-billing-check {
   h5,
   p,
   .ant-checkbox-wrapper {
      display: inline-flex;
   }
   .ant-checkbox-wrapper {
      float: right;
   }
   .ant-checkbox-inner {
      width: 25px;
      height: 25px;
      &::after {
         top: 40%;
         left: 20%;
         width: 8.428571px;
         height: 18.28571428px;
         border-color: #ffffff !important;
      }
   }
   .ant-checkbox-checked {
      .ant-checkbox-inner {
         background-color: #1890ff !important;
         border-color: #1890ff !important;
         &::after {
            border-color: #ffffff !important;
         }
      }
   }
}

.mt-10 {
   margin-top: 10px;
}

.newProfile {
   .ant-modal-content {
      border-radius: 20px;
      .ant-modal-close-x {
         display: block;
         width: 58px;
         height: 54px;
         line-height: 54px;
      }
   }
   .ant-notification-notice-message {
      display: none;
   }
   .invalid-feedback {
      color: #a5685f !important;
   }
   .ant-select-selection-search {
      right: 0 !important;
      input {
         text-align: right;
      }
   }
   .ant-select-dropdown {
      border-radius: 10px;
   }
}
.cardContainer {
   height: 112px;
   width: 664px;
   border-radius: 2px;
   padding: 15px 11px 11px 24px;
   border: none;
}
.cardLabel {
   font-family: Roboto;
   font-size: 15px;
   font-style: normal;
   font-weight: 700;
   line-height: 18px;
   letter-spacing: 1px;
   text-align: left;
   color: #4f4f4f;
}
.cardBody {
   font-family: Roboto;
   font-size: 13px;
   font-style: normal;
   font-weight: 400;
   line-height: 17px;
   letter-spacing: 0px;
   text-align: left;
   color: #4f4f4f;
}
.cardFooter {
   font-family: Roboto;
   font-size: 13px;
   font-style: normal;
   font-weight: 400;
   line-height: 15px;
   letter-spacing: 0px;
   text-align: left;
   color: rgba(79, 79, 79, 0.7);
}
.subscription-container {
   width: 118px;
   height: 50px;
   position: absolute;
   right: 0;
   top: 0;
}

.corner {
   width: 0;
   height: 0;
   //border-top: 150px solid #ffcc00;
   border-top: 50px solid #bddeff;
   border-bottom: 150px solid transparent;
   border-left: 150px solid transparent;
   position: absolute;
   right: 0;
}
.newFeatureContainer {
   width: 83px;
   height: 18px;
   position: absolute;
   right: 0;
   top: 0;
   padding: 0;
}
.newFeature {
   color: #fff;
   background: rgba(27, 173, 161, 1);
   font-family: Roboto;
   font-size: 11px;
   font-style: normal;
   font-weight: 500;
   text-align: center;
   padding: 5px 7px;
}
.corner span {
   position: absolute;
   top: -45px;
   width: 100px;
   left: -90px;
   text-align: center;
   -webkit-transform: rotate(0deg);
   transform: rotate(0deg);
   display: block;
   font-family: Roboto;
   font-size: 12px;
   font-style: normal;
   font-weight: 500;
}
.FreePlanLabel {
   font-family: Roboto;
   font-size: 13px;
   font-style: normal;
   font-weight: 600;
   line-height: 15px;
   letter-spacing: 1px;
   text-align: right;
   color: rgba(43, 136, 216, 1);
}
.subscriptionAmtContainer {
   font-family: Roboto;
   text-align: center;
}
.subscriptionAmount {
   font-style: normal;
   font-weight: 400;
   padding-bottom: 5px;
   text-align: left;
}
.subscriptionAmtContainer div {
   position: absolute;
   right: 40px;
   bottom: 18px;
   display: grid;
}
.subscriptionAmtContainer button {
   font-family: Roboto;
   font-size: 13px;
   font-style: normal;
   font-weight: 500;
   text-align: center;
   width: auto;
   background: transparent;
   color: rgba(43, 136, 216, 1);
   border: 1px solid rgba(43, 136, 216, 1);
}
.cancelSubscription {
   border-radius: 5px !important;
   color: #2d88d8 !important;
   border: 1.5px solid #2d88d8 !important;
   font-family: Roboto;
}

// subscription plans design
.subscription-card-container {
   .empty-box {
      min-height: 15px;
   }
   .subscription-card-wrapper {
      .subscription-card {
         position: relative;
         border: 0;
         background: #ffffff;
         &:before {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: "";
            background: inherit;
            transition: transform 0.3s ease;
            transform-origin: 50% 50%;
         }
         &:hover {
            z-index: 2;
            // cursor: pointer;
            &:before {
               // transform: scale(1.01);
               border-color: #bddeff;
               border-radius: 2px;
               box-shadow: 0 0 4px 0 #bddeff66;
               border: 1.5px solid #bddeff;
            }
         }
         .ant-card-body {
            padding: 18px 24px;
         }
         .subscription-card-row {
            .subscription-card-free-plan-text {
               font-family: $secondary-font;
               font-size: $font-size-15px;
               font-style: normal;
               font-weight: 600;
               line-height: 15px;
               text-align: right;
               letter-spacing: 1px;
               color: $blue-color;
            }
            .subscription-plan-desc-wrapper {
               .subscription-plan-details {
                  label {
                     margin: 0;
                     font-family: $secondary-font;
                     font-size: $font-size-17px;
                     font-style: normal;
                     font-weight: 700;
                     letter-spacing: 1px;
                  }
                  p {
                     font-family: $secondary-font;
                     font-size: $font-size-15px;
                     font-style: normal;
                     font-weight: 400;
                     color: $dark-charcoal-black;
                  }
               }
               .subscription-plan-date {
                  min-height: 15px;
                  font-family: $secondary-font;
                  font-size: $font-size-15px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 15px;
                  color: rgba(79, 79, 79, 0.7);
               }
            }
            .subscription-plan-pricing-wrapper {
               display: flex;
               flex-direction: column;
               justify-content: flex-end;
               .subscription-plan-amount {
                  font-family: $secondary-font;
                  font-size: $font-size-13px;
                  font-style: normal;
                  font-weight: 400;
                  color: $dark-charcoal-black;
                  span {
                     sup {
                        font-family: $secondary-font;
                        font-size: $font-size-15px;
                        font-style: normal;
                        font-weight: 500;
                        letter-spacing: 1px;
                     }
                     strong {
                        padding: 0 3px;
                        font-family: $secondary-font;
                        font-size: $font-size-20px;
                        font-style: normal;
                        font-weight: 700;
                        letter-spacing: 1px;
                        color: $dark-charcoal-black;
                     }
                  }
               }
               .subscription-plan-btn {
                  font-family: $secondary-font;
                  font-size: $font-size-15px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 15px;
                  color: $blue-color;
                  background: #ffffff;
                  border-radius: 4px;
                  border: 1.5px solid #2d88d8;
                  &:hover,
                  &:active {
                     color: #ffffff;
                     background: $blue-color;
                     border: 1.5px solid $blue-color;
                  }
               }
            }
         }
         .subscription-card-status-tag {
            position: absolute;
            top: 0;
            right: 0;
            .subscription-card-status-text {
               padding: 5px 10px;
               font-family: $secondary-font;
               font-size: $font-size-13px;
               font-style: normal;
               font-weight: 500;
               line-height: 13px;
               letter-spacing: 0.01em;
               color: #ffffff;
               background: #1bada1;
               border-radius: 0px 2px 0px 0px;
            }
         }
      }
      .activated-arc {
         &:before {
            content: "";
            position: absolute;
            right: 0;
            width: 0;
            height: 0;
            border-top: 50px solid #bddeff;
            border-bottom: auto solid transparent;
            border-left: 150px solid transparent;
         }
         .subscription-card-status-text {
            position: relative;
            padding: 7px 12px !important;
            font-family: $secondary-font;
            font-size: $font-size-13px !important;
            font-style: normal;
            font-weight: 500;
            line-height: 14px;
            color: rgba(63, 63, 63, 0.8) !important;
            background: transparent !important;
         }
      }
   }
}

.subscription-plan-btn {
   font-family: $secondary-font;
   font-size: $font-size-15px;
   font-style: normal;
   font-weight: 500 !important;
   line-height: 15px;
   color: $blue-color !important;
   background: #ffffff !important;
   border-radius: 4px !important;
   border: 1.5px solid #2d88d8 !important;
   &:hover,
   &:active {
      color: #ffffff !important;
      background: $blue-color !important;
      border: 1.5px solid $blue-color !important;
   }
}

.subscription-modal-container {
   .modal-content {
      border-radius: 2px;
   }
   .modal-close-icon {
      position: absolute;
      right: 15px;
      top: 10px;
      font-size: 16px;
      font-weight: normal;
      cursor: pointer;
   }
   .close {
      color: #4a4a4a;
   }
   .header-wrapper h1 {
      margin: 10px 0px !important;
      font-family: Roboto;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 500;
      color: rgba(79, 79, 79, 1);
   }
   .subscription-plan-checkbox-wrapper span.ant-checkbox-inner {
      border-radius: 50px;
      width: 18px;
      height: 18px;
      border: 1.2px solid !important;
      color: #979797;
   }
   .subscription-plan-checkbox-wrapper {
      .ant-checkbox-checked::after {
         border-radius: 25px;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
         background-color: #6aa7e1 !important;
         color: #6aa7e1 !important;
      }
      .ant-checkbox-checked .ant-checkbox-inner::after {
         border-color: #ffffff !important;
      }
   }
   .unsubscribe-button-wrapper {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: flex-end;
      max-width: 50%;
      margin: 0px 0 0 auto !important;
      .primary-btn {
         font-family: $secondary-font;
         font-size: $font-size-13px;
         font-weight: 600;
         font-style: normal;
         font-weight: 500;
         line-height: 15px;
         text-align: center;
         letter-spacing: 0.02em;
         color: #ffffff;
         border-color: #6aa7e1;
         border-radius: 24px;
         background: #6aa7e1;
      }
      .secondary-btn {
         margin-right: 10px;
         font-family: $secondary-font;
         font-size: $font-size-13px;
         font-weight: 600;
         line-height: 15px;
         letter-spacing: 1px;
         color: #6aa7e1;
         border: 0;
         // box-shadow: none;
         background: transparent;
      }
   }
   .subscribe-sub-heading {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 14px;
   }
   .subscribe-footer-content {
      margin: 18px 0px 18px 0;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
   }
}
