.top-feeds-wrapper {
   padding: 10px;
   border: 1px solid #ced4da;
   border-radius: 8px;
   background-color: #e4e4e4;
   background-image: url("/assets/images/Magnifi_Icon_Blue_Backdrop.png");
   background-repeat: no-repeat;
   background-position-x: 94%;
   background-size: 32% auto;
   background-position-y: center;
   transition: ease-in-out 0.4s;

   .slick-active.slick-current {
      color: red;
      margin: 0;
   }

   .feeds-item {
      color: black;
   }

   #marquee-feed {
      height: 153px;
      overflow: hidden;
   }

   .feeds-type-writer {
      font-family: "Open Sans";
      font-size: 1.56rem;
      font-weight: 800;
      overflow: hidden; /* Ensures the content is not revealed until the animation */
      border-right: none; /* The typwriter cursor */
      white-space: nowrap; /* Keeps the content on a single line */
      margin: 0; /* Gives that scrolling effect as the typing happens */
      letter-spacing: 0; /* Adjust as needed */
      cursor: pointer;
      animation: typing 1s steps(100, end);
      &:nth-child(odd) {
         color: #4a4a4a;
      }
      &:nth-child(even) {
         color: #1b8be0;
      }
   }

   /* The typing effect */
   @keyframes typing {
      from {
         width: 0;
      }
      to {
         width: 100%;
      }
   }
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
   #marquee-feed {
      height: 60px !important;
   }

   .feeds-type-writer {
      font-size: 10px !important;
   }

   .top-feeds-wrapper {
      background-size: 32% auto;
   }
   // background-size: 110px auto;
}

@media only screen and (min-width: 1280px) and (max-width: 1439px) {
   #marquee-feed {
      height: 70px !important;
   }

   .feeds-type-writer {
      font-size: 11px !important;
   }

   .top-feeds-wrapper {
      background-size: 32% auto;
   }
}

@media only screen and (min-width: 1440px) and (max-width: 1919px) {
   #marquee-feed {
      height: 80px !important;
   }

   .feeds-type-writer {
      font-size: 13px !important;
   }

   .top-feeds-wrapper {
      background-size: 32% auto;
   }
}

@media only screen and (min-width: 1920px) and (max-width: 2559px) {
   #marquee-feed {
      height: 104px !important;
   }

   .feeds-type-writer {
      font-size: 1rem !important;
   }

   .top-feeds-wrapper {
      background-size: 32% auto;
   }
}

@media only screen and (min-width: 2560) {
   #marquee-feed {
      height: 104px !important;
   }

   .feeds-type-writer {
      font-size: 1rem !important;
   }

   .top-feeds-wrapper {
      background-size: 32% auto;
   }
}
