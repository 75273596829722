//
// Variables
// --------------------------------------------------
@import "../layouts/WebDashboard/colors";
@import "../layouts/WebDashboard/variables";

//## Font, line-height, and color for body text, headings, and more.
$default-font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial",
   sans-serif;
$font-awesome-pro: "Font Awesome 5 Pro";

$font-s: 0;
$font-xs: 1rem;
$font-xs-s: 1.1rem;
$font-xs-m: 1.2rem;
$font-sm: 1.4rem;
$font-sm-m: 1.5rem;
$font-lg: 1.6rem;
$font-xl: 1.8rem;
$font-xl-s: 2rem;
$font-xl-ls: 2.1rem;
$font-xl-l: 2.2rem;
$font-xxl: 2.4rem;
$font-xxl-ll: 2.5rem;
$font-xxl-l: 2.8rem;
$font-xxxl: 3rem;

$font-sm-s: 14px;
$font-lg-m: 16px;
$font-xs-s-px: 11px;
$font-sm-m-px: 15px;
$font-lg-l: 25px;
$font-lg-px: 24px;
$font-xl-ls-px: 21px;
$font-xl-px: 18px;
$font-xxl-l-px: 28px;
$font-xs-m-px: 12px;
$font-xs-px: 10px;
$font-xl-ss-px: 19px;
$font-lg-l-px: 17px;
$font-xs-mm-px: 13px;
$font-xxxxlll-px: 42px;
$privacy-fontsize: 4rem;
$html-fontsize: 10px;

$font-weight-thin: 100;
$font-weight-thin-italic: 100;
$font-weight-light: 300;
$font-weight-light-italic: 300;
$font-weight-regular: 400;
$font-weight-regular-italic: 400;
$font-weight-medium: 500;
$font-weight-medium-italic: 500;
$font-weight-bold: 700;
$font-weight-bold-italic: 700;
$font-weight-black: 900;
$font-weight-black-italic: 900;
$font-weight-600: 600;

$body-width: 1080px;

$body-base-size: $font-lg;
$body-background-color: #fff;
$body-font-color: #333333;
$body-line-height: 1.45;
$default-font-weight: 400;
$label-font-weight: 200;
$semibold-font-weight: 500;
$bold-font-weight: 700;

$main-max-width: 61.25em;
$main-padding: 0 22px;
$main-padding-767: 0 25px;
$main-padding-320: 0 15px;

//-------------------------------
//		Container definition
//-------------------------------
$container-width: 100%;
$container-largewidth: 1080px;
$container-xlwidth: 1150px;

// ===============================
//      Color definition
// ===============================
$primary-color: #f36300;
$primary-color-hover: #ce6015;

$secondary-color: #000;
$secondary-color-hover: #333;

$tertiary-color: #3ea0ea;
$tertiary-color-hover: #0073b9;

$footer-link-color: #0070c9;

$gray20: #333;
$black: #000;
$white: #fff;
$blue: #08c;

$h2-fontsize: $font-xxl;
$h2-lineheight: 1.08654;
$h2-weight: 200;
$h2-letter-spacing: 0;

$h4-fontsize: $font-xl-l;
$h4-letter-spacing: 0;

$h5-fontsize: $font-xl;
$h5-letter-spacing: 0;

$p-fontsize: $font-lg;
$p-lineheight: 1.5;
$p-margin: 0 0 1em;

$p-legal-fontsize: $font-sm;
$p-legal-fontcolor: #737373;
$autofill-disabled: #bbbbbb;
//---------------------------------
// Footer variable definitions
//---------------------------------
$footer-background-color: #f2f2f2;
$footer-wrapper-padding: 18px 22px;
$global-footer-fontsize: $font-xxl;
$global-footer-padding: 50px 0 70px;
$global-footer-fontcolor: #bfbfbf;

//---------------------------------
// Fonts
//---------------------------------

$primary-font: "Open Sans";
$secondary-font: "Roboto";

$font-size-49px: 2.4rem;
$font-size-30px: 1.8rem;
$font-size-29px: 1.6rem;
$font-size-28px: 1.5rem;

$font-size-26px: 1.2rem; // 1.35vw;
$font-size-25px: 1.15rem; //1.2vw;
$font-size-23px: 1.12rem;
$font-size-22px: 1.1rem; //1.2vw;
$font-size-20px: 1rem; //1.15vw;
$font-size-18px: 0.9375rem; //1.05vw;

$font-size-17px: 0.925rem; //1.02vw;
$font-size-16px: 0.875rem; //1vw;

$font-size-15px: 0.815rem; //0.91vw;   //13px;
$font-size-14px: 0.8rem; //12px;
$font-size-13px: 0.75rem;
$font-size-12px: 0.625rem; //10px;
$font-size-11px: 0.6rem;

$font-size-10px: 0.575em;
$font-size-9px: 0.5em;
$font-size-8px: 8px;

$font-size-16vw: 1vw;

$accounts-summary-card-col: 4px;

$magnifi-icon-blue-backdrop: "/assets/images/Magnifi_Icon_Blue_Backdrop.png";

//---------------------------------
// Color codes
//---------------------------------

$black: #000000;
$border-color: #dbdbdb;
$border-color-4: #bcbcbc;
$border-color-opacity: rgba(0, 0, 0, 0.1);
$primary-color: #56a9e8;
$sub-header-color: #bababb;
$dark-active-color: #1e5f91;
$in-active-color: #dbdbdb;
$tag-grey-bg-color: #f5f5f5;
$tag-blue-bg-color: #eff8ff;
$label-black-color: #4a4a4a;
$label-black-color-3: #474747;
$white-color: #ffffff;
$dark-blue-color: #1d6091;
$indicator-color: #d6d6d6;
$bg-color-light: #f7f7f7;
$bg-color-hover: #dff1ff;
$lock-color: #c3c3c3;
$label-black-color-2: #424242;
$primary-color-opacity: #61aee9;
$border-color-2: #e2e2e2;
$border-color-3: #d9dad9;
$red-color: #a5685f;
$red-color-opacity: #a5685f26;
$disabled-text-color: #c6c6c6;
$other-grey-color: #a0a0a0;
$other-grey-color-2: #c5c5c5;
$other-dark-grey-color: #707070;
$other-bg-color: #efefef;
$other-bg-color-2: #eaeaea;
$other-active-color: #57a9e8;
$other-red-color: #b07b73;
$orange-color: #e26a05;
$primary-color-2: #5eade9;
$other-grey-color-3: #9a9a9a;
$other-grey-color-4: #dee2e6;
$selected-card-border-color: #e7f3fb;
$dark-green-color: #005383;
$light-green-color: #3f7367;
$light-red-color: #a96f67;
$dark-grey-color-2: #807f7f;
$light-charcoal-black: #6c757d;
$dark-charcoal-black: #4f4f4f;
$disabled-text-color-2: #d9d9d9;
$disbaled-bg-color: #f5f5f5;
$vehicle-color: #57869c;
$blue-color: #55a9e8;
$light-blue-color: #6face4;
// $light-blue-color: #5a87cb;
$error-color: #dc3545;
$background-color-light-blue: #f5f7f9;
$light-grey-color: #797979;
$navigation-text-color: #5488c9;
$total-blue-text: #145b8a;
$red-error-code: #db3f3e;

$primary-color-opacity-2: rgba(86, 169, 232, 0.21);
$primary-color-opacity-05: rgba(86, 169, 232, 0.05);
