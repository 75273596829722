@import "../../node_modules/bootstrap/scss/bootstrap.scss";

// @import "../../node_modules/@fortawesome/fontawesome-pro/css/fontawesome.css";
// @import "../../node_modules/@fortawesome/fontawesome-pro/css/solid.css";
// @import "../../node_modules/@fortawesome/fontawesome-pro/css/light.css";
// @import "../../node_modules/@fortawesome/fontawesome-pro/css/brands.css";
// @import "../../node_modules/@fortawesome/fontawesome-pro/css/regular.css";

@import "../../node_modules/react-datepicker/dist/react-datepicker.css";

@import "fonts.scss";

@import "../scss/App.scss";

/******* Override ***********/
@import "./override/bootstrap";
@import "./override/fontawesome";

/******* variables ***********/
@import "./variables";
@import "./base/global";

/******* MIXINS *******/
@import "./mixins/mixins";

/******* Components ***********/
@import "./components/button";
@import "./components/modal";
@import "./components/datepicker";
@import "./components/tick-icon";
@import "./components/navbar";
@import "./components/spinner";
@import "./components/toggle-switch";
@import "./components/os-checkbox";
@import "./components/overlay";
@import "./components/checkbox";
@import "./components/csvpreviewupload";
@import "./components/video-player";
@import "./components/toggle";
@import "components/CountryAndCurrency/_index.scss";
@import "components/ProtectedButton/_index.scss";

/******* Pages ***********/
@import "./pages/login";
@import "./pages/main";
@import "./pages/register";
@import "./pages/settings";
@import "./pages/profile";
@import "./pages/broker";
@import "./pages/about";
@import "./pages/contact";
@import "./pages/account-confirm";
@import "./pages/securities-page";
@import "./pages/sfm-modal-changes";
@import "./pages/sfm-responsive-changes";
@import "./responsive/responsive.scss";
@import "./pages/account-page";

@import "../layouts/index";
