@import "./Elements/index";
@import "./Pages/Allocation/index";
@import "./Pages/OnBoardScreen/index";
@import "./Pages/GettingStarted/index";
@import "./responsive";

.community-content-wrapper {
   min-height: calc(100vh - 60px);
   // overflow: hidden;
   position: relative;
   justify-content: center;
   background: $bg-color-light !important;
   background-color: $bg-color-light !important;
   // background: $body-background-color !important;
   // background-color: $body-background-color !important;

   .navigation-button {
      margin-bottom: 28px;
      padding-left: 0px;
      font-size: $font-lg-m;
      color: $navigation-text-color;
      font-weight: 500 !important;

      &:hover {
         color: $navigation-text-color;
      }

      span {
         margin-left: 10px;
      }
   }

   .main-heading {
      font-size: 36px !important;
      font-weight: 500;
      color: black;
   }

   .default-button,
   .secondary-button,
   .ant-btn-default {
      padding: 4px 25px;
      font-family: $primary-font;
      font-style: normal;
      font-weight: 600;
      font-size: $font-size-16px;
      color: $primary-color;
      border-radius: 30px;
      border-color: $primary-color;
      i {
         margin-right: 5px;
      }
      span {
         padding-left: 3px;
      }
   }

   .primary-button,
   .ant-btn-primary {
      padding: 4px 25px;
      color: white !important;
      font-family: $primary-font;
      font-style: normal;
      font-weight: 600;
      font-size: $font-size-16px;
      border-radius: 30px;
      border-color: $total-blue-text;
      background: $total-blue-text;
      background-color: $total-blue-text;
      i {
         margin-right: 5px;
      }
      span {
         padding-left: 3px;
      }
   }
}

.no-portfolio-found {
   width: 100%;
   height: 100%;
   padding: 3rem;
   display: flex;
   justify-content: center;
   align-items: center;
   background: #f5f7f9;
}
