$select-color: #315e8d;
$border-color: #e7e7e7;

.country-current-dropdown-container {
   position: relative;
   width: 64px;
   height: 30px;
   padding: 0 18px 0 0;

   .ant-select-selection-item {
      .country-current-icon {
         font-size: 13px !important;
         font-weight: 600;
         color: #3a80b7;
      }
   }
   .country-current-icon {
      margin-right: 4px;
      font-size: 11px !important;
   }
   .country-current-text {
   }
   .ant-select {
      position: absolute;
      left: -118px;
      right: unset;
      width: 140px !important;
      background-color: #ffffff !important;
      border-radius: 2px;
      .ant-select-selector {
         padding: 0 !important;
         border: 0 !important;
         background: transparent !important;
         background-color: transparent !important;
         box-shadow: none !important;
         .ant-select-selection-item {
            display: flex;
            justify-content: space-between;
            opacity: 1 !important;
            font-weight: 500;
            color: $select-color;
            margin-left: 10px;
         }
         .country-current-text {
            display: none;
         }
      }
      .ant-select-arrow {
         top: 44%;
         right: 0;
         margin-right: 10px;
         .fa-caret-down {
            font-size: 16px;
            color: $select-color;
         }
      }
   }

   .country-current-dropdown-wrapper {
      min-width: 120px !important;
      font-family: $secondary-font;
      .ant-select-item-group {
         font-size: 11px;
         color: $select-color;
         border-bottom: 1px solid $border-color;
      }
      .ant-select-item-option-selected {
         .ant-select-item-option-content {
            color: #3e3e3e !important;
            font-weight: 600 !important;
         }
      }
      .country-current-dropdown-options,
      .ant-select-item {
         padding: 5px 12px;
         color: #3a80b7 !important;
         font-weight: 600;
         font-size: 11px;
         .ant-select-item-option-active,
         .ant-select-item-option-content {
            width: 100%;
            display: flex;
            align-items: center;
            color: #9e9e9e;
            font-weight: 400;
            .country-current-text {
               padding-left: 5px;
            }
         }
      }

      div {
         &::-webkit-scrollbar {
            width: 4px !important;
            border-radius: 6px;
            background-color: transparent;
         }

         &::-webkit-scrollbar-thumb {
            width: 4px !important;
            border-radius: 6px;
            background-color: transparent;
         }

         &:hover {
            &::-webkit-scrollbar-thumb {
               background-color: #dbdbdb !important;
            }
         }
      }
   }
   @media (max-width: 991px) {
      padding: 0;
      width: 30px;
      .country-current-icon {
         margin-right: 2px;
      }
   }
}
