.orange-checkbox {
   display: block;
   position: relative;
   padding-left: 35px;
   margin-bottom: 12px;
   cursor: pointer;
   font-size: 22px;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
}

/* Hide the browser's default checkbox */
.orange-checkbox input {
   position: absolute;
   opacity: 0;
   cursor: pointer;
   height: 0;
   width: 0;
}

/* Create a custom checkbox */
.checkmark {
   position: absolute;
   top: 0;
   left: 0;
   height: 20px;
   width: 20px;
   border-radius: 4px;
   margin-left: 0px;
   background-color: white;
   // border:2px solid #e26b02
   border: 2px solid #eeefee;
}

/* When the checkbox is checked, add a blue background */
.orange-checkbox input:checked ~ .checkmark {
   background-color: #e26b02;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
   content: "";
   position: absolute;
   display: none;
}
.m-block {
   display: block;
   width: 100%;
}
/* Show the checkmark when checked */
.orange-checkbox input:checked ~ .checkmark:after {
   display: block;
}

/* Style the checkmark/indicator */
.orange-checkbox .checkmark:after {
   left: 4px;
   top: 0px;
   width: 7px;
   height: 12px;
   border: solid white;
   border-width: 0 2px 2px 0;
   -webkit-transform: rotate(45deg);
   transform: rotate(45deg);
}
