$background: #f7f7f7;
$border: #b7b7b7;
$text-color: #4a4a4a;
$text-color-highlighted: #1e5f91;
$fontRoboto: "Roboto";
$fontOpenSans: "Open Sans";

.planHeader {
   display: flex;
   align-items: center;
   margin-bottom: 20px;
   .subHeader {
      cursor: pointer;
      font-size: 22px;
      color: $border !important;
   }
   .active {
      color: $text-color-highlighted !important;
      text-decoration: underline;
   }
}
.subtext {
   font-family: "Open Sans", serif;
   font-size: 14px;
   color: #4a4a4a;
   margin-bottom: 15px;
}
.separator {
   height: 18px;
   margin: 0 24px;
   border-radius: 2px;
   border: 1px solid $border;
}
