#investment {
   .nav-pills .dropdown-menu {
      margin-left: 0px !important;
      left: 50% !important;
      transform: translate(-50%, 46px) !important;
      z-index: 100 !important;
   }

   .overlay.active {
      z-index: 2000;
   }

   .referral-link {
      font-weight: 700 !important;
      font-size: 14px !important;
      color: #55a9e8 !important;
      padding: 0;
      text-decoration: none;
      position: absolute;
      right: 460px;

      &:hover {
         color: #55a9e8;
         text-decoration: none;
      }
   }
}

.portfolio-dropdown {
   position: relative;

   &:hover {
      z-index: 101;
      .delete-icon {
         display: block;
      }
   }

   .delete-icon {
      position: absolute;
      top: 0;
      right: 8px;
      font-size: 12px;
      z-index: 150;
      display: none;
      cursor: pointer;
   }

   .fa-trash-alt {
      z-index: 2000;
   }
}

.account-container {
   .text-heading {
      color: $sfmodal-header;
      font-weight: 600;
   }

   .nav {
      max-width: max-content;
      text-align: center;
      margin: 0 auto;
      border-radius: 30px;
      li {
         border-radius: 30px;
         a {
            border-radius: 30px;
         }
      }
   }
}

.upl-tool-info {
   .tooltip-inner {
      text-align: left !important;
      line-height: 1.6;
   }
}

.ant-upload-disabled {
   &:hover {
      .upld-cont-info,
      .arrow-up {
         display: block !important;
      }
   }
}

.arrow-up {
   display: none;
   position: absolute;
   top: 33px;
   left: 10px;
   width: 0;
   height: 0;
   border-left: 8px solid transparent;
   border-right: 8px solid transparent;
   border-bottom: 8px solid #4a4a4a;
   // opacity: .7;
}

.upld-cont-info {
   // transform: translate3d(364px, 559px, 0px) !important;
   // top: -13px !important;
   // left: -13% !important;

   display: none;
   max-width: 200px;
   padding: 0.25rem 0.5rem;
   color: #fff;
   text-align: center;
   background-color: #4a4a4a;
   border-radius: 0.25rem;
   text-align: left !important;
   line-height: 1.6;
   position: absolute;
   top: 40px;
   font-size: 0.7rem !important;

   // .tooltip-inner {
   //   text-align: left !important;
   //   line-height: 1.6;
   // }
   // // .arrow {
   // //   left: 20px !important;
   // // }
}

.account-modal {
   .content-wrapper {
      margin-bottom: 0 !important;
   }

   .modal-content {
      width: 85%;

      .account-dropdown-element {
         display: flex;
         flex-direction: column;
         padding-bottom: 2rem;
         margin: 0;
         font-family: Open Sans;
         font-weight: 600;
         font-size: 0.8rem;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.35;
         letter-spacing: normal;
         text-align: left;
         span {
            color: #1e5f91 !important;
         }
         .dd-input-area {
            height: auto;
            padding: 7px 0;
            font-size: 0.8rem;
            color: #1e5f91;
            border: 0;
            border-radius: 0;
            border-bottom: 1px solid;
            border-bottom-color: #1e5f91;
            background-color: transparent;
            transition: 0.2s ease all;
            //width: 100%;
            &::placeholder {
               color: #1e5f91;
            }
            &:focus,
            &:hover {
               border-bottom-color: #1e5f91;
               box-shadow: none !important;
            }
         }
      }
   }
}

.account-dropdown-wrapper {
   .show {
      display: block !important;
      border-radius: 6px !important;
   }
   .dropdown-toggle {
      width: 100%;
      min-height: 34px;
      text-align: left;
   }
   .dropdown-toggle::after {
      position: absolute;
      right: 0;
   }
}

.upload-form-content {
   label {
      margin: 0;
      font-size: 0.8rem;
      font-weight: normal;
      color: #1e5f91 !important;
   }

   .element-box {
      display: flex;
      flex-direction: column;
      padding-bottom: 25px;
      margin: 0;
      font-family: "Open Sans";
      font-weight: 600;
      font-size: 0.8rem;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.35;
      letter-spacing: normal;
      text-align: left;

      .input-area {
         height: auto;
         padding: 7px 0;
         font-size: 0.8rem;
         color: #1e5f91 !important;
         background-color: transparent !important;
         border: 0;
         border-radius: 0;
         border-bottom: 1px solid;
         border-bottom-color: #1e5f91 !important;
         -webkit-transition: 0.2s ease all;
         transition: 0.2s ease all;
      }
   }

   .upload-btn {
      position: relative;
      padding-left: 0;
      padding-bottom: 15px;
      // flex-direction: row;

      .ant-btn {
         font-size: 12px;
         border-radius: 30px;
         background-color: transparent;
         border-color: #1e5f91;
         color: #1e5f91;
      }

      .sample-file {
         margin-left: auto;
         cursor: pointer;
         // display: flex;
         // align-items: center;
         font-size: 12px;
         font-weight: 400;
         position: absolute;
         right: 0;
         top: 10px;

         & a {
            color: #1e5f91;
            text-decoration: underline;
         }
      }

      #upload-tooltip {
         margin-left: 8px;
         color: #1e5f91;
         cursor: pointer;
         // display: flex;
         // align-items: center;
         position: absolute;
         top: 10px;
         left: 110px;
      }
   }

   .cta-wrapper {
      padding-left: 0;
      justify-content: flex-start;

      .btn {
         height: 38px;
         position: relative;

         span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
         }
      }
   }

   .upload-error {
      color: #a5685f;
      padding: 10px 0;
   }

   .ant-upload-list,
   .ant-upload-list-item-card-actions .anticon {
      color: #ffffff;
   }

   .ant-progress-inner {
      display: none;
   }

   .ant-upload-list-item-info .anticon-loading,
   .ant-upload-list-item-info .anticon-paper-clip {
      display: none;
   }

   .ant-upload-list-item-name {
      padding-left: 0;
   }

   .ant-upload-list-item:hover .ant-upload-list-item-info {
      background-color: transparent;
   }
}

.upl-port-label {
   text-decoration: underline;
   color: #56a9e8;
   cursor: pointer;

   &:hover {
      text-decoration: underline;
      color: #56a9e8;
      background-color: transparent;
   }
}

.modal .delete-confirm-modal {
   max-width: 500px !important;

   .modal-body {
      padding: 1.5rem !important;

      .content-wrapper {
         margin-top: 15px;
         margin-bottom: 0;

         .question {
            font-size: 16px;
         }

         .cta-wrapper {
            justify-content: flex-start;
            // justify-content: flex-end;
            margin-top: 15px;
            // padding-left: 0;

            .btn {
               position: relative;
               height: 25px;
               width: 80px !important;
               padding: 10px;

               span {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
               }
            }
         }
      }
   }
}

.modal .save-portfolio-modal {
   max-width: 550px !important;

   .modal-body {
      padding: 2.5rem !important;

      .content-wrapper {
         margin-top: 15px;
         margin-bottom: 0;

         .question {
            font-size: 16px;
         }

         .cta-wrapper {
            justify-content: flex-start;
            // justify-content: flex-end;
            margin-top: 15px;
            // padding-left: 0;

            .btn {
               position: relative;
               height: 31px;
               width: 100px !important;
               padding: 10px;

               span {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
               }
            }
         }
      }
   }
}

.account-nav-header {
   display: flex;
   justify-content: flex-end;

   ul {
      margin: 0 auto;
   }

   .blueBg {
      margin-left: 0 !important;
   }

   .whiteBg {
      margin-right: 0 !important;
   }

   .whiteBg {
      .nav-link.active {
         color: #4a4a4a !important;
         background-color: #fff !important;
      }
   }
}

.port-custom-container {
   display: flex;
   justify-content: center;
   align-items: center;
   text-align: center;
}

@include respond(phone) {
   #investment {
      .referral-link {
         position: absolute;
         top: 17px !important;
         right: 68px !important;
      }
   }
}

@include respond(tab) {
   #investment {
      .referral-link {
         position: absolute;
         right: 84px;
         top: 15px;
      }
   }
}

@media only screen and (max-width: 1195px) and (min-width: 992px) {
   #investment {
      .referral-link {
         position: relative;
         right: -332px;
      }
   }
}

@media only screen and (width: 1196px) {
   #investment {
      .referral-link {
         position: absolute;
         left: 328px;
      }
   }
}

@media only screen and (max-width: 1201px) and (min-width: 1197px) {
   #investment {
      .referral-link {
         position: absolute;
         left: 191px;
      }
   }
}

@media only screen and (max-width: 1926px) and (min-width: 1202px) {
   #investment {
      .referral-link {
         position: relative;
         left: 420px;
      }
   }
}

@media only screen and (max-width: 1281px) and (min-width: 999px) {
   #investment {
      .referral-link {
         top: 0px !important;
      }
   }
}
