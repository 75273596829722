$font-size-13: 13px;
$font-size-14: 14px;
$font-color: #4f4f4f;
$border-color: #9f9f9f;

.trending-search {
   width: 62%;
   height: 22px;
   margin: 1rem auto;
   // display: flex;
   // justify-content: center;

   .trending-search-label {
      width: 140px;
      max-width: 140px;
      height: 100%;
      padding-right: 1rem;
      font-family: $secondary-font;
      font-size: $font-size-14;
      font-style: normal;
      line-height: 22px;
      font-weight: 600;
      color: #4f4f4f;
   }

   .trending-marquee-container {
      // display: inline-flex;
      // width: 54%;
      // width: calc(100% - 145px);
      // margin-left: 1rem;
      height: 100%;
      margin: unset;
      align-items: center;
      flex-flow: wrap-reverse;
      font-family: $secondary-font;
      font-size: $font-size-13;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      cursor: pointer;
      text-transform: unset;
      white-space: nowrap;
      overflow: hidden;

      .trending-search-marquee {
         // margin: unset;
         // font-family: $secondary-font;
         // font-size: $font-size-13;
         // font-style: normal;
         // font-weight: 400;
         // cursor: pointer;
         // text-transform: unset;
         // white-space: nowrap;
      }

      .query-text {
         padding: 0px 14px;
         border-right: 2px solid $border-color;
         &:first-child {
            padding-left: 0px;
         }
         &:last-child {
            padding-right: 0px;
            border-right: 0;
         }
      }

      .trending-search-marquee span {
         margin: 0px 0px !important;
      }

      .trending-search-marquee {
         display: inline-block;
         padding-left: unset !important;
         -webkit-animation: t-marquee 110s linear infinite;
         animation: t-marquee 110s linear infinite;
      }

      /* Make it move */
      @-webkit-keyframes t-marquee {
         0% {
            transform: translate(0, 0);
         }
         100% {
            transform: translate(-100%, 0);
         }
         // 0%   { left: 100%; }
         // 100% { left: -100%; }
      }
      @keyframes t-marquee {
         0% {
            transform: translate(0, 0);
         }
         100% {
            transform: translate(-100%, 0);
         }
         // 0%   { left: 100%; }
         // 100% { left: -100%; }
      }

      .trending-search-marquee {
         &:hover {
            -moz-animation-play-state: paused;
            -webkit-animation-play-state: paused;
            animation-play-state: paused;
         }
      }

      // .trending-search-marquee {
      //   div {
      //     span {
      //       span:first-child {
      //         padding: 0px 14px;
      //       }
      //     }
      //   }
      // }
      // .trending-search-marquee {
      //   div {
      //     span {
      //       // cursor: pointer;
      //       // font-weight: 400;
      //       // font-family: $secondary-font;
      //       // font-style: normal;
      //       // font-size: $font-size-13;
      //       // text-transform: unset;
      //       // margin: unset;
      //     }
      //   }
      // }
      // .query-width {
      //   font-family: $secondary-font !important;
      //   font-size: 13px;
      //   font-style: normal;
      //   font-weight: 400;
      //   color: #4F4F4F;
      //   text-transform: unset;
      // }
      // .query-divider {
      //   width: 14px;
      //   border: 0.5px solid $border-color;
      //   transform: rotate(90deg);
      // }
   }

   .disabled {
      cursor: disabled;
   }

   // .trending-marquee-container.span-section-disable{
   //   display: inline-flex;
   //   width:54%;
   //   margin-left: 16px;
   //   align-items: center;
   //   flex-flow: wrap-reverse;
   //   .trending-search-marquee {
   //     white-space: nowrap;
   //     overflow: hidden;
   //   }
   //   .trending-search-marquee span{
   //     margin: 0px 0px!important;
   //   }
   //   .trending-search-marquee div {
   //     display: inline-block;
   //     -webkit-animation: t-marquee 50s linear infinite;
   //     animation: t-marquee 50s linear infinite;
   //   }
   //
   //   /* Make it move */
   //   @-webkit-keyframes t-marquee {
   //     0%   { transform: translate(0, 0); }
   //     100% { transform: translate(-100%, 0); }
   //     // 0%   { left: 100%; }
   //     // 100% { left: -100%; }
   //   }
   //   @keyframes t-marquee {
   //     0%   { transform: translate(0, 0); }
   //     100% { transform: translate(-100%, 0); }
   //     // 0%   { left: 100%; }
   //     // 100% { left: -100%; }
   //   }
   //   .trending-search-marquee *:hover {
   //     -moz-animation-play-state: paused;
   //     -webkit-animation-play-state: paused;
   //     animation-play-state: paused;
   //   }
   //   .trending-search-marquee{
   //     div {
   //       span {
   //         span:first-child{
   //           padding: 0px 14px;
   //         }
   //       }
   //     }
   //   }
   //   .trending-search-marquee{
   //     div {
   //       span{
   //         cursor: not-allowed;
   //         font-weight: 400;
   //         font-family: $secondary-font;
   //         font-style: normal;
   //         font-size: 13px;
   //         text-transform: unset;
   //         margin: unset;
   //       }
   //     }
   //   }
   //   .query-width{
   //     font-family: $secondary-font!important;
   //     font-size: 13px;
   //     font-style: normal;
   //     font-weight: 400;
   //     color: #4F4F4F;
   //     text-transform: unset;
   //   }
   //   .query-divider{
   //     width: 14px;
   //     border: 0.5px solid $border-color;
   //     transform: rotate(90deg);
   //   }
   // }
}
