@import "./variables";

@media only screen and (min-width: 640px) {
   #investments-item-list-top {
      margin-right: 20px !important;
      border-bottom: 0px;
      border-top: 2px solid rgb(76, 80, 83);
   }

   #investments-item-list-bottom {
      margin-right: 20px !important;
      border-top: 0px;
      border-bottom: 2px solid rgb(76, 80, 83);
   }

   #investments-item-list {
      .card-body {
         max-height: 105px;
         min-height: 105px;
      }

      &::-webkit-scrollbar-track {
         -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
         //background-color: rgba(0.1, 0.1, 0.1, 0.1);
      }

      &::-webkit-scrollbar {
         width: 16px;
         border: 1px solid #b6b6b6;
         background-color: #ffffff;
      }

      &::-webkit-scrollbar-thumb {
         border-radius: 12px;
         -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
         background-color: #b6b6b6;
         border: 3px solid transparent;
         background-clip: content-box;
      }

      &::-webkit-scrollbar-button:vertical:start:decrement {
         background: linear-gradient(
               120deg,
               rgb(182, 182, 182) 40%,
               rgba(0, 0, 0, 0) 41%
            ),
            linear-gradient(
               240deg,
               rgb(182, 182, 182) 40%,
               rgba(0, 0, 0, 0) 41%
            ),
            linear-gradient(0deg, rgb(182, 182, 182) 30%, rgba(0, 0, 0, 0) 31%);
         border-radius: 2px 2px 0 0;
      }

      &::-webkit-scrollbar-button:vertical:end:increment {
         background: linear-gradient(
               300deg,
               rgb(182, 182, 182) 40%,
               rgba(0, 0, 0, 0) 41%
            ),
            linear-gradient(60deg, rgb(182, 182, 182) 40%, rgba(0, 0, 0, 0) 41%),
            linear-gradient(
               180deg,
               rgb(182, 182, 182) 30%,
               rgba(0, 0, 0, 0) 31%
            );
         background-color: #ffffff;
         border-radius: 0 0 2px 2px;
      }
   }
   #investments-alerts {
      .card-body {
         max-height: 212px;
         min-height: 212px;
      }
      border-bottom: 2px solid #4c5053 !important;
      border-right: 2px solid #4c5053 !important;
      border-left: 2px solid #4c5053 !important;
      border-radius: 0;
   }

   #investments-allocation {
      .card-body {
         max-height: 212px;
         min-height: 212px;
      }
      border-bottom: 2px solid #4c5053 !important;
      border-top: 2px solid #4c5053 !important;
      border-right: 2px solid #4c5053 !important;
      border-left: 2px solid #4c5053 !important;
      border-radius: 0;
   }

   #investments-account-info {
      .card-body {
         max-height: 105px;
         min-height: 105px;
      }
   }
}

@media (max-height: 773px) {
   .fullHeightCards {
      display: none;
   }
   .smallHeightCards {
      // color: red!important;
   }
   #investments-alerts {
      border-top: 2px solid #4c5053 !important;
   }
}

@media (min-height: 773px) {
   .fullHeightCards {
      // color: green!important;
   }
   .smallHeightCards {
      display: none;
   }
}

.card-carousal-bottom-area {
   position: absolute;
   bottom: 0;
   right: 0;
}

.card-carousal-top-area {
   position: absolute;
   top: 0;
   right: 0;
}

.card-carousal-bottom-area .btnIcon {
   display: inline-block;
   /* dummy styling */
   border-radius: 8px;
   border: 1px solid rgba(255, 255, 255, 0.5);
   background: rgba(255, 255, 255, 0.3);
   // padding: 8px;
   color: #4c5053;
   text-decoration: none;
}

.card-carousal-top-area .btnIcon {
   display: inline-block;
   /* dummy styling */
   border-radius: 8px;
   border: 1px solid rgba(255, 255, 255, 0.5);
   background: rgba(255, 255, 255, 0.3);
   // padding: 8px;
   color: #4c5053;
   text-decoration: none;
}
