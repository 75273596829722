.magnifi-header-wrapper {
   // position: fixed;
   // top: 0;
   // z-index: 9;
   min-width: 100%;
   height: 75px !important;
   background-color: #f7f7f7 !important;
   transition: background-color 500ms ease;
   display: flex;
   // display: contents;
   align-items: center;
   line-height: normal !important;
   padding: 0 !important;
   z-index: 1;
   // box-sizing: content-box !important;
   // -webkit-box-sizing: content-box !important;

   nav {
      width: 100%;
      height: 100%;

      .container {
         margin: 0;
         width: 100%;
         max-width: 100%;
         padding-left: 0;
         padding-right: 0;
         justify-content: flex-start !important;
         display: flex;
         // margin-top: 4px !important;
         .navbar-brand {
            // box-sizing: content-box !important;
            // -webkit-box-sizing: content-box !important;
            button {
               color: #fff !important;
               font-family: "Roboto";
               font-style: normal;
               font-weight: 400;
               font-size: 15px;
               line-height: 19px;
               padding: 11px 20px;
            }
         }
         .logoWrapper {
            img {
               width: 142px;
               height: 61px;
            }
         }

         ul {
            li {
               i {
                  font-size: 1.3rem;
               }
            }
         }

         .portfolio-tools-wrap {
            // position: absolute;
            // left: calc(25% + 25px);
            // // right: 55px;
            // width: 35%;
            // // width: 42%;
            min-width: max-content;
            max-width: 800px;
            max-width: 812px;
            margin-left: 20px;
            display: flex;
            align-items: center;
            // .referral-link {
            //   font-weight: 700;
            //   color: #55a9e8;
            //   padding: 0;
            //   text-decoration: none;
            //   position: unset !important;
            //   margin-right: 20px;
            //   // right: 73px;
            //
            //   &:hover {
            //     color: #55a9e8;
            //     text-decoration: none;
            //   }
            // }
            .tools-txt {
               width: 100%;
               min-width: max-content;
               margin-right: 10px;
               padding: 7px 0;
               font-family: "Open Sans";
               font-size: 1rem;
               font-weight: 800;
               font-stretch: normal;
               font-style: normal;
               line-height: normal;
            }
            .acc-page {
               margin-top: 1px;
            }
            // .tools-badge{
            //   background: white !important;
            //   border: 1px solid #5eade9;
            //   border-radius: 50px;
            //   background: transparent;
            //   margin-right: 10px !important;
            //   padding-top: 2px;
            //   .ant-badge-count{
            //     background: #5eade9;
            //   }
            // }
            .tool-badge.disabled-selector {
               // border: 1px solid #dddddd !important;
               // border-top: 1px solid #dddddd !important;
               // border-bottom: 1px solid #dddddd !important;
               a {
                  color: #9f9f9f !important;
               }
            }
            .search-result-tool-badge {
               // border-left-width: 1px !important;
               // border-top-left-radius: 3px !important;
               // border-bottom-left-radius: 3px !important;
               font-size: 16px !important;
            }
            .search-result-tool-badge-communtiy {
               // border-left-width: 1px !important;
               // border-top-right-radius: 3px !important;
               // border-bottom-right-radius: 3px !important;
               font-size: 16px !important;
            }
            .selector-tool-badge {
               // border-left-width: 1px !important;
               // border-top-right-radius: 0px !important;
               // border-bottom-right-radius: 0px !important;
               font-size: 16px !important;
            }
            // .search-result-tool-badge-home {
            //    border-left-width: 1px !important;
            //    border-top-left-radius: 3px !important;
            //    border-bottom-left-radius: 3px !important;
            // }
            .tool-badge {
               width: 100%;
               max-width: 170px;
               // min-width: 80px;
               padding: 3px 3px 5px;
               // padding: 7px 16px;
               line-height: 15px;
               border-radius: 0;
               border-left-width: 0;
               background: transparent;
               text-align: center;
               cursor: pointer;

               span {
                  font-size: 13px;
                  padding-right: 8px;

                  svg {
                     margin-bottom: 4px;
                  }
               }

               .ant-badge-count {
                  top: 4px;
                  right: -10px;
                  width: 5px;
                  z-index: 99;
                  background: black;
               }

               // &:first-child {
               //   border-left-width: 1px !important;
               //   border-top-left-radius: 3px !important;
               //   border-bottom-left-radius: 3px !important;
               // }
               // &:last-child {
               //   margin: 0 !important;
               //   border-right-width: 1px;
               //   border-top-right-radius: 3px;
               //   border-bottom-right-radius: 3px;
               // }
               &.selected {
                  color: #101828;
                  a {
                     color: white !important;
                  }
                  border-bottom: 2px solid #101828 !important;
                  font-weight: 600;
                  button {
                     color: #101828 !important;
                     border-radius: 0px !important;
                  }
               }
               &.unselectable {
                  color: #101828;
                  border-bottom: white !important;
                  cursor: not-allowed;
               }
            }
            .tools-link {
               display: flex;
               align-items: center;
               justify-content: center;
               width: 100% !important;
               max-width: 100% !important;
               height: 100%;
               font-family: "Roboto";
               font-style: normal;
               font-weight: 400;
               font-size: 16px;
               line-height: 19px;
               color: #101828 !important;
               padding: 0;
               text-decoration: none;
               .beta_icon {
                  margin-right: 0;
                  margin-left: 4px;
                  margin-top: 10px;
                  width: 25px !important;
               }
               img {
                  // width: 20px;
                  // height: 20px;
                  margin-right: 10px;
               }
               &:hover {
                  color: #55a9e8;
                  text-decoration: none;
               }
            }
         }
      }
      .user-menu-popover {
         // min-width: 270px !important;
         .navbar-brand {
            margin: 0;
            padding: 0;
         }
         .nav-link {
            i {
               font-size: 1.3rem;
            }
         }
      }
      .referral-link {
         font-weight: 700;
         color: $primary-color;
         padding: 0;
         text-decoration: none;
         // position: absolute;
         // right: 73px;

         &:hover {
            color: $primary-color;
            text-decoration: none;
         }
      }
   }
}

.client-portfolio-menu {
   // position: absolute;
   // right: 65px;
   margin-right: 10px;
   // border: solid 1px #5eade9;
   // border-radius: 50px;
   // background: #fff;
   padding: 0 !important;
   .disabled-events {
      pointer-events: none !important;
   }
   .client-portfolio-menu-dropdown {
      .ant-dropdown-menu {
         .ant-dropdown-menu-item {
            position: relative;
            padding: 8px 15px;
            font-weight: 400;
            font-size: 14px;
            color: #202529;
            border-bottom: 1px solid rgba(205, 212, 219, 0.5);
            .menu-label {
               font-weight: bold;
               font-size: 14px;
               color: #5eade9 !important;
               text-decoration: none;
            }
            #uploadCustomPortfolio {
               position: absolute;
               left: 0;
               top: 0;
               z-index: 1;
               padding-right: 38px;
               opacity: 0;
            }
            .icon {
               width: 15px;
               height: 15px;
               vertical-align: 0;
            }
            .prefix-icon {
               margin-right: 10px;
               font-size: inherit;
               vertical-align: 0;
            }
            .suffix-icon {
               position: absolute;
               bottom: 13px;
               right: 15px;
               z-index: 9;
               margin: 0;
               vertical-align: 0;
            }
            &:last-child {
               border: 0;
            }
         }
      }
   }
   .client-portfolio-menu-active {
      color: #000 !important;
      background-color: #1e5f91 !important;
      border: 1px solid #1e5f91 !important;
   }
   .client-portfolio-menu-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 7px 17px;
      line-height: normal;
      font-family: "Open Sans";
      font-size: 14px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      color: #5eade9;
      border: 0;
      // border-color: #5eade9;
      // border-radius: 3px;
      .button-label {
         // height: 24px;
         line-height: 24px;
      }
      i {
         font-size: 20px;
         // position: absolute;
         // top: 6px;
         // left: 18px;
         // margin: 0 !important;
      }
      .anticon {
         // position: absolute;
         // right: 12px;
         // bottom: 5px;
         // margin: 0;
         // vertical-align: 0;
         line-height: inherit;
      }
   }
}

@media only screen and (max-width: 1023px) and (min-width: 320px) {
   .client-portfolio-menu {
      display: none !important;
      .client-portfolio-menu-button {
         i {
            // font-size: 20px;
         }
         .button-label {
            // height: 24px;
         }
      }
   }
}

@media only screen and (max-width: 1280px) and (min-width: 1024px) {
   .portfolio-tools-wrap {
      // position: unset !important;
      display: flex;
      margin-right: 10px;
      align-items: center;

      .tools-txt {
         min-width: max-content !important;
         font-size: 0.65em !important;
      }
      .tool-badge {
         // height: 27px;
         // height: 27px;
         // min-width: unset;
         // padding: 5px 17px !important;
         .ant-badge-count {
            top: 0px !important;
            width: 16px;
            // min-width: 16px;
            // height: 16px;
            // padding: 1px !important;
            line-height: 16px;
            font-size: 9px !important;
         }
         .tools-link {
            color: transparent !important;
            font-size: 11px !important;
            img {
               width: 15px !important;
               height: 15px !important;
            }
         }
         &.selected {
            .tools-link {
               color: transparent !important;
            }
         }
      }
      .client-portfolio-menu {
         height: 27px;
         padding: 0 !important;
      }
   }

   .client-portfolio-menu {
      // height: 35px !important;
      // position: unset !important;
      margin-right: 10px !important;
      .client-portfolio-menu-button {
         // height: 27px !important;
         position: relative;
         font-size: 11px !important;
         padding: 5px 17px !important;
         i {
            width: 14px !important;
            height: 13px !important;
            font-size: 14px !important;
         }
         .button-label {
            // height: 15px !important;
            line-height: 14px !important;
         }
         .anticon-down {
            // bottom: 6px !important;
            font-size: 0.8em !important;
         }
      }
   }
   .client-portfolio-menu-dropdown {
      .ant-dropdown-menu {
         .ant-dropdown-menu-item {
            font-size: 11px !important;
            .menu-label {
               font-size: 11px !important;
            }
            #uploadCustomPortfolio {
            }
            .icon {
               width: 11px !important;
               height: 11px !important;
            }
            .prefix-icon {
            }
            .suffix-icon {
            }
            &:last-child {
               border: 0;
            }
         }
      }
   }

   .magnifi-header-wrapper nav .container .portfolio-tools-wrap .tool-badge {
      // min-width: 80px !important;
   }
}

@media only screen and (max-width: 1439px) and (min-width: 1281px) {
   .portfolio-tools-wrap {
      // position: unset !important;
      margin-right: 10px;
      align-items: center;

      .tools-txt {
         min-width: max-content !important;
         text-align: center;
         // font-size: 0.65em !important;
      }
      .tool-badge {
         // height: 35px;
         width: 100px;
         .ant-badge-count {
            top: 2px !important;
            width: 20px;
            min-width: 20px;
            height: 20px;
         }
         .tools-link {
            color: transparent !important;
            // font-size: 0.7em !important;
            img {
               width: 20px !important;
               height: 20px !important;
            }
         }
         &.selected {
            .tools-link {
               color: transparent !important;
            }
         }
      }
   }
   .client-portfolio-menu {
      height: 35px;
      // position: unset !important;
      margin-right: 10px !important;
      .client-portfolio-menu-button {
         // height: 35px !important;
         position: relative;
         // font-size: 0.7em !important;
         i {
            width: 18px !important;
            height: 18px !important;
            font-size: 18px !important;
         }
         .button-label {
            // height: 19px !important;
            line-height: 19px !important;
         }
         .anticon-down {
            // bottom: 6px !important;
            // font-size: 0.8em !important;
         }
      }
   }
   .client-portfolio-menu-dropdown {
      .ant-dropdown-menu {
         .ant-dropdown-menu-item {
            // font-size: 11px !important;
            .menu-label {
               // font-size: 11px !important;
            }
            #uploadCustomPortfolio {
            }
            .icon {
               // width: 11px !important;
               // height: 11px !important;
            }
            .prefix-icon {
            }
            .suffix-icon {
            }
            &:last-child {
               border: 0;
            }
         }
      }
   }
}

@media only screen and (max-width: 1919px) and (min-width: 1440px) {
   .portfolio-tools-wrap {
      // position: unset !important;
      margin-right: 10px;
      align-items: center;

      .tools-txt {
         min-width: max-content !important;
         // font-size: 0.65em !important;
      }
      .tool-badge {
         // height: 35px;
         width: 100px;
         .ant-badge-count {
            top: 0px !important;
            width: 16px;
            // min-width: 16px;
            // height: 16px;
            // padding: 1px !important;
            line-height: 16px;
            font-size: 9px !important;
         }
         .tools-link {
            color: transparent !important;
            // font-size: 0.7em !important;
            img {
               width: 20px !important;
               height: 20px !important;
            }
         }
         &.selected {
            .tools-link {
               color: transparent !important;
            }
         }
      }
   }
   .client-portfolio-menu {
      height: 35px;
      // position: unset !important;
      margin-right: 10px !important;
      .client-portfolio-menu-button {
         // height: 35px !important;
         position: relative;
         // font-size: 0.7em !important;
         i {
            width: 18px !important;
            height: 18px !important;
            font-size: 18px !important;
         }
         .button-label {
            // height: 19px !important;
            line-height: 19px !important;
         }
         .anticon-down {
            // bottom: 6px !important;
            // font-size: 0.8em !important;
         }
      }
   }
}

@media only screen and (min-width: 1920px) {
   .portfolio-tools-wrap {
      // position: unset !important;
      // margin-right: 10px;
      // align-items: center;

      .tools-txt {
         // min-width: max-content !important;
         // font-size: 0.65em !important;
      }
      .tool-badge {
         height: 23px !important;
         .tools-link {
            font-size: 1rem !important;
            img {
               // width: 20px !important;
               // height: 20px !important;
            }
         }
         &.selected {
            .tools-link {
               // color: transparent !important;
            }
         }
      }
   }
   .client-portfolio-menu {
      .client-portfolio-menu-button {
         font-size: 1rem !important;
         // height: 40px !important;
      }
      .client-portfolio-menu-dropdown {
         .ant-dropdown-menu {
            .ant-dropdown-menu-item {
               font-size: 1rem !important;
               .menu-label {
                  font-size: 1rem !important;
               }
               #uploadCustomPortfolio {
               }
               .icon {
               }
               .prefix-icon {
               }
               .suffix-icon {
               }
               &:last-child {
                  border: 0;
               }
            }
         }
      }
   }
}

@media only screen and (min-width: 992px) {
   .magnifi-header-wrapper nav .container .portfolio-tools-wrap .tool-badge {
      // min-width: 150px !important;
      margin-left: 40px;
   }
}

@media (max-width: 991px) {
   .magnifi-header-wrapper {
      display: none !important;
   }
   .portfolio-tools-wrap {
      display: none !important;
   }
}

.mobile-navbar-wrapper {
   display: none;
   @media (max-width: 991px) {
      display: block;
      background: white;
   }
}

.homepage {
   background-color: white !important;
   transition: background-color 500ms ease;
}
