$dark-active-color: #1e5f91;
$font-size-14px: 0.8rem; //12px;
$secondary-font: "Roboto";

.switch-wrapper {
   .ant-switch {
      background-color: #4a4a4a;
      .ant-switch-inner {
      }
   }
   .ant-switch-small {
      min-width: 28px;
      height: 10px;
   }
   .ant-switch-checked {
      &:after {
         width: 18px !important;
         height: 18px !important;
         top: -5px !important;
         left: 100% !important;
         margin-left: 2px;
         border-radius: 50%;
         background-color: #56a9e8 !important;
      }
   }
   .ant-switch::after {
      position: absolute;
      top: -5px;
      left: -3px;
      width: 18px;
      height: 18px;
      background-color: #dbdbdb;
   }
   .toggle-label {
      min-width: max-content;
      margin-left: 5px;
      padding-right: 5px;
      display: inline-block;
      font-size: $font-size-14px;
      font-family: $secondary-font;
      font-weight: 400;
      color: $dark-active-color;
      cursor: pointer;
   }
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
   .switch-wrapper {
      .ant-switch {
         .ant-switch-inner {
         }
      }
      .ant-switch-small {
         min-width: 28px;
         height: 8px;
      }
      .ant-switch-checked {
         &:after {
            width: 16px !important;
            height: 16px !important;
         }
      }
      .ant-switch::after {
         width: 16px;
         height: 16px;
      }
   }
}
