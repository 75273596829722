@import "./Elements/index";
@import "./ItemList/index";
@import "./ItemCard/index";

.select-btn-box {
   position: relative;
   // height: 40px;
   .options-btn-body {
      width: 100%;
      margin: 0 auto;
      justify-content: flex-end;
      // position: absolute;
      // top: 0;
      // left: 0;
      .retail-cta-card-button-wrapper {
         width: 100%;
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;
         // .buy-btn-tooltip-wrapper {
         //   padding: 0 4px;
         //   margin-bottom: 5px;
         // }
         .btn-select {
            box-shadow: none;
         }
         .buy-btn {
            width: 52px;
            // min-width: 52px;
            height: 24px;
            padding: 5px;
            color: $orange-color;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.07;
            letter-spacing: 0.28px;
            text-align: center;
            background: #fff;
            border-radius: 30px;
            border: 1px solid $orange-color;
            transition: all 0.3s;
            &:hover {
               color: #fff;
               background: $orange-color;
               border: 1px solid $orange-color;
            }
         }
         .cart-btn {
            box-shadow: none;
            // padding: 0 6px;
            // margin-left: -6px;
            // cursor: pointer;
            width: 52px;
            // min-width: 60px;
            height: 24px;
            padding: 0 5px;
            margin-bottom: 5px;
            color: $blue-color;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.07;
            letter-spacing: 0.28px;
            text-align: center;
            background: #fff;
            border-radius: 30px;
            border: 1px solid $blue-color;
            transition: all 0.3s;
            &:hover {
               color: #fff;
               background: $blue-color !important;
            }
         }
         .selected-state {
            background: #fff !important;
            &:hover {
               color: $blue-color !important;
               background: #fff !important;
            }
         }
         .disabled {
            background: rgb(232, 236, 239);
            border-color: rgb(209, 210, 214);
            color: rgb(209, 210, 214);
            cursor: not-allowed !important;
         }
      }

      @media only screen and (min-width: 1920px) {
         .retail-cta-card-button-wrapper {
            width: 100%;
            max-width: 112px;
            flex-direction: row !important;
            justify-content: space-between !important;
            .btn-select {
               box-shadow: none;
            }
            .buy-btn {
               width: 52px;
               // min-width: 52px;
               height: 28px;
            }
            .cart-btn {
               box-shadow: none;
               width: 52px;
               // min-width: 52px;
               height: 28px;
               margin-right: 5px !important;
               margin-bottom: 0px !important;
               padding: 0 !important;
            }
         }
      }
      .selector-btn {
         position: relative;
         // margin-right: 8px;
         // margin-left: 8px;
         text-align: center;
         button {
            padding: 0;
            line-height: 1;
         }
      }
      .builder-btn {
         position: relative;
         // width: calc(100%/2);
         text-align: center;
         button {
            padding: 0;
            line-height: 1;
         }
      }
      .unselected {
         background: transparent !important;
         border-radius: 50%;
         width: 32px;
         height: 32px;
         img {
            // position: relative;
            // width: 18px;
            // right: 6px;
            // bottom: 5px;
         }
         &:hover {
            background: #e26a03 !important;
         }
      }
      .selected {
         background: #807f7f !important;
         &:hover {
            background: #807f7f !important;
         }
      }
      .btn-info-txt {
         position: absolute;
         left: -0.6vw;
         bottom: -14px;
         width: auto;
         font-size: 0.48rem;
         line-height: 1;
         text-align: center;
         white-space: nowrap;
         color: #e26a03;
         font-weight: 700;
      }
      .btn-info-txt.builder {
      }
      .clear {
         position: absolute;
         left: 0;
         bottom: -14px;
         width: auto;
         font-size: 0.48rem;
         text-align: center;
         color: #807f7f;
      }
      .cart {
         // left: 0;
      }
      .clear.builder-clear {
         // position: absolute;
         left: 0px;
      }
   }
}

.align-center {
   justify-content: center !important;
}

.ad-logo {
   position: absolute;
   top: 5px;
   bottom: 0;
   width: 70px;
   line-height: 1;
   height: 15px;
   padding: 3px 5px;
   font-family: "Roboto";
   font-size: 10px;
   color: #ffffff;
   text-align: center;
   border-radius: 3px;
   background-color: #4a4a4a;
}

.sponsored-logo-tag {
   position: absolute;
   top: 8px;
   left: 8px;
   width: 70px;
   line-height: 1;
   height: 15px;
   // height: max-content;
   // width: max-content;
   padding: 3px 5px;
   // line-height: normal;
   font-family: "Roboto";
   font-size: 10px;
   color: #ffffff;
   text-align: center;
   border-radius: 3px;
   background-color: #4a4a4a;
}

.universe-split-wrapper {
   // width: 50%;
   // display: flex;
   display: flex;
   align-items: center;
   // justify-content: space-evenly;
   // min-width: 208px;
}

.contentWrapper {
   position: fixed;
   top: 60px;
   width: 100%;
   height: 100%;
   margin: 0;
   padding-right: 15px;
   padding-left: 15px;
   // padding-top: 65px;
   // height: 100vh;
   color: $charcoal-black !important;

   .sideBarContainer {
      padding-right: 15px;
      border-right: 1px solid #fff;
      .sideBarWrapper {
         padding: 22px 0;
         .loader {
            font-size: 8px;
         }
         .show {
            opacity: 1 !important;
            visibility: visible !important;
         }
         .hide {
            opacity: 0;
            visibility: hidden;
         }
         .element {
            padding-bottom: 20px;
            transition: all 0.3s;

            h2 {
               padding-bottom: 15px;
               margin: 0;
               font-family: Roboto;
               font-size: 20px !important;
               font-weight: bold;
               color: inherit;
               // line-height: 1.5;
            }
            .label {
               font-family: Roboto;
               font-size: 18px;
               color: inherit;
               margin-bottom: 7px;
            }
            .sub-label {
               font-size: 0.6rem;
            }
            .searchInput {
               input {
                  height: 40px;
                  padding: 5px 70px 5px 35px;
                  font-size: 15px;
                  font-weight: normal;
                  transition: all 0.3s;
                  &::-ms-clear {
                     display: none !important;
                  }
               }
               .search-icon {
                  font-size: 20px;
                  right: 8px;
               }
               .clear-icon {
                  position: absolute;
                  right: 40px;
                  line-height: 35px;
                  width: 15px;
                  text-align: center;
                  z-index: 9;
                  opacity: 0.2;
                  font-size: 15px;
                  transition: line-height 0.5s, width 0.5s, font-size 0.5s;
                  &.active {
                     opacity: 1;
                     cursor: pointer;
                  }
               }
               // @include respond(phone) {
               //   .clear-icon {
               //     line-height: 35px;
               //     width: 15px;
               //     text-align: center;
               //     right: 35px;
               //   }
               // }
               //
               // @include respond(tab){
               //   .clear-icon {
               //     line-height: 35px;
               //     width: 15px;
               //     text-align: center;
               //     right: 35px;
               //   }
               // }

               // @include respond(desktop){
               //   .clear-icon {
               //     line-height: 35px;
               //     width: 15px;
               //     text-align: center;
               //     right: 35px;
               //   }
               // }
            }
            .tagWrapper {
               display: flex;
               flex-direction: row;
               flex-wrap: wrap;
               overflow: auto;
               font-size: 16px;
               max-height: 122px;
               color: inherit !important;
               .tag {
                  // min-width: max-content;
                  max-width: 290px;
                  overflow: hidden;
                  margin-right: 10px;
                  margin-bottom: 10px;
                  padding: 6px 15px;
                  color: $tag-blue;
                  font-size: 12px;
                  border: 1px solid $tag-blue;
                  background: #fff;
                  border-radius: 3px;
                  transition: all 0.3s;
                  white-space: nowrap;
                  cursor: pointer;
                  text-overflow: ellipsis;

                  &:hover {
                     background: $tag-blue;
                     color: #fff;
                  }
               }
               .tag-question {
                  color: #55a9e8;
                  margin-right: 10px;

                  &:hover {
                     cursor: pointer;
                  }
               }
            }
            .cardWrapper {
               .card {
               }
            }
         }
      }
   }
   .contentContainer {
      position: relative;
      #landing-page {
         .trending-theme-row {
            max-width: 90%;
         }
         .trending-theme h1 {
            text-align: center;
            font-weight: 300;
            font-size: 20px !important;
            font-family: Roboto, Roboto;
            margin-top: 20px;
            color: #4a4a4a !important;
            margin-bottom: 0;
            padding: 0;
         }
         .trending-theme-card {
            border-radius: 8px;
            overflow: hidden;
            height: 8.2rem;
            width: 8.2rem;
            position: relative;
            background: none;
            margin: 1.2rem;
         }
         .banner-items {
            h1 {
               font-weight: 500;
               margin-bottom: 10px !important;
               font-size: 26px !important;
               line-height: 1.2;
            }
            h4 {
               font-size: 18px !important;
            }
         }
      }
      .trending-searches {
         margin-top: 35px;
         .inner-container {
            border: unset;
            width: 85%;
            margin: auto;
            padding: 0;
            border-radius: unset;
            text-align: unset;
            h1 {
               text-align: center;
               font-weight: 300;
               font-size: 28px !important;
               font-family: Roboto !important;
               line-height: 28px;
               margin-bottom: 10px;
               padding: 0;
               text-transform: capitalize;
               color: #494a49;
               font-family: Roboto;
               font-weight: 400;
               margin-bottom: 50px;
            }
            .search_card_trending {
               padding: 8px;
               background: #fff;
               margin-right: 20px;
               margin-bottom: 10px;
               display: inline-block;
               border-radius: 5px;
               font-size: 12px;
               color: #5eade9;
               font-size: 14px;
               font-weight: 350;
            }
         }
      }
      .show {
         display: inherit !important;
         visibility: visible !important;
         opacity: 1 !important;
      }
      .hide {
         display: none !important;
         visibility: hidden !important;
         opacity: 0 !important;
      }
      .spinner-wrapper {
         position: absolute;
         width: 100%;
         height: 100%;
         background: #f7f7f7;
      }
      .spinnerMessage {
         margin: 50px;
         display: flex;
         flex-direction: column;
         vertical-align: middle;
         height: 55%;
         align-items: center;
         justify-content: center;

         h3 {
            color: #6c757d;
         }
      }

      .itemListContainer {
         padding: 15px 15px 0 15px;
         padding-left: 0;
         padding-right: 0;

         .itemListHeaderWrapper {
            .cardListToolbarWrapper .container {
               max-width: unset;
               padding: unset;
            }
            .cardListToolbarWrapper {
               .container {
                  min-height: 103px;
                  max-width: unset;
                  padding: unset;
                  .toolbar-wrapper-flex-center {
                     display: flex;
                     align-items: center;
                  }
                  .viewChartsColumn {
                     display: flex;
                     flex-wrap: wrap;
                     align-items: center;
                     // min-height: 88px;

                     .viewDropdownContainer {
                        width: 100%;
                        // min-height: 69px;

                        .viewDropdownWrapper {
                           // height: 22px;
                           // margin-bottom: 2px;
                           .viewBtn {
                              // margin-top: -10px !important;
                              line-height: normal !important;
                           }
                           .viewDropDownList {
                              min-width: unset !important;
                              // padding: 2px !important;
                              .viewDropDownList-Child {
                                 // font-size: 10px !important;
                                 .dynamic-chart-wrap {
                                    border-bottom: 2px solid $other-bg-color !important;
                                 }
                              }
                           }
                        }
                        .view-text-container {
                           // width: 130px !important;
                           .slick-slider {
                              width: 136px;
                              width: 160px;
                              .slick-slide {
                                 margin: 0 2px;
                              }
                              .slick-list .slick-track {
                                 display: flex;
                                 align-items: center;
                              }
                              .slick-arrow {
                                 width: 12px;
                                 height: 12px;
                                 font-size: 9px;
                                 color: #4a4a4a;
                                 line-height: 1;
                              }
                              .slick-disabled {
                                 color: #bababb;
                              }
                              .slick-prev {
                                 left: -18px;
                              }
                              .slick-next {
                                 right: -18px;
                              }
                              .slick-dots {
                                 bottom: -33px;
                                 ul {
                                    height: 12px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    margin: 0;
                                    padding: 0;
                                    li {
                                       width: 5px;
                                       height: 5px;
                                       margin: 0 5px;
                                       padding: 0;
                                       button {
                                          font-size: 0;
                                          line-height: 0;
                                          width: 5px;
                                          height: 5px;
                                          padding: 0;
                                          &:before {
                                             font-size: 6px;
                                             line-height: 5px;
                                             width: 5px;
                                             height: 5px;
                                          }
                                       }
                                    }
                                 }
                              }
                           }
                           // margin-bottom: 0;
                           // margin-top: 3px;
                           .view-text-label {
                              line-height: normal;
                              overflow-wrap: break-word;
                              // margin-right: 2px;
                              // font-size: 8px !important;
                           }
                        }
                        .view-sub-title-container {
                           min-height: 18px;
                           h6 {
                              // font-size: 8px !important;
                              line-height: normal;
                           }
                           .dropdown-menu {
                              min-width: unset !important;
                           }
                        }
                        .static-chart-list-wrap {
                        }
                        .dropdown-item-header {
                           font-weight: 700;
                           color: #1b8be0;
                           cursor: none;
                           pointer-events: none;
                        }
                        .divider {
                        }
                        .dropdown-item {
                           &:hover {
                              background-color: #dff1ff !important;
                           }
                           &:focus {
                              color: #212529 !important;
                              background-color: #dff1ff !important;
                           }
                        }
                     }
                  }
                  .date-range-slider {
                     // padding-right: 0 !important;
                     .irs {
                        .irs-from,
                        .irs-to,
                        .irs-single {
                           // font-size: 8px !important;
                        }
                     }
                     ul {
                        li {
                           // font-size: 10px !important;
                           line-height: 1 !important;
                           button {
                              // font-size: 10px !important;
                           }
                        }
                     }
                  }
               }
            }
         }
         .itemListArea {
            // height: calc(100vh - (280px))!important;
            // height: calc(100vh - (250px))!important;
            padding-top: 1rem;
            height: calc(95vh - (260px));
            margin-right: -10px;
            padding-left: 0 !important;
            padding-right: 6px !important;
            overflow: hidden;
            overflow-y: scroll;
            position: relative;
            // padding-bottom:48px;

            .card-background {
               background: $other-bg-color !important;
               border-radius: 8px !important;
            }

            .itemCardWrapper {
               height: 124px;
               display: flex;
               flex-direction: column;
               overflow-x: visible;
               border-radius: 8px;
               border: 1px solid $border-color;
               .border-transparent {
                  border-color: transparent !important;
               }
               .border-bg-filter-bg-color {
                  border-color: $filter-bg-color !important;
               }
               .itemCard {
                  // min-height: 124px;
                  height: 100%;
                  border-color: transparent;
                  border-radius: 8px !important;
                  background: transparent !important;
                  overflow: initial;
                  // .app-card-left {
                  //   border-top-left-radius: 8px;
                  //   border-bottom-left-radius: 8px;
                  // }
                  // .app-card-right {
                  //   border-top-right-radius: 8px;
                  //   border-bottom-right-radius: 8px;
                  // }
                  .card-body {
                     .row {
                        height: 100%;
                     }
                  }
                  .feesReturnRiskWrapper {
                     display: flex;
                     align-items: center;
                     .search-fees-return-stats-padding {
                        height: 100%;
                        // padding: 28px 0px 28px;
                     }
                     .search-risk-stats-padding {
                        height: 100%;
                        padding: 0px;
                     }
                     .search-risk-stats-highlighting {
                        .fees-flag-color,
                        .returns-flag-color,
                        .risk-flag-color {
                           color: $tag-blue;
                        }
                     }
                     .w-100 {
                        display: grid;
                        height: 100%;

                        .fees-flag-color,
                        .returns-flag-color,
                        .risk-flag-color {
                           height: 100%;
                           align-items: center;
                           display: flex;
                           flex-direction: column;
                           justify-content: center;
                           img {
                              margin-top: 2px;
                              margin-bottom: 3px;
                              // width: 20px;
                           }
                           i {
                              // font-size: 14px;
                           }
                           .__val {
                              // font-size: 9px !important;
                           }
                           .__state {
                              // font-size: 8px !important;
                           }
                        }
                     }
                  }

                  .paddingTpBtLt {
                     padding-top: 0 !important;
                     padding-bottom: 0 !important;
                     padding-left: 0 !important;
                  }
                  .paddingTpBt {
                     padding-top: 0 !important;
                     padding-bottom: 0 !important;
                  }
                  .paddingTpBtRt {
                     padding-top: 0 !important;
                     padding-bottom: 0 !important;
                     padding-right: 0 !important;
                  }

                  .selectBtn {
                     padding: 5px;
                     padding: 8px 15px !important;
                     font-size: 12px !important;
                     border-radius: 30px;
                     line-height: 1.4;
                     background: transparent;
                     border-color: $ssf-orange-d1;
                     color: $ssf-orange-d1;
                     white-space: nowrap;
                     &:hover {
                        background: $ssf-orange-d1;
                        color: #fff;
                     }
                     &.btn-unselect {
                        color: #fff;
                        background-color: #807f7f;
                        border-color: #807f7f;
                     }
                     span {
                        span.anticon-caret-down {
                           svg {
                              margin-bottom: 5px !important;
                           }
                        }
                     }
                  }
                  .selectDropDownBtn {
                     padding: 8px 15px 5px !important;
                  }
                  .chart-block {
                     .primaryTextStyle {
                        left: 50%;
                     }
                     .highlightTooltip {
                        // font-size: 10px;
                     }
                     .guage-chart-wrapper {
                        .guage-chart-details {
                           .col {
                              // font-size: 9px !important;
                           }
                        }
                     }
                     .flip-items {
                        li {
                           text-align: center;
                           h6 {
                              // font-size: 10px !important;
                           }
                        }
                     }
                  }
               }
               &:last-child {
                  // margin-bottom: 48px;
               }
            }

            &::-webkit-scrollbar {
               // display: none !important;
               // width: 0 !important;
            }
         }
      }
      .survey-container {
         text-align: center;
      }
   }
}

.survey-overlay-container {
   display: flex;
   justify-content: center;
   align-items: center;
}

.overlay-wrap-margin {
   width: 100%;
   // margin-top: 5px;
   .overlay-container {
      position: absolute;
      left: 0;
   }

   & .sign-in-securities {
      top: 45%;
      position: absolute;
      left: unset;
      transform: translate(-35%, -50%);
   }
}

.popTheme-wrapper {
   width: 100%;
   display: flex;
   flex-direction: column;
   vertical-align: middle;

   &__item {
      position: relative;
      width: 100%;
      height: 100%;
      max-height: 105px;
      margin: 10px 0;
      border-radius: 14px;
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
      overflow: hidden;
      cursor: pointer;

      &:before {
         content: "";
         display: block;
         position: absolute;
         border-radius: 9px;
         height: 100%;
         width: 100%;
         background: linear-gradient(to right, transparent 40%, black 107%);
      }
      &:hover {
         transition: all 0.5s;
         -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
         filter: grayscale(0%);
      }

      & img {
         width: 100%;
         height: 100%;
         max-height: inherit;
         object-fit: cover;
      }
   }

   &__item:first-child {
      margin-top: 5px;
   }

   &__item:last-child {
      margin-bottom: 5px;
   }

   &__title {
      position: absolute;
      font-size: 0.9rem; // or 12px
      font-family: "Open Sans";
      color: #ffffff;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
   }
}

.sidebar-content {
   height: calc(100vh - (400px));
   margin-right: -12px !important;
   padding-left: 0 !important;
   padding-right: 8px !important;
}

.relInsight-wrapper {
   position: relative;
   width: 100%;
   display: flex;
   flex-direction: column;
   vertical-align: middle;
   border-radius: 14px;
   background-color: $other-bg-color;

   & .spin-loader {
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
   }

   &__item {
      display: flex;
      position: relative;
      margin: 15px 15px 0;
      border-radius: 14px;
      height: 96px;
      max-height: 96px;
      background-color: #e4e4e4;
      border: solid 1px $border-color;
      overflow: hidden;

      & img {
         width: 40%;
         min-width: 40%;
         max-width: 110px;
         // height: 100%;
         object-fit: cover;
         -webkit-filter: grayscale(100%);
         filter: grayscale(100%);
      }
   }

   // &__item:last-child {
   //   margin-bottom: 15px;
   // }

   &__content {
      position: relative;
      font-family: "Open Sans";
      display: flex;
      flex-direction: column;
      margin: 10px;
      height: 48px;
      // justify-content: center;
      overflow: hidden;

      & h5 {
         font-size: 10px !important;
         color: #979797;
         font-weight: normal;
         font-style: normal;
         font-stretch: normal;
         line-height: 1.36;
         letter-spacing: normal;
         margin-bottom: 2px;
      }

      & .body__Wrapper {
         width: 100%;
         font-weight: bold;
         font-size: 12px;
         font-weight: bold;
         color: #4a4a4a !important;
      }

      & .more-dots {
         position: absolute;
         bottom: 0;
         right: 0;
      }

      & .ad_related_insight {
         width: 66px;
         height: 17px;
         background-color: #4a4a4a;
         font-size: 10px;
         font-family: "Roboto";
         color: #ffffff;
         text-align: right;
         display: inline-block;
         padding: 2px;
         padding-right: 4px;
         margin-bottom: 4px;
         border-radius: 3px;
         //padding-bottom: 14px;
      }
   }

   & .mio-logo {
      text-align: center;
      margin: 5px 0;

      & img {
         width: 50px;
      }
   }
}
//-------------------------For Firefox only
@-moz-document url-prefix() {
   .tagWrapper {
      .tag {
         margin-bottom: 9px !important;
      }
   }
   .contentWrapper {
      .contentContainer {
         .itemListContainer {
            padding: 0;
         }
      }
   }
}

// .client-portfolio-menu {
//   position: absolute;
//   right: 65px;
//   border: solid 1px #5eade9;
//   border-radius: 50px;
//   background: #fff;
//   .disabled-events {
//     pointer-events: none !important;
//   }
//   .client-portfolio-menu-dropdown {
//     .ant-dropdown-menu {
//       .ant-dropdown-menu-item {
//         position: relative;
//         padding: 8px 15px;
//         font-weight: 400;
//         font-size: 16px;
//         color: #202529;
//         border-bottom: 1px solid rgba(205, 212, 219, 0.5);
//         .menu-label {
//           font-weight: bold;
//           font-size: 16px;
//           color: #5eade9 !important;
//           text-decoration: none;
//         }
//         #uploadCustomPortfolio {
//           position: absolute;
//           left: 0;
//           top: 0;
//           z-index: 1;
//           padding-right: 38px;
//           opacity: 0;
//         }
//         .icon {
//           width: 15px;
//           height: 15px;
//           vertical-align: 0;
//         }
//         .prefix-icon {
//           margin-right: 10px;
//           font-size: inherit;
//           vertical-align: 0;
//         }
//         .suffix-icon {
//           position: absolute;
//           bottom: 13px;
//           right: 15px;
//           z-index: 9;
//           margin: 0;
//           vertical-align: 0;
//         }
//         &:last-child {
//           border: 0;
//         }
//       }
//     }
//   }
//   .client-portfolio-menu-button {
//     position: relative;
//     padding: 0 20px;
//     padding: 0 25px 0 50px;
//     font-size: 16px;
//     font-weight: bold;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 1.38;
//     letter-spacing: normal;
//     text-align: center;
//     color: #5eade9;
//     border: 0;
//     border-color: #5eade9;
//     border-radius: 50px;
//     i {
//       font-size: 21px;
//       position: absolute;
//       top: 6px;
//       left: 18px;
//       margin: 0 !important;
//     }
//     .anticon {
//       position: absolute;
//       right: 12px;
//       bottom: 5px;
//       margin: 0;
//       vertical-align: 0;
//       line-height: 2;
//     }
//   }
// }
//
// @media only screen and (max-width: 1023px) and (min-width: 320px) {
//   .client-portfolio-menu {
//     display: none !important;
//     .client-portfolio-menu-button {
//       .button-label {
//         display: none !important;
//       }
//     }
//   }
// }

// @media only screen and (max-width: 1280px) and (min-width: 1024px) {
//   .portfolio-tools-wrap{
//     align-items: center;
//     .tool-badge {
//       height: 35px;
//       width: 100px;
//       .tools-link{
//         color: transparent!important;
//         font-size: 13px;
//         img{
//           height: 20px;
//           width: auto;
//         }
//         span{
//           display: none;
//         }
//       }
//       &.selected {
//         .tools-link{
//           color: transparent!important;
//         }
//       }
//     }
//   }
//   .client-portfolio-menu {
//     .client-portfolio-menu-button {
//       .button-label {
//         display: none !important;
//       }
//     }
//   }
// }
//
// @media only screen and (max-width: 1459px) and (min-width: 1281px) {
//   .client-portfolio-menu {
//     .client-portfolio-menu-button {
//       .button-label {
//         display: none !important;
//       }
//     }
//   }
// }
.relInsight-wrapper__item {
   cursor: pointer;
}

@media only screen and (max-width: 1275px) and (min-width: 1027px) {
   .referral-link,
   .referral-link-account {
      position: relative;
      // top: 10px !important;
   }
}

@media only screen and (min-width: 1276px) and (max-width: 1280px) {
   .referral-link,
   .referral-link-account {
      position: relative;
      // top: 10px !important;
   }
}

@media only screen and (width: 1280px) {
   .relInsight-wrapper__content .body__Wrapper {
      line-height: 1.3;
   }

   .relInsight-wrapper__content .ad_related_insight {
      float: left;
   }
}

@media only screen and (min-width: 1281px) and (max-width: 1583px) {
   .relInsight-wrapper__content .ad_related_insight {
      float: right;
   }
}

@media only screen and (min-width: 1024px) and (max-width: 1262px) {
   .relInsight-wrapper__content .ad_related_insight {
      float: right;
   }
}

.premium-msg-modal {
   &.premium-feature-process-modal-container {
      .cta-wrapper {
         .download-modal-btn {
            width: 218px !important;
            height: 40px;
            font-size: 20px;
            line-height: 0;
         }
      }
   }
}

.fs-20 {
   font-size: 20px !important;
}

.fs-18 {
   font-size: 20px !important;
}

@include respond(phone) {
   .fs-18 {
      font-size: 18px !important;
   }
   .premium-msg-modal {
      &.premium-feature-process-modal-container {
         .cta-wrapper {
            .download-modal-btn {
            }
         }
      }
   }
}

.checkout-model-popup-overlay {
   position: fixed;
   left: 0px;
   right: 0px;
   bottom: 0px;
   top: 0px;
   background: rgba(255, 255, 255, 0.5);
   z-index: 9;
   backdrop-filter: blur(5px);
}
.checkout-model-popup-main {
   position: fixed;
   left: 0px;
   right: 0px;
   bottom: 0px;
   top: 0px;
   display: flex;
   align-items: center;
   justify-content: center;
   z-index: 100;

   .modal-content {
      border-radius: 31px;
   }
}

.checkout-model-outer {
   background: #55a8e8;
   padding: 40px 30px;
   width: 622px;
   border-radius: 30px;
   -webkit-border-radius: 30px;
   position: relative;

   .checkout-model-cross {
      position: absolute;
      right: 30px;
      top: 20px;
      img {
         height: 20px;
      }
   }

   .checkout-model-title {
      display: block;
      text-align: center;
      font-size: 30px;
      color: #ffffff;
      margin-bottom: 35px;
   }
   .checkout-model-row {
      padding: 5px;
      display: flex;
      width: 100%;
      background: #ffffff;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      margin-bottom: 10px;

      .checkout-model-col {
         background: #f1f1f1;
         padding: 5px;
         flex: 1;

         &.checkout-model-left-col {
            min-width: 260px;
            border-left: 9px solid #55a8e8;
            padding-left: 20px;
            position: relative;
            border-right: 5px solid #ffffff;

            img.checkout-model-company-logo {
               height: 20px;
               max-width: 100px;
               margin-bottom: 10px;
               margin-top: 5px;
            }
            .checkout-model-companyType {
               font-size: 15px;
               color: #9b9b9b;
               position: absolute;
               right: 5px;
               top: 5px;
            }
            .checkout-model-dosName {
               color: #4a4a4a;
               font-size: 16px;
               margin-top: 5px;
               margin-bottom: 0px;
            }
         }
         &.checkout-model-middle-col {
            min-width: 135px;
            border-right: 5px solid #ffffff;
            .checkout-model-magnify {
               background: #55a8e8;
               width: 100%;
               height: 57px;
               display: flex;
               align-items: center;
               justify-content: center;
               font-weight: 600;
               font-size: 14px;
               color: #ffffff;
            }
         }
         &.checkout-model-right-col {
            min-width: 135px;
            display: flex;
            align-items: center;
            justify-content: center;

            .checkout-model-order {
               text-align: center;
               width: 100%;
               word-break: break-all;

               span {
                  font-weight: 600;
                  font-size: 15px;
                  display: block;
               }
               a {
                  color: #4a4a4a;
                  font-size: 11px;
                  font-weight: 600;
                  text-decoration: underline;
               }
               p {
                  color: #a5685f;
                  font-size: 11px;
               }
               &.ordered {
                  span {
                     color: #60b746;
                  }
               }
               &.order-failed {
                  span {
                     color: #a5685f;
                  }
               }
            }
         }
      }
   }
   .checkout-model-btns {
      display: flex;
      margin-top: 25px;
      .checkout-model-btn-section {
         flex: 1;
         padding: 0px 15px;

         button {
            cursor: pointer;
            width: 100%;
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #ffffff;
            color: #ffffff;
            font-size: 18px;
            font-weight: 600;
            border-radius: 21px;
            background: none;
            -webkit-border-radius: 21px;
            text-transform: uppercase;

            &:hover {
               background: #ffffff;
               color: #69a9e3;
            }
         }
      }
   }
}
.checkout-model-error-text {
   display: block;
   text-align: center;
   font-size: 15px;
   color: #fff;
}
.checkout-model-row-outer-container-section {
   max-height: 50vh;
   overflow-y: auto;

   &::-webkit-scrollbar {
      width: 5px;
      background: rgba(255, 0, 0, 0.2);
   }

   &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.2);
      width: 5px;
      border-radius: 10px;
      -webkit-order-radius: 10px;
   }

   &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 1);
      width: 5px;
      border-radius: 10px;
      -webkit-order-radius: 10px;
   }
}
.checkout-model-reason {
   font-size: 11px !important;
}
.checkout-model-tooltip {
   pointer-events: none !important;
   font-size: 19px;

   .tooltip-inner {
      background-color: #020000 !important;
   }
   .arrow:before {
      border-bottom-color: #4a4a4a !important;
   }
   &__tootlip {
      background-color: #fff;
   }
}
.ipo-calender-container {
   background-image: url("/assets/images/IpoCalender.svg");
   background-repeat: no-repeat;
   background-size: contain;
   background-position: center;
   display: flex;
   width: 174px;
   height: 100%;
   position: absolute;
}
.ipo-date-container {
   width: 174px;
   margin: 25px auto 25px;
   position: relative;
   text-align: center;
   font-size: 14px;
   color: #55a8e8;
}
.ipo-calender-title {
   color: #000;
   display: block;
   margin-bottom: 10px;
   text-align: center;
}

.currency-symbol {
   padding-right: 2px;
}

.currency-symbol-svg {
   height: 14px;
   font-size: inherit;
   color: inherit;
}

@import "./responsive";
