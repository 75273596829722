.premium-feature-process-modal-backdrop {
   display: block !important;
}

.premium-msg-modal {
   max-width: 630px !important;

   .modal-content {
      border: 0;
      border-radius: 15px;
      background-color: white;
      h1 {
         color: red;
      }
   }
   .modal-body {
      padding: 3.5rem;
   }
}

@include respond(tab) {
   .premium-feature-process-modal {
      width: 80%;
   }
}

@include respond(phone) {
   .premium-feature-process-modal {
      width: 95%;
   }
}

.premium-feature-process-modal {
   max-width: 936px !important;

   .modal-content {
      border: 0;
      border-radius: 15px;
      background-color: #fff;
   }
   .modal-body {
      padding: 3.5rem;
   }
}

.fs-1 {
   font-size: 1rem !important;
}

.text-align-center {
   text-align: center !important;
}

.text-align-left {
   text-align: left !important;
}

.justify-content-left {
   justify-content: left !important;
}

.premium-feature-process-modal-container {
   div.site-collapse-custom-collapse {
      div.ant-collapse-header {
         color: #7d7d7d;
         padding-left: 0px;
         width: 35%;
         span {
            svg {
               font-size: 15px !important;
            }
         }
      }
      div.ant-collapse-content-box {
         padding-left: 0px !important;
         padding-top: 0px !important;
         p {
            color: #7d7d7d !important;
         }
      }
      div.ant-collapse-item.site-collapse-custom-panel {
         border: #fff !important;
      }
      p {
         margin-bottom: 0px !important;
      }
   }
   .premium-feature-completed-close {
      position: absolute;
      right: 1rem;
      top: 1rem;
      z-index: 9;
      font-size: 1.5rem;
      color: #1e5f91;
      cursor: pointer;
   }

   .premium-feature-completed-check {
      font-size: 6rem;
      text-align: center;
      color: #fff;
   }

   .header-wrapper {
      h1 {
         font-size: 1.7rem !important;
         // font-size: 2rem !important;
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.31;
         letter-spacing: normal;
         color: #1e5f91;
         font-family: "Roboto";
      }
      h2 {
         font-size: 13px !important;
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.38;
         letter-spacing: normal;
         color: #1e5f91;
      }
      p {
         font-family: "Open Sans";
         font-size: 14px;
         color: #4a4a4a;
         margin-bottom: 15px;
      }
   }
   .premium-feature-title-text {
      padding: 0 30px;
      font-size: 32px;
      font-weight: 500;
      font-family: $secondary-font;
      line-height: 1.2;
      text-align: center;
      color: $label-black-color-2;
      .learn-more-text {
         color: $primary-color;
         cursor: pointer;
      }
   }
   .content-wrapper {
      font-size: 13px;
      font-weight: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      text-align: left;
      color: #1e5f91;
      margin-bottom: 1px;
   }

   .cta-wrapper {
      flex-direction: row;
      align-items: normal;
      .btn {
         // width: 140px !important;
         width: auto !important;
         font-size: 12px;
         font-weight: normal;
         padding: 13px 31px !important;
         line-height: 1;
         color: white;
         background-color: #1e5f91;
         border: 1px solid #1e5f91;
         &:first-child {
            margin-right: 20px;
         }
      }
   }
}

@include respond(phone) {
   .premium-msg-modal {
      .modal-content {
      }
      .modal-body {
         padding: 1rem;
      }
   }

   .premium-feature-process-modal {
      .modal-content {
      }
      .modal-body {
         padding: 1rem;
      }
   }

   .premium-feature-process-modal-container {
      .premium-feature-completed-close {
         right: 7px !important;
         top: 7px !important;
         font-size: 1.2rem !important;
      }

      .premium-feature-completed-check {
      }

      .header-wrapper {
         h1 {
            font-size: 1.1rem !important;
         }
         h2 {
            font-size: 13px !important;
         }
      }
      .content-wrapper {
      }
      .cta-wrapper {
         .btn {
            line-height: 1.2;
            font-size: 1rem !important;
            &:first-child {
               margin-right: 0.8rem;
            }
         }
      }
   }
}

$secondary-blue: #1e5f91;

.premium-feature-sub-heading {
   color: $secondary-blue;
   .fas {
      font-weight: 600;
      font-size: 12px;
      margin-left: 6px;
      &:hover {
         cursor: pointer;
      }
   }
}

.premium-msg-modal-sm-light {
   max-width: 560px !important;
   .premium-feature-sub-text {
      font-size: 13px;
      font-weight: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      text-align: left;
      color: $secondary-blue;
      margin-bottom: 15px;
      .fas {
         font-weight: 600;
         font-size: 12px;
         margin-left: 6px;
         &:hover {
            cursor: pointer;
         }
      }
   }
   .premium-feature-main-heading {
      color: $secondary-blue !important;
   }
   .close-light {
      color: $secondary-blue !important;
   }
   .modal-content {
      border: 0;
      border-radius: 15px;
      background-color: #f7f7f7;
   }
   .modal-body {
      padding: 3.5rem;
   }
}

.premium-msg-plan-details {
   margin-bottom: 20px;
   font-size: 13px;
   font-weight: normal;
   font-style: normal;
   line-height: 1.38;
   letter-spacing: normal;
   text-align: left;
   color: $secondary-blue;
   font-family: "Open Sans";
   h4 {
      color: $secondary-blue;
   }
   ul {
      height: 182px;
      overflow-y: scroll;
      list-style-type: none;
      padding-left: 20px;
      padding-right: 6px;
      &::-webkit-scrollbar {
         width: 4px !important;
         border-radius: 13px;
         background-color: transparent;
         margin-left: 5px;
      }
      &::-webkit-scrollbar-thumb {
         border-radius: 4px;
         background-color: #dbdbdb !important;
      }
   }
   ul li {
      line-height: 2;
      color: #4a4a4a;
      font-size: 14px;
      &::before {
         content: "\2022";
         color: $secondary-blue;
         font-weight: bold;
         display: inline-block;
         width: 0.5em;
         margin-left: -1em;
         font-size: 20px;
         height: 27px;
         float: left;
         margin-top: -5px;
      }
   }
   .terms-condition-select {
      span {
         font-size: 14px;
         line-height: 1.43;
         letter-spacing: normal;
         color: $secondary-blue;
      }
      .terms-text {
         text-decoration: underline;
      }
   }
}

.premium-msg-raf {
   margin-top: 10px;
   margin-bottom: 0px;
   color: $secondary-blue;
   a {
      text-decoration: none;
      cursor: pointer;
      color: $secondary-blue;
      border-bottom: 1px solid $secondary-blue;
      &:hover {
         color: $secondary-blue;
      }
   }
}

.button-blue {
   .secondary-btn {
      background-color: $secondary-blue !important;
      color: #ffffff !important;
      border: 1px solid $secondary-blue !important;
   }
}

.payment-status-modal {
   .payment-message-image {
      img {
         width: 200px;
         margin-bottom: 30px;
      }
   }
   .payment-status-heading {
      h1 {
         font-size: 35px !important;
         margin-bottom: 10px;
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.31;
         letter-spacing: normal;
      }
      .payment-success {
         color: $secondary-blue;
      }
      .payment-failure {
         color: #5e5e5e;
      }
   }
   .premium-feature-sub-text {
      color: rgba(0, 0, 0, 0.65);
   }
}

@include respond(phone) {
   .premium-msg-plan-details {
      ul li {
         font-size: 12px !important;
      }
   }
}

.print-selection-preview-modal {
   width: 75vw;
   min-width: 800px;
   max-width: 1060px;
   .close-light {
      color: $secondary-blue !important;
   }
   .modal-content {
      border: 0;
      border-radius: 15px;
      height: 90vh;
      max-height: 800px;
      background-color: #f7f7f7;
   }
   .modal-body {
      height: 100%;
      width: 100%;
      padding: 30px 50px;
   }
}
.upload-profile-modal-sm-light {
   min-width: 600px !important;
   //min-height: 440px;
   background: #f5f7f9;
   border-radius: 10px;
   padding: 2.5rem 0 2.5rem;
   // .premium-feature-sub-text {
   //   font-size: 13px;
   //   font-weight: normal;
   //   font-style: normal;
   //   line-height: 1.38;
   //   letter-spacing: normal;
   //   text-align: left;
   //   color: $secondary-blue;
   //   margin-bottom: 15px;
   //   .fas{
   //     font-weight: 600;
   //     font-size: 12px;
   //     margin-left: 6px;
   //     &:hover{
   //       cursor: pointer;
   //     }
   //   }
   // }
   // .premium-feature-main-heading {
   //   color: $secondary-blue !important;
   // }
   // .close-light {
   //   color: $secondary-blue !important;
   // }
   .modal-content {
      border: 0;
      border-radius: 15px;
      background-color: #f7f7f7;

      .modal-body {
         padding: 0px 1rem 0px;
         //height: 440px !important;
         .ant-upload.ant-upload-drag {
            padding: 4rem 0px 4rem 0px;
            //min-height: 225px !important;
            width: 510px !important;
            border-radius: 8px !important;
            margin: 0 auto !important;
         }
         .ant-upload-text {
            font-family: Roboto;
            font-size: 16px;
            font-weight: 300;
            text-align: center;
         }
      }
   }
}
.upload-profile-photo-modal-container {
   .header {
      h1 {
         font-family: Roboto;
         font-size: 2rem !important;
         font-weight: 500;
         text-align: center;
      }
      .centerDiv {
         text-align: center;
         width: 100%;
         top: 50%;
         left: 50%;
         position: relative;
         -webkit-transform: translate(-50%, -50%);
         transform: translate(-50%, -50%);
      }
   }
}
.details-button {
   border: 1px solid $primary-color !important;
   border-radius: 30px !important;
   width: 180px;
   height: 40px !important;
   color: $primary-color !important;
   font-size: $font-xs-m-px;
   font-weight: 600 !important;
   background-color: #fff;

   &:hover {
      background-color: $primary-color !important;
      color: white !important;
   }
}
.details-button-primary {
   background: $primary-color !important;
   background-color: $primary-color !important;
   border: 1px solid $primary-color !important;
   border-radius: 30px !important;
   width: 180px;
   height: 40px !important;
   color: White !important;
   font-size: $font-xs-m-px;
   font-weight: 600 !important;

   &:hover {
      background-color: white !important;
      color: $primary-color !important;
   }
}
.form-file-upload {
   height: 225px;
   width: 510px;
   border-radius: 8px;
   max-width: 100%;
   text-align: center;
   position: relative;
   margin: auto;
}
.file-upload-label {
   font-family: "Roboto";
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
}

@media screen and (max-width: 768px) {
   .upload-profile-modal-sm-light {
      min-width: 340px !important;
      margin-top: 15%;
   }
   .details-button {
      width: 130px !important;
   }
   .details-button-primary {
      width: 130px !important;
   }
}

.react__crop {
   .ReactCrop__image {
      max-width: 100% !important;
      object-fit: contain !important;
      @media (min-width: 991px) {
         max-height: 400px !important;
      }
      @media (max-width: 991px) {
         max-height: 300px !important;
      }
   }
}
