@import "../../BuildPortfolio/colors";
// Configuration settings for PDF
$dark-blue: #1d6091;
$light-blue: #56a9e8;

$lvl1-header-font-family: "Roboto";
// $lvl1-header-font-color: #1d6091;
$lvl1-header-font-size: 2.2rem;

$lvl2-header-font-family: "Roboto";
// $lvl2-header-font-color: #1d6091;
$lvl2-header-font-size: 1.6rem;

$body-font-family: "Open Sans";
$body-font-color: #4a4a4a;
$body-font-size: 1.2rem;

$chart-label-grid-color: #b0b0b0;

$pg-px: 12mm;

// .portfolio-reports-v2 .container{
//   width: 21cm !important;
//   min-width: 21cm !important;
// }

.recommendation-report {
   padding-top: 0;
   background-color: white !important;
   height: 100%;
   font-family: $body-font-family;
   font-color: $body-font-color;
   font-size: $body-font-size;
   max-width: 1080px;
   margin: auto;

   .page-header {
      margin-bottom: 10px;
      .logo,
      .logo-placeholder {
         max-height: 68px;
         height: 68px;
         width: auto;
      }
      .logo-placeholder {
         margin: 0;
         display: flex;
         align-content: center;
         font-size: 1.35rem;
         font-weight: bold;
      }
      .ml-auto {
         align-self: center;
         color: #a0a0a0;
      }
      //img{
      //  margin-left: -5px;
      //}
   }

   .page {
      .header {
         display: flex;
         flex-direction: column;
         justify-content: center;
         min-height: 80px;
         padding: 4mm $pg-px;
         border-bottom: 2px solid blue;
         margin-bottom: 6mm;

         h3,
         h5 {
            margin: 4px 0;
         }
         h3 {
            font-weight: bold;
         }
         h5 {
            color: blue;
         }

         &.type2 {
            padding: 6mm;
            border-bottom: none;
            background-color: blue;
         }
      }
      .page-header,
      .page-footer {
         // background-color: $gray-700;
         // color: white;
         padding: 7mm 0;
         font-size: 12px;
      }
      .page-footer {
         border-top: 1px solid #e3e3e3;
         color: #909090;
         padding-top: 20px;
         //margin-top: 10px;
         margin-bottom: 10px;
      }
   }

   .pr-v2-header {
      h3 {
         font-family: $lvl1-header-font-family;
         font-size: $lvl1-header-font-size !important;
         color: $dark-blue;
         margin-bottom: 50px;
      }
   }

   .pr-v2-disclosure {
      padding-top: 20px;
      padding-bottom: 60px;
      font-size: 0.85rem;
   }

   .pr-v2-light-blue {
      color: $light-blue;
   }

   .pr-v2-dark-blue {
      color: $dark-blue;
   }

   .recharts-cartesian-axis-tick text,
   .recharts-legend-item-text {
      font-size: 1.2rem;
      fill: $chart-label-grid-color;
      font-family: "Roboto";
   }
   .area-chart-label,
   .line-chart-ref-label {
      font-size: 1rem;
      fill: #4a4a4a;
      font-family: "Roboto";
   }
   .line-chart-label {
      font-size: 1rem;
      font-family: "Roboto";
   }
   .stackedAreaChart
      .recharts-wrapper
      .recharts-cartesian-grid-horizontal
      line:last-child {
      stroke-opacity: 0;
   }
   .recharts-legend-item path,
   .recharts-bar-rectangle path {
      shape-rendering: crispEdges;
   }
   .recharts-wrapper .recharts-surface {
      width: auto !important;
   }

   .pr-v2-chart-legends {
      // margin-top: 30px;
      p {
         padding-right: 70px;
         padding-left: 7px;
         float: left;
         font-size: 1.4rem !important;
         color: #aaaaaa;
         display: flex;
         height: 20px;
         align-self: center;
         align-items: center;
         margin-bottom: 0px;
         &:first-child {
            border-left: 4px solid $light-blue;
         }
         &:last-child {
            border-left: 4px solid $dark-blue;
         }
         span {
            padding-left: 40px;
         }
      }
   }

   .print-wrapper {
      text-align: center;
      margin: 0 auto;
      .recharts-wrapper,
      .d-flex {
         margin: 0 auto !important;
      }
   }

   .pr-v2-cover-page {
      padding-top: 60px;
      padding-bottom: 80px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      font-size: 1.35rem;
      color: $body-font-color !important;
      img,
      .cover-logo {
         margin-top: 29%;
         margin-bottom: 70px;
      }
      .cover-logo {
         width: auto;
         height: 75px;
         max-height: 75px;
         font-weight: bold;
      }
      .title-name-logo {
         margin-bottom: 40px;
         h1 {
            color: $dark-blue;
            font-size: 4.3rem !important;
            margin-bottom: 50px;
            display: inline;
            font-family: "Roboto";
            font-weight: 400;
         }
         img {
            display: inline;
            margin: 0 0 0 20px;
         }
      }
      h1 {
         color: $dark-blue;
         font-size: 4.3rem !important;
         margin-bottom: 50px;
         font-family: "Roboto";
         font-weight: 400;
      }
      .glossary-footer {
         font-size: 1.2rem;
         line-height: 1.6;
         span {
            margin-bottom: 5px;
         }
      }
   }

   .pr-v2-toc {
      font-size: 1.4rem !important;
      color: $dark-blue;
      tr {
         &:nth-child(odd) {
            //background: $c5;
         }
      }
      td {
         padding-top: 12px;
         padding-bottom: 12px;
         border: none;
         &:first-child {
            padding-left: 40px;
         }
         &:nth-child(2) {
            text-align: right;
            padding-right: 40px;
         }
      }
   }

   .pr-v2-ex-summary {
      h4 {
         font-size: $lvl2-header-font-size !important;
         font-family: $lvl2-header-font-family;
         color: $dark-blue;
         margin-top: 40px;
         margin-bottom: 15px;
      }
      .lt-bl-text {
         color: $dark-blue;
      }
      .pr-v2-ex-sum-table {
         margin-top: 30px;
         font-size: 21px;
         td {
            padding-top: 15px;
            padding-bottom: 15px;
            border: none;
            &:first-child {
               padding-left: 40px;
            }
            &:nth-child(2) {
               color: $light-blue;
            }
            &:last-child {
               padding-right: 40px;
               color: $dark-blue;
            }
         }
      }
   }

   .pr-v2-port-performance {
      .pp-value-wrapper {
         font-size: 17px;
         border: 1px solid $dark-blue;
         padding: 10px 25px;
         color: $dark-blue;
         display: inline-block;
         width: 100%;
         .ppv-left {
            text-align: left;
         }
         .ppv-right {
            text-align: right;
         }
      }
      .pr-v2-pp-stats {
         margin-top: 40px;
         margin-bottom: 40px;
      }
      .pr-v2-pp-sections {
         h4 {
            color: $dark-blue;
            font-size: $lvl2-header-font-size !important;
            font-family: $lvl2-header-font-family;
            margin-bottom: 15px;
         }
         #portfolio-linechart {
            .recharts-cartesian-axis-line {
               display: none;
            }
         }
      }
      .pr-v2-pp-table {
         margin-top: 40px;
         font-size: 21px;
         .row {
            &:first-child {
               padding-top: 10px;
            }
            padding-top: 25px;
            padding-bottom: 10px;
            &:nth-child(odd) {
               //background: $c5;
            }
            .col-8,
            .col-12 {
               padding-left: 40px;
            }
            // .col-2{
            //   color: $light-blue;
            //   &:last-child{
            //     padding-right: 60px;
            //     color: $dark-blue;
            //   }
            // }
            .pp-table-row-desc {
               font-size: 18px;
               line-height: 2;
               padding-top: 15px;
               padding-bottom: 15px;
               padding-right: 40px;
            }
         }
      }
      .bg-chart-block {
         width: 100%;
         display: flex;
         flex-direction: column;
         padding: 30px 30px;
         margin-bottom: 15px;
         background: #f6f6f6 !important;
      }
   }

   .pr-v2-asset-alloc {
      .pr-v2-aa-header {
         color: $dark-blue;
         font-size: $lvl2-header-font-size !important;
         font-family: $lvl2-header-font-family;
         margin-bottom: 20px;
      }
      h4 {
         font-size: 21px !important;
         margin-bottom: 15px;
      }
      .aa-chart-legend {
         font-size: 1.4rem !important;
         color: #aaaaaa;
         td {
            padding-bottom: 15px;
         }
         .aa-chart-legend-name {
            padding-left: 7px;
            float: left;
            display: flex;
            height: 20px;
            align-self: center;
            align-items: center;
            margin-bottom: 0px;
         }
         .aa-chart-legend-value {
            display: flex;
            align-self: center;
            align-items: center;
            height: 20px;
            margin-bottom: 0px;
         }
      }
   }

   .pr-v2-rec-enh {
      h4 {
         color: $dark-blue;
         font-size: $lvl2-header-font-size !important;
         font-family: $lvl2-header-font-family;
         margin-bottom: 15px;
      }
      .gen-row-ht {
         height: 100px;
      }
      .blue-bg {
         background: $dark-blue !important;
         color: #fff;
      }
      .lb-border {
         border: 1px solid $light-blue !important;
         color: $light-blue;
      }
      .rec-tt-wrapper {
         position: relative;
         height: 100px;
         width: 50px;
         .rec-transform-text {
            position: absolute;
            top: 50%;
            left: 50%;
            font-size: 1rem;
            -moz-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
            -webkit-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
            transform: translateX(-50%) translateY(-50%) rotate(-90deg);
         }
      }
      .rec-enh-chart-wrapper {
         display: flex;
         height: 100%;
         border: 1px solid #dee2e6 !important;
      }
      .rec-enh-tbl-hdr {
         th {
            vertical-align: middle;
            text-align: center;
            border: none;
         }
      }
      .pr-v2-rec-enh-table {
         margin-bottom: 0px;
         border: none !important;
         margin-top: 8px;
         tr {
            &:nth-child(odd) {
               border-collapse: separate;
               border-spacing: 0 15px;
            }
         }
         th {
            color: $dark-blue;
            font-size: 1.1rem;
            font-weight: bold;
            border: none !important;
            &:first-child {
               width: 5%;
               color: #dbdbdb;
            }
            &:not(:first-child) {
               width: 14%;
            }
            &:last-child {
               width: 20%;
            }
         }
         .rec-enh-ticker-name {
            font-size: 1.1rem;
            color: #4a4a4a;
            span {
               &:first-child {
                  display: inline-block;
                  margin-bottom: 5px;
               }
            }
         }
         .gauge-chart-report {
            .siw-meter-min,
            .siw-meter-value,
            .siw-meter-max {
               font-size: 1rem;
            }
            .siw-meter-value {
               font-weight: 600;
            }
            .siw-meter-desp {
               font-size: 1rem;
               margin-top: 10px;
               display: block;
               padding: 0px 10px;
               color: #979797;
            }
         }
      }
      .pr-v2-rec-enh-table.tbl-first {
         margin-top: 0px;
      }
      .row-break {
         height: 20px;
      }
      .pr-v2-rec-enh-table td {
         border: 1px solid #dee2e6 !important;
         vertical-align: middle;
         text-align: center;
      }
      .fees-flag-color,
      .returns-flag-color,
      .risk-flag-color {
         font-size: 1.1rem;
         color: #4a4a4a;
         font-weight: normal;
         i {
            color: #999999;
            font-size: 20px;
            margin-bottom: 5px;
         }
      }
      .flag-highlight {
         font-weight: bold;
      }
   }

   .pr-v2-conclusion {
      .pr-v2-conc-desc {
         position: absolute;
         background: #ffffff;
         top: 34px;
         right: 213px;
         border: 1px solid $dark-blue;
         padding: 5px 15px;
         color: $dark-blue;
         span {
            padding-left: 25px;
         }
      }
   }

   .pr-v2-clm-alignAll {
      align-self: center;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
   }

   .pr-v2-glossary {
      padding-top: 60px;
      padding-bottom: 60px;
      font-size: 0.85rem;
   }
   .appendix {
      width: 100%;
      .head {
         font-family: $secondary-font;
         font-size: $font-size-18px;
         background: $bg-color-light;
         font-weight: 500;
         font-stretch: normal;
         color: $label-black-color-2;
         border-bottom: 2px solid $other-bg-color-2;
         font-style: normal;
         line-height: 1.5;
      }
      tr {
         border-bottom: 2px solid $other-bg-color-2;
         font-family: $secondary-font;
         font-size: $font-size-14px;
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.19;
         letter-spacing: normal;
         text-align: left;
         height: 30px;
         padding-left: 30px;
         color: $label-black-color-2;
      }
      .serial {
         padding-left: 30px;
      }
      .weight {
         text-align: center;
      }
   }
}

@media print {
   .recommendation-report {
      .page {
         //page-break-after: always;
         height: 100%;
         min-height: 100%;
         display: flex;
         flex-direction: column;
         .content {
            flex: 1;
            // padding: 4mm $pg-px;
         }
      }
      .pr-v2-glossary {
         page-break-before: always;
      }
   }

   // .portfolio-reports-v2 .container{
   //   width: 100% !important;
   //   min-width: 100% !important;
   // }

   // .recharts-responsive-container {
   //   margin: 0 auto !important;
   // }

   .recharts-wrapper,
   .recharts-surface {
      width: 100% !important;
      min-width: 100% !important;
   }

   .table td,
   .table th {
      background-color: transparent !important;
   }
}

.swap-icon-wrapper {
   color: #b1b1b1 !important;
   font-size: 20px !important;
   font-weight: 600;
}
