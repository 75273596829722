@mixin respond($breakpoint) {
   @if $breakpoint==phone {
      @media only screen and (max-width: 767px) {
         @content;
      }
   }
   @if $breakpoint==tab {
      @media only screen and (max-width: 991px) {
         @content;
      }
   }

   @if $breakpoint==desktop {
      @media only screen and (max-width: 1024px) {
         @content;
      }
   }
}
