.invite-friends-wrapper {
   width: 100% !important;
   max-width: unset !important;
   margin: 0 auto;
   padding: 0 !important;

   .note-text {
      position: absolute;
      bottom: 20px;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.42;
      letter-spacing: normal;
      text-align: left;
      color: #1e5f91;
   }

   .header-area {
      padding-bottom: 2.3rem;
      font-family: "Open Sans";
      font-weight: 600;
      line-height: 1.35;
      letter-spacing: normal;
      text-align: left;

      .header-label {
         font-size: 20px;
         color: #1e5f91 !important;
      }
      .sub-header-label {
         font-size: 14px;
         color: #1e5f91 !important;
      }
   }
   // .disabled {
   //   pointer-events: none;
   //   cursor: not-allowed;
   // }
   .body-wrapper {
      height: auto;
      padding-bottom: 36px;

      .label {
         margin: 0;
         font-size: 0.8rem;
         font-weight: normal;
         color: #1e5f91 !important;
      }

      .custom-scroll {
         height: auto;
         max-height: 220px;
         padding-left: 0 !important;
         transition: all 0.3s;

         .row {
            &:last-child {
               .element-box {
                  padding-bottom: 0;
               }
            }
         }
         @include respond(phone) {
            .row {
               margin-bottom: 20px !important;
               &:last-child {
                  .element-box {
                     padding-bottom: 10px;
                  }
               }
            }
         }
      }
      @include respond(phone) {
         .custom-scroll {
            max-height: calc(100vh - (220px)) !important;
         }
      }
      .add-more-btn {
         position: absolute;
         bottom: 12px;
         right: 0;
         font-size: 13px;
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.38;
         letter-spacing: normal;
         text-align: right;
         color: #1e5f91;
         border: 0;
         background: transparent;
         padding: 0;
      }
      .index-number-box {
         display: flex;
         align-items: center;
         justify-content: center;
         padding: 0;
         padding-left: 5px;
         .index-number {
            width: 25px;
            height: 25px;
            padding: 5px;
            line-height: 1;
            border-radius: 50px;
            border: 1px solid #1e5f91;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: center;
            color: #1e5f91;
         }
      }
      @include respond(phone) {
         .index-number-box {
            padding: 0 1rem !important;
            justify-content: left !important;
            .index-number {
               margin-bottom: 10px;
            }
         }
      }
      @include respond(tab) {
         .index-number-box {
            padding-left: 1rem !important;
            justify-content: left !important;
            .index-number {
               margin-bottom: 10px;
            }
         }
      }
      .element-box {
         display: flex;
         flex-direction: column;
         padding-bottom: 10px;
         margin: 0;
         font-family: "Open Sans";
         font-weight: 600;
         font-size: 0.8rem;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.35;
         letter-spacing: normal;
         text-align: left;

         .invalid-feedback {
            position: absolute;
            bottom: 12px;
         }

         .required:after {
            // content:"*";
            //color: red;
         }
         .input-area {
            height: auto;
            padding: 7px 0;
            font-size: 0.8rem;
            color: #1e5f91 !important;
            background-color: transparent !important;
            border: 0;
            border-radius: 0;
            border-bottom: 1px solid;
            border-bottom-color: #1e5f91 !important;
            transition: 0.2s ease all;
            &::placeholder {
               color: #1e5f91;
            }
            &:focus,
            &:hover {
               border-bottom-color: #1e5f91 !important;
               transition: all 0.3s;
               box-shadow: none !important;
            }
         }

         input:-webkit-autofill,
         input:-webkit-autofill:hover,
         input:-webkit-autofill:focus,
         textarea:-webkit-autofill,
         textarea:-webkit-autofill:hover,
         textarea:-webkit-autofill:focus,
         select:-webkit-autofill,
         select:-webkit-autofill:hover,
         select:-webkit-autofill:focus {
            // -webkit-text-fill-color: transparent;
            // -webkit-box-shadow: 0 0 0 30px white inset !important;
            -webkit-animation-name: autofill;
            -webkit-animation-fill-mode: both;
         }

         .hyper-link {
            position: absolute;
            bottom: 8px;
            right: 0;
         }
         .error-message {
            font-size: 0.8rem;
            color: #a5685f;
         }
         // .invalid-feedback {
         //   font-size: 0.8rem;
         //   color:#a5685f;
         // }
         // .form-invalid-field {
         //   font-size: 0.8rem;
         //   position: absolute;
         //   bottom: 12px;
         //   color: #a5685f;
         // }
      }

      .was-validated .form-control:invalid,
      .form-control.is-invalid {
         background-image: none !important;
      }
   }
   .margin-left-51 {
      margin-left: 70px !important;
   }
   @include respond(tab) {
      .margin-left-51 {
         margin-left: 40px !important;
      }
   }
   @include respond(phone) {
      .margin-left-51 {
         margin-left: 0 !important;
         margin-bottom: 30px !important;
      }
   }

   .cta-wrapper {
      margin: 0;
      .btn {
         width: 140px !important;
         height: auto;
         font-size: 12px;
         padding: 13px 31px !important;
         line-height: 1;
         margin: 0;
      }
   }
}
