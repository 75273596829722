@import "./variables";
@import "./mixins/mixins.scss";

.bg-sfmodal-header {
   background-color: $sfmodal-header !important;
}
.color-sfmodal-header {
   color: $sfmodal-header !important;
}
.bg-sfmodal-grey {
   background-color: $sfmodal-tab-active !important;
}
.text-blue-6i {
   color: $sfmodal-text-blue !important;
}
.text-white-6i {
   color: #fff !important;
}
.text-sfmodal-black {
   color: $sfmodal-header !important;
}
.bg-secondary-tab {
   background-color: $sfmodal-tab !important;
}

.font-weight-6 {
   font-weight: 600 !important;
}

.modal.suggest {
   .modal-dialog {
      .modal-content {
         border: 4px solid rgba(0, 0, 0, 0.5);
         border-radius: unset;
      }
   }
}

.modal.feedback {
   display: block;
   position: fixed;
   bottom: 0;
   overflow: unset;
   transform: translateY(100%);
   .modal-dialog {
      margin: 0 auto;
      transition: min-width 0.3s, max-width 0.3s, transform 0.3s, left 0.3s;
   }
   &.show {
      &.min .modal-dialog {
         transform: translateY(-49px);
         max-width: 200px;
         min-width: 200px;
         left: -455px;
      }
      &.full .modal-dialog {
         left: -75px;
         transform: translateY(-100%);
         min-width: 400px;
      }
   }
   .modal-header {
      cursor: pointer;
      .modal-title {
         line-height: 1rem;
      }
      .close {
         font-size: 1rem;
         margin: 0;
         position: absolute;
         top: 0;
         right: 0;
      }
   }
}

.modal.sfp {
   display: block;
   position: fixed;
   bottom: 0;
   overflow: unset;
   transform: translateY(100%);
   .modal-dialog {
      margin: 0 auto;
      right: -150px;
      min-width: 900px;
   }
   &.show {
      &.min .modal-dialog {
         transform: translateY(-49px);
      }
      &.anim .modal-dialog {
         transform: translateY(-96px); // previous -85
      }
      &.full .modal-dialog {
         transform: translateY(-100%);
      }
   }
   .modal-header {
      cursor: pointer;
      .modal-title {
         line-height: 1rem;
      }
      .close {
         font-size: 1rem;
         margin: 0;
         position: absolute;
         top: 0;
         right: 0;
      }
   }
}

.modal.sfp.fade .modal-dialog {
   -webkit-transform: translate(0, 25%);
   transform: translate(0, 25%);
}

//.main.modal-open .modal { overflow: unset; }
.main .modal-backdrop {
   display: none;
}
.brokers-backdrop {
   display: block !important;
}
.main.modal-open-suggest .suggest-backdrop {
   display: block;
}
.main.modal-open-sfm .sfp-backdrop {
   display: block !important;
   opacity: 0.4;
   background-color: #060606;
}
.main.modal-open-fb .feedback-backdrop {
   display: block;
}
.main.modal-open-fb {
   overflow: hidden;
}
.main.modal-open-sfm {
   overflow: hidden;
}
// .main.modal-open-suggest {overflow : hidden;}
body.main:not(.modal-open) {
   padding-right: 0 !important;
}

.modal.sfp .modal-content {
   .nav.nav-tabs {
      @include respond(phone) {
         flex-wrap: nowrap !important;
         overflow: hidden;
         overflow-x: scroll;
      }
      color: $white;
      background-color: $ssf-blue-d2;
      border-bottom: none;
      border-top: 1px solid $ssf-blue;
      border-top: 1px solid #797c7e;
      .nav-item {
         border-right: 1px solid $ssf-blue;
         border-right: 1px solid #797c7e;
      }
      .nav-link {
         border: none;
         border-radius: 0;
         padding: 6px 6px 2px !important;
         @include respond(phone) {
            padding: 10px 15px !important;
         }
         background-color: $sfmodal-tab;
         text-transform: uppercase;
         border-bottom: 4px solid transparent;
         cursor: pointer;
         &.active {
            color: $white;
            // border-bottom: 4px solid rgba(0,0,0,0.1);
            color: $ssf-blue-d3;
            font-weight: 600;
            background-color: $sfmodal-tab-active;
            @include respond(phone) {
               background-color: $ssf-gray-l8;
               color: white;
               border-bottom: 2px solid white;
            }
         }
      }
      .nav-item:last-of-type {
         border-right: none;
      }
   }
   .modal-body-ssf {
      height: 448px;
      overflow-y: auto;
   }
   .modal-footer {
      background-color: $ssf-gray-l3;
      border-top-color: $gray-400;
   }
}
.modal.confirm {
   .modal-sm {
      max-width: 350px;
   }
   .modal-footer {
      border-top: none;
      .btn-block {
         font-weight: 600;
         padding: 0.45rem !important;
         border-radius: 25px !important;
         border: 1px solid #fff;
         width: 200px;
         margin: 0 auto;
         text-decoration: none;
         &.continue {
            background-color: #fff;
            color: #56a9e8 !important;
         }
      }
   }
   .close-icon {
      position: absolute;
      right: 7px;
      top: 10px;
      cursor: pointer;
   }
}

.modal.sfp .modal-content {
   .chart-wrap {
      height: 89px;
   }
   .list-wrap {
      height: 380px;
   }
   .list-wrap-h100 {
      position: relative;
      // height: 440px;
      max-height: 100%;
   }
   .btn-wrap {
      .btn-group {
         white-space: nowrap !important;
      }
   }
   .selected-card {
      // min-height: 71px;
      min-height: 97px;
   }
   .info-tile {
      min-height: 315px;
   }
   .ssf-spacer {
      padding-top: 3px;
      padding-bottom: 3px;
   }
}

@media (max-height: 640px) {
   .modal.feedback {
      &.show {
         &.min .modal-dialog {
            transform: translateY(-41px);
         }
         &.anim .modal-dialog {
            transform: translateY(-83px); // previous -72
         }
      }
   }
   .modal.feedback .modal-content {
      .modal-header,
      .modal-header button {
         padding-top: 0.75rem !important;
         padding-bottom: 0.75rem !important;
      }
      .modal-footer {
         .btn.h3 {
            // padding: .75rem !important;
            // font-size: 1rem !important;
         }
      }
   }
   .modal.sfp {
      &.show {
         &.min .modal-dialog {
            transform: translateY(-41px);
         }
         &.anim .modal-dialog {
            transform: translateY(-83px); // previous -72
         }
      }
   }
   .modal.sfp .modal-content {
      .SFM-v2-tabs .ant-tabs-content,
      .SFM-v2-tabs .ant-tabs-top-content,
      .SFM-v2-tabs .ant-tabs-bottom-content {
         min-height: 384px;
      }
      .nav.nav-tabs .nav-link {
         font-size: 0.875rem;
         line-height: 1.4;
      }
      .modal-body-ssf {
         height: 384px;
         max-height: 384px;
         .p-3 {
            padding: 0.75rem !important;
         }
      }
      .chart-wrap {
         // height: 70px;
         height: 86px !important;
      }
      .list-wrap {
         height: 330px;
      }
      .list-wrap-h100 {
         height: 384px;
      }
      .info-tile {
         min-height: 260px;
         td {
            padding: 5px;
            font-size: 0.75rem;
         }
      }
      .ssf-spacer {
         padding-top: 0;
         padding-bottom: 0;
      }
      .modal-header,
      .modal-header button {
         // padding-top: .75rem !important;
         // padding-bottom: .75rem !important;
      }
      .modal-footer {
         .btn.h3 {
            padding: 0.75rem !important;
            font-size: 1rem !important;
         }
      }
   }
   .modal.sfp {
      .btn-wrap {
         &.my-1 {
            margin-top: 0 !important;
         }
         .h3 {
            font-size: 1rem !important;
         }
         .btn-group {
            white-space: nowrap !important;
         }
      }
      .progress.score {
         height: 21px !important;
      }
   }
}

.modal-open {
   overflow: hidden;
   overflow-y: auto;
   padding-right: 0 !important;
}

.embed-link-modal {
   max-width: 800px;

   & .modal-content {
      background-color: #fff;
   }

   & .modal-body {
      padding-top: 0;
   }
}

.alert-window-modal-class {
   z-index: 9999;
}

.alert-window-modal {
   margin-right: 0;
   z-index: 9999;
   // width: max-content;
   .modal-content {
      .modal-body {
         padding: 0;
         margin: 0;
      }
   }
}

.sfm-combine-tab-wrapper {
   // position: relative;
}

.alert-windown-wrapper {
   // width: max-content;
   z-index: 9999;
   margin: 0;
}

.report-spinner-wrapper {
   position: absolute;
   width: 100%;
   align-items: center;
   display: flex;
   height: 100%;
   justify-content: center;
   z-index: 9999;
   opacity: 0.4;
   background-color: #060606;
   i {
      color: #fff !important;
   }
}

.modal-btn-br {
   border-radius: 30px;
   font-size: 12px !important;
   // line-height: 1.4;
   padding: 6px 15px !important;
}

.sfmodal-sub-head {
   padding-left: 5px;
   font-size: 11px !important;
   font-weight: bold;
}

.sfmodal-key {
   border-radius: 10px;
   background-color: $sfmodal-text-blue;
   padding: 2px 7px;
}

.sfmodal-date {
   background-color: #dbdbdb;
   border-radius: 10px;
   padding: 2px 7px;
}

.sfmodal-btn-arw {
   color: $sfmodal-tab !important;
   border-color: $sfmodal-tab !important;
}

.sfmodal-btn-arw:not(.comm) {
   background-color: $white !important;
   &:hover {
      background-color: $sfmodal-tab !important;
   }
}
