#funds-board-head {
   .pf-content-header {
      padding: 0;
      align-items: center;
      .col-wrap {
         padding: 10px 0px 12px 0;
         &:first-child {
            padding-top: 8px !important;
         }
         &:last-child {
            padding-top: 2px;
         }
      }
      .showing-result-col {
         margin-top: 0px;
         padding-top: 0;
         padding-bottom: 7px;
         font-size: 16px !important;
      }
      .evaluate-col {
         .prt-cat-coll-wrapper {
            label {
               &:first-child {
                  margin-top: -2px;
               }
            }
            .ant-dropdown-trigger {
               padding-top: 0 !important;
               font-stretch: normal;
               font-style: normal;
               text-align: left;
               line-height: 1;
               color: $dark-active-color;
               .anticon-down {
                  top: 54% !important;
                  right: 8px !important;
               }
            }
         }
      }
      .enhancer-portfolio-name-container {
         align-items: center;
         line-height: 1;
      }
      .return-home-button {
         margin-top: -8px;
         padding: 0px;
         border-radius: 50%;
         img {
            border-radius: 50%;
         }
      }
   }
   .enhancer-cta-container {
      padding: 12px 0;
   }
}
