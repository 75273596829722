@import "./colors";
@import "./Elements/index";
@import "./responsive";

.cp-comp {
   padding: 0px !important;
   background: #fff !important;
   // Model Selector Dropdown Styles
   .cus-pf-scroll {
      max-height: 200px;
      overflow-y: auto;
      &::-webkit-scrollbar {
         width: 15px !important;
         background-color: transparent;
         border-left: 1px solid #fff;
      }
      &::-webkit-scrollbar-thumb {
         border-radius: 25px;
         background-color: #d2d2d2;
         border: 5px solid transparent;
         background-clip: content-box;
      }
   }
}

.client-portfolio-sponsor-dropdown {
   ul {
      &:first-child {
         max-height: 40vh;
         overflow: hidden !important;
         overflow-y: scroll !important;
         border-radius: 10px !important;
      }
   }
}

.cp-wrap {
   height: 100%;
   overflow: hidden;
   display: flex;
   flex-direction: column;
   // background: #fff;
   .navBarWrapper {
      background: $bg-color-light;
      padding-bottom: 5px !important;
   }
   .client-portfolio-screen-wrapper {
      background: #fff;
      flex: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      .content-title {
         color: $label-black-color;
         font-size: $font-size-28px;
         text-align: center;
         font-family: $secondary-font;
         // font-weight: bold;
         // padding-top: 12px;
         padding-bottom: 12px;
         margin-top: -10px;
      }
      .content-body {
         display: flex;
         flex-direction: column;
         flex: 1;
         overflow: hidden;
      }
   }
   .magnifi-header-wrapper {
      position: relative;
   }
   .selected-pf-dropdown {
      background: #c1c1c1;
      text-align: center;
      border-radius: 25px;
      margin: 10px;
      padding: 5px 10px;
      font-size: $font-size-11px;
      margin: 0px 5px;
      display: flex;
      align-items: center;
      color: #fff;
      justify-content: center;
      width: 190px;
      &.active {
         background: $primary-color;
      }
      i {
         padding-left: 10px;
      }
   }
   .rdt_TableBody,
   .ant-tabs-tabpane {
      // height: calc(100vh - 540px);
      overflow: hidden;
      //overflow-y: auto !important;
      height: 95%;
      &::-webkit-scrollbar {
         width: 20px !important;
         background-color: transparent;
         border-left: 1px solid #fff;
      }
      &::-webkit-scrollbar-thumb {
         border-radius: 25px;
         background-color: #d2d2d2;
         border: 7px solid transparent;
         background-clip: content-box;
      }
   }
   .rdt_TableRow {
      min-height: 54px;
   }
   .rdt_TableCell {
      // padding-top: 18px;
      // padding-bottom: 18px;
      // min-height: inherit;
      min-height: 54px;
      span {
         display: flex;
         align-items: center;
         text-align: center;
         .tbl-edit {
            padding-left: 10px;
         }
      }
   }
   .ant-table-body {
      // display: flex;
      &::-webkit-scrollbar {
         width: 15px !important;
         background-color: transparent;
         border-left: 1px solid #fff;
      }
      &::-webkit-scrollbar-thumb {
         border-radius: 20px;
         background-color: #d2d2d2;
         border: 5px solid transparent;
         background-clip: content-box;
      }
   }
   .cta-wrapper {
      .tgen-cta {
         width: max-content;
         padding: 0.5em 4em !important;
         margin-top: 10px;
         color: #fff;
         border-color: $dark-blue-color;
         background-color: $dark-blue-color;
         &:focus {
            background-color: $dark-blue-color;
         }
         &.no-fill {
            color: $dark-blue-color;
            background-color: inherit;
         }
         &:disabled {
            color: $other-grey-color-2;
            border-color: $other-grey-color-2;
            background-color: inherit !important;
         }
      }
   }
   .general-wrapper {
      margin: 0 auto;
      width: 86%;
      max-width: 1200px;
   }
   .gen-spacing {
      padding-top: 30px;
      padding-bottom: 30px;
   }
   .gen-divider {
      border: 2px solid $bg-color-light;
   }
   .custom-checkbox {
      .custom-control-label {
         display: list-item !important;
         &::before {
            border: 2px solid $primary-color !important;
            border-color: $primary-color !important;
            border-width: 2px;
            border-radius: 50%;
            background: #fff;
         }
      }
      .custom-control-input:checked ~ .custom-control-label::before {
         background-color: $primary-color !important;
      }
   }
}

body.modal-open .cp-wrap {
   -webkit-filter: blur(5px);
   -moz-filter: blur(5px);
   -o-filter: blur(5px);
   -ms-filter: blur(5px);
   filter: blur(5px);
}

body.cp-comp.modal-open {
   padding-top: 0px !important;
   overflow: auto !important;
}

.cp-modal-close {
   position: absolute;
   right: 1rem;
   top: 1rem;
   z-index: 9;
   font-size: 1rem;
   color: #c5c5c5;
   cursor: pointer;
}

.v-center {
   display: flex;
   flex-direction: column;
   height: 100%;
   align-items: center;
   align-self: center;
   justify-content: center;
}

// .client-portfolio-menu {
//   position: absolute;
//   right: 65px;
//   border: solid 1px #5eade9;
//   border-radius: 50px;
//   background: #fff;
//   .disabled-events {
//     pointer-events: none !important;
//   }
//
//   .client-portfolio-menu-button {
//     position: relative;
//     padding: 0 20px;
//     padding: 0 25px 0 50px;
//     font-size: 16px;
//     font-weight: bold;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 1.38;
//     letter-spacing: normal;
//     text-align: center;
//     color: #5eade9;
//     border: 0;
//     border-color: #5eade9;
//     border-radius: 50px;
//     i {
//       font-size: 21px;
//       position: absolute;
//       top: 6px;
//       left: 18px;
//       margin: 0 !important;
//     }
//     .anticon {
//       position: absolute;
//       right: 12px;
//       bottom: 5px;
//       margin: 0;
//       vertical-align: 0;
//       line-height: 2;
//     }
//   }
// }
//
// .client-portfolio-menu-active {
// 	background-color: #1E5F91 !important;
// 	border: 1px solid #1E5F91 !important;
// 	.client-portfolio-menu-button {
// 		background-color: #1E5F91 !important;
// 		color: #fff !important;
// 	}
// }
//
// @media only screen and (max-width: 1023px) and (min-width: 320px) {
//   .client-portfolio-menu {
//     display: none !important;
//     .client-portfolio-menu-button {
//       .button-label {
//         display: none !important;
//       }
//     }
//   }
// }
//
// @media only screen and (max-width: 1280px) and (min-width: 1024px) {
//   .client-portfolio-menu {
//     .client-portfolio-menu-button {
//       .button-label {
//         display: none !important;
//       }
//     }
//   }
// }
//
// @media only screen and (max-width: 1459px) and (min-width: 1281px) {
//   .client-portfolio-menu {
//     .client-portfolio-menu-button {
//       .button-label {
//         display: none !important;
//       }
//     }
//   }
// }
