.protected-button-modal-container {
   height: 100%;
   min-width: 320px;
   max-width: 370px;
   margin: 0 auto;
   position: relative;
   @media (max-width: 400px) {
      width: 320px;
   }
   .modal-content {
      border: 0;
      border-radius: 15px;
      background-color: $primary-color;
   }
   .modal-body {
      border-radius: 15px;
      background-color: $primary-color;

      .protected-button-container {
         .premium-feature-completed-close {
            color: #fff;
         }

         .header-wrapper {
            p {
               font-size: 20px;
               color: #fff;
            }
         }

         .primary-btn {
            min-width: 160px;
            height: 42px;
            color: $primary-color;
            font-weight: 600;
            border: 1px solid $primary-color;
            border-radius: 50px;
            background: #fff;
            font-size: 14px !important;
         }

         .secondary-btn {
            min-width: 160px;
            height: 42px;
            color: #fff;
            font-weight: 600;
            border: 1px solid #fff;
            border-radius: 50px;
            background: $primary-color;
            font-size: 14px !important;
         }

         .divider {
            margin: 20px 0;
            position: relative;
            display: inline-block;

            .circle {
               width: 40px;
               height: 40px;
               border-radius: 50%;
               color: $primary-color;
               line-height: 37px;
               text-align: center;
               background: $primary-color;
               border: 1px solid $primary-color;
            }

            &:before {
               content: "______________";
               position: absolute;
               top: 2px;
               left: -105px;
               color: #fff;
            }

            &:after {
               content: "______________";
               position: absolute;
               top: 2px;
               right: -105px;
               color: #fff;
            }
         }
      }
   }
}
