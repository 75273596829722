$background: #f7f7f7;
$border: #b7b7b7;
$text-color: #4a4a4a;
$text-color-highlighted: #56a9e8;
$fontRoboto: "Roboto";
$fontOpenSans: "Open Sans";
.container {
   display: flex;
   width: 100%;
   flex-direction: column;
   background: $background;
   color: $text-color;
   position: relative;
}

.name {
   font-family: $fontOpenSans;
   // font-size: 30px;
   font-weight: bold;
   padding-bottom: 20px;
   text-transform: capitalize;
}

.subHeader {
   font-size: 22px;
   font-family: $fontRoboto;
   line-height: 34px;
   // margin-top: 60px;
}

.form {
   display: flex;
   flex-direction: column;
}

.formGroup {
   display: flex;
   margin-bottom: 0 !important;
   align-items: center;
   flex-direction: row;
   justify-content: space-between;
   height: 40px;
   font-size: 14px;
   padding: 8px 0;
   input:-webkit-autofill,
   input:-webkit-autofill:hover,
   input:-webkit-autofill:focus,
   textarea:-webkit-autofill,
   textarea:-webkit-autofill:hover,
   textarea:-webkit-autofill:focus,
   select:-webkit-autofill,
   input:-internal-autofill-selected,
   select:-webkit-autofill:hover,
   select:-webkit-autofill:focus {
      font-size: 14px;
      box-shadow: none;
      -webkit-text-fill-color: $text-color-highlighted;
      -webkit-box-shadow: 0 0 0px 1000px $background inset;
   }

   .key {
      text-align: left;
      color: $text-color !important;
      margin-bottom: 0;
      font-family: $fontRoboto;
   }

   .value {
      outline: none;
      background: none;
      border: none;
      text-align: right;
      min-width: 80px;
      height: 24px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 0;
      font-family: $fontOpenSans;
      color: $text-color-highlighted !important;

      * {
         margin-bottom: 0;
      }
   }

   .addressInfo {
      display: flex;
      flex-direction: column;
      .firstInfo {
         display: flex;
         margin-bottom: 10px;
      }
      .secInfo {
         display: flex;
         justify-content: flex-end;
      }
   }

   .cursorPointer {
      cursor: pointer;
   }

   .AccIve {
      cursor: pointer;
      width: 80px;
      text-align: right;
   }

   .genderSelection {
      display: flex;
      flex-direction: column;

      * label {
         margin-right: 0;
         padding-left: 0;
      }
   }

   .input {
      border: none;
      outline: none !important;
      background: none;
      text-align: right;
      margin: 0 0 0 10px;
      cursor: pointer;
      padding: 0 0 0 4px !important;
      height: 24px;
      font-family: $fontOpenSans;
      font-size: 14px;
      font-weight: 700;
      width: 130px;
      display: flex;
      align-items: center;
      border-radius: 0;
      border-bottom: 0;
      color: $text-color-highlighted;
      .tooltipIcon {
         display: none;
      }
   }

   .input:hover {
      cursor: pointer;
   }

   .input:focus {
      outline: none;
      background: none;
      box-shadow: none;
      cursor: text;
      border-radius: 0;
      color: $text-color-highlighted;
      border-bottom: 1px solid $border;
   }
   .antSelect {
      min-width: 150px;
      width: auto;
      max-width: 220px !important;
      padding: 0 !important;
      div {
         padding: 0 !important;
         background-color: transparent !important;
         span:nth-child(2) {
            padding: 0;
         }
      }
      .benchmarkRow {
         justify-content: flex-end;
      }
      .tooltipIcon {
         display: none;
      }
   }
   .aum {
      max-width: 250px !important;
   }
   .input:active {
      cursor: text;
   }

   .inputInvalid {
      border: none;
      border-bottom: 1px solid #a5685f !important;
      border-radius: 0;
      background-color: transparent;
      width: 160px;
      padding: 0;
      padding-right: 0 !important;
      height: 24px;
      text-align: right;
      font-weight: 700;
      color: $text-color;
      background-image: none !important;
      outline: none !important;
   }

   .inputInvalid:focus {
      outline: none !important;
   }

   .inputInvalid:active {
      cursor: text;
   }

   .baseInputClass {
      background: none;
      height: 24px;
      padding: 0;
      border: none;
      outline: none !important;
      font-size: 14px;
      box-shadow: none;
      color: $text-color-highlighted;
      font-family: $fontOpenSans;
      border-radius: 0;
      font-weight: 700;
   }

   .country {
      width: 120px;
      border-bottom: 1px solid $border;
   }

   .country:focus,
   .hearAboutUS:focus {
      outline: none;
      background: none;
      box-shadow: none;
   }

   .DOB {
      width: 130px;
      cursor: pointer;
      text-align: right;
      padding-right: 0;
      font-family: $fontOpenSans;

      * label {
         display: none;
      }
   }

   .hearAboutUS {
      width: 210px;
   }
}

.addressGroup {
   height: auto;
   align-items: flex-start;
}

.divider {
   min-width: 100%;
   height: 1px;
   margin: 20px 0;
   background-color: $text-color;
   opacity: 0.4;
}

.delete {
   align-self: flex-end;
   height: 26px;
   border-radius: 24px;
   padding: 0 18px;
   border: solid 1px $text-color-highlighted;
   margin-top: 20px;
   background: none;
   font-size: 14px;
   font-family: $fontRoboto;
   color: $text-color-highlighted;
   text-transform: uppercase;
   font-weight: 500;
   cursor: pointer;
}

.delete_disable {
   align-self: flex-end;
   height: 26px;
   border-radius: 24px;
   padding: 0 18px;
   border: solid 1px gray;
   margin-top: 20px;
   background: none;
   font-size: 14px;
   font-family: $fontRoboto;
   color: gray;
   text-transform: uppercase;
   font-weight: 500;
   cursor: not-allowed;
}

.update {
   align-self: flex-end;
   width: 95px;
   height: 26px;
   border-radius: 24px;
   padding: 0 18px;
   border: solid 1px $text-color-highlighted;
   margin-top: 20px;
   background: none;
   font-size: 14px;
   font-family: $fontRoboto;
   color: $text-color-highlighted;
   text-transform: uppercase;
   font-weight: 500;
   cursor: pointer;
}

.changePassword {
   text-align: center;
   display: flex;
   flex-direction: column;

   .title {
      margin: 20px 0;
      font-size: 30px;
      color: $text-color;
      text-align: center;
   }

   .formGroup {
      height: auto;
      display: flex;
      flex-direction: column;

      .input {
         border: 0;
         outline: none !important;
         background: none;
         text-align: left;
         margin: 0 0 10px 0;
         font-weight: normal;
         padding: 10px 0 !important;
         height: 30px;
         font-family: $fontOpenSans;
         font-size: 14px;
         width: 250px;
         border-radius: 0;
         border-bottom: 1px solid $border;
         color: $text-color;
         cursor: text;
      }

      .input:focus {
         outline: none;
         background: none;
         box-shadow: none;
         cursor: text;
         border-radius: 0;
         color: $text-color;
         border-bottom: 1px solid $border;
         box-shadow: none !important;
      }

      .input:active {
         cursor: text;
      }

      .inputInvalid {
         border: none;
         border-bottom: 1px solid #a5685f !important;
         border-radius: 0;
         background-color: transparent;
         padding: 10px 0;
         height: 24px;
         font-size: 14px;
         font-weight: normal;
         box-shadow: none;
         text-align: left;
         width: 250px;
         box-shadow: none !important;
         color: $text-color;
         outline: none !important;
      }

      .inputInvalid:focus {
         box-shadow: none !important;
         outline: none !important;
      }

      .inputInvalid:active {
         cursor: text;
         box-shadow: none;
      }
   }

   .update {
      width: auto;
      height: 34px;
      font-weight: 500;
      padding: 0 18px;
      font-family: $fontRoboto;
      font-size: 14px !important;
   }
}
.errorWithFeedback {
   display: flex;
   flex-direction: column;
   align-items: flex-end;
   margin-left: 10px;
}

.dropdownANT {
   div {
      div {
         &::-webkit-scrollbar {
            width: 4px !important;
            border-radius: 6px;
            background-color: transparent;
         }

         &::-webkit-scrollbar-thumb {
            width: 4px !important;
            border-radius: 6px;
            background-color: $border;
         }
      }
   }
}
.toastSuccess {
   background-color: #d4edda !important;
   color: #155724;
}
.toastFail {
   background-color: #f8d7da !important;
   color: #721c24 !important;
}

.benchmarkRow {
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.mfDropdown {
   display: flex;
   .mFinput {
      display: block;
      width: 240px !important;
      justify-content: flex-end;
      cursor: pointer !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
   }
   .inputInvalid {
      cursor: pointer !important;
      width: 240px !important;
   }
}
.mfDropdownContainer {
   background: white !important;
   border-radius: 10px !important;
   height: 240px;
   .menu {
      color: #000000d9;
      display: flex;
      justify-content: space-between;
   }
}

.cursorNotAllowed {
   cursor: not-allowed;
}

.phoneContainer {
   input[name="phone"] {
      text-align: right !important;
   }
}

.formInvalidField {
   font-size: 0.8rem;
   bottom: 12px;
   color: #a5685f;
   text-align: right;
}

.toggleSelfRole {
   padding-left: 10px !important;
}

.name {
   font-family: $fontOpenSans;
   // font-size: 30px;
   color: $text-color;
   // text-transform: capitalize;
   padding-bottom: 0px;
   position: relative;
   // input {
   //   height: px;
   // }
   i {
      position: absolute;
      font-size: 17px;
      cursor: pointer;
      right: 0;
      top: 20px;
      color: #53a6e0;
   }
}

.editInput {
   background: TRANSPARENT !important;
   border: 0 !important;
   border-bottom: 1px solid #d5d5d5 !important;
   border-radius: 0px !important;
   text-align: right;
   padding-bottom: 0px !important;
   &:focus {
      box-shadow: none !important;
      outline: 0px !important;
      border-bottom-color: #4a4a4a !important;
   }
}
.nameEditBox {
   display: flex;
   flex-direction: row;
   // justify-content: center;
   align-items: center;
   width: 100%;
   // position: absolute;
   top: 0px;
   // input {
   //   &:read-only {
   //     margin-right: 10px;
   //     cursor: not-allowed;
   //   }
   // }
   .key {
      text-align: left;
      color: $text-color !important;
      margin-bottom: 0;
      font-family: $fontRoboto;
      font-size: 14px;
   }

   .name {
      padding-left: 28px;
      // padding-right: 28px;
      padding-bottom: 0px;
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
   }

   i {
      color: #5eade9;
      font-size: 12px;
   }
}

.editableName {
   display: flex;
   flex-direction: column;
   width: 50%;
   margin-right: 10px;
   min-height: 58px;
   &:last-child {
      margin-right: 0px;
   }
   text-align: right;
}
.hoverableValue {
   padding-left: 0px !important;
   padding-right: 0px !important;
   color: #56a9e8;
   cursor: pointer;
}

.goodbyemodal {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding: 37px 48px 37px 48px;

   .noun_Account_Delete {
      width: 68px;
      height: 66.4px;
      margin: 0 0 16.6px 0;
      object-fit: contain;
   }

   .icon {
      font-size: 2rem;
   }

   .title {
      margin: 0 0 9.9px 0;
      font-size: 32px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: center;
      color: #1e5f91;
      font-family: Roboto;
   }

   .Group_4050 {
      width: 30px;
      height: 30px;
   }

   .subtitle {
      margin: 0 0 48px 0;
      font-size: 23px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: 0.14px;
      text-align: center;
      color: #9f9f9f;
   }
   .main {
      height: 24px;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: 0.12px;
      text-align: left;
      color: #9f9f9f;
      b {
         height: 24px;
         font-size: 20px;
         font-weight: 500;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.2;
         letter-spacing: 0.12px;
         text-align: left;
         color: #56a9e8;
      }
   }

   .mainP {
      width: 650px;
      font-size: 25px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.16;
      letter-spacing: 0.15px;
      text-align: center;
      color: #9f9f9f;
      margin: -15px 0 0 0 !important;
   }

   .body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
   }

   b {
      color: #5eade9;
   }

   button {
      border-radius: 30px;
      padding: 8px 60px;
      text-transform: uppercase;
      cursor: pointer;
      font-family: $fontRoboto;
      font-size: 0.8rem;
   }

   .actions {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      .ok {
         width: 340px;
         height: 56px;
         margin: 0 17px 0 0;
         padding: 17px 97px;
         background-color: #1d6091;
         border: solid 0.5px #1d6091;
         margin-top: 90px;
         span {
            width: 146px;
            height: 22px;
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: 0.19px;
            text-align: center;
            color: #ffffff;
         }
      }

      .cancel {
         width: 340px;
         height: 56px;
         margin: 0 0 0 17px;
         padding: 17px 102px;
         border: solid 1px #707070;
         margin-top: 90px;
         background: unset !important;
         span {
            width: 136px;
            height: 22px;
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: 0.19px;
            text-align: center;
            color: #707070;
         }
      }
   }

   .message {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: normal;
      text-align: left;
      color: #505050;
      margin-top: 82px;
   }
}
.profileLabel {
   font-family: Roboto;
   font-size: 14px;
   font-weight: 600;
   line-height: 28px;
   letter-spacing: 0em;
   color: #5dacea;
   right: 0;
   position: absolute;
}
.sub_content {
   span {
      font-family: Roboto;
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      color: #494a49;
   }
}

@media only screen and (max-width: 375px) {
   .goodbyemodal {
      button {
         font-size: 0.5rem;
      }
   }
}
