.popover {
   width: auto !important;
   min-width: 300px !important;
   max-width: auto !important;
}
.logoWrapper {
   padding: 0 !important;
}
.search-badge {
   white-space: nowrap;
}
.btn-outline-select:not(.comm):hover {
   color: white !important;
}
#investments-tabs,
#investments-item-list {
   padding-right: 0px !important;
   h2 {
      margin-top: 0px !important;
      padding-top: 2rem !important;
   }
}
.contact-address-container p {
   min-height: 130px;
}
.recharts-wrapper {
   .recharts-surface {
      width: 100% !important;
   }
}
@include respond(desktop) {
   //EXTRA CHANGE
   .search-header-label {
      font-size: 1rem !important;
      margin-top: 0px !important;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
   }
   #overlay-container {
      display: flex;
      top: 0 !important;
      left: 0 !important;
      height: 100vh !important;
   }
   .badge-wrapper {
      margin-bottom: 0.5rem !important;
   }
   .media-mycard-desc {
      & > div > * {
         font-size: 0.8rem;
      }
   }
   .view-sub-title-container,
   .viewDropdownContainer {
      button {
         font-size: 0.6rem !important;
      }
   }
   .media-h-auto {
      height: auto !important;
      min-height: auto !important;
   }
   .view-text-label {
      padding: 4px 7px;
      font-size: 10px !important;
   }
   .mainMastHeadVerticalCenter form {
      // padding-left: 3rem;
      // padding-right: 3rem;
   }
   .sm .dynamic.e1 {
      height: auto !important;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
   }
   #opensesafi-navbar .navbar-brand img {
      width: 140px !important;
   }
   .search-badge-node {
      display: flex;
      flex-wrap: nowrap !important;
      overflow: scroll !important;
      .search-badge {
         font-size: 10px !important;
         padding: 6px 12px !important;
      }
   }
   .search-related-wrapper {
      // white-space: nowrap;
      // // max-width: 95%;
      // overflow: hidden;
      // text-overflow: ellipsis;
      > *:not(.searched-query) {
         font-size: 10px !important;
      }
      .searched-query {
         font-size: 13px;
         text-transform: capitalize;
      }
   }
   .search-box {
      input {
         font-size: 1rem !important;
         // width: 90% !important;
         height: 40px !important;
      }
      i {
         font-size: 20px !important;
      }
   }
   html {
      font-size: 16px;
   }
   .container {
      max-width: 100% !important;
   }
   .recharts-wrapper {
      .recharts-surface {
         width: 100% !important;
      }
   }
   .modal.sfp .modal-dialog {
      margin: 0 auto !important;
      // height: 100vh;
      right: auto !important;
      min-width: 100vw !important;
   }
   #filter-wrapper {
      padding-bottom: 0.6rem;
   }

   #overlay-container {
      top: 0 !important;
      left: 0 !important;
      height: calc(100vh) !important;
      .btn {
         // top: 50% !important;
         // position: absolute;
         // left: 50% !important;
         // -webkit-transform: translate(-50%, -50%) !important;
         // transform: translate(-50%, -50%) !important;
      }
      .main-container-fluid > *:not(#main__fp-nav) {
         width: 100% !important;
      }
      .mychart-desc,
      .mychart-name {
         font-size: 8px !important;
      }
      .modal.sfp .modal-content .modal-body-ssf {
         // height: calc(100vh - (60px))!important;
         height: calc(63vh - (144px)) !important;
         max-height: 100%;
         min-height: 490px;
         margin-right: 5px;
         overflow-y: auto;
      }
      #investments-account-info .card-body {
         min-height: auto !important;
         max-height: 100% !important;
      }
      #investments-item-list .card-body {
         max-height: auto !important;
         height: auto !important;
      }
      .setting-wrapper {
         display: flex;
         .settings__title {
            position: unset !important;
         }
         .settings__menu > .settings__menu-item {
            margin-right: 2rem !important;
         }
         .settings__menu-content-area {
            margin: 0px;
            margin-left: 2rem;
         }
      }
      .settings__menu > .settings__menu-item {
         margin-right: 2rem !important;
      }
      .settings__menu-content-area {
         margin: 0px;
         margin-left: 2rem;
      }
   }
   .settings__menu-content-area ul {
      font-family: "Roboto";
      margin-left: 0px !important;
   }
   .marquee {
      //margin-bottom: 1.2rem !important;
   }
   .fees-returns-risk-header-container .highlight {
      background: none;
      color: black;
   }
   .fees-returns-risk-header-container .FRR-Label {
      padding: 2px;
      font-size: 10px;
   }
   .masthead,
   .team-masthead,
   .whatwedo_masthead,
   .contact-masthead,
   .masthead-main,
   .contactBottomRow {
      height: unset !important;
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
   }
   .contact-address-container {
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
   }
   #main__fp-nav.fp-right {
      right: 2px;
   }
   .main-container-x .navbar {
      padding-right: 5px !important;
   }
   .main-container-fluid {
      display: unset !important;
   }

   .modal.confirm {
      .modal-header {
         padding: 0.5rem 0.5rem;
      }
      .modal-md {
         max-width: 350px;
      }
      h4 {
         font-size: 13px !important;
      }
      h5 {
         font-size: 11px !important;
      }
      button {
         font-size: 13px !important;
      }
      .h-300 {
         height: 200px;
      }
      .modal-footer .btn-block {
         padding: 0.5rem !important;
      }
   }
}

@include respond(tab) {
   .itemListArea {
      padding-bottom: 0px;
   }
   .recharts-wrapper {
      max-height: 130px;
      .recharts-surface {
         -webkit-transform: scale(0.85) !important;
         transform: scale(0.85) !important;
      }
   }
   .display-grid-center-range {
      ul {
         li {
            button {
               font-size: 11px;
            }
         }
      }
   }
   .topSectionWrapper {
      z-index: 999;
      // z-index: 999999;
   }
   .didumean-wrapper {
      padding-bottom: 7px !important;
      span,
      div {
         font-size: 10px !important;
      }
   }
   .broker-label-wrapper .broker-label-bg {
      font-size: 0.75rem;
   }
   #investments-account-info h4 {
      font-size: 0.75rem !important;
   }
   .main-container-fluid {
      display: unset !important;
   }
   .primaryTextStyle {
      z-index: 12;
      font-size: 8.5px !important;
   }
   .setting-wrapper {
      display: flex;
      .settings__menu-content-area {
         margin: 0px;
         margin-left: 2rem;
      }
   }
   .embed-section {
      flex-wrap: wrap;
   }
   .embed-section__preview {
      flex: unset;
      width: 100%;
      .preview-section {
         iframe {
            width: 100%;
         }
      }
   }
   .embed-section__code {
      display: none;
   }
   #investments-allocation {
      .recharts-tooltip-wrapper {
         bottom: -0 !important;
         margin-top: 1rem;
         left: 50% !important;
         top: unset !important;
         transform: translateX(-50%) !important;
      }
   }
   .cardListToolbarWrapper {
      padding-bottom: 1rem;
   }
   .navbar-dark .navbar-nav .nav-link {
      color: white !important;
   }
   .marquee {
      //margin-bottom: 1.2rem !important;
   }
   .fees-returns-risk-header-container .highlight {
      background: none;
      color: black;
   }
   .fees-returns-risk-header-container .FRR-Label {
      padding: 2px;
      font-size: 10px;
   }
   .masthead,
   .team-masthead,
   .whatwedo_masthead,
   .contact-masthead,
   .masthead-main,
   .contactBottomRow {
      height: unset !important;
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
   }
   .contact-address-container {
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
   }
   #main__fp-nav.fp-right {
      right: 2px;
   }
   .main-container-x .navbar {
      padding-right: 5px !important;
   }
   .tab-12 {
      max-width: 100%;
      width: 100%;
      flex: 1;
      svg {
         height: 100% !important;
         max-height: 150px !important;
      }
      .recharts-wrapper,
      .media-sector,
      .media-sector div {
         height: auto !important;
         padding: 0 !important;
      }
   }
   .media-p-0 {
      padding: 0 !important;
   }
   .media-m-0 {
      margin: 0 !important;
   }
   .media-mt-2 {
      margin-top: 1rem !important;
   }
   .media-no-row {
      display: block !important;
   }
   html {
      font-size: 16px;
   }
   svg {
      transform: scale(0.75);
   }
   .no-shrink svg {
      transform: scale(1.3) !important;
      & .text-info {
         font-size: 0.6rem !important;
      }
   }
   .media-sector {
      text-align: center;
   }
   .search-badge-node {
      display: flex;
      flex-wrap: nowrap;
      overflow: scroll !important;
      .search-badge {
         font-size: 10px;
      }
   }
   .modal.sfp .modal-dialog {
      margin: 0 auto !important;
      // height: 100vh;
      right: auto !important;
      min-width: 100vw !important;
   }
   .mychart-desc {
      position: absolute;
      top: 39% !important;
      font-size: 7px !important;
   }
   .mychart-name {
      font-size: 6px !important;
      top: 48% !important;
   }
   .media-d-flex {
      display: flex;
   }

   .media-arrow {
      position: absolute;
      left: 16px;
      font-size: 17px;
      top: 50%;
      transform: translateY(-50%);
   }
   .account-confirm-modal {
      .agree-terms-modal__desc {
         font-size: 13px !important;
      }
      .modal-header .modal-title {
         font-size: 16px !important;
      }
      .account-confirm-modal__footer {
         button {
            font-size: 1.2rem !important;
            padding-top: 0.6rem;
            padding-bottom: 0.6rem;
         }
      }
   }
   .account-confirm-modal__footer {
      margin-left: -10px;
      margin-right: -10px;
      margin-bottom: -10px;
   }
   .media-show {
      display: block !important;
   }
   .tab-d-none {
      display: none !important;
   }

   .filter-dropdown {
      display: block !important;
      & + .lists {
         position: absolute;
         z-index: 123;
         margin-top: 4px;
         flex-direction: column !important;
      }
   }
   .lists-2 {
      position: absolute;
      z-index: 123;
      margin-top: 34px;
      flex-direction: column !important;
   }
   .media-filter {
      background: transparent !important;
      border-color: transparent !important;
      color: grey !important;
      margin-right: 1rem;
      &:focus {
         box-shadow: none !important;
      }
      // span{
      //     display: none !important;
      // }
   }
   .media-bg-grey {
      background: grey !important;
      li.text-green {
         color: black !important;
      }
      .media-sector {
         width: 100% !important;
         margin-top: 2rem;
      }
      #overlay-container {
         display: flex;
         top: 0 !important;
         left: 0 !important;
         height: calc(100vh) !important;
         .btn {
            // top: 50% !important;
            // position: absolute;
            // left: 50% !important;
            // -webkit-transform: translate(-50%, -50%) !important;
            // transform: translate(-50%, -50%) !important;
         }
      }
   }
   .media-sector {
      margin: 0 !important;
   }
   .row-eq-height {
      button {
         max-width: 100%;
         overflow: hidden;
         text-overflow: ellipsis;
      }
   }
   [id^="AnalyticsPopover-Fees-"],
   .fa-star {
      // transform: scale(0.8);
   }
}

@include respond(phone) {
   .table-responsive {
      margin-bottom: 4em !important;
   }
   .itemListArea {
      padding-bottom: 0px;
   }
   .mycharts {
      .recharts-wrapper {
         .recharts-surface {
            -webkit-transform: scale(2.3) !important;
            transform: scale(2.3) !important;
         }
      }
   }
   .simplePieChartWrapper {
      .recharts-wrapper {
         .recharts-surface {
            -webkit-transform: scale(2) !important;
            transform: scale(2) !important;
         }
      }
   }

   .didumean-wrapper {
      padding-bottom: 7px !important;
      span,
      div {
         font-size: 10px !important;
      }
   }
   .setting-wrapper {
      .settings__menu {
         .settings__menu-item {
            margin-right: 1rem !important;
            font-size: 0.75rem;
         }
      }
   }

   .weights-container {
      width: 100%;
   }

   html {
      font-size: 16px !important;
   }
   .media-mt-3 {
      margin-top: 2rem;
   }
   .table-sm {
      .LinesEllipsis {
         white-space: unset !important;
      }
   }
   .pop-wrapper {
      padding: unset !important;
      margin: unset !important;
      background: transparent;
      .popover-close {
         position: absolute;
         display: block;
         right: -5px;
         top: -5px;
         z-index: 10;
         width: 20px;
         height: 20px;
         border-radius: 50%;
         background: #f8f9fa;
         line-height: 15px;
         border: 1px solid rgba(0, 0, 0, 0.2);
         font-size: large;
      }
   }
   .broker__list-item {
      .broker__header-logo {
         img {
            max-width: 75px;
            width: 75px;
         }
      }
      .broker__header-value {
         .broker__currency-label {
            font-size: 10px;
         }
         .broker__currency-value {
            font-size: 14px;
         }
      }
      .broker__acc-list-cell {
         .broker__account-label,
         .broker__currency-label {
            font-size: 10px;
         }
         .broker__account-value,
         .broker__currency-value {
            font-size: 14px;
         }
      }
   }
   .navbar-brand {
      font-size: 2rem !important;
   }
   .pageContainer .topSectionWrapper {
      .referral-link {
         font-weight: 700 !important;
         font-size: 14px !important;
         color: #55a9e8 !important;
         padding: 0;
         text-decoration: none;
         position: absolute;
         right: 68px;
         top: 17px;

         &:hover {
            color: #55a9e8;
            text-decoration: none;
         }
      }
      .referral-link-account {
         right: 144px;
         top: 17px;
      }
   }
   .toolbarWrapper .sortButtonWrapper .sortbutton {
      padding: 0px 5px !important;
   }
   .pageContainer
      .topSectionWrapper
      .toolbarWrapper
      .filterButtonWrapper
      .filterbutton {
      padding: 0px 5px !important;
   }
   .pageContainer
      .topSectionWrapper
      .toolbarWrapper
      .filterButtonWrapper
      .filterbutton
      .dropdown-menu
      .dropright
      .second-level-dropdown
      .dropdown-wrapper {
      .dropdown-item {
         max-width: 178px !important;
         .custom-control {
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
         }
      }
   }
   // .viewDropdownContainer {
   //   display: none;
   // }
   .flipster .flipster__item img {
      width: 6rem;
      height: 6rem;
   }
   .sortbutton,
   .filterbutton {
      height: 100% !important;
      font-size: inherit;
      // padding: 0px 4px !important;
      text-align: center;
      display: inline-block !important;
   }

   .mydetails-close {
      padding: 0px !important;
   }
   .recharts-surface .recharts-wrapper {
      min-height: 500px;
   }
   .simplePieChartWrapper {
      height: 215px !important;
      padding-top: 2rem;
   }
   .simplePieChartWrapper .primaryTextStyle {
      // top: 88% !important;
      font-size: 1rem !important;
   }
   .d3ChartWrapper .primaryTextStyle {
      // top:36% !important;
      // color:black;
      font-size: 1rem !important;
   }
   .sortButtonWrapper,
   .filterButtonWrapper {
      .btn {
         display: block;
         height: 23px;
         line-height: 23px;
         text-align: center;
      }
      .mydetails-close {
         padding: 0px !important;
      }
      .recharts-surface .recharts-wrapper {
         min-height: 500px;
      }
      .simplePieChartWrapper .primaryTextStyle {
         top: 85% !important;
         left: 50%;
         font-size: 1rem !important;
      }
      .d3ChartWrapper .primaryTextStyle {
         // top:36% !important;
         // color:black;
         font-size: 1rem !important;
      }
      .sortButtonWrapper,
      .filterButtonWrapper {
         .btn {
            display: block;
            height: 23px;
            line-height: 23px;
            text-align: center;
         }
      }
      .media-fund-detail-wrapper {
         min-height: 135px;
         position: relative;
         .recharts-tooltip-wrapper {
            top: unset !important;
            bottom: -10px !important;
            margin-top: 1rem;
            left: 50% !important;
            top: unset !important;
            transform: translateX(-50%) !important;
         }
      }
      .media-chart-wrapper {
         .recharts-tooltip-wrapper {
            top: unset !important;
            bottom: -114px !important;
            margin-top: 1rem;
            left: 50% !important;
            top: unset !important;
            transform: translateX(-50%) !important;
         }
         .d3ChartWrapper {
            width: 100%;
         }
      }
      .media-fund-detail-name {
         .LinesEllipsis {
            width: 245px;
         }
      }

      .settings__menu-content-area ul {
         font-family: "Roboto";
         margin-left: 0px !important;
      }

      .setting-wrapper {
         .settings__menu > .settings__menu-item {
            margin-right: 1rem !important;
         }
      }

      .results-count-color {
         overflow: hidden;
         text-overflow: ellipsis;
      }
   }
   .media-fund-detail-wrapper {
      min-height: 135px;
      position: relative;
      .recharts-tooltip-wrapper {
         top: unset !important;
         bottom: -10px !important;
         margin-top: 1rem;
         left: 50% !important;
         top: unset !important;
         transform: translateX(-50%) !important;
      }
   }
   .media-chart-wrapper {
      .recharts-tooltip-wrapper {
         top: unset !important;
         bottom: -114px !important;
         margin-top: 1rem;
         left: 50% !important;
         top: unset !important;
         transform: translateX(-50%) !important;
      }
      .d3ChartWrapper {
         width: 100%;
      }
   }
   .media-fund-detail-name {
      .LinesEllipsis {
         width: 245px;
      }
   }
   .results-count-color {
      overflow: hidden;
      text-overflow: ellipsis;
   }
   .badge-wrapper {
      margin-bottom: 0.8rem;
   }
   .search-risk-stats-highlighting {
      background: white !important;
   }
   .search-icon {
      right: 5px !important;
   }
   .search-box {
      .form-control {
         padding-right: 1.5rem;
      }
   }
   .search-box i {
      font-size: 16px !important;
   }
   .search-header-label,
   .search-label {
      font-size: 0.75rem !important;
      padding-top: 0 !important;
      padding-bottom: 0.2rem !important;
      .results-count-color {
         &:first-child {
            height: unset !important;
         }
      }
   }
   .share-component {
      &__wrapper {
         margin-bottom: 0.2rem !important;
      }
   }
   .searchBoxWrapper {
      margin-top: 0.3rem;
      margin-bottom: 0.2rem;
   }
   .sm .dynamic .search-box input {
      padding-right: 2.5rem;
   }
   .media-p-3 {
      padding: 1.2rem !important;
   }
   .pageContainer .pageWrapper .itemCardWrapper{
      min-width: 345px;
   }
   .pageContainer .pageWrapper .itemCardWrapper .itemCard .card-background {
      background: white !important;
      border-radius: 0px;
   }

   .setting-wrapper {
      display: flex;
      .settings__menu-content-area {
         margin: 0px;
         margin-left: 1rem;
      }
   }

   .tab-12 {
      .recharts-wrapper,
      .media-sector,
      .media-sector div {
         height: auto !important;
         margin: 0 !important;
      }
   }
   .tab-12 {
      .recharts-wrapper,
      .media-sector,
      .media-sector div {
         height: auto !important;
         margin: 0 !important;
      }
      .recharts-tooltip-wrapper {
         top: 0 !important;
         left: 50%;
         transform: translate(-50%, 128px) !important;
      }
   }
   .logoWrapper {
      img {
         width: 100px;
         height: auto;
      }
   }
   .media-pt-0 {
      padding-top: 0px !important;
   }
   .recharts-polar-radius-axis-line .h5 {
      font-size: 0.3rem !important;
   }
   .media-flex-column {
      flex-direction: column;
   }
   #investments-tabs,
   #investments-item-list {
      padding-right: 0px !important;
      h2 {
         margin-top: 0px !important;
         padding-top: 2rem !important;
      }
   }
   .card-details {
      max-width: 177px !important;
   }
   .media-flex-basis-auto {
      flex-basis: 100% !important;
      max-width: 100% !important;
   }
   /*
    .recharts-tooltip-wrapper {
      top: 0 !important;
      left: 50%;
      transform: translate(-50%, 128px) !important;
    }
*/

   .logoWrapper {
      img {
         width: 100px;
      }
   }
   .media-pt-0 {
      padding-top: 0px !important;
   }
   .recharts-polar-radius-axis-line .h5 {
      font-size: 0.3rem !important;
   }
   .media-flex-column {
      flex-direction: column;
   }
   #investments-tabs,
   #investments-item-list {
      padding-right: 0px !important;
      h2 {
         margin-top: 0px !important;
         padding-top: 2rem !important;
      }
   }
   .card-details {
      max-width: 177px !important;
   }
   .media-flex-basis-auto {
      flex-basis: 100% !important;
      max-width: 100% !important;
   }
   // .recharts-surface{
   //     height: 120px !important;
   // }
   .media-none {
      display: none !important;
   }
   .sm .dynamic.e1 {
      height: auto !important;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
   }
   // POPOVER
   .user-popover {
      z-index: 1080 !important;
      max-width: 50% !important;
   }
   //NAVBAR RESPONSIVE

   #opensesafi-navbar {
      .container {
         justify-content: center;
      }
      .navbar-brand img {
         content: url("/assets/images/OpenSesafi_Logo_Tag_White.png") !important;
         width: 100%;
      }
      #user-menu {
         position: absolute;
         right: 20px;
         top: 50%;
         transform: translateY(-50%);
         i {
            font-size: 23px;
            color: white;
         }
      }
   }

   // BADGE RESPONSIVE

   .search-badge-node {
      display: flex;
      flex-wrap: nowrap !important;
      overflow: scroll !important;
      .search-badge {
         font-size: 10px !important;
         padding: 6px 12px !important;
      }
   }

   // FILTER RESPONSIVE

   .filter-dropdown {
      display: block !important;
      & + .lists {
         position: absolute;
         z-index: 123;
         margin-top: 4px;
         flex-direction: column !important;
      }
   }
   .lists-2 {
      position: absolute;
      z-index: 123;
      margin-top: 29px;
      flex-direction: column !important;
   }

   //MODAL
   .modal.sfp .modal-dialog {
      margin: 0 auto !important;
      // height: 100vh;
      right: auto !important;
      min-width: 100vw !important;
   }

   //CARD

   .app-card {
      border-radius: 0px;
   }

   .overlay-wrapper {
      .container {
         padding: 0 !important;
         margin: 0 !important;
      }
   }

   .icon-orange {
      .path2::before {
         color: rgb(250, 120, 5) !important;
      }
   }

   .hover-orange {
      .path2::before,
      .path3::before {
         color: rgb(250, 120, 5) !important;
      }
   }

   .icon-grey {
      .path2::before {
         color: rgb(229, 229, 229);
      }
   }

   .hover-orange:hover {
      .path2::before,
      .path3::before {
         color: rgb(250, 120, 5) !important;
      }
   }
   .hover-orange:hover {
      .path2::before,
      .path3::before {
         color: rgb(250, 120, 5) !important;
      }
   }

   //MODAL RESPONSIVE
   .modal.sfp.show.min .modal-dialog {
      transform: translateY(-50px) !important;
      -webkit-transform: translateY(-50px) !important;
   }
   .modal.sfp.show.anim .modal-dialog {
      transform: translateY(-83px) !important;
      -webkit-transform: translateY(-83px) !important;
   }

   .close-wrapper {
      margin-right: 3px;
      margin-left: 3px;
      justify-content: center;
      i {
         font-size: 22px;
      }
   }
   .media-header {
      width: max-content !important;
      background: transparent !important;
      color: #4a4a4a;
      font-size: 16px;
      margin: 0.5rem auto;
      padding-top: 0.25rem !important;
   }
   .media-f-md {
      font-size: 15px !important;
      margin-top: 1px;
   }
   .media-px-3 {
      padding-left: 1rem;
      padding-right: 1rem;
   }
   .media-text-overflow > div {
      width: 100%;
      min-width: 200px;
      max-width: 280px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
   }
   .media-blue {
      color: #1b8be0 !important;
   }
   .media-gray {
      color: #adb5bd !important;
   }
   .card {
      border-color: #adb5bd !important;
   }
   .media-py-2 {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
   }
   .modal.sfp .modal-content .modal-body-ssf {
      // height: calc(63vh - (145px))!important;
      // max-height: 100% ;
      // overflow-y: auto;
   }

   .media-fund-card {
      background-color: transparent !important;
      height: 100%;
      overflow-y: auto;
   }

   // SETTING FORM
   .profile-editable-form,
   .change-password-form {
      width: 100% !important;
   }
   .broker__link .coming-soon-img {
      margin-left: 116px !important;
      transform: none !important;
   }
   .media-filter {
      background: transparent !important;
      border-color: transparent !important;
      color: grey !important;
      font-size: 15px !important;
      margin-right: 1rem;
      &:focus {
         box-shadow: none !important;
      }
      // span{
      //     display: none !important;
      // }
   }
   .media-bg-grey {
      background: grey !important;
      li.text-green {
         color: black !important;
      }
   }
   .media-sector {
      width: 100% !important;
      margin-top: 2rem;
   }

   .nav-tabs .nav-link {
      padding: 10px 15px !important;
   }
   .settings__title {
      margin-bottom: 0px;
      font-size: 1rem !important;
      display: flex;
      align-items: center;
   }
   .settings__menu-content-area {
      margin-bottom: 0px;
      ul {
         margin-left: 2rem;
         display: flex;
         flex-wrap: nowrap;
         align-items: center;
         margin-bottom: 0px;
         > *:first-child {
            margin-right: 20px;
         }
      }
   }
   .search-box {
      input {
         font-size: 0.8rem !important;
         width: 90% !important;
         height: 35px !important;
      }
   }
   .table-sm {
      .font-weight-semi-bold {
         text-align: right;
      }
   }
   .media-sm-none {
      display: none !important;
   }

   //HOME SCREEN
   .mainMastHeadVerticalCenter {
      img {
         width: 200px !important;
      }
      form {
         padding-left: 20px;
         padding-right: 20px;
      }
      .bg-ssf-blue {
         margin-left: 18px !important;
         margin-right: 18px !important;
      }
   }
   .main-container-fluid {
      display: unset !important;
   }
   .masthead,
   .team-masthead,
   .whatwedo_masthead,
   .contact-masthead,
   .masthead-main,
   .contactBottomRow {
      height: auto !important;
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
   }
   .contact-address-container {
      padding-top: 2rem !important;
   }
   #main__fp-nav.fp-right {
      right: 2px;
   }
   .main-container-x .navbar {
      padding-right: 5px !important;
   }
   .contactUsAnchor {
      padding-bottom: 2rem !important;
   }
   #overlay-wrapper {
      padding: 1rem;
      h2 {
         font-size: 16px !important;
         line-height: 20px !important;
      }
   }
   .sign-in-securities {
      top: 50% !important;
      position: absolute;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
   }
   .button-text {
      font-size: 0.7rem !important;
   }
   .media-bb-1 {
      border-bottom: 1px solid #dee2e6;
   }
   .input-group > input {
      &::-webkit-input-placeholder {
         /* Chrome/Opera/Safari */
         font-size: 0.9rem;
      }
      &::-moz-placeholder {
         /* Firefox 19+ */
         font-size: 0.9rem;
      }
      &:-ms-input-placeholder {
         /* IE 10+ */
         font-size: 0.9rem;
      }
      &:-moz-placeholder {
         /* Firefox 18- */
         font-size: 0.9rem;
      }
   }
   .media-close {
      position: absolute;
      left: 2% !important;
      top: 50% !important;
      transform: translateY(-50%);
      opacity: 1;
      & > i.fa-minus {
         display: none;
      }
      & > i {
         font-size: 1rem !important;
      }
   }
   .account-confirm-modal {
      .modal-content {
         width: calc(100vw - 12px) !important;
      }
      .agree-terms-modal__desc {
         font-size: 12px !important;
      }
      .modal-header .modal-title {
         font-size: 16px !important;
      }
      .account-confirm-modal__footer {
         button {
            font-size: 0.8rem !important;
         }
      }
   }
   .whatwedo_masthead {
      img {
         width: 100%;
      }
   }
   .account-confirm-modal__footer {
      button {
         font-size: 0.8rem !important;
      }
   }
   .modal.sfp .modal-content .modal-body-ssf {
      // height: calc(63vh - (145px))!important;
      // max-height: 100% ;
      // overflow-y: auto;
   }
   // .mycharts .recharts-wrapper,
   // .mycharts .recharts-surface{
   //     width:310px !important;
   //     height: 110px !important ;
   // }
   #overlay-container {
      display: flex;
      top: 0 !important;
      left: 0 !important;
      height: 100vh !important;

      .pb-0 {
         padding-bottom: 1rem !important;
      }

      .survey-container {
         position: relative;
         width: 90%;
         padding: 0.5rem;
         // overflow-y: scroll;

         .survey-wrapper {
            padding: 0px;
         }

         .header-area {
            padding-bottom: 1rem;

            .header-label {
               font-size: 1.2rem;
               text-align: center;
            }
         }
         .body-wrapper {
            padding-bottom: 0px;

            .invalid-feedback,
            .form-invalid-field {
               font-size: 0.7rem;
               bottom: 5px;
            }

            .country-wrapper {
               .dropdown-menu {
                  max-height: 350px;
                  overflow-y: scroll;
                  top: 0;
                  transform: translate3d(-162px, -225px, 0px) !important;
               }
            }
         }

         .cta-wrapper {
            padding-bottom: 0px;

            .btn {
               font-size: 0.6rem;
               width: 40%;
            }
         }
      }
   }
   .close-wrap {
      -webkit-font-smoothing: subpixel-antialiased;
      transform-style: preserve-3d;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%) translateZ(0);
      transition: all 0.3s;
      width: 20px;
      height: 20px;
      display: block;
      overflow: hidden;
   }
   .modal-dialog {
      transition: all 1.2s;
   }
   .media-transparent {
      background: transparent !important;

      .sponsored-align {
         flex-direction: column;
      }
   }
   .media-flex-column {
      flex-direction: column;
      img {
         object-fit: contain;
         width: 95px;
      }
   }
   .media-mb-3 {
      margin-bottom: 2rem !important;
   }
   .media-p-0 {
      padding-left: 0 !important;
   }
   .media-m-0 {
      margin: 0 !important;
   }
   form {
      // padding-left: 20px;
      // padding-right: 20px;
   }
   .bg-ssf-blue {
      margin-left: 18px !important;
      margin-right: 18px !important;
   }
   .main-container-fluid {
      display: unset !important;
   }
   .masthead,
   .team-masthead,
   .whatwedo_masthead,
   .contact-masthead,
   .masthead-main,
   .contactBottomRow {
      height: auto !important;
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
   }
   .contact-address-container {
      padding-top: 2rem !important;
   }
   #main__fp-nav.fp-right {
      right: 2px;
   }
   .main-container-x .navbar {
      padding-right: 5px !important;
   }
   .contactUsAnchor {
      padding-bottom: 2rem !important;
   }
   #overlay-wrapper {
      padding: 1rem;
      h2 {
         font-size: 16px !important;
         line-height: 20px !important;
      }
   }
   .sign-in-securities {
      top: 50% !important;
      position: absolute;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
   }
   .button-text {
      font-size: 0.7rem !important;
   }
   .media-bb-1 {
      border-bottom: 1px solid #dee2e6;
   }
   .input-group > input {
      &::-webkit-input-placeholder {
         /* Chrome/Opera/Safari */
         font-size: 0.9rem;
      }
      &::-moz-placeholder {
         /* Firefox 19+ */
         font-size: 0.9rem;
      }
      &:-ms-input-placeholder {
         /* IE 10+ */
         font-size: 0.9rem;
      }
      &:-moz-placeholder {
         /* Firefox 18- */
         font-size: 0.9rem;
      }
   }
   .media-close {
      position: absolute;
      left: 2% !important;
      top: 50% !important;
      transform: translateY(-50%);
      opacity: 1;
      & > i.fa-minus {
         display: none;
      }
      & > i {
         font-size: 1rem !important;
      }
   }
   .account-confirm-modal {
      .modal-content {
         width: calc(100vw - 12px) !important;
      }
      .agree-terms-modal__desc {
         font-size: 12px !important;
      }
      .modal-header .modal-title {
         font-size: 16px !important;
      }
      .account-confirm-modal__footer {
         button {
            font-size: 0.8rem !important;
         }
      }
   }
   .whatwedo_masthead {
      img {
         width: 100%;
      }
   }
   .account-confirm-modal__footer {
      button {
         font-size: 0.8rem !important;
      }
   }
   .modal.sfp .modal-content .modal-body-ssf {
      // height: calc(63vh - (145px))!important;
      // max-height: 100% ;
      // overflow-y: auto;
   }
   // .mycharts .recharts-wrapper,
   // .mycharts .recharts-surface{
   //     width:310px !important;
   //     height: 110px !important ;
   // }
   #overlay-container {
      top: 0 !important;
      left: 0 !important;
      height: calc(100vh) !important;
   }
   .close-wrap {
      -webkit-font-smoothing: subpixel-antialiased;
      transform-style: preserve-3d;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%) translateZ(0);
      transition: all 0.3s;
      width: 20px;
      height: 20px;
      display: block;
      overflow: hidden;
   }
   .modal-dialog {
      transition: all 1.2s;
   }
   .media-transparent {
      background: transparent !important;
   }
   .media-flex-column {
      flex-direction: column;
      img {
         object-fit: contain;
         width: 95px;
      }
   }
   .media-mb-3 {
      margin-bottom: 2rem !important;
   }
   .media-p-0 {
      padding-left: 0 !important;
   }
   .media-m-0 {
      margin: 0 !important;
   }
   .media-pt-1 {
      padding-top: 1rem !important;
   }
   .my-information {
      position: absolute;
      right: 20px;
      top: 25px;
      transform: translateY(-50%);
      font-size: 1.5rem;
   }
   .media-left {
      text-align: left !important;
   }
   .media-pl-2 {
      padding-left: 1rem !important;
   }
   .media-pr-2 {
      padding-right: 1rem !important;
   }
   .media-card {
      border: none !important;
      margin-top: 0 !important;
      box-shadow: none !important;
   }
   .media-p-0 {
      padding: 0px !important;
   }
   .media-m-0 {
      margin: 0 !important;
   }
   .media-bg-white {
      background: white;
   }
   .media-pb-1 {
      padding-bottom: 16px !important;
   }
   .search-related-wrapper {
      white-space: nowrap;
      // max-width: 95%;
      overflow: hidden;
      text-overflow: ellipsis;
      > *:not(.searched-query) {
         font-size: 10px !important;
      }
      .searched-query {
         font-size: 13px;
         text-transform: capitalize;
      }
   }
   .list-wrap-h100 {
      padding-left: 0.5rem !important;
   }
   .modal.sfp .modal-content .nav.nav-tabs .nav-link.active {
      height: 98.5%;
   }
   .bg-ssf-gray-l5 {
      background: white !important;
   }
   .popover {
      z-index: 1;
   }
   .popover-body:not(.media-popover) {
      > div,
      p,
      h4,
      h3,
      h5,
      h6,
      span:not(.user-menu__user-name) {
         font-size: 0.7rem !important;
      }
   }
   .user-menu__name-badge {
      width: 36px !important;
      height: 36px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px;
   }
   .sm .dynamic.e1 {
      height: auto !important;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
   }
   #top-bar section.dynamic {
      height: auto !important;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
   }
   .search-related-wrapper {
      margin: 0 !important;
      height: auto !important;
   }
   .overlay-wrapper {
      padding-top: 0px !important;
   }
   .LinesEllipsis {
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding-bottom: 2px;
   }
   .table .LinesEllipsis {
      width: 100%;
   }

   #NamePopover-1823 h5 {
      width: 100% !important;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
   }
   .media-mycard-desc {
      width: 147px;
   }
   .hamburger-box {
      position: relative;
      display: inline-block;
      width: 40px;
      height: 24px;
   }
   .hamburger {
      height: 0px;
      visibility: hidden;
   }
   .hamburger.active {
      height: auto !important;
      visibility: unset;
   }

   .hamburger-inner {
      top: 50%;
      display: block;
      margin-top: -2px;
   }

   .hamburger-inner,
   .hamburger-inner:after,
   .hamburger-inner:before {
      position: absolute;
      width: 24px;
      height: 2px;
      transition-timing-function: ease;
      transition-duration: 0.15s;
      transition-property: transform;
      border-radius: 4px;
      background-color: #fff;
   }

   .hamburger-inner:after,
   .hamburger-inner:before {
      display: block;
      content: "";
   }

   .hamburger-inner:before {
      top: -10px;
   }

   .hamburger-inner:after {
      bottom: -10px;
   }
   .active .hamburger-inner {
      // transition-delay: 1s !important;
      transition-timing-function: ease-out;
      background-color: transparent !important;
   }

   .active .hamburger-inner:before {
      top: -80px;
      left: -80px;
      // transition-delay: 1s !important;
      transition: left 0.525s ease-out, top 0.05s linear 0.525s,
         transform 0.525s cubic-bezier(0.075, 0.82, 0.165, 1) 0.6s;
      transform: translate3d(80px, 80px, 0) rotate(45deg);
   }

   .active .hamburger-inner:after {
      top: -80px;
      right: -80px;
      // transition-delay: 1s !important;
      transition: right 0.925s ease-out, top 0.05s linear 0.925s,
         transform 0.925s cubic-bezier(0.075, 0.82, 0.165, 1) 0.7s;
      transform: translate3d(-80px, 80px, 0) rotate(-45deg);
   }
   .modal.sfp .modal-header .close {
      opacity: 1;
   }
   .search-badge {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
   }
   .container + div {
      padding: 0 !important;
   }
   .lists-2 .nav-link {
      padding: 0.55rem 0.5rem !important;
      font-size: 0.85rem !important;
      color: #f8f9fa;
      cursor: pointer;
      text-align: right;
   }
   .lists .nav-link {
      padding: 0.55rem 0.5rem !important;
      font-size: 0.85rem !important;
   }
   .modal-content > div {
      // z-index: 1231231231;
      overflow: hidden;
   }
   .dropdown-toggle:not(.mydropdown) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &::after {
         display: block !important;
         width: 2px;
      }
   }
   .mydropdown,
   .mydropdown:hover {
      // color: black
   }

   .media-justify-center {
      justify-content: center !important;
   }
   .media-details-tab {
      // height: 41px;
      width: 100%;
   }
   .media-close {
      position: absolute;
      left: 2% !important;
      top: 50% !important;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      opacity: 1;
   }
   .modal.sfp .modal-header .close {
      opacity: 1;
      right: unset;
   }
   .modal.sfp .modal-dialog {
      z-index: 9999999;
   }
   .sfp.full {
      z-index: 9999999;
      transform: none !important;
      .modal-dialog {
         bottom: 0 !important;
         transform: translate(0) !important;
      }
      .modal-body,
      .modal-body-ssf {
         //height: calc(100vh - 50px) !important;
         //max-height: 100vh !important;
         //min-height: 89vh !important;
         margin: 0 !important;
         //overflow-y: scroll;
      }
   }
   .media-sector,
   .media-sector div {
      height: 215px !important;
      padding-top: 2rem !important;
   }
   .mycharts svg {
      transform: scale(2);
      margin-top: 2rem;
      margin-bottom: 2rem;
   }
   .recharts-wrapper + div {
      margin-top: 4rem;
   }
   .reveleance-menu > * {
      padding: 0.5rem 1rem !important;
      font-size: 1rem !important;
      max-width: 300px;
      text-overflow: ellipsis;
      overflow: hidden;
   }
   .mydetails-close {
      position: absolute !important;
      left: 20px !important;
      top: 50% !important;
      transform: translateY(-50%) !important;
   }
   .mychart-desc {
      position: absolute;
      top: 80% !important;
      left: 50%;
   }
   .mychart-name {
      top: 65% !important;
   }
   .user-popover {
      min-width: 70% !important;
   }
   .user-profile {
      .dropdown-menu {
         min-width: 200px;
      }
   }
   .sm .dynamic.e1 {
      height: auto !important;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
   }
   .d-sm-none {
      display: none !important;
   }
   .table-sm .LinesEllipsis {
      // text-align: right;
      max-width: 150px !important;
   }
   .media-mt-2 {
      margin-bottom: 0.4rem !important;
   }
   .masthead-main {
      padding-left: 1rem;
      padding-right: 1rem;
   }
   .media-flexnowrap {
      flex-wrap: nowrap;
   }
   .media-px-2 {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
   }
   .irs-slider {
      top: 16px;
      width: 22px;
      height: 22px;
      border: 1px solid rgba(0, 0, 0, 0.3);
      background-color: #cbcfd5;
      background: linear-gradient(to bottom, white 0%, #b4b9be 30%, white 100%);
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2), inset 0 0 3px 1px white;
      border-radius: 30px;
   }
   #investments-item-list {
      height: 161px !important;
   }
   .media-px-5 {
      @media only screen and (max-width: 500px) {
         padding-left: 0.6rem !important;
         padding-right: 0.6rem !important;
      }
      padding-left: 3rem !important;
      padding-right: 3rem !important;
   }
   #overlay-wrapper {
      padding-bottom: 3.7rem;
   }
   #ps1,
   .media-fund-card > * > *,
   .card {
      padding-left: 0 !important;
      padding-right: 0 !important;
      border-radius: 0 !important;
      border: none !important;
   }
   .mysponsor {
      margin: 0 !important;
      padding: 0 !important;
      margin-top: 0.2rem !important;
      & > div {
         padding: 0 !important;
      }
   }
   .modal.sfp .modal-content .nav.nav-tabs .nav-link {
      font-size: 0.715rem !important;
   }

   .modal.confirm {
      display: flex !important;
      justify-content: center;
   }

   // Universe Container
   .universe-container {
      margin-bottom: 0.5rem;
      .universe-section {
         &__item {
            // width: 80px !important;
            font-size: 10px !important;

            // width: 70px !important;
            // margin-right: 10px;
            position: relative;
            padding-right: 10px;

            .check-label {
               font-size: 10px !important;
               left: 28px !important;
               // right: -21px !important;
               bottom: -4px !important;
            }

            .uni-tooltp {
               font-size: 10px !important;
               // right: 25px !important;
               bottom: -1px !important;
            }

            .switch {
               height: 6px !important;
               width: 20px !important;
            }

            .slider:before {
               height: 12px !important;
               width: 12px !important;
            }
         }
      }
   }

   // Share widget
   .share-component__wrapper {
      height: 19px;
      margin-left: 2px;
   }
   .share-component__item {
      font-size: 10px;
      height: 15px;
      width: 15px;
      padding: 5px;
      margin: 2px;
      // img{
      //   height: 26px !important;
      //   width: 26px !important;
      // }
   }
}
.loading-wrapper {
   position: fixed;
   left: 50%;
   top: 50%;
   transform: translate(-50%, -50%);
   width: 100vw !important;
   height: 100vh;
}
.default-card-padding {
   padding: 0px 2px 2px;
}

.mygraph,
.mygraph .recharts-surface {
   width: 100% !important;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
   .setting-wrapper {
      .settings__menu-content-area {
         .settings__menu {
            margin-top: 4px;
         }
      }
   }
}

@include respond(tab) {
   .referral-link {
      font-weight: 700 !important;
      color: #55a9e8 !important;
      padding: 0;
      text-decoration: none;
      position: absolute;
      right: 88px;
      top: 18px;

      &:hover {
         color: #55a9e8;
         text-decoration: none;
      }
   }
   .referral-link-account {
      font-weight: 700 !important;
      color: #55a9e8 !important;
      padding: 0;
      text-decoration: none;
      position: absolute;
      //right: 160px;
      top: 18px;

      &:hover {
         color: #55a9e8;
         text-decoration: none;
      }
   }
}

@media only screen and (max-width: 1023px) and (min-width: 992px) {
   .referral-link {
      font-weight: 700 !important;
      color: #55a9e8 !important;
      padding: 0;
      text-decoration: none;
      position: absolute;
      right: 88px;
      top: 17px;

      &:hover {
         color: #55a9e8;
         text-decoration: none;
      }
   }

   .referral-link-account {
      font-weight: 700 !important;
      color: #55a9e8 !important;
      padding: 0;
      text-decoration: none;
      position: absolute;
      right: 167px;
      top: 17px;

      &:hover {
         color: #55a9e8;
         text-decoration: none;
      }
   }
}

.icon-details-row {
   margin-right: 2px;
   @media (min-width: 767px) {
      display: none;
   }
   @media (min-width: 414px) and (max-width: 767px) {
      margin-right: 16px;
   }
}
.view_res_funds_details_btn {
   @media (min-width: 767px) {
      display: none;
   }
}
.hover-orange {
   .path2::before {
      color: #fa7805 !important;
   }
}

.app-card-left {
   // padding-top:1rem
   padding: 4px 16px 4px !important;
   @media (max-width: 375px) {
      padding: 8px !important;
   }
}

.overlay-wrapper {
   @media (max-width: 991px) {
      margin-top: 300px;
   }
}
