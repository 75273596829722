@import "./Elements/index";

.alert-warning {
   background: #fceabf !important;
   border-color: #fceabf !important;
}

.evaluate-all-funds-container {
   .sub-head {
      position: relative;
      padding-right: 15px;
      min-width: max-content;
      display: inline-block;
      font-size: $font-size-14px;
      font-family: $secondary-font;
      font-weight: 400;
      color: $dark-active-color !important;
      cursor: pointer;
      .down-fund-arrow {
         position: absolute;
         top: 10px;
         right: 0;
      }
   }
}

.evaluate-ticker-box-container {
   padding: 10px;
   background-color: #fff;
   border-radius: 4px;
   box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
   .checkbox-options-wrap {
   }
   .unsupported-tickers {
   }
}

.pf-enhance-tick {
   flex-wrap: wrap;
   width: 280px;
   flex-direction: row;

   label {
      margin: 5px 5px !important;
      min-width: 80px;
      width: 80px;
   }

   .ant-checkbox-wrapper {
      font-size: 12px;
   }
}

.enhancer-funds-portfolio-container {
   .unidentified_ticker_suggestion_button {
      border: none;
      outline: none;
      color: #1d6091;
      position: absolute;
      background: none;
      right: 0px;
      font-family: "Roboto";
      line-height: 0.7;
      font-size: 15px;
   }
   .unidentified_ticker_suggestion_text {
      height: auto;
      line-height: 20px;
      font-family: "Roboto";
      // font-size: 1em;
      font-size: 18px;
      // font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #848484;
      span {
         color: #494949;
      }
   }

   // .primary-cta-button {
   // 	position: relative;
   //   font-size: 14px;
   //   font-weight: 600;
   //   color: #1e5f91 !important;
   //   background-color: #ddeefa !important;
   //   border: 0;
   //   border-radius: 50px;
   //   height: 34px;
   //   color: #fff !important;
   //   background-color: #1e5f91 !important;
   //   text-transform: uppercase;
   //   line-height: normal;
   // 	.anticon {
   // 		position: absolute;
   // 		top: 8px;
   // 		vertical-align: 0;
   // 		line-height: normal;
   // 	}
   // }
   .enhancer-funds-portfolio-results-container {
      margin-right: -9px;
      padding-right: 5px !important;
   }
}

.custom-ant-checkbox {
   left: 7px;
   &:hover,
   &:focus {
      .ant-checkbox-inner {
         border-color: #e26b02 !important;
      }
   }
   .ant-checkbox-inner {
      border-radius: 5px;
      border: 1px solid #e26b02;
   }
   .ant-checkbox-checked {
      .ant-checkbox-inner {
         background-color: #e26b02 !important;
         border-color: #e26b02 !important;
         &::after {
            border-color: #ffffff !important;
         }
      }
   }
}

.showHide {
   cursor: pointer !important;
}
