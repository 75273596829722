// Stats component styles starts
.stats-wrapper {
   margin-top: 16px;
   margin-bottom: 16px;
   display: flex;
   justify-content: center;
   padding: 0px 16px;
   .sw-highlight-card {
      // background: $dark-blue-color;
      // box-shadow: 0 0 15px 0 rgba(34, 99, 147, 0.12);
      // border-radius: 10px;
      padding: 25px;
      height: 100%;
      padding-left: 0px !important;
      .sw-card-det {
         // color: #fff;
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.2;
         letter-spacing: normal;
         text-align: left;
         color: $label-black-color;
         // font-weight: 500;
         .sub-title {
            font-family: $secondary-font;
            font-size: 12px !important;
            margin-bottom: 0px;
         }
         .main-title {
            font-family: $secondary-font;
            font-size: 22px; //$font-size-25px;
            margin-bottom: 4px;
         }
      }
      .sw-card-icon {
         img {
            width: 50px !important;
            height: 50px !important;
         }
      }
   }
   .sw-card-wrapper {
      border: 1px solid #bebebe;
      box-sizing: border-box;
      border-radius: 6px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      height: 100%;
      padding: 15px 150px 15px 34px;
      background-size: 100%;
      align-items: center;
      .dynamic-container {
         //margin-left: 8px;
         //margin-right: 50px;
         .dynamic-sub-cont-1 {
            font-size: 16px;
            font-weight: 400;
            font-family: "Roboto";
            color: #565656;
            margin-bottom: 10px;
         }
         .dynamic-sub-cont-2-s {
            margin-bottom: 10px;
            width: max-content;
            display: flex;
            .left-arrow {
               padding-right: 8px;
               font-weight: 800;
               color: #5488c9;
            }
            .left-arrow-d {
               padding-right: 8px;
               font-weight: 800;
               color: #cbcbcb;
            }
            .right-arrow {
               padding-left: 8px;
               font-weight: 800;
               color: #5488c9;
            }
            .right-arrow-d {
               padding-left: 8px;
               font-weight: 800;
               color: #cbcbcb;
            }
            .side-arrow:hover {
               cursor: pointer;
            }

            .disable-side-arrow:hover {
               cursor: not-allowed;
            }

            .highlight-ticker {
               flex-grow: 1;
               //width: 85px;
               text-overflow: ellipsis;
               overflow: hidden;
               white-space: nowrap;
               cursor: pointer;
            }
            .highlight {
               text-align: center;
               padding: 6px 20px;
               line-height: 1;
               font-weight: 500;
               font-size: 14px;
               font-style: normal;
               font-family: "Roboto";
               background: #064676;
               padding: 4px 8px;
               border-radius: 30px;
               color: #ffffff;
            }
         }
         .dynamic-sub-cont-2 {
            margin-bottom: 10px;
            width: max-content;
            display: flex;
            margin-left: -18px;
            .left-arrow {
               padding-right: 8px;
               font-weight: 800;
               color: #5488c9;
            }
            .left-arrow-d {
               padding-right: 8px;
               font-weight: 800;
               color: #cbcbcb;
            }
            .right-arrow {
               padding-left: 8px;
               font-weight: 800;
               color: #5488c9;
            }
            .right-arrow-d {
               padding-left: 8px;
               font-weight: 800;
               color: #cbcbcb;
            }
            .side-arrow:hover {
               cursor: pointer;
            }

            .disable-side-arrow:hover {
               cursor: not-allowed;
            }

            .highlight-ticker {
               flex-grow: 1;
               //width: 85px;
               text-overflow: ellipsis;
               overflow: hidden;
               white-space: nowrap;
               cursor: pointer;
            }
            .highlight {
               text-align: center;
               padding: 6px 20px;
               line-height: 1;
               font-weight: 500;
               font-size: 14px;
               font-style: normal;
               font-family: "Roboto";
               background: #064676;
               padding: 4px 8px;
               border-radius: 30px;
               color: #ffffff;
            }
         }

         .dynamic-sub-cont-3 {
            font-size: 10px;
            font-weight: 400;
            font-style: italic;
            color: #565656;
            font-family: "Roboto";
         }
      }
   }
   .padd-right {
      padding: 15px 40px 15px 25px;
   }
   .sw-card {
      .sw-card-det {
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.2;
         letter-spacing: normal;
         text-align: left;
         color: $label-black-color;
         // font-weight: 500;
         .sub-title {
            font-family: $secondary-font;
            font-size: 12px;
            font-weight: 400;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.22;
            letter-spacing: normal;
            text-align: left;
            color: #565656;
         }
         .main-title {
            font-family: $secondary-font;
            font-size: 27px;
            font-weight: 500;
         }
         .main-sub-title {
            color: #000000;
            font-size: $font-size-16px;
            font-weight: 400;
         }
      }
   }
   .sw-gen-style {
      display: flex;
      flex-direction: row;
      // justify-content: center;
      align-items: center;
      padding-left: 128px;
      .vertical-line {
         height: 52px;
         margin-left: 25px;
         margin-top: 5px;
      }
      .gen-divider {
         border: 0.5px solid #ced4da;
      }
      .sw-card-icon {
         padding-right: 30px;
         img {
            width: 35px;
            height: 35px;
         }
         .ant-progress-circle {
            .ant-progress-inner {
               width: 64px !important;
               height: 64px !important;
            }
         }
      }
      .sw-card-det {
         display: flex;
         flex-direction: column;
         align-items: center;
         align-self: center;
         .sub-title {
            width: 100%;
            display: inline-block;
            margin-top: 18px;
            font-size: 12px;
         }
         .main-title {
            width: 100%;
         }
      }
   }

   .sw-gen-style-d {
      display: flex;
      flex-direction: row;
      // justify-content: center;
      align-items: center;
      padding-left: 70px;
      padding-right: 46px;
      .vertical-line {
         height: 52px;
         margin-left: 25px;
         margin-top: 5px;
      }
      .gen-divider {
         border: 0.5px solid #ced4da;
      }
      .sw-card-icon {
         padding-right: 30px;
         img {
            width: 35px;
            height: 35px;
         }
         .ant-progress-circle {
            .ant-progress-inner {
               width: 64px !important;
               height: 64px !important;
            }
         }
      }
      .sw-card-det {
         display: flex;
         flex-direction: column;
         align-items: center;
         align-self: center;
         .sub-title {
            width: 100%;
            display: inline-block;
            margin-top: 18px;
            font-size: 12px;
         }
         .main-title {
            width: 100%;
         }
      }
   }
}

// Stats component styles ends
