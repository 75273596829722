// Preview List modal styles starts
.pf-list-sm-light-modal-backdrop {
   display: block !important;
}

.pf-list-sm-light {
   max-width: 600px !important;
   .modal-content {
      border-radius: 20px;
   }
   .modal-body {
      padding: 20px;
   }
   .premium-feature-sub-text {
      font-size: 13px;
      font-weight: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      text-align: left;
      color: $dark-blue-color;
      margin-bottom: 15px;
      .fas {
         font-weight: 600;
         font-size: 12px;
         margin-left: 6px;
         &:hover {
            cursor: pointer;
         }
      }
   }
}

.pf-list-preview-wrapper {
   h3 {
      font-family: $secondary-font;
      color: $dark-blue-color;
      font-size: $font-size-30px !important;
      margin-bottom: 10px;
      font-weight: normal;
   }
   .success-string-message {
      margin: 0;
      font-family: $primary-font;
      font-size: $font-size-14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: normal;
      text-align: center;
      margin-top: 6px;
      margin-bottom: 10px;
      color: $dark-blue-color;
   }
   .note-message {
      margin-top: 20px;
      margin-bottom: 0px;
      font-size: $font-size-16px;
      font-weight: 500;
      font-family: $secondary-font;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: normal;
      text-align: center;
      color: $dark-blue-color;
   }
   .rdt_TableRow {
      font-family: $primary-font;
      font-size: $font-size-14px;
      color: #4a4a4a;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
   }
   .rdt_TableCol {
      font-family: $secondary-font;
      color: $dark-blue-color;
      font-size: $font-size-16px;
   }
   .rdt_TableHeadRow {
      min-height: 40px;
      border: 0px;
      // background: $header-grey-bg-color;
      background: #fff;
      font-family: $secondary-font;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: normal;
      text-align: center;
      color: $dark-blue-color;
      border: 0;
      background: transparent;
   }
   .secondary-btn.fill {
      background: $dark-blue-color !important;
      color: #fff !important;
   }
   .delete-all {
      // border-bottom: 1px solid $dark-blue-color;
      // cursor: pointer;
      text-decoration: underline;
   }
   .delete-row {
      cursor: pointer;
   }
   .secondary-btn {
      border: 1px solid $dark-blue-color !important;
      color: $dark-blue-color !important;
      &:not(:disabled):not(.disabled):active {
         background: inherit !important;
      }
   }
   .update-row {
      i {
         color: $disabled-text-color;
         &.active {
            color: green;
         }
      }
   }
   .selection-arrow {
      font-weight: bold;
      color: $dark-blue-color;
   }
   .foot-note {
      margin: 10px 0;
      font-family: $primary-font;
      font-size: $font-size-14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: normal;
      text-align: center;
      color: $label-black-color;
   }
}
// Preview List modal styles ends
