.open-summary-custom-scroll {
   // height: calc(100vh - 482px) !important;
   height: calc(100vh - 100px) !important;
   padding-bottom: 0 !important;
   padding-left: 10px !important;
   padding-right: 5px !important;
   margin-right: -15px !important;
}

// .modal-dialog {
//   height: 100vh;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

.summary-container {
   .primary-cta-totum {
      font-size: 14px;
      font-weight: 600;
      border: 1px solid black;
      border-radius: 50px;
      height: 34px;
      color: #1e5f91;
      background-color: white;
      text-transform: uppercase;
      line-height: normal;
   }
   .primary-cta-button {
      position: relative;
      font-size: 14px;
      font-weight: 600;
      border: 0;
      border-radius: 50px;
      height: 34px;
      color: #fff !important;
      background-color: #1e5f91 !important;
      text-transform: uppercase;
      line-height: normal;
      .anticon {
         position: absolute;
         top: 8px;
         vertical-align: 0;
         line-height: normal;
      }
   }
   .summary-wrapper {
      margin-top: -3px;
      margin-right: -7px;
      padding-right: 5px !important;
   }
}

.summary-container,
.enhancer-model-portfolio-container,
.enhancer-funds-portfolio-container {
   h5 {
      &.font-one {
         &.text-heading {
            font-weight: 600;
         }
      }
   }
}
