// PF upload modal styles starts
.pf-up-sm-light-modal-backdrop {
   display: block !important;
}

.pf-up-sm-light {
   max-width: 550px !important;
   .modal-content {
      border-radius: 20px;
   }
   .modal-body {
      padding: 40px 20px;
   }
   .premium-feature-sub-text {
      font-size: 13px;
      font-weight: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      text-align: left;
      color: $dark-blue-color;
      margin-bottom: 15px;
      .fas {
         font-weight: 600;
         font-size: 12px;
         margin-left: 6px;
         &:hover {
            cursor: pointer;
         }
      }
   }
}
// PF upload modal styles ends
.modal-content {
   .ssf-modal__body {
      .cus-pointer {
         cursor: pointer;
         width: unset;
      }
   }
}

.pf-upload-wrapper {
   h3 {
      font-family: $secondary-font;
      color: $dark-blue-color;
      font-size: $font-size-30px !important;
      // margin-bottom: 40px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
   }
   .select-btn-label {
      width: 100%;
   }
   .table-preview-wrapper {
      display: flex;
      .table-preview-col {
         display: flex;
         flex-direction: column;
         padding: 0 1px;
         .table-head {
            background: #c1c1c1;
            .table-title {
               display: flex;
               align-items: center;
               justify-content: center;
               height: 44px;
               padding: 0 4px;
               font-family: $secondary-font;
               font-size: 12px;
               font-weight: normal;
               font-stretch: normal;
               font-style: normal;
               line-height: 1;
               letter-spacing: normal;
               text-align: center;
               color: #fff;
            }
         }
         .table-cell {
            background: #f7f7f7;
            .table-title {
               display: flex;
               align-items: center;
               justify-content: center;
               height: 44px;
               padding: 0 4px;
               font-family: $secondary-font;
               font-size: 12px;
               font-weight: normal;
               font-stretch: normal;
               font-style: normal;
               line-height: 1;
               letter-spacing: normal;
               text-align: center;
               color: $label-black-color;
            }
         }
         .table-cell-required-field {
            .fa-asterisk {
               position: absolute;
               right: 4px;
               font-size: 8px;
            }
         }

         &:first-child {
            .table-cell {
               &:last-child {
                  border-bottom-left-radius: 5px;
               }
            }
         }
         &:last-child {
            .table-cell {
               &:last-child {
                  border-bottom-right-radius: 5px;
               }
            }
         }
      }
   }

   .uploadPortfolioBtn {
      border: 1px solid $dark-blue-color;
      padding: 4px 35px;
      border-radius: 0;
      color: #fff;
      background: $dark-blue-color;
      font-size: $font-size-26px;
      font-family: $primary-font;
      font-weight: 500;
      cursor: pointer;
      display: block;
      margin-top: 10px;
      text-transform: uppercase;
      img {
         width: 15px;
         margin-bottom: 2px;
         margin-right: 15px;
      }
   }
   .dld-sample {
      color: $dark-blue-color;
      font-family: $primary-font;
      font-size: $font-size-16px;
      text-decoration: underline;
      display: block;
      margin-top: 20px;
      &:hover {
         color: $dark-blue-color;
         text-decoration: underline;
      }
   }
   .foot-note {
      font-size: $font-size-18px;
      font-family: $secondary-font;
      font-weight: 400;
      margin-top: 50px;
      margin-top: 20px;
      margin-bottom: 0;
   }
}
