$light-blue-color: #6face4;
$light-red-color: #b2716a;

.csv-preview-upload-popover {
   width: auto !important;
   min-width: 160px !important;
   z-index: 9999;
}

.row-has-error {
   background: #d8636345 !important;
   td {
      background: #d8636345 !important;
   }
}

.top-60px {
   top: 60px !important;
}

.col-has-error {
   position: relative;
   .error-message {
      position: absolute;
      bottom: -12px;
      left: 0px;
      width: 100%;
      font-size: 10px !important;
      overflow: hidden;
      line-height: 1;
      text-overflow: ellipsis;
   }
}

.ant-table-filter-dropdown {
   .ant-btn-primary {
      color: #fff !important;
      background: #1d6091 !important;
      border-color: #1d6091 !important;
   }
   .anticon {
      vertical-align: 0;
   }
   .ant-input {
      &:hover,
      &:active,
      &:focus {
         background: #fff;
         color: #1d6091;
         border-color: #1d6091;
         box-shadow: 0 0 0 2px rgba(29, 96, 145, 0.1);
      }
   }
   .ant-btn {
      background: #fff;
      color: #1d6091;
      border-color: #1d6091;
      &:hover {
         background: #fff;
         color: #1d6091;
         border-color: #1d6091;
      }
   }
}

.disclaimer-text {
   position: relative;
   font-family: "Open Sans";
   font-size: 12px;
   font-weight: normal;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.19;
   letter-spacing: normal;
   text-align: left;
   color: #c5c5c5;
   margin: 18px 0;
   padding-left: 8px;
   i {
      position: absolute;
      top: 1px;
      left: 0;
      font-size: 7px;
   }
}

.csv-preview-upload {
   top: 60px !important;
   z-index: 9999 !important;

   .csv-preview-modal-title-head-input-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      width: 50%;
      margin: 0 auto;
      input {
         font-family: "Roboto";
         font-size: 1.7rem !important;
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.31;
         letter-spacing: normal;
         text-align: center !important;
         color: #1e5f91 !important;
         // text-transform: capitalize;
         border: 0;
         box-shadow: unset;
      }
      .csv-preview-uplaod-pencil-icon {
         position: absolute;
         right: 0;
      }
   }
   .ant-modal {
      width: 718px !important;
      padding-top: 24px;
      padding-bottom: 24px;
      // max-width: 600px;
   }
   .ant-modal-content {
      border-radius: 20px !important;
      padding: 0 24px 24px 24px !important;
      .ant-modal-header {
         padding: 24px;
         border: 0;
         border-radius: 20px;
      }
      .ant-modal-title {
         border: 0;
         // border-radius: 30px;
      }
      .title-head {
         font-family: "Roboto";
         font-size: 1.7rem !important;
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.31;
         letter-spacing: normal;
         text-align: center;
         color: #1e5f91;
      }
      .ant-modal-body {
         padding-top: 0;
         // height: 675px;
         button {
            border-radius: 30px;
         }
      }
   }

   .cp-modal-close {
      font-size: 1.2rem;
   }
}
.csv-preview-upload-container {
   .ant-table-wrapper {
      // margin-bottom: 20px;
   }
   .editable-row {
      position: relative;
   }
   .ant-table {
      .ant-table-column-sorter-up.active,
      .ant-table-column-sorter-down.active {
         color: #1d6091 !important;
      }
      .editable-enable-overflow {
         overflow: unset !important;
      }
      .td-disable-box {
         visibility: hidden !important;
      }
      .editable-enable-overflow + td > div {
         // visibility: hidden !important;
      }

      .ant-form-item {
         .ant-form-item-control {
            .ant-form-item-control-input {
               min-height: auto;
               position: relative;
               .ant-form-item-control-input-content .ant-input {
                  // height: 28px;
                  height: 100%;
                  padding: 4px 8px;
                  text-align: center;
               }
            }
         }

         .ant-form-item-explain {
            position: absolute;
            bottom: -12px;
            font-size: 10px;
            line-height: 1;
            min-height: auto;
         }
      }
      .editable-box-container {
         height: 35px;
         position: absolute;
         top: 0px;
         left: 0;
         width: 325px;
         z-index: 9999;
         .ant-form-item-control {
            height: 100%;
            .ant-form-item-control-input-content {
               height: 100%;
               .ant-input {
                  height: 32px;
               }
            }
         }
      }

      table {
         thead {
            tr {
               th {
                  font-family: Roboto;
                  font-size: 14px;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.14;
                  letter-spacing: normal;
                  text-align: center;
                  color: #1d6091;
                  border: 0;
                  background: transparent;
                  .anticon-search {
                     font-size: 14px;
                  }
                  &:first-child {
                     text-align: left;
                  }
               }
               &:first-child {
                  th:last-child {
                     text-align: center;
                  }
               }
               th[title="Ticker"] {
                  text-align: left;
               }
            }
         }
         tbody {
            tr {
               td,
               span {
                  font-family: "Open Sans";
                  font-size: 14px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.36;
                  letter-spacing: normal;
                  text-align: center;
                  color: #4a4a4a;
               }
               td {
                  // border-bottom-color: #c5c5c5;
                  &:first-child {
                     text-align: left;
                  }
                  &:last-child {
                     text-align: center;
                     i {
                        cursor: pointer;
                     }
                  }
                  .ant-empty {
                     display: none !important;
                  }
               }
               td.ticker {
                  text-align: left;
               }
               td.weight,
               td.values {
                  padding: 0 8px;
               }
            }
            .ant-table-placeholder {
               td {
                  padding: 0 !important;
                  border: 0 !important;
               }
            }
         }
      }
   }
   .ant-pagination {
      position: absolute;
      top: -45px;
      right: 0;
      .ant-pagination-item,
      .ant-pagination-prev,
      .ant-pagination-next,
      .ant-pagination-jump-prev,
      .ant-pagination-jump-next {
         min-width: 22px;
         height: 22px;
         font-size: 12px;
         line-height: 20px;
         &:hover,
         &:active,
         &:focus {
            .ant-pagination-item-link {
               background: #fff;
               color: #1d6091;
               border-color: #1d6091;
               box-shadow: 0 0 0 2px rgba(29, 96, 145, 0.1);
            }
         }
      }
      .ant-pagination-item-link {
         // margin-top: -1px;
      }
      .anticon {
         vertical-align: 0.2em;
         font-size: 10px;
      }
      .ant-pagination-item-active {
         border-color: #1d6091 !important;
         a {
            color: #1d6091 !important;
         }
      }
   }

   .custom-ticker-search-box-loader {
      position: absolute;
      z-index: 999;
      width: 100%;
      text-align: center;
      .anticon {
         line-height: 0 !important;
         color: $light-blue-color !important;
      }
   }

   .custom-ticker-search-box-wrapper {
      width: 100%;

      .ant-select {
         width: 100%;
         margin-bottom: 0px;
         box-shadow: none;
         border-radius: 0;
         .ant-select-selector {
            height: 35px;
            padding: 0 !important;
            border: 0 !important;
            background: transparent !important;
            box-shadow: none !important;
            border-radius: 0 !important;
            // border-bottom: 1px solid #c5c5c5 !important;
            .ant-select-selection-search {
               width: 100%;
               left: 0;
            }
            input {
               height: 35px;
               padding: 8px 8px !important;
            }
            .ant-select-selection-placeholder {
               padding-left: 24%;
               padding-right: 0;
               text-align: center;
               line-height: 35px;
            }
            .ant-select-selection-item {
               display: flex;
               align-items: center;
               justify-content: space-between;
               padding: 8px 8px;
               .option-ticker {
                  width: 30%;
                  text-align: left;
               }
               .option-ticker-name {
                  width: 70%;
               }
            }
         }
         .ant-select-arrow {
            right: 0 !important;
            .search-icon {
               right: 0;
               top: 5px;
               font-size: 20px;
            }
         }
      }

      .custom-ticker-search-box-dropdown {
         // height: 120px;
         div[role="listbox"] + div {
            &::-webkit-scrollbar {
               width: 4px !important;
               border-radius: 6px;
               background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
               width: 4px !important;
               border-radius: 6px;
               background-color: $light-blue-color;
            }
         }
         .custom-ticker-search-list-option {
            .ant-select-item-option-content {
               display: flex;
               align-items: center;
               .option-ticker {
                  text-align: left;
               }
               .option-ticker-name {
                  text-align: center;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
               }
            }
         }
      }
      .ant-select-dropdown {
         z-index: 999999;
      }
   }

   .add-new-ticker-label {
      height: 36px;
      padding: 8px 8px 8px 40px;
      margin-bottom: 20px;
      span {
         font-size: 0.8rem;
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         letter-spacing: normal;
         text-align: left;
         line-height: 1.5;
         color: $light-blue-color;
         cursor: pointer;
      }
   }

   .total-text {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      text-align: left;
      line-height: 1.5;
      letter-spacing: normal;
      color: #1d6091;
      border: 0;
      background: transparent;
   }

   .add-new-ticker-container {
      width: 100%;
      height: 36px;
      margin-bottom: 20px;
      .add-new-ticker-col {
         height: 36px;
         padding: 4px 8px;
         line-height: 2;
         border-bottom: 1px solid #f0f0f0;
         .fa-plus {
            font-weight: 100;
            color: $light-blue-color;
            cursor: pointer;
         }
         .fa-times {
            font-weight: 500;
            color: $light-red-color;
            cursor: pointer;
         }
         .ant-select {
            width: 100%;

            .ant-select-selector {
               height: 36px;

               .ant-select-selection-search {
                  width: 100%;
                  left: 0;
               }
               input {
                  height: 36px;
               }
               .ant-select-selection-placeholder {
                  padding: 0 0 0 7px;
                  text-align: left;
                  line-height: 35px;
               }
               .ant-select-selection-item {
                  padding: 8px 8px;
                  .option-ticker {
                     width: 30%;
                     text-align: left;
                  }
                  .option-ticker-name {
                     width: 70%;
                     text-align: center;
                     white-space: nowrap;
                     text-overflow: ellipsis;
                     overflow: hidden;
                  }
               }
            }
            .ant-select-arrow {
               right: 0 !important;
               .search-icon {
               }
            }
         }
         .input-box {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 2;
            text-align: center;
         }
         .error {
            border: solid 1px #dc3545;
         }
         .ant-form-item {
            height: 100%;
            margin: 0;
            .ant-form-item-control {
               .ant-form-item-control-input {
                  height: 100%;
                  min-height: unset;
                  .ant-form-item-control-input-content {
                     button {
                        height: 100%;
                        min-height: unset;
                        margin: 0px;
                        padding: 0;
                        border: 0;
                        background: transparent;
                        box-shadow: unset;
                     }
                  }
               }
            }
         }
         .ant-form-item-has-error {
            .ant-form-item-control {
               .ant-form-item-control-input {
                  .ant-form-item-control-input-content {
                     input {
                        border: solid 1px #dc3545;
                     }
                  }
               }
               .ant-form-item-explain {
                  display: none;
               }
            }
         }
      }
   }

   .ant-tooltip {
      .ant-tooltip-content {
         .ant-tooltip-inner {
            font-size: 11px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: 0.01px;
            text-align: center;
            color: #fff;
            border-radius: 5px;
         }
      }
   }

   .allocation-header-container {
      position: relative;
      overflow: visible;
      .allocation-radio-button-wrapper {
         position: absolute;
         top: 0;
         left: 0;
         z-index: 99;
         width: 200%;
         height: 100%;
         display: flex;
         align-items: center;
         justify-content: center;
         .allocation-head-text {
            margin-right: 5px;
         }
         .ant-radio-button-wrapper {
            width: 25px;
            height: 25px;
            padding: 0px;
            line-height: 25px;
            &:hover {
               color: #1e5f91;
            }
         }
         .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
            color: #1e5f91;
            border-color: #1e5f91;
         }
      }
   }

   .disabled {
      color: #ababab;
   }
}

.csv-preview-upload-container,
.custom-ticker-search-box-wrapper {
   width: 100%;

   .ant-select {
      width: 100%;
      margin-bottom: 0px;
      box-shadow: none;
      border-radius: 0;
      .ant-select-selector {
         height: 35px;
         padding: 0 !important;
         border: 0 !important;
         background: transparent !important;
         box-shadow: none !important;
         border-radius: 0 !important;
         // border-bottom: 1px solid #c5c5c5 !important;
         .ant-select-selection-search {
            width: 100%;
            left: 0;
         }
         input {
            height: 35px;
            padding: 8px 8px !important;
         }
         .ant-select-selection-placeholder {
            padding-left: 24%;
            padding-right: 0;
            text-align: center;
            line-height: 35px;
         }
         .ant-select-selection-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 8px 8px;
            .option-ticker {
               width: 30%;
               text-align: left;
            }
            .option-ticker-name {
               width: 70%;
               text-align: center;
               white-space: nowrap;
               text-overflow: ellipsis;
               overflow: hidden;
            }
         }
      }
      .ant-select-arrow {
         right: 0 !important;
         .search-icon {
            right: 0;
            top: 5px;
            font-size: 20px;
         }
      }
   }

   .custom-ticker-search-box-dropdown {
      // height: 120px;
      div[role="listbox"] + div {
         &::-webkit-scrollbar {
            width: 4px !important;
            border-radius: 6px;
            background-color: transparent;
         }

         &::-webkit-scrollbar-thumb {
            width: 4px !important;
            border-radius: 6px;
            background-color: $light-blue-color;
         }
      }
      .custom-ticker-search-list-option {
         .ant-select-item-option-content {
            display: flex;
            align-items: center;
            .option-ticker {
               text-align: left;
            }
            .option-ticker-name {
               text-align: center;
               white-space: nowrap;
               text-overflow: ellipsis;
               overflow: hidden;
            }
         }
      }
   }
   .ant-select-dropdown {
      z-index: 999999;
   }
}

.invalid-ticker-table {
   padding-bottom: 15px;
   // .ant-table-wrapper{
   //   .ant-table{
   //     max-height: 110px;
   //     overflow: hidden;
   //   }
   //   &.show-all-invalid-tickers{
   //     .ant-table{
   //       height: 100%;
   //       max-height: 100%;
   //       overflow: auto;
   //     }
   //   }
   // }
   .head-note {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 35px;
      padding-left: 40px;
      p {
         margin-bottom: 0;
         font-size: 16px;
         font-weight: normal;
         color: #1d6091;
      }
      span {
         color: #ababab;
         text-decoration: underline;
         cursor: pointer;
      }
   }
   .error-desc {
      font-size: 12px;
      color: #b2716a;
   }
   .valid-desc {
      font-size: 12px;
      color: #00823a;
   }
   .foot-note {
      // position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      p {
         width: 85%;
         color: #b2716a;
         margin-top: 5px;
         margin-bottom: 0px;
      }
      span {
         width: 15%;
         padding: 0 8px;
         color: #ababab;
         text-decoration: underline;
         cursor: pointer;
      }
   }
   .edit-ticker {
      input[type="text"] {
         width: 100% !important;
         height: 20px;
         border: 1px solid #ccc;
         padding: 10px 6px;
      }
   }
}

.valid-ticker-table {
   .ant-table-wrapper {
      .ant-table {
         //  min-height: 280px;
      }
   }
   .head-note {
      height: 35px;
      display: flex;
      align-items: center;
      padding-left: 40px;

      p {
         margin-bottom: 0;
         font-size: 16px;
         font-weight: normal;
         color: #1d6091;
      }
   }
}

.fx-clm-space-between {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}

.fx-clm {
   display: flex;
   flex-direction: column;
}

.editable-cell {
   position: relative;
}

.editable-cell-value-wrap {
   padding: 5px 0px !important;
   cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
   border: 1px solid #d9d9d9;
   border-radius: 4px;
   padding: 4px 11px;
   .error-message {
      bottom: -14px !important;
   }
}

[data-theme="dark"] .editable-row:hover .editable-cell-value-wrap {
   border: 1px solid #434343;
}

.csv-preview-uplaod-pencil-icon {
   font-weight: 100;
   color: $light-blue-color;
   cursor: pointer;
}

.csv-preview-uplaod-circle-check-icon {
   width: 16px;
   height: 16px;
   padding: 3px 3px;
   font-size: 9px;
   font-weight: bold;
   color: #fff;
   border-radius: 50px;
   border: 1px solid $light-blue-color;
   background: $light-blue-color;
   cursor: pointer;
}
