html {
   -webkit-tap-highlight-color: transparent;
   -ms-text-size-adjust: 100%;
   -webkit-text-size-adjust: 100%;
   // font-size: $html-fontsize;
}

body {
   // font-size: $body-base-size;
   line-height: $body-line-height;
   color: $body-font-color;
   //background-color: $body-background-color;
   -webkit-font-smoothing: antialiased;
   font-family: $default-font-family;
   font-weight: $font-weight-regular;
}

.primary-link {
   font-weight: $font-weight-medium;
   font-size: 14px;
   color: #55a9e8;
   font-family: "Roboto";

   &:hover {
      color: #55a9e8;
   }

   &:active {
      color: #55a9e8;
   }
}
