@import "./TabComponents/ResultCharts/common/index";

.model-selector-wrapper {
   margin-top: 20px;
   padding: 0px 15px;
   flex: 1;
   overflow: hidden;
   .tbl-wrapper {
      height: 100%;
      height: calc(100vh - 424px);
      overflow: hidden;
      .model-panel {
         display: flex;
         flex-direction: column;
         height: 100%;
      }
      .ant-tabs {
         display: flex;
         flex-direction: column;
         height: 100%;
         // height: calc(100vh - 490px);
         height: calc(100vh - 474px);
      }
      .tab-holdings {
         .ant-tabs-content {
            // height: calc(100vh - 540px);
            height: calc(100vh - 524px);
            overflow: hidden;
            padding-bottom: 0px;
         }
      }
      .ant-tabs-content {
         height: 100%;
         padding-bottom: 50px;
      }
      .ant-tabs-tabpane {
         display: flex;
         flex-direction: column;
         overflow: auto;
         height: 100%;
      }
   }
   .rdt_TableRow {
      font-family: $primary-font;
      font-size: $font-size-16px;
      color: #4a4a4a;
      .tbl-edit {
         color: $dark-blue-color;
      }
      border-bottom: 2px solid #cbcbcb !important;
   }
   .rdt_TableCol {
      // font-family: $secondary-font;
      font-family: $primary-font;
      font-size: $font-size-16px;
      font-weight: bold;
      color: $label-black-color;
   }
   .rdt_TableHeadRow {
      min-height: 50px;
      border-bottom: 1px solid #979797;
      .rdt_TableCol {
         font-size: $font-size-20px !important;
         color: $label-black-color !important;
      }
      // border: 0px;
   }
   .rdt_TableBody,
   .ant-tabs-tabpane {
      //overflow-y: auto !important;
      &::-webkit-scrollbar-thumb {
         background-color: #fdfdfd !important;
      }
   }
   .tbl-content {
      display: flex;
      flex: 1;
      overflow: hidden;
      height: 100%;
      & > div {
         overflow: auto;
         height: 100%;
         & > div {
            display: flex;
            height: 100%;
         }
      }
   }
   .sub-head {
      color: #256084;
   }
   .tbl-edit {
      cursor: pointer;
   }
   .model-panel {
      .model-header {
         text-align: center;
         padding: 15px;
         border-radius: 10px 10px 0px 0px;
         h4 {
            margin-bottom: 0px;
            color: #fff !important;
            line-height: 1.4;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            .fa-redo {
               position: absolute;
               top: 4px;
               left: 0;
               transform: rotate(28deg) scaleX(-1);
               cursor: pointer;
            }
         }
         &.ma {
            background: $dark-blue-color;
         }
         &.sm {
            background: $dark-blue-color;
         }
      }
   }
   .ant-tabs {
      background: #e1effa;
      &-nav {
         & > div {
            display: flex;
            width: 100%;
         }
         display: flex;
         .ant-tabs-tab {
            flex-grow: 1;
            margin-right: 0px;
            width: 100%;
            text-align: center;
            color: #919191;
            font-weight: bold;
            font-size: $font-size-20px;
            font-family: $secondary-font !important;
            &.ant-tabs-tab-active {
               color: $dark-blue-color;
            }
         }
      }
      .ant-tabs-bar {
         margin-bottom: 0px;
         border-bottom: 1px solid #fff;
      }
      .ant-tabs-ink-bar {
         background-color: $dark-blue-color;
      }
   }
   .risk-chart-wrapper {
      padding: 10px 20px;
      h4 {
         font-family: $secondary-font;
         color: $dark-blue-color;
         font-size: $font-size-17px !important;
      }
   }
   .returns-summary-wrapper {
      .returns-summary-item {
         display: flex;
         justify-content: space-between;
         padding: 10px 15px;
         border-bottom: 1px solid #fff;
      }
   }
   .up-down-capture-chart {
      .title {
         display: flex;
         width: 100%;
         justify-content: space-between;
         h1 {
            width: 180px;
         }
         .up-down-capture-dropdown {
            width: 180px;
         }
      }
   }
   // Model Search Results Styles
   .sr-wrapper {
      .rdt_TableRow {
         min-height: 104px !important;
      }
      .ticker-wrapper,
      .sponsor-wrapper,
      .frr-wrapper {
         span {
            &:nth-child(2) {
               display: block;
               overflow: hidden;
               text-overflow: ellipsis;
               white-space: nowrap;
            }
         }
      }
      .ticker-wrapper {
         span {
            display: block;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: $font-size-13px !important;
            &:last-child {
               text-align: left;
            }
         }
      }
      .sponsor-wrapper {
         span {
            text-transform: capitalize;
            font-weight: bold;
            &:last-child {
               text-transform: none;
               font-weight: normal;
               text-align: left;
            }
         }
      }
      .frr-wrapper {
         span {
            &:last-child {
               font-size: $font-size-11px !important;
            }
         }
      }
      .guage-chart-wrapper {
         width: 120px !important;
         display: flex;
         flex-direction: column;
         align-items: center;
         .fz {
            font-size: 8px !important;
         }
      }
      .siw-1-pie-holder {
         display: flex;
         flex-direction: column;
         align-items: center;
         .siw-1-pie-title {
            font-size: 8px;
         }
      }
      .siw-1-carouse-holder {
         .siw-1-pie-title {
            display: block;
         }
         .flipster__item__content {
            overflow: inherit;
         }
      }
      .frr-head-wrapper {
         display: flex;
         flex-direction: row;
         align-items: center;
         margin-left: -10px;
         .fassf {
            margin-right: -2px;
         }
      }
      .rdt_TableCell {
         padding-top: 13px;
         padding-bottom: 13px;
         .select-btn {
            height: auto;
            padding: 8px 20px;
            font-family: Roboto;
            font-size: $font-size-14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            color: $dark-blue-color;
            background: transparent;
            border-radius: 30px;
            border: solid 1px $dark-blue-color;
         }
      }
   }
   // Allocation Not Found Wrapper
   .not-found-wrapper {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      align-self: center;
      justify-content: center;
      height: 100%;
      color: #aaacaf;
      img {
         width: 100px;
      }
      i {
         font-size: $font-size-30px !important;
      }
      span {
         width: 235px;
         margin-top: 15px;
         line-height: 1.3;
         font-family: $secondary-font !important;
         font-size: $font-size-25px !important;
      }
   }
   .text-capitalize {
      text-transform: capitalize;
   }
}
