@import "./Folder/index";
@import "./ResultSection/index";

.build-from-scratch-container {
   width: 100%;
   .ant-card-body {
      padding: 15px;
   }
   .primary-button {
      width: 120px;
      height: 42px;
      font-family: $secondary-font;
      font-size: $font-size-18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: normal;
      color: #ffffff;
      background: #1d6091;
      background-color: #1d6091;
      border: solid 1px #1d6091;
      &:hover,
      &:focus {
         background: #1d6091;
         border: solid 1px #1d6091;
      }
   }
   .go-to-dashboard {
      width: 130px;
      font-size: 20px;
      cursor: pointer;
   }
   .primary-button.go-to-dashboard.back-icn {
      border-radius: 50%;
      width: 42px;
      margin-right: 10px;
      span.back {
         position: relative;
         right: 5px !important;
         bottom: 4px !important;
      }
   }
   .primary-button.go-to-dashboard.home-icn {
      span.home {
         position: relative;
         bottom: 5px !important;
         padding-left: 10px;
      }
   }
   .primary-button.generate-tradelist {
      width: 150px !important;
      margin-right: 5px !important;
   }
   margin: 0 !important;
   .card-wrapper {
      .ant-card-head {
         padding: 0 15px;
      }
      .ant-card-body {
         height: 100%;
      }

      .ant-card-head-wrapper {
         width: 100%;
         .ant-card-head-title {
            .card-title-wrap {
               margin: 0;
               .card-title {
                  font-family: $secondary-font;
                  font-size: $font-size-26px;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  letter-spacing: normal;
                  text-align: left;
                  color: $dark-blue-color;
               }
               .card-sub-title {
                  padding-left: 20px;
                  font-family: $secondary-font;
                  font-size: $font-size-18px;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  letter-spacing: normal;
                  text-align: left;
                  color: $label-black-color;
               }
            }
         }
         .ant-card-extra {
            .ant-menu {
               border: 0 !important;
               .ant-menu-item {
                  padding-left: 12px;
                  padding-right: 12px;
                  font-family: $secondary-font;
                  font-size: $font-size-18px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  letter-spacing: normal;
                  text-align: left;
                  color: $primary-color;
               }
            }
         }
      }

      .ant-menu-horizontal {
         line-height: normal;
         border: 0px !important;
         .ant-menu-item {
            border: 0px !important;
            &:hover {
               color: $primary-color;
            }
         }
         .ant-menu-submenu {
            border: 0px !important;
         }
         .ant-menu-item-selected {
            color: $primary-color !important;
            border: 0px !important;
         }
         .ant-menu-submenu-title:hover {
            color: $primary-color !important;
         }
         .ant-menu-submenu-selected {
            color: $primary-color !important;
            border: 0px !important;
         }
      }
      .anticon {
         vertical-align: 0.025em;
      }
   }

   .steps-indicator-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-bottom: 15px;

      .steps-text {
         padding-right: 10px;
         font-family: $secondary-font;
         font-size: $font-size-12px;
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.17;
         letter-spacing: normal;
         text-align: left;
         color: $indicator-color;
      }

      .steps-pointer {
         position: relative;
         padding-left: 10px;

         &:before {
            content: "";
            position: absolute;
            top: 3px;
            left: 0;
            width: 5px;
            height: 5px;
            border: 1px solid $indicator-color;
            border-radius: 10px;
         }
      }
   }

   .build-from-scratch-header {
      width: 100%;
      height: 62px;
      padding: 10px 15px 10px 15px;
      .d-flex-wrap {
         height: 100%;
         display: flex;
         align-items: center;
         flex-wrap: wrap;
      }
      .portfolio-name-edit-wrapper {
         .text-left.finalize-input {
            width: 90px;
            margin-right: 5px;
         }
         .save-icon {
            margin-right: 5px;
            color: $primary-color;
            font-size: $font-size-26px;
         }
         .edit-input {
            text-overflow: ellipsis;
            width: 175px;
            margin: 0;
            padding: 5px 0;
            font-family: $secondary-font;
            font-size: $font-size-26px;
            font-weight: 500;
            line-height: 1.15;
            letter-spacing: normal;
            text-align: left;
            color: #1d6091;
            background: transparent;
            box-shadow: none;
            outline: none;
            border: 0;
            &:focus {
               border-bottom: 1px solid #b5b5b5;
            }
         }
         .pf-value-input {
            width: 135px;
            margin-left: 30px;
         }
         ۞︎ .edit-icon {
            margin-left: 5px;
            color: #c5c5c5;
            font-size: $font-size-26px;
         }
      }

      .timeline-button-group {
         width: 100%;
         max-width: 442px;
         flex-direction: row;
         justify-content: space-between;
         margin: 0 auto;
         .divider-holder {
            width: 265px;
            padding: 0 1em;
         }
         .timeline-button {
            font-family: $secondary-font;
            font-size: $font-size-16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.19;
            letter-spacing: normal;
            color: #b5b5b5;
            .index-number {
               padding-right: 4px;
            }
         }
         .timeline-active {
            color: #606060;
            .index-number {
               font-family: $secondary-font;
               font-size: $font-size-26px;
               font-weight: 500;
               color: #606060;
            }
         }
         .ant-divider-with-text-center {
            &:after,
            &:before {
               border-top: 1px solid #b5b5b5;
            }
         }
      }
   }

   .build-from-scratch-content-wrapper {
      width: 100%;
   }

   .left-block {
      padding: 0px 4px 15px 15px;
      .ant-card-head-wrapper {
         width: 100%;
         .ant-card-head-title {
            width: 40%;
            max-width: max-content;
            .card-title-wrap {
               margin: 0;
            }
         }
         .ant-card-extra {
            width: 60%;
            max-width: calc(100% - 413px);
            padding: 0;
            text-align: right;
            .ant-menu {
            }
         }
      }
   }
   .right-block {
      padding: 0px 15px 15px 10px;
      .ant-card-head-wrapper {
         width: 100%;
         .ant-card-head-title {
            width: 20%;
            min-width: max-content;
            .card-title-wrap {
               margin: 0;
            }
         }
         .ant-card-extra {
            width: 80%;
            padding: 0;
            max-width: calc(100% - 54px);
            text-align: right;
         }
         .result-card-menus {
         }
      }
   }

   .skeleton-bg-transparent {
      background: transparent !important;
      border: 0 !important;
   }

   .skeleton-body-padding-0 {
      .ant-card-body {
         padding: 0 !important;
      }
   }
   .card-loader,
   .ant-card-loading {
      .ant-card-body {
         display: block !important;
         padding: 15px !important;
      }
   }

   .finalize-button-tooltip {
      background: none !important;
      border: none !important;
      button {
         width: 100%;
         height: 100%;
      }
   }
}

.account-broker-modal.premium-msg-modal.account-modal.finalize-modal-body {
   width: 100%;
}

.ssf-modal__body.finalize-modal {
   background: #f7f7f7;
   border-radius: 15px;
   padding: 3.5rem 2.5rem;
   .header-wrapper {
      h1 {
         color: #1d6091 !important;
         font-size: 1.3rem !important;
      }
   }
   .premium-feature-completed-close.finalize-close {
      position: absolute;
      right: 1rem;
      top: 1rem;
      z-index: 9;
      font-size: 1.5rem;
      color: #1d6091;
      cursor: pointer;
   }
   .finalize-button {
      width: 170px;
      height: 32px;
      margin-top: 20px;
      font-family: $secondary-font;
      font-size: $font-size-18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: normal;
      color: #ffffff;
      background: #1d6091;
      background-color: #1d6091;
      border: solid 1px #1d6091;
      &:hover,
      &:focus {
         background: #1d6091;
         border: solid 1px #1d6091;
      }
   }
   .input-wrapper {
      .text-left.finalize-input {
         width: 70%;
         margin: 0 auto;
      }
   }
   .act-button-wrapper {
      text-align: center;
      width: 100%;
   }
   .footer-text {
      text-align: center;
      width: 100%;
      font-size: 12px;
      color: #b5b5b5;
      margin-top: 18px;
      margin-bottom: 0;
   }
}

//.ant-tooltip{
//  .ant-tooltip-content{
//    .ant-tooltip-inner{
//      color: #000 ;
//      background: #ffffff ;
//      border: 1px solid #000000 ;
//    }
//  }
//}

.toastFail {
   background-color: #f8d7da !important;
   color: #721c24 !important;
   .ant-notification-notice-content {
      .ant-notification-notice-message {
         display: none;
      }
   }
}
