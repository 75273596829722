$content-height: calc(100vh - 60px);
$scroll-width: 6px;
$select-color: #e26b02;
$head-color: #4a4a4a;
$active-color: #1e5f91;
$disabled-color: #bababb;
$background-color: #f7f7f7;
$primary-color: #55a9e8;

@media only screen and (min-width: 320px) and (max-width: 1023px) {
   .enhancer-model-portfolio-container {
      .model-portfolio-item-card {
         .model-portfolio-item-card-wrapper {
            .FRR-Class {
               .__val {
               }
            }
         }
      }
      .portfolio-funds-list-wrap {
         .portfolio-funds-list-head-row {
            .portfolio-funds-list-head-col {
               &:last-child {
               }
            }
         }
         .portfolio-funds-list-data-row {
            .portfolio-funds-list-data-col {
               &:last-child {
               }
            }
         }
      }
      .print-wrapper {
         .siw-meter-title {
            font-size: 0.6em !important;
         }
      }
      .enhance-result-page-container {
         .static-card-wrapper {
            .swap-icon-wrapper {
            }
            .static-card-label {
            }
            .static-card-body {
               .ant-card-body {
               }
            }
         }

         .FRR-wrapper {
            .col {
               .FRR-container {
                  img {
                     // margin: 0 auto !important;
                  }
               }
               &:nth-child(2) {
                  // padding: 0 2px;
               }
            }
         }

         .custom-dynamic-card-body {
            // height: 321px !important;
            .ant-card-body {
               // padding: 1rem !important;
            }
         }

         #select-card {
            .ant-card-body {
               // padding-bottom: 15px !important;
            }
         }

         .dynamic-card-wrapper {
            .dynamic-card-body {
               .empty-message-wrapper {
                  .ant-card-body {
                  }
                  .text-box {
                     .text-bold {
                     }
                  }
               }
               .ant-card-body {
                  .p-top-right {
                     .custom-control-input {
                     }
                  }
                  .ps-wrap {
                     .pr-3 {
                        &:last-child {
                           // padding-right: 0px !important;
                        }
                     }
                  }
                  .not-applicable-icon-wrapper {
                  }
               }
            }
            .dynamic-card-disable-scroll {
            }
            .dynamic-card-disable-scroll::-webkit-scrollbar {
            }

            .dynamic-card-disable-scroll::-webkit-scrollbar-thumb {
            }
         }
         .card-tab-container {
            .ant-tabs-bar {
               border: 0;
               margin-top: 5px;
            }
            .ant-tabs-nav-container {
               .ant-tabs-nav-wrap {
                  .ant-tabs-nav {
                     width: 100%;
                     & > div {
                     }
                  }
                  .ant-tabs-tab {
                  }
                  .ant-tabs-tab-active {
                  }
                  .ant-tabs-ink-bar {
                  }
               }
            }
         }
      }
   }
}

@media only screen and (min-width: 1024px) and (max-width: 1036px) {
   .enhancer-model-portfolio-container {
      margin-bottom: 40px;
      .model-portfolio-item-card {
         .model-portfolio-item-card-wrapper {
            .print-wrapper {
               width: calc(100% - 0px);
               .siw-meter-title {
                  font-size: 0.6em !important;
               }
               .meter-value {
                  .siw-meter-min {
                  }
                  .siw-meter-value {
                  }
                  .siw-meter-max {
                     text-align: right;
                  }
                  span {
                     font-size: 0.5em;
                  }
               }
            }
            .name-sponsor-block {
               .short-name {
                  font-size: 0.6em !important;
               }
               .category-text {
                  font-size: 0.6em !important;
               }
            }
            .FRR-Class {
               .__state {
                  font-size: 0.59em !important;
               }
               .__val {
                  font-size: 0.63em !important;
               }
            }
         }
      }
      .portfolio-funds-list-wrap {
         .portfolio-funds-list-head-row {
            .portfolio-funds-list-head-col {
               &:last-child {
               }
            }
         }
         .portfolio-funds-list-data-row {
            .portfolio-funds-list-data-col {
               &:last-child {
               }
            }
         }
      }
      .enhance-result-page-container {
         .static-card-wrapper {
            .swap-icon-wrapper {
            }
            .static-card-label {
            }
            .static-card-body {
               .ant-card-body {
               }
            }
         }

         .FRR-wrapper {
            .col {
               .FRR-container {
                  img {
                     // margin: 0 auto !important;
                  }
               }
               &:nth-child(2) {
                  // padding: 0 2px;
               }
            }
         }

         .custom-dynamic-card-body {
            // height: 321px !important;
            .ant-card-body {
               // padding: 1rem !important;
            }
         }

         #select-card {
            .ant-card-body {
               // padding-bottom: 15px !important;
            }
         }

         .dynamic-card-wrapper {
            .dynamic-card-body {
               .empty-message-wrapper {
                  .ant-card-body {
                  }
                  .text-box {
                     .text-bold {
                     }
                  }
               }
               .ant-card-body {
                  .p-top-right {
                     .custom-control-input {
                     }
                  }
                  .ps-wrap {
                     .pr-3 {
                        &:last-child {
                           // padding-right: 0px !important;
                        }
                     }
                  }
                  .not-applicable-icon-wrapper {
                  }
               }
            }
            .dynamic-card-disable-scroll {
            }
            .dynamic-card-disable-scroll::-webkit-scrollbar {
            }

            .dynamic-card-disable-scroll::-webkit-scrollbar-thumb {
            }
         }
         .card-tab-container {
            .ant-tabs-bar {
               border: 0;
               margin-top: 5px;
            }
            .ant-tabs-nav-container {
               .ant-tabs-nav-wrap {
                  .ant-tabs-nav {
                     width: 100%;
                     & > div {
                     }
                  }
                  .ant-tabs-tab {
                  }
                  .ant-tabs-tab-active {
                  }
                  .ant-tabs-ink-bar {
                  }
               }
            }
         }
      }
   }
}

@media only screen and (min-width: 1440px) {
   .enhancer-model-portfolio-container {
      .model-portfolio-item-card {
         .model-portfolio-item-card-wrapper {
            .FRR-Class {
               .__val {
               }
            }
         }
      }
      .portfolio-funds-list-wrap {
         .portfolio-funds-list-head-row {
            .portfolio-funds-list-head-col {
               &:last-child {
               }
            }
         }
         .portfolio-funds-list-data-row {
            .portfolio-funds-list-data-col {
               &:last-child {
               }
            }
         }
      }
      .print-wrapper {
         .siw-meter-title {
            // font-size: 0.6875em !important;
         }
      }
      .enhance-result-page-container {
         .static-card-wrapper {
            .swap-icon-wrapper {
            }
            .static-card-label {
            }
            .static-card-body {
               .ant-card-body {
               }
            }
         }

         .FRR-wrapper {
            .col {
               .FRR-container {
                  img {
                     // margin: 0 auto !important;
                  }
               }
               &:nth-child(2) {
                  // padding: 0 2px;
               }
            }
         }

         .custom-dynamic-card-body {
            // height: 321px !important;
            .ant-card-body {
               // padding: 1rem !important;
            }
         }

         #select-card {
            .ant-card-body {
               // padding-bottom: 15px !important;
            }
         }

         .dynamic-card-wrapper {
            .dynamic-card-body {
               .empty-message-wrapper {
                  .ant-card-body {
                  }
                  .text-box {
                     .text-bold {
                     }
                  }
               }
               .ant-card-body {
                  .p-top-right {
                     .custom-control-input {
                     }
                  }
                  .ps-wrap {
                     .pr-3 {
                        &:last-child {
                           // padding-right: 0px !important;
                        }
                     }
                  }
                  .not-applicable-icon-wrapper {
                  }
               }
            }
            .dynamic-card-disable-scroll {
            }
            .dynamic-card-disable-scroll::-webkit-scrollbar {
            }

            .dynamic-card-disable-scroll::-webkit-scrollbar-thumb {
            }
         }
         .card-tab-container {
            .ant-tabs-bar {
               border: 0;
               margin-top: 5px;
            }
            .ant-tabs-nav-container {
               .ant-tabs-nav-wrap {
                  .ant-tabs-nav {
                     width: 100%;
                     & > div {
                     }
                  }
                  .ant-tabs-tab {
                  }
                  .ant-tabs-tab-active {
                  }
                  .ant-tabs-ink-bar {
                  }
               }
            }
         }
      }
   }
}

@media only screen and (min-width: 1440px) and (max-width: 1919px) {
   .enhancer-model-portfolio-container {
      margin-bottom: 40px;
      .model-portfolio-item-card {
         .model-portfolio-item-card-wrapper {
            .FRR-Class {
               .__val {
               }
            }
         }
      }
      .portfolio-funds-list-wrap {
         .portfolio-funds-list-head-row {
            .portfolio-funds-list-head-col {
               &:last-child {
               }
            }
         }
         .portfolio-funds-list-data-row {
            .portfolio-funds-list-data-col {
               &:last-child {
               }
            }
         }
      }
      .print-wrapper {
         .siw-meter-title {
            // font-size: 0.6875em !important;
         }
      }
      .enhance-result-page-container {
         .static-card-wrapper {
            .swap-icon-wrapper {
            }
            .static-card-label {
            }
            .static-card-body {
               .ant-card-body {
               }
            }
         }

         .FRR-wrapper {
            .col {
               .FRR-container {
                  img {
                     // margin: 0 auto !important;
                  }
               }
               &:nth-child(2) {
                  // padding: 0 2px;
               }
            }
         }

         .custom-dynamic-card-body {
            // height: 321px !important;
            .ant-card-body {
               // padding: 1rem !important;
            }
         }

         #select-card {
            .ant-card-body {
               // padding-bottom: 15px !important;
            }
         }

         .dynamic-card-wrapper {
            .dynamic-card-body {
               .empty-message-wrapper {
                  .ant-card-body {
                  }
                  .text-box {
                     .text-bold {
                     }
                  }
               }
               .ant-card-body {
                  .p-top-right {
                     .custom-control-input {
                     }
                  }
                  .ps-wrap {
                     .pr-3 {
                        &:last-child {
                           // padding-right: 0px !important;
                        }
                     }
                  }
                  .not-applicable-icon-wrapper {
                  }
               }
            }
            .dynamic-card-disable-scroll {
            }
            .dynamic-card-disable-scroll::-webkit-scrollbar {
            }

            .dynamic-card-disable-scroll::-webkit-scrollbar-thumb {
            }
         }
         .card-tab-container {
            .ant-tabs-bar {
               border: 0;
               margin-top: 5px;
            }
            .ant-tabs-nav-container {
               .ant-tabs-nav-wrap {
                  .ant-tabs-nav {
                     width: 100%;
                     & > div {
                     }
                  }
                  .ant-tabs-tab {
                  }
                  .ant-tabs-tab-active {
                  }
                  .ant-tabs-ink-bar {
                  }
               }
            }
         }
      }
   }
}

.enhancer-model-portfolio-container
   .portfolio-funds-list-wrap
   .portfolio-funds-list-data-body
   .portfolio-funds-list-data-body-scroll {
   height: calc(420px - 165px) !important;
   // height: 250px !important;
   padding: 0 !important;
}

.enhancer-model-portfolio-container {
   .name-sponsor-block {
      .short-name {
         height: 20px;
         line-height: 20px;
         font-family: Roboto;
         font-size: 0.9em !important;
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         letter-spacing: normal;
         text-align: left;
         color: #1d5f91;
      }
      .category-text {
         height: 20px;
         line-height: 20px;
         font-family: Roboto;
         font-size: 0.9em !important;
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         letter-spacing: normal;
         text-align: left;
         color: #5b899e;
      }
   }
   .model-portfolio-item-card {
      // height: 420px;
      height: 436px;
      .notapp-status {
         img {
            display: initial;
         }
      }
      .asset-allocation-bottom-title {
         margin-top: 8px;
         font-family: Roboto;
         font-size: 0.85em;
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         line-height: 1;
         letter-spacing: normal;
         text-align: center;
         color: #6c757d !important;
         @media only screen and (min-width: 1024px) and (max-width: 1280px) {
            font-size: 0.6em !important;
         }
      }
      .simplePieChartWrapper {
         .recharts-wrapper {
            margin: 0 auto;
            .recharts-tooltip-wrapper {
               z-index: 9;
            }
         }
         .highlightTooltip {
            min-width: 34px;
            left: -20px;
            top: 22px;
            z-index: 9999;
         }
         .primaryTextStyle {
            font-weight: bold;
         }
      }
      .model-portfolio-item-card-wrapper {
         height: auto;
         min-height: 108px;
         padding: 0 5px;
         display: flex;
         align-items: center;
         justify-content: space-around;
         border-color: rgba(112, 112, 112, 0.2) !important;
         .profile-card {
            .profile-initial-icon {
               width: 40px;
               height: 40px;
               margin-bottom: 10px;
               background-color: #cdd4db;
               border-radius: 50px;
               line-height: 40px;
               text-align: center;
               text-transform: uppercase;
            }
            .profile-name {
               font-family: Roboto;
               font-size: 0.9em;
               font-weight: normal;
               font-stretch: normal;
               font-style: normal;
               line-height: 1.14;
               letter-spacing: normal;
               text-align: left;
               color: #1d5f91;
            }
         }

         .FRR-Class {
            height: 100%;
            padding-top: 16px !important;
            padding-bottom: 16px !important;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .__state {
               height: 20px;
               margin: 0;
               line-height: 1;
               display: flex;
               align-items: center;
               justify-content: center;
               font-size: 0.6875em;
            }
            .__val {
               margin: 0;
               font-size: 0.6875em;
               font-weight: bold;
            }
         }
      }
   }
   .portfolio-funds-list-wrap {
      .portfolio-funds-list-head-row {
         padding: 15px;
         border-bottom: 1px solid rgba(112, 112, 112, 0.2);
         .portfolio-funds-list-head-col {
            font-family: "Roboto";
            font-size: 1em;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: left;
            color: #1d5f91;
            &:last-child {
               // text-align: right;
            }
         }
      }

      .portfolio-funds-list-data-body {
         height: 100%;
         overflow: hidden;
         .portfolio-funds-list-data-body-scroll {
            margin: 0;
            .portfolio-funds-list-data-row {
               padding: 10px 15px;
               border-bottom: 1px solid rgba(112, 112, 112, 0.2);
               .portfolio-funds-list-data-col {
                  display: flex;
                  align-items: center;
                  font-family: "Open Sans";
                  font-size: 1em;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.33;
                  letter-spacing: normal;
                  text-align: left;
                  color: #4a4a4a;
                  &:last-child {
                     // justify-content: flex-end;
                     padding-left: 4px;
                  }
               }
               &:last-child {
                  border: 0;
               }
            }
         }
      }
   }

   .itemlist-carousel-wrap .slick-slider .slick-arrow {
      display: none !important;
   }

   .print-wrapper {
      width: calc(100% - 0px);
      .siw-meter-title {
         font-size: 0.75em !important;
         line-height: 1;
      }
      .meter-value {
         width: 100%;
         justify-content: space-between;
         .siw-meter-min {
            width: calc(100% / 3);
         }
         .siw-meter-value {
            width: calc(100% / 3);
         }
         .siw-meter-max {
            width: calc(100% / 3);
            text-align: right;
         }
         span {
            font-size: 0.6em;
            width: calc(100% / 3);
         }
      }
   }
   .enhance-result-page-container {
      width: 100%;
      margin-bottom: 10px !important;
      display: flex;
      .static-card-wrapper {
         width: 48%;
         max-width: 48%;
         min-width: 264px;
         margin-right: 0;
         padding: 4px 0 4px 0 !important;
         // .swap-icon-wrapper {
         //   position: absolute;
         // 	top: calc(50% + 10px);
         //   left: calc(34vw + 4px);
         //   font-size: 20px;
         // }
         .static-card-label {
         }
         .static-card-body {
            border: 0;
            background-color: $background-color;
            .ant-card-body {
               padding: 1.5rem;
               padding-left: 0;
               padding-right: 0;
            }
         }
      }

      .swap-icon-col {
         width: 4%;
         padding: 4.8rem 0 1.8rem 0;
         justify-content: center;
         display: flex;
         align-items: center;
         .swap-icon-wrapper {
            font-size: 20px;
         }
      }

      .FRR-wrapper {
         .col {
            .FRR-container {
               img {
                  margin: 0 auto !important;
               }
            }
            &:nth-child(2) {
               padding: 0 2px;
            }
         }
      }

      .custom-dynamic-card-body {
         // height: 321px !important;
         .ant-card-body {
            // padding: 1rem !important;
            padding: 1.5rem 1rem 1.5rem 1rem !important;
         }
      }

      #select-card {
         .ant-card-body {
            padding-bottom: 15px !important;
         }
      }

      .dynamic-card-wrapper {
         width: 48% !important;
         max-width: 48% !important;

         .dynamic-card-body {
            height: auto;
            min-height: 332px;
            .slick-dots li {
               height: 22px;
            }
            .empty-message-wrapper {
               .ant-card-body {
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  padding: 1rem !important;
               }
               .text-box {
                  font-family: Roboto;
                  font-size: 15px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  letter-spacing: -0.19px;
                  text-align: center;
                  .text-bold {
                     font-weight: bold;
                  }
               }
            }
            .ant-card-body {
               padding: 1.5rem 1rem 10px 1rem !important;
               .p-top-right {
                  z-index: 9;
                  .custom-control-input {
                     display: none !important;
                  }
               }
               .ps-wrap {
                  .pr-3 {
                     &:last-child {
                        padding-right: 0px !important;
                     }
                  }
               }
               .not-applicable-icon-wrapper {
                  height: 66px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
               }
            }
         }
         .dynamic-card-disable-scroll {
            position: static;
            overflow-x: scroll;
         }
         .dynamic-card-disable-scroll::-webkit-scrollbar {
            width: 2px !important;
            border-radius: 6px;
            background-color: #dbdbdb;
         }

         .dynamic-card-disable-scroll::-webkit-scrollbar-thumb {
            width: 2px !important;
            border-radius: 6px;
            background-color: blue;
         }

         //.dropdown-container{
         //	display: flex;
         //	justify-content: space-around;
         //	margin-top: 30px;
         //	.custom-replace-selection{
         //		height: 437px;
         //
         //	}
         //	.sponsered,.my-portfolio{
         //		max-height: 35px;
         //		border: 1px solid #1e1e1e;
         //		border-radius: 20px;
         //		padding: 10px 24px;
         //		min-width: 45%;
         //		.buttons-item-label{
         //			display: flex;
         //			align-items: center;
         //			span{
         //				padding-left: 8px;
         //			}
         //		}
         //	}
         //
         //}
      }
      .card-tab-container {
         .ant-tabs-bar {
            border: 0;
            margin-top: 5px;
         }
         .ant-tabs-nav-container {
            .ant-tabs-nav-wrap {
               .ant-tabs-nav {
                  width: 100%;
                  & > div {
                     display: flex;
                     flex-direction: row;
                     justify-content: space-between;
                  }
               }
               .ant-tabs-tab {
                  height: 30px;
                  padding: 2px 0;
                  font-family: Roboto;
                  font-size: 15px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  letter-spacing: -0.19px;
                  text-align: left;
                  color: $disabled-color;
                  border-bottom: 3px solid $disabled-color;
               }
               .ant-tabs-tab-active {
                  font-family: Roboto;
                  font-size: 15px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  letter-spacing: -0.19px;
                  text-align: left;
                  color: $active-color;
                  border-bottom: 3px solid $active-color;
                  cursor: default;
               }
               .ant-tabs-ink-bar {
                  z-index: 0;
                  display: none !important;
                  visibility: none !important;
                  opacity: 0 !important;
                  position: unset !important;
                  background-color: $active-color;
               }
            }
         }
      }
   }
}

//.market-popup-head{
//	//width: max-content;
//	//max-width: 350px;
//	//margin: 0 !important;
//	//padding: 0 !important;
//	//overflow: hidden !important;
//	.market-popup-wrapper {
//		//position: relative;
//		//max-height: 190px !important;
//		//overflow: hidden !important;
//		//overflow-y: auto !important;
//		//font-size: $font-size-14px !important;
//		.market_name-label{
//			//height: 30px;
//			//display: flex;
//			//align-items: center;
//		}
//		//&::-webkit-scrollbar {
//		//	width: 4px !important;
//		//	border-radius: 6px;
//		//	background-color: transparent;
//		//}
//		//&::-webkit-scrollbar-thumb {
//		//	width: 4px !important;
//		//	border-radius: 6px;
//		//	background-color: $in-active-color ;
//		//}
//	}
//
//}

.ant-input-search-icon::before {
   display: none !important;
   border: none !important;
   margin: 0 !important;
}
.ant-input-search-icon {
   margin: 0 !important;
}
.ant-menu-item:hover {
   color: #000000d9 !important;
}

.ant-menu-submenu-arrow::after,
.ant-menu-submenu-arrow::before {
   background: #1e1e1e !important;
}
.build-portfolio-onboard-screen-dropdown {
   ul {
      &:first-child {
         //max-height: 250px;
         //overflow: auto !important;
         //overflow-y: scroll !important;
         //border-radius: 10px !important;
      }
      &::-webkit-scrollbar {
         //width: 4px !important;
         //border-radius: 6px;
         //background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
         //width: 4px !important;
         //border-radius: 6px;
         //background-color: $in-active-color ;
      }
   }
}
