$btn-color: #1e5f91;
$btn-bg-color: #ddeefa;
$text-color: #4a4a4a;

.drawerWrapper {
   padding-right: 16px;
   .buttonStyle {
      font-size: 11px;
      color: $btn-color !important;
      background-color: $btn-bg-color !important;
      border: 0;
      border-radius: 50px;
   }
   .drawerContainer {
      z-index: 1;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      background-color: #ffffff;
      // height: 100vh;
      width: 0% !important;
   }
   .ant-drawer-header {
      font-family: Roboto;
      font-weight: 500;
      letter-spacing: -0.24px;
      text-align: left;
      color: $text-color;
      border: 0;
   }

   .ant-drawer-close {
      font-weight: 800 !important;
      color: black;
   }
   .ant-drawer-content-wrapper {
      // height: calc(100vh - 115px) !important;
      // top: 58px;
      // width: 545px !important;
      width: calc(33vw) !important;
      max-width: 545px !important;
      .performance-impact-loader-wrapper {
         height: 100%;
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: center;
      }
      @media only screen and (min-width: 1024px) and (max-width: 1280px) {
         // padding-bottom: 2rem !important;
         width: calc(38vw) !important;
      }
      .ant-drawer-body {
         position: relative;
         .spinner-wrapper {
            position: absolute;
         }
      }
      .mdrawer-body-wrapper.custom-scroll {
         height: calc(100vh - 260px) !important;
         padding: 5px !important;
         padding-right: 12px !important;
         margin-right: -16px;
         font-size: 14px;
         @media only screen and (min-width: 1024px) and (max-width: 1280px) {
            // padding-bottom: 2rem !important;
         }
         @include respond(tab) {
            // padding-bottom: 2rem !important;
         }
      }
   }

   .fnt-wght-6 {
      font-weight: 600;
   }

   .year-label {
      font-family: Roboto;
      text-align: left;
      color: rgba(0, 0, 0, 0.45);
   }

   .col-blue {
      color: #56a9e8;
      font-size: 14px;
   }
   .col-lght-black {
      color: #1e5f91;
      font-size: 14px;
   }

   .mg-btm-0 {
      margin-bottom: 0.5rem !important;
   }

   hr {
      margin-top: 0;
   }

   .row {
      margin-top: 10px;
      margin-bottom: 10px;
   }
   .row:first-child {
      margin-bottom: 0;
   }

   .sub-head-label {
      font-size: 14px;
      color: #1e5f91;
   }
}
