.settings-container {
   background: #e6e6e6;
   min-height: 100vh;

   .referral-link {
      font-weight: 700 !important;
      font-size: 14px !important;
      color: #55a9e8 !important;
      padding: 0;
      text-decoration: none;
      position: absolute;
      right: 447px;

      &:hover {
         color: #55a9e8;
         text-decoration: none;
      }
   }
}

.settings__header {
   display: flex;
   align-items: center;
   height: 62px;
   border-bottom: 1px solid #cdd4db;
   margin-bottom: 20px;
}

.settings__title {
   font-weight: 700;
   font-size: 24px;
   position: absolute;
   @media (max-width: 990px) {
      position: static;
   }
}

.settings__menu-content-area,
.settings__content-area {
   max-width: 546px;
   margin: 0 auto;
}

.settings__content-wrapper {
   height: 100vh;
}

.settings__menu {
   // :first-child {
   //   padding-left: 0;
   //   padding-right: 0;
   // }
   // :last-child {
   //   padding-left: 0;
   //   padding-right: 0;
   // }

   > .settings__menu-item {
      margin-right: 50px;
      border-bottom: 2px solid transparent;
      padding-bottom: 3px;
   }
}

.settings__menu-item {
   font-weight: 700;
   color: #55a9e8;
   padding: 0;
   text-decoration: none;

   &:hover {
      color: #55a9e8;
      text-decoration: none;
   }

   &:active {
      color: #202529;
      text-decoration: none;
   }

   &.active {
      color: #202529;
      padding-bottom: 3px;
      border-bottom: 2px solid #55a9e8;
   }
}

.app-header {
   display: flex;
   align-items: center;
   height: 50px;
   background: #202529;
}

.app-header__logo {
   height: 30px;
   color: #ffffff;
}

.weights-container {
   //width: 500px;
   margin: 0 auto;
   //border: 1px solid #6c757d;
   border-radius: 5px;
   //box-shadow: 1px 3px 7px #6c757d;
}

.referral-code-container {
   width: 100%;
   padding-bottom: 30px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;

   .header {
      width: 100%;
      padding: 1rem;
      border-bottom: 1px solid #e6e6e6;
   }
   .border-bottom-radius-0 {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
   }
   .border-top-left-radius-0 {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
   }
   .border-bottom-style {
      border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
   }
   .card {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 1rem !important;
      border: 0;
      border-radius: 15px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);

      @include respond(phone) {
         .card {
            padding: 1rem !important;
         }
      }

      p {
         margin: 0;
         font-size: 12px;
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.33;
         letter-spacing: normal;
         text-align: left;
         color: #4a4a4a;
      }
      .sub-referal-text {
         font-weight: bold;
         color: #56a9e8;
      }
      .status-icon {
         font-weight: bold;
      }
      .sub-email-text {
         color: #56a9e8;
         font-weight: bold;
         font-size: 16px;
      }
      .secondary-btn {
         padding: 6px 15px;
         font-size: 9px;
         font-weight: 500;
         line-height: 1.22;
         letter-spacing: 0.2px;
         text-align: center;
         color: #56a9e8 !important;
         border-radius: 30px;
         background: #fff !important;
         border-color: #56a9e8 !important;
         transition: all 0.3s;
         &:hover {
            background: #56a9e8 !important;
            color: #fff !important;
         }
         &:focus {
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
         }
      }
   }
   .invitation-table-wrapper {
   }
   thead {
      tr {
         td {
            font-family: Roboto;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: normal;
            text-align: left;
            color: #4a4a4a;
            &:first-child {
               width: 92%;
            }
            &:last-child {
               width: 8%;
               text-align: center;
            }
         }
      }
   }

   tbody {
      tr {
         td {
            padding: 5px 0;
            font-size: 12px;
            font-weight: normal;
            line-height: 2;
            letter-spacing: normal;
            text-align: left;
            color: #979797;
            &:last-child {
               text-align: center;
            }
         }
         &:last-child {
            td {
               // padding-bottom: 1rem !important;
            }
         }
      }
   }

   .referral-code-input {
      width: 70%;
      min-width: max-content;
      max-width: unset;
      border: 1px dotted #e6e6e6;
      padding: 1.5rem;
      border-radius: 5px;
      pointer-events: none;
   }
}

.failed-referral-message-block {
   width: 100%;
   padding: 3rem;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   background: #fff;
   i {
      font-size: 3rem;
      margin-bottom: 1rem;
      color: #55a9e8;
   }
}

@include respond(phone) {
   // .settings__title {
   //   font-weight: 700;
   //   font-size: 16px !important;
   //   // position: absolute;
   //   // @media (max-width: 990px) {
   //   //   position: static;
   //   // }
   // }

   .referral-code-container {
      .card {
         border: 0px !important;
         border-radius: 15px !important;
      }
   }
   .setting-wrapper {
      .settings__menu-item {
         font-size: 12px;
      }
   }

   .settings-container {
      .referral-link {
         position: absolute;
         top: 17px !important;
         right: 65px !important;
      }
   }
}

@include respond(tab) {
   .settings-container {
      .referral-link {
         position: absolute;
         right: 80px;
         top: 15px;
      }
   }
}

@media only screen and (width: 1024px) {
   .settings-container {
      .referral-link {
         position: relative;
         left: 328px;
      }
   }
}

@media only screen and (max-width: 1195px) and (min-width: 992px) {
   .settings-container {
      .referral-link {
         position: relative;
         right: -261px;
         top: 10px !important;
      }
   }
}

@media only screen and (width: 1196px) {
   .settings-container {
      .referral-link {
         position: relative;
         left: 328px;
      }
   }
}

@media only screen and (max-width: 1926px) and (min-width: 1201px) {
   .settings-container {
      .referral-link {
         position: relative;
         left: 402px;
      }
      .referral-link-account {
         position: relative;
         left: 420px;
      }
   }
}

// @media only screen and (max-width: 1200px) and (min-width: 1198px) {
//   .settings-container {
//     .referral-link {
//       top:13px !important;
//       //left: 922px !important;
//     }
//   }
// }

@media only screen and (max-width: 1281px) and (min-width: 999px) {
   .settings-container {
      .referral-link {
         top: 0px !important;
      }
   }
}

.footerContainer {
   padding: 10px 50px !important;
   // background: $background !important;
   box-shadow: 0px 0 10px #c7c0c0;
   position: fixed;
   bottom: 0px;
   width: 100%;
   text-align: center;
   font-family: "Open Sans";
   font-size: 12px !important;
   img {
      padding-right: 10px;
      width: 30px;
   }
}

.settings__body {
   @media (max-width: 991px) {
      padding-top: 80px;
   }
}
