.folder-container {
   width: 100%;
   .folder-tabs-wrapper {
      width: 100%;
      .folder-tabs-header-wrap {
         width: 100%;
         height: 100%;
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: space-around;
         .text-label {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-family: $secondary-font;
            font-size: $font-size-18px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.17;
            letter-spacing: normal;
            text-align: left;
            color: $label-black-color;
            .label {
            }
            .slider-value {
            }
         }
         .selected-funds-text {
            width: 100%;
            font-family: $secondary-font;
            font-size: $font-size-16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.19;
            letter-spacing: normal;
            text-align: left;
            color: $other-grey-color;
         }
      }
      .ant-tabs-bar {
         margin: 0;
         .ant-tabs-nav-container {
            // height: 105px !important;
            height: 85px !important ;
         }
         .ant-tabs-nav {
            width: 100%;
            .ant-tabs-tab {
               width: calc((100% - 12px) / 3);
               margin-right: 5px;
               height: 85px;
               background: $other-bg-color;
               border-bottom: 0;
               border-radius: 3px;
               padding: 15px;
               &:last-child {
                  margin-right: 0 !important;
               }
            }
            .ant-tabs-tab-active {
               height: 85px;
               background: $dark-blue-color;
               border-color: transparent;
               .folder-tabs-header-wrap {
                  .text-label {
                     color: #fff;
                  }
                  .selected-funds-text {
                     color: #fff;
                  }
               }
            }
         }
      }
      .ant-tabs-content {
         margin-top: 15px;
         background: $bg-color-light;
      }
   }
}

.equity-section.custom-scroll {
   width: 100%;
   height: calc(98vh - 254px) !important;
   padding-top: 0 !important;
   padding-bottom: 0 !important;
   padding-left: 0px !important;
   padding-right: 6px !important;
   margin-right: 0px !important;
}
.bonds-section.custom-scroll {
   width: 100%;
   height: calc(98vh - 254px) !important;
   padding-top: 0 !important;
   padding-bottom: 0 !important;
   padding-left: 0px !important;
   padding-right: 6px !important;
   margin-right: 0px !important;
}
.thematic-section.custom-scroll {
   width: 100%;
   height: calc(98vh - 254px) !important;
   padding-top: 0 !important;
   padding-bottom: 0 !important;
   padding-left: 0px !important;
   padding-right: 6px !important;
   margin-right: 0px !important;
}

.folder-content-container {
   width: 100%;
   margin: 0 !important;
   padding: 15px;
   background: #fff;
   border: 1px solid #eceef1;

   .text-transform-cap {
      text-transform: capitalize !important;
      input {
         text-transform: capitalize !important;
      }
   }

   .query-search-box {
      position: relative;
      width: 60%;
      min-width: 320px;
      max-width: 470px;
      margin: 5px 0px 20px;

      .search-input-field {
         // margin: 0 15px;
         line-height: 34px;
         max-height: 43px;
         border-color: #d3d3d3;
         .ant-input {
            // border: solid 1px $indicator-color;
            background-color: #ffffff;
            // border-radius: 50px !important;
            box-shadow: none;
            &::placeholder {
               color: #6c757d !important;
            }
            &:focus {
               overflow: hidden !important;
               text-overflow: ellipsis !important;
            }
            &::placeholder {
               overflow: hidden !important;
               text-overflow: ellipsis !important;
            }
         }
         .ant-input-suffix {
            align-items: center;
            display: flex;
            justify-content: space-evenly;
            .ant-input-search-icon {
               font-size: $font-size-18px;
            }
         }
         .ant-select-selection {
            border-radius: 50px;
            .ant-select-selection__choice {
               display: none !important;
            }
            .ant-select-search__field {
               width: 100% !important;
            }
         }
      }
      .ant-input-affix-wrapper:focus,
      .ant-input-affix-wrapper-focused {
         box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.4) !important;
      }
      .search-icon {
         opacity: 0.2;
         color: #6c757d;
      }

      .clear-icon {
         height: 100%;
         position: absolute;
         top: 0;
         bottom: 0;
         right: 48px;
         opacity: 0.2;
         align-items: center;
         vertical-align: middle;
         line-height: 32px;
         line-height: 42px;
         color: #6c757d;
         z-index: 1;
      }
      .active-icon {
         opacity: 1;
         color: #6c757d;
         cursor: pointer;
         z-index: 1;
      }
   }

   .folder-content-card-wrapper {
      width: 100%;
      background: #fff;
      border: 3px solid $other-bg-color;
      .ant-card-head {
         padding: 0 15px;
         border-bottom: 3px solid $other-bg-color;
      }
      .ant-card-body {
         padding: 0px 0px 0px 0px;
         .custom-scroll {
            height: calc(100vh - 342px) !important;
            min-height: 370px;
            padding-top: 10px !important;
            padding-bottom: 5px !important;
            padding-left: 15px !important;
            padding-right: 7px !important;
            margin-right: 8px !important;
         }
      }
      .right-head-wrap {
         .label {
            width: max-content;
            margin-left: auto;
            font-family: $secondary-font;
            font-size: $font-size-14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: right;
            color: $primary-color;
            cursor: pointer;
         }
      }

      .folder-content-accordion {
         margin-top: 1px;
         border: 0;
         background: transparent;
         .ant-collapse-item {
            background: #fafafa;
            border: 0;
            border-bottom: 3px solid $other-bg-color;
            .ant-collapse-header {
               width: 100%;
               display: flex;
               flex-direction: row;
               align-items: flex-start;
               justify-content: flex-start;
               flex-wrap: wrap;
               padding: 15px 50px 0px 0px;
               .caret-icon {
                  font-size: $font-size-30px;
                  color: $other-dark-grey-color;
               }
               .accordin-head-wrap {
                  width: 100%;
                  display: flex;
                  flex-direction: row !important;
                  align-items: flex-start;
                  justify-content: flex-start;
                  flex-wrap: wrap;

                  .tag-list-area {
                     .pending-text,
                     .disabled-text {
                        padding-left: 15px !important;
                     }
                  }
                  .title {
                     display: flex;
                     justify-content: space-between;
                     min-width: 250px;
                     margin-right: 15px;
                     padding: 13px 15px 13px 15px;
                     font-family: $secondary-font;
                     font-size: $font-size-18px;
                     font-weight: 500;
                     font-stretch: normal;
                     font-style: normal;
                     line-height: 1.17;
                     letter-spacing: normal;
                     text-align: left;
                     color: $label-black-color;
                     margin-bottom: 15px;
                     background-color: #1d6091;
                     color: #fff;
                     border-color: $indicator-color;
                     background-color: $dark-blue-color;
                     border-radius: 4px;

                     .slider-value {
                        // margin: 0 0 0 60px;
                     }
                  }
                  .query-search-box {
                     position: relative;
                     width: 45%;
                     min-width: 320px;
                     max-width: 470px;
                     margin: 0 15px 10px 0;

                     .search-input-field {
                        // padding: 10px 60px 10px 13px !important;
                        line-height: 34px;
                        max-height: 43px;
                        .ant-input {
                           background-color: #ffffff;
                           box-shadow: none;
                        }
                     }
                     .clear-icon {
                        line-height: 43px !important;
                     }
                  }
               }
            }
            .ant-collapse-content {
               border: 0;
               .ant-collapse-content-box {
                  // padding: 0px 15px 8px 15px;
                  // padding: 30px 15px 30px 15px;
                  padding: 15px;
               }
            }
            &:last-child {
               border: 0;
            }
         }
         .ant-collapse-item-active {
            background: transparent;
            .ant-collapse-header {
               .accordin-head-wrap {
                  flex-direction: row !important;
                  align-items: flex-start;
                  justify-content: flex-start;
                  flex-wrap: wrap;
                  .title {
                     margin-bottom: 15px;
                     background-color: #1d6091;
                     color: #fff;
                     border-color: $indicator-color;
                     background-color: $dark-blue-color;
                     border-radius: 4px;
                  }
               }
            }
            .caret-icon {
               transform: rotate(180deg);
            }
         }
      }

      .folder-content-card-title {
         margin: 0;
         font-family: $secondary-font;
         font-size: $font-size-20px;
         font-weight: 500;
         font-stretch: normal;
         font-style: normal;
         letter-spacing: normal;
         text-align: left;
         color: $dark-blue-color;
      }
      .ant-card-extra {
         padding: 0;
      }

      .folder-content-card-menu {
         background: transparent;
         border: 0 !important;
         .default-menu {
            color: $label-black-color-2 !important;
            border: 0px !important;
            cursor: default;
         }
         .disabled-menu {
            border: solid 1px $indicator-color !important;
         }
         .ant-menu-item {
            font-family: $secondary-font;
            font-size: $font-size-14px !important;
            text-align: left;
            color: $primary-color;
         }
      }
      .ant-menu-horizontal {
         line-height: normal;
         border: 0px !important;
         .ant-menu-item {
            width: 100px;
            padding: 10px 0;
            margin-right: 10px;
            line-height: normal;
            text-align: center;
            border: solid 1px $indicator-color;
            border-radius: 4px;
            &:hover {
               color: $primary-color;
            }
         }
         .ant-menu-submenu {
            border: 0px !important;
         }
         .ant-menu-item-selected {
            font-weight: 500 !important;
            color: #fff !important;
            text-align: center !important;
            border-color: $dark-blue-color;
            background-color: $dark-blue-color;
         }
         .ant-menu-submenu-title:hover {
            color: $indicator-color;
         }
         .ant-menu-submenu-selected {
            color: $primary-color !important;
            border: 0px !important;
         }
      }
      .anticon {
         vertical-align: 0em;
      }
   }

   .tag-list-area {
      display: flex;
      align-items: flex-start;

      .tag-wrapper {
         display: flex;
         flex-wrap: wrap;
         padding: 0 10px;

         .tag {
            position: relative;
            margin-right: 10px;
            margin-bottom: 10px;
            padding: 13px 30px 13px 15px;
            font-family: $secondary-font;
            font-size: $font-size-16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.19;
            letter-spacing: normal;
            text-align: left;
            color: #5cace8;
            border: solid 1px $indicator-color;
            background-color: #ffffff;
            border-radius: 50px;

            i {
               position: absolute;
               top: 16px;
               right: 10px;
               font-size: $font-size-14px;
               cursor: pointer;
            }
         }
      }
   }

   .thematic-tab-content-container {
      width: 100%;
      .ant-card-body {
         padding: 15px 0 0 0;
      }
      .choose-theme-btn {
         height: auto;
         margin-bottom: 10px;
         padding: 14px;
         font-family: $secondary-font;
         font-size: $font-size-14px;
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.14;
         letter-spacing: normal;
         text-align: left;
         color: #ffffff !important;
         transition: all 0.3s;
         border: solid 1px $dark-blue-color;
         border-radius: 50px;
         background-color: $dark-blue-color !important;
      }
      .active {
         color: #ffffff;
         background-color: $dark-blue-color;
         .anticon {
         }
      }

      .thematic-controls-wrap {
         display: flex;
         // align-items: center;
         justify-content: space-between;
         padding: 0 15px;
         margin-bottom: 5px;
      }

      .thematic-search-body-wrap {
         height: 100% !important;
         // padding: 0 15px;

         .query-search-box {
            margin-left: 15px;
            .search-input-field {
               max-height: 43px;
            }
         }

         .thematic-dropdown-container {
            width: 100% !important;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            margin-top: 20px;
            background: #fff;
            box-shadow: none !important;

            .grid-theme-container {
               width: 100% !important;
            }
            ul {
               max-height: 300px !important;
               // min-height: 400px;
               // max-height: 400px;
               display: flex;
               flex-wrap: wrap;
               align-items: center;
               justify-content: space-between;
               background: $bg-color-light;
               // margin-right: -14px !important;
               // #56a9e8
               li {
                  padding: 0px !important;
                  margin: 5px 0;
                  // padding: 5px 22px 5px 5px !important;
               }
               .ant-select-dropdown-menu-item-selected {
                  color: $other-active-color;
               }
            }
         }

         .themes-item-block {
            // width: 120px;
            width: 92px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .img-holder {
               width: 90px;
               height: 90px;
               border-radius: 7px;
               margin-bottom: 10px;
               overflow: hidden;
               transition: all 0.3s;
               img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
               }
            }
            label {
               width: 90px;
               height: 40px;
               margin: 0;
               font-family: $secondary-font;
               font-size: $font-size-16px;
               font-weight: normal;
               font-stretch: normal;
               font-style: normal;
               line-height: 1.19;
               letter-spacing: normal;
               text-align: center;
               word-break: break-word;
               color: $label-black-color;
            }
         }

         .no-result-found-wapper {
            .no-result-found-message {
               margin-left: 15px;
               font-family: $primary-font;
               font-size: $font-size-16px;
               font-weight: normal;
               font-stretch: normal;
               font-style: normal;
               line-height: 1.88;
               letter-spacing: normal;
               text-align: left;
               color: $label-black-color;
            }
         }

         .theme-item-checkbox {
            margin: 0 17px;
            .ant-checkbox {
               display: none;
            }
         }

         .ant-checkbox-wrapper-checked {
            .themes-item-block {
               .img-holder {
                  border: 2px solid #56a9e8;
               }
               label {
                  color: $other-active-color;
               }
            }
         }

         .custom-scroll {
            // height: calc(100vh - 510px) !important;
            height: calc(88vh - 464px) !important;
            min-height: 230px !important;
            padding: 0 !important;
            margin-right: 6px !important;
            margin-bottom: 15px;
         }
      }
   }
}

.act-content-container {
   // height: calc(98vh - 224px) !important;
   height: 100%;

   .folder-content-card-wrapper {
      .ant-card-head {
         .ant-card-head-title {
            width: 100% !important;
            max-width: 100% !important;
         }
      }
      .ant-card-body {
         .custom-scroll {
         }
      }
      .right-head-wrap {
         .label {
         }
      }

      .folder-content-card-title {
         position: relative;
         text-align: center;
         color: $label-black-color;
         .close-icon {
            position: absolute;
            right: 0;
            top: 2px;
            font-size: $font-size-26px;
            color: $other-grey-color-2;
            cursor: pointer;
         }
      }
      .ant-card-extra {
      }

      .folder-content-card-menu {
         .default-menu {
         }
         .ant-menu-item {
         }
      }
      .anticon {
      }
      .box-wrapper {
         border-bottom: 1px solid #efefef;
         .box {
            width: 100%;
            height: calc((100vh - 340px) / 2);
            min-height: 226px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: all 0.3s;
            border-right: 2px solid #efefef;
            button {
               width: 100%;
               height: 100%;
               border: 0;
               .tabs-icn {
                  margin-bottom: 5px;
               }
               .title {
                  font-family: $secondary-font;
                  font-size: 25px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.16;
                  letter-spacing: normal;
                  text-align: center;
                  color: $dark-blue-color;
               }
               .sub-title {
                  margin: 5px 0;
                  font-family: $secondary-font;
                  font-size: $font-size-18px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.16;
                  letter-spacing: normal;
                  text-align: center;
                  color: #4a4a4a;
                  white-space: initial;
               }
               .coming-soon {
                  color: $other-grey-color-2;
               }
               &:focus {
                  background: $primary-color-opacity-2 !important ;
               }
               &:hover {
                  background: $primary-color-opacity-05;
               }
            }
            &:last-child {
               border: 0;
            }
         }
         .disabled {
            cursor: not-allowed;
            button:hover {
               background: $tag-grey-bg-color;
            }
         }
         &:last-child {
            border: 0;
         }
      }
   }
}

.funds-list-view-container {
   width: 100%;
   // min-height: 209px;
   padding-bottom: 24px;
   transition: all 0.3s;
   .fund-card-container {
      width: 240px;
      //height: 185px;
      border: solid 1px $border-color-2;
      background-color: #f7f7f7;

      .delete-fund-card {
         position: absolute;
         right: -19px;
         top: -1px;
         background: #fff;
         line-height: 1;
         padding: 3px 5px;
         cursor: pointer;
         font-size: 12px;
         color: $sub-header-color;
         border: solid 1px $border-color-2;
         transition: all 0.3s;
         &:hover {
            color: $red-color;
            background: $red-color-opacity;
            border: solid 1px transparent;
         }
      }
      .ant-card-body {
         padding: 0 !important;
         .name-block-wrapper {
            height: 114px;
            border-bottom: solid 1px $border-color-2;
            .first-block {
               display: flex;
               flex-direction: column;
               justify-content: flex-start;
               align-items: flex-start;
               height: 100%;
               height: 114px;
            }
            .vertical-ticker-price-wrapper {
               height: 100%;
               display: flex;
               padding: 4px 0;
               justify-content: flex-start;
               align-items: center;
               .ant-divider {
                  height: 100%;
                  background: $label-black-color;
               }
            }
            .ticker-price {
               line-height: 1;
               font-size: $font-size-11px !important;
               color: $label-black-color !important;
            }
            .name-wrapper {
               width: 100%;
               height: 100%;
               display: flex;
               flex-direction: column;
               justify-content: space-evenly;
               > .font-details {
                  width: 100%;
                  font-size: $font-size-15px !important;
                  line-height: normal;
               }
               .funds-name {
                  padding-bottom: 2px;
               }
               .funds-category {
               }
            }
            .chart-graph-wrapper {
               display: flex;
               align-items: center;
               flex-direction: column;
               align-items: flex-end;
               justify-content: space-around;

               .action-box {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-end;
                  font-family: $secondary-font;
                  font-size: 8px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.13;
                  letter-spacing: normal;
                  text-align: left;
                  color: $label-black-color;
                  .text {
                     margin: 0 0 7px 0;
                     i {
                        cursor: pointer;
                        color: $dark-blue-color;
                     }
                  }
                  .ant-checkbox-wrapper {
                     margin: 7px 0;
                     .ant-checkbox:hover {
                        .ant-checkbox-inner {
                           border-color: $orange-color !important;
                        }
                     }
                     .ant-checkbox-input:focus + .ant-checkbox-inner {
                        border-color: $orange-color !important;
                     }
                     .ant-checkbox-checked {
                        &:after {
                           border-color: $orange-color !important;
                        }
                        .ant-checkbox-inner {
                           background-color: $orange-color !important;
                           border-color: $orange-color !important;
                           &:after {
                              border-color: #fff !important;
                           }
                        }
                     }
                  }
               }
               // .siw-meter {
               //   .siw-meter-title {
               //     font-size: 8px !important;
               //   }
               //   .recharts-wrapper {
               //     width: 68px !important;
               //     height: 50px !important;
               //   }
               //   .meter-value {
               //     width: 100% !important;
               //     .siw-meter-min {
               //       font-size: 8px !important;
               //     }
               //     .siw-meter-value {
               //       font-size: 8px !important;
               //     }
               //     .siw-meter-max {
               //       font-size: 8px !important;
               //     }
               //   }
               // }
               .progress-chrt-box {
                  .progress-chart-value {
                  }
               }
            }
         }
         .fees-risk-return-stats-wrapper {
            justify-content: space-evenly;
            .__state {
               margin: 0;
               font-family: $primary-font;
               font-size: 11px;
               font-weight: normal;
               font-stretch: normal;
               font-style: normal;
               line-height: 1.4;
               letter-spacing: normal;
               text-align: center;
            }
            .fassf {
               width: auto;
               height: 25px;
               line-height: 29px;
            }
            .__val {
               margin: 0;
               font-family: $primary-font;
               font-size: 11px;
               font-weight: 600;
               font-stretch: normal;
               font-style: normal;
               letter-spacing: normal;
               text-align: center;
            }
         }
      }
   }
   .show {
      display: inherit !important;
      visibility: visible !important;
   }
   .hide {
      display: none !important;
      visibility: visible !important;
   }
   .custom-ticker-block {
      .ant-card-body {
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: center;
         padding: 0 !important;
         img {
            margin-bottom: 10px;
         }
         .select-box {
            width: 150px;
            .ant-select-selection {
               box-shadow: none;
               border: 0;
               border-bottom: 1px solid rgb(108, 117, 125);
               border-radius: 0;
               .ant-select-selection__rendered {
                  margin: 0;
               }
               .ant-select-search__field {
                  padding: 0;
               }
               .ant-select-arrow {
                  .anticon {
                     font-size: $font-size-16px;
                  }
                  .ant-select-arrow-icon svg {
                     transform: rotate(0deg);
                  }
               }
            }
         }
      }
   }
   .slick-slider {
      .slick-list {
         .slick-track {
            .slick-active {
               .fund-card-container {
                  margin: 0 auto;
               }
            }
         }
      }
      .slick-dots {
         text-align: center;
         li {
            button {
               &:before {
                  font-size: 10px;
                  line-height: 18px !important;
                  color: $primary-color;
               }
            }
         }
         .slick-active {
            button {
               &:before {
                  line-height: 18px !important;
                  color: $primary-color-opacity;
               }
            }
         }
      }
   }
}

.thematic-radio-list-design-container {
   .thematic-radio-funds-design-container {
      width: 100%;
      min-height: 302px;
      margin-top: 1px;
      border: 0;
      border: 0;
      // border-bottom: 2px solid $other-bg-color;

      .thematic-radio-funds-design-head-container {
         width: 100%;
         padding: 0px;
         display: flex;
         flex-direction: row;
         align-items: flex-start;
         justify-content: flex-start;
         flex-wrap: wrap;
      }
      .head-wrap {
         width: 100%;
         display: flex;
         flex-direction: row !important;
         align-items: flex-start;
         justify-content: flex-start;
         flex-wrap: wrap;

         .tag-list-area {
            .pending-text,
            .disabled-text {
               padding-left: 15px !important;
            }
         }
         .title {
            display: flex;
            justify-content: space-between;
            min-width: 250px;
            margin-right: 15px;
            padding: 13px 15px 13px 15px;
            font-family: $secondary-font;
            font-size: $font-size-18px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.17;
            letter-spacing: normal;
            text-align: left;
            color: $label-black-color;
            margin-bottom: 15px;
            background-color: #1d6091;
            color: #fff;
            border-color: $indicator-color;
            background-color: $dark-blue-color;
            border-radius: 4px;

            .slider-value {
               // margin: 0 0 0 60px;
            }
         }
         .query-search-box {
            position: relative;
            width: 45%;
            min-width: 320px;
            max-width: 470px;
            margin: 0 15px 10px 0;

            .search-input-field {
               // padding: 10px 60px 10px 13px !important;
               line-height: 34px;
               max-height: 43px;
               .ant-input {
                  background-color: #ffffff;
                  box-shadow: none;
               }
            }
            .clear-icon {
               line-height: 43px !important;
            }
         }
      }
      .TRFD-content-container {
         width: 100%;
         display: inherit;
         padding: 15px;
      }
   }
   .thematic-radio-button-design-container {
      width: 100%;
      border-top: 3px solid $other-bg-color;
      .TRFD-button-list-container {
         width: 100% !important;
         display: block;
         margin: 20px auto;
         background: #fff;
         box-shadow: none !important;

         .label {
            padding: 15px;
            font-family: $secondary-font;
            font-size: $font-size-18px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.17;
            letter-spacing: normal;
            text-align: left;
            color: #1d6191;
         }
         .ant-radio-group {
            width: 100%;
            display: inherit;
            padding-top: 25px;
            padding-bottom: 30px;
            height: auto;
         }
         .theme-item-radio {
            margin: 0;
            span.ant-radio + * {
               width: 100%;
               display: flex;
            }
            .ant-radio {
               display: none;
            }
            .themes-item-block {
               width: 90px;
               margin: 0 auto;
               .img-holder {
                  width: 90px;
                  height: 90px;
                  border-radius: 7px;
                  margin-bottom: 10px;
                  overflow: hidden;
                  transition: all 0.3s;
                  img {
                     width: 100%;
                     height: 100%;
                     object-fit: cover;
                  }
               }
               label {
                  width: 90px;
                  height: 40px;
                  margin: 0;
                  font-family: $secondary-font;
                  font-size: $font-size-16px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.19;
                  letter-spacing: normal;
                  text-align: center;
                  word-break: break-word;
                  white-space: pre-wrap;
                  color: $label-black-color;
               }
            }
         }
         .ant-radio-wrapper-checked {
            .themes-item-block {
               .img-holder {
                  border: 2px solid #56a9e8;
               }
               label {
                  color: $other-active-color;
               }
            }
         }
      }
   }
   .slick-slider {
      .slick-list {
         .slick-track {
            .slick-active {
               .fund-card-container {
                  margin: 0 auto;
               }
            }
         }
      }
      .slick-dots {
         text-align: center;
         li {
            button {
               &:before {
                  font-size: 10px;
                  line-height: 18px !important;
                  color: $primary-color;
               }
            }
         }
         .slick-active {
            button {
               &:before {
                  line-height: 18px !important;
                  color: $primary-color-opacity;
               }
            }
         }
      }
   }
}

.no-results-message {
   p {
      margin: 0;
      font-family: $secondary-font;
      font-size: $font-size-16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.19px;
      text-align: center;
      span {
         font-weight: bold;
      }
   }
}

.pending-text {
   min-width: max-content;
   margin-bottom: 10px;
   padding: 14px 0;
   font-family: $secondary-font;
   font-size: $font-size-16px;
   font-weight: normal;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.19;
   letter-spacing: normal;
   color: $other-red-color;
}
.disabled-text {
   min-width: max-content;
   margin-bottom: 10px;
   padding: 14px 0;
   font-family: $secondary-font;
   font-size: $font-size-16px;
   font-weight: normal;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.19;
   letter-spacing: normal;
   color: $disabled-text-color;
}

.selected-funds-tag {
   position: relative;
   margin-right: 10px;
   margin-bottom: 10px;
   padding: 13px 30px 13px 15px;
   font-family: $secondary-font;
   font-size: $font-size-16px;
   font-weight: normal;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.19;
   letter-spacing: normal;
   text-align: left;
   color: $dark-blue-color;
   border: solid 1px $indicator-color;
   background-color: #ffffff;
   border-radius: 50px;

   i {
      position: absolute;
      top: 16px;
      right: 10px;
      color: $dark-blue-color;
      font-size: $font-size-14px;
      cursor: pointer;
   }
}

.enhancer-fund-card {
   position: relative;
   display: flex;
   flex-direction: column;
   word-wrap: break-word;
   background-color: #fff;
   background-clip: border-box;
   border: 1px solid rgba(0, 0, 0, 0.125) !important;
   border-radius: 0.25rem;
}
.fund-card-container * .ant-progress-circle-trail {
   stroke: #dadada !important;
}
