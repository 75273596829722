/******* layout style import ***********/
@import "./WebDashboard/index";

@-webkit-keyframes autofill {
   to {
      color: #fff;
      background: transparent;
   }
}

.suggestion_box {
   position: absolute;
   background: #fff;
   border: 2px solid #56a9e8;
   border-radius: 5px;
   max-height: 40vh;
   z-index: 2;
   left: 0;
   right: 0;
   text-align: left;
   overflow: auto;
   &::-webkit-scrollbar {
      width: 6px !important;
      border-radius: 6px;
      background-color: transparent;
   }

   &::-webkit-scrollbar-thumb {
      width: 6px !important;
      border-radius: 6px;
      background-color: #dbdbdb !important;
   }
   .suggestion_container {
      width: 100%;
      // padding: 0 10px;
      .list_item {
         width: 100%;
         padding: 10px 0;
         .heading {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 5px;
            color: #56a9e8;
            padding: 0 20px;
            // &:hover{
            //   cursor: pointer;
            // }
         }
         .child-item {
            padding: 0 20px;
            font-size: 15px;
            margin-bottom: 2px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: gray;
            b {
               color: #585858;
               font-weight: bold;
            }
            &:hover,
            &:focus {
               cursor: pointer;
               background: #f0f6fb;
            }
         }
      }
      .separator {
         width: 100%;
         background: #c6dff3;
         height: 1px;
      }
   }
   &:hover {
      cursor: initial;
      &::-webkit-scrollbar-thumb {
         background-color: #dbdbdb !important;
      }
   }
}

.dropdown-element {
   .dropdown-wrapper {
      .show {
         display: block !important;
         border-radius: 6px !important;
      }
      .dropdown-toggle {
         width: 100%;
         min-height: 34px;
         text-align: left;
      }
      .dropdown-toggle::after {
         position: absolute;
         right: 0;
      }
   }
}

.toggle-switch-wrapper {
   display: flex;
   flex-direction: row;
   align-items: center;

   .form-check {
      padding-left: 0;
   }

   .toggle-content {
      //padding-left: 10px;
   }

   .toggle-switch {
      position: relative;
      display: inline-block;
      width: 30px;
      height: 12px;
   }

   .toggle-switch .toggle-switch-input {
      opacity: 0;
      width: 0;
      height: 0;
   }

   .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #494949;
      -webkit-transition: 0.4s;
      transition: 0.4s;
   }

   .slider:before {
      position: absolute;
      content: "";
      height: 18px;
      width: 18px;
      left: 0px;
      bottom: -3px;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
   }

   .toggle-switch-input:checked + .slider {
      background-color: #494949;
   }

   .toggle-switch-input:focus + .slider {
      box-shadow: 0 0 1px #56a9e8;
   }

   .toggle-switch-input:checked + .slider:before {
      background: #56a9e8 !important;
      -webkit-transform: translateX(14px);
      -ms-transform: translateX(14px);
      transform: translateX(14px);
   }

   /* Rounded sliders */
   .slider.round {
      border-radius: 34px;
   }

   .slider.round:before {
      border-radius: 50%;
   }
}

@include respond(phone) {
   .magnifi-auth-page-wrapper {
      // height: auto !important;
   }
}

.magnifi-auth-page-wrapper {
   width: 100%;
   height: 100vh;
   margin: 0;
   background: #fff;

   @media (max-width: 767px) {
      height: auto;
   }

   .contact-info p {
      text-align: center;
      margin-bottom: 5px;
   }

   .left-side-panel-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #56a9e8;

      font-size: 1rem;
      font-weight: 400;
      line-height: 1;

      .contact-info a {
         color: white;
      }

      .contact-info a:hover {
         color: rgb(210, 236, 255);
      }

      .logo-wrapper {
         text-align: center;
         width: 50%;
         img {
            width: 78%;
            height: auto;
         }
         @include respond(phone) {
            img {
               width: auto;
               height: 5.8rem;
            }
         }
      }
      @include respond(phone) {
         .logo-wrapper {
            width: 100%;
            padding: 10px 0;
         }
      }
   }
   .left-side-panel-container-login {
      //padding-top: 165px !important;
      display: flex;
      align-items: center !important;
      justify-content: center;
      background-color: #56a9e8;

      .logo-wrapper {
         text-align: center;
         width: 50%;
         img {
            width: 78%;
            height: auto;
            // width: auto;
            // height: 70px;
         }
         @include respond(phone) {
            img {
               width: auto;
               height: 5.8rem;
            }
         }
      }
      @include respond(phone) {
         .logo-wrapper {
            width: 100%;
            padding: 10px 0;
         }
      }
   }
   .right-side-panel-container {
      // height: 100vh;
      position: relative;
      // height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      background: white;
      overflow-y: auto;
      @media (max-width: 767px) {
         min-height: 100vh;
         height: auto !important;
      }
      // @media(max-width:375px){

      // }
      .back-to-page {
         position: absolute;
         top: 2rem;
         left: 2rem;
         font-size: 1.5rem;
         color: #979797;
      }

      .close-icon {
         position: absolute;
         top: 1rem;
         right: 1rem;
         opacity: 0.6;
         font-size: 1.5rem;
         color: #56a9e8;
         line-height: 1;
         cursor: pointer;
         @include respond(phone) {
            top: 0px;
         }
      }

      .thank-you-label {
         font-weight: bold;
         font-size: 20px;
      }

      .check-cirlce {
         color: #56a9e8;
         font-size: 70px;
      }

      .token-label {
         color: #56a9e8;
         font-weight: bold;
         font-size: 32px;
      }

      .user-label {
         color: #56a9e8;
         font-weight: bold;
         font-size: 20px;
      }

      .forgot-pwd-label {
         color: #56a9e8;
         font-size: 32px;
         font-weight: 600;
      }

      .forgot-email-instr {
         color: #979797;
         font-weight: 700;
         font-size: 16px;
      }

      .invalid-feedback {
         // position: absolute;
         bottom: 12px;
         color: #a5685f !important;
      }

      .label {
         margin: 0;
         font-size: 0.8rem;
         color: #4a4a4a;
      }
      .input-area {
         height: auto;
         padding: 7px 0;
         font-size: 0.8rem;
         color: #4a4a4a;
         border: 0;
         border-radius: 0;
         border-bottom: 1px solid;
         border-bottom-color: #4a4a4a;
         transition: 0.2s ease all;
         &::placeholder {
            color: #dbdbdb;
         }
         &:focus,
         &:hover {
            border-bottom-color: #56a9e8;
            box-shadow: none !important;
         }
      }
      .hyper-link {
         // position: absolute;
         // bottom: 8px;
         // right: 0;
      }
      .invalid-feedback {
         font-size: 0.8rem;
      }
      .form-control:invalid,
      .form-control.is-invalid {
         background-image: none !important;
      }
   }
}

.magnifi-auth-design-container {
   width: 95%;
   height: auto;
   min-width: 320px;
   max-width: 400px;
   padding: 20px 40px;
   overflow-y: auto;
   overflow-x: hidden;
   &::-webkit-scrollbar {
      width: 4px !important;
      border-radius: 6px;
      background-color: transparent;
   }
   @media (max-width: 991px) {
      padding-top: 50px;
   }

   &::-webkit-scrollbar-thumb {
      width: 4px !important;
      border-radius: 6px;
      background-color: #dbdbdb;
   }

   .details {
      padding-bottom: 2.5rem !important;
   }

   .header-area {
      // padding-bottom: 1.3rem!important;
      font-family: "Roboto";
      font-weight: 500;
      line-height: 20px;
      letter-spacing: normal;
      text-align: center;

      .header-label {
         font-size: 24px;
         color: #6ba7e3;
      }
      .sub-header-label {
         font-size: 1rem;
         color: #979797;
      }
   }

   .widget-register {
      .invalid-feedback {
         position: inherit;
      }
   }

   .body-wrapper {
      //padding-bottom: 2rem;
      .element-box {
         display: flex;
         flex-direction: column;
         padding-bottom: 2rem;
         // padding-bottom: 14px;
         margin: 0;
         font-family: "Open Sans";
         font-weight: 600;
         font-size: 0.8rem;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.35;
         letter-spacing: normal;
         text-align: left;

         .invalid-feedback {
            //position: absolute;
            // position: absolute;
            bottom: 12px;
            color: #a5685f !important;
         }

         .label {
            margin: 0;
            font-size: 0.875rem !important;
            color: #4a4a4a;
         }
         .input-area {
            height: auto;
            padding: 7px 0;
            font-size: 1rem !important;
            color: #4a4a4a;
            border: 0;
            border-radius: 0;
            border-bottom: 1px solid;
            border-bottom-color: #4a4a4a;
            transition: 0.2s ease all;
            &::placeholder {
               color: #dbdbdb;
            }
            &:focus,
            &:hover {
               border-bottom-color: #56a9e8;
               box-shadow: none !important;
            }
         }
         .hyper-link {
            position: absolute;
            bottom: 8px;
            right: 0;
         }
         .invalid-feedback {
            font-size: 0.8rem;
         }
         .form-control:invalid,
         .form-control.is-invalid {
            background-image: none !important;
         }

         // .country-wrapper {
         //   .dropdown-menu {
         //     background-color: #fff;
         //     max-height: 350px;
         //     overflow-y: scroll;
         //   }
         // }
      }
      .country-wrapper {
         .dropdown-menu {
            background-color: #fff;
            max-height: 350px;
            overflow-y: scroll;
         }
         .dropdown-menu::-webkit-scrollbar {
            width: 4px;
         }
         .dropdown-menu::-webkit-scrollbar-track {
            background-color: #ffffff;
            -webkit-border-radius: 10px;
         }
         .dropdown-menu::-webkit-scrollbar-thumb {
            -webkit-border-radius: 10px;
            background: #dbdbdb;
         }
      }

      .are-you-other {
         position: absolute;
         top: 17px !important;
         width: 95%;
         z-index: 2;

         &:focus,
         &:hover {
            top: 17px !important;
         }
      }

      .form-invalid-field {
         font-size: 0.8rem;
         bottom: 12px;
         color: #a5685f;
      }

      .radio-group {
         display: flex;

         &__item {
            width: 50%;

            [type="radio"]:checked,
            [type="radio"]:not(:checked) {
               position: absolute;
               left: -9999px;
            }
            [type="radio"]:checked + label,
            [type="radio"]:not(:checked) + label {
               position: relative;
               padding-left: 28px;
               cursor: pointer;
               line-height: 20px;
               display: inline-block;
               color: #666;
            }
            [type="radio"]:checked + label:before,
            [type="radio"]:not(:checked) + label:before {
               content: "";
               position: absolute;
               left: 0;
               top: 5px;
               width: 18px;
               height: 18px;
               border: 1px solid #56a9e8;
               border-radius: 100%;
               background: #fff;
            }
            [type="radio"]:checked + label:after,
            [type="radio"]:not(:checked) + label:after {
               content: "";
               width: 12px;
               height: 12px;
               background: #56a9e8;
               position: absolute;
               top: 8px;
               left: 3px;
               border-radius: 100%;
               -webkit-transition: all 0.2s ease;
               transition: all 0.2s ease;
            }
            [type="radio"]:not(:checked) + label:after {
               opacity: 0;
               -webkit-transform: scale(0);
               transform: scale(0);
            }
            [type="radio"]:checked + label:after {
               opacity: 1;
               -webkit-transform: scale(1);
               transform: scale(1);
            }
         }
      }

      .radio-invalid-feedback {
         font-size: 0.8rem;
         bottom: 12px;
         color: #a5685f !important;
      }
   }
}

.register-magnifi-auth-design {
   max-width: 458px !important;
   //position: relative !important;
   //top: 35px;

   .hyper-refree-link {
      cursor: pointer;
      color: #56a9e8 !important;
   }
}

.survey-container {
   width: 46%;
   height: auto;
   min-width: 320px;
   max-width: 370px;
   margin: 0 auto;
   margin-top: -50px;
   position: relative;
   border-radius: 15px;
   background-color: #56a9e8;

   @media (max-width: 375px) {
      width: 320px;
   }

   .survey-wrapper {
      padding: 35px 15px;

      .cta-wrapper {
         .btn {
            width: 140px !important;
            font-size: 14px !important;
            padding: 13px 31px !important;
            line-height: 1;
            text-transform: none !important;
         }
         .btn1 {
            width: 200px !important;
            font-size: 14px !important;
            padding: 13px 31px !important;
            line-height: 1;
            text-transform: none !important;
         }
         .primary-btn {
            border-color: #fff;
         }
      }

      p {
         color: #4a4a4a;
         position: relative;
         display: inline-block;
      }

      .circle {
         width: 40px;
         height: 40px;
         border-radius: 50%;
         color: #6db7ef;
         line-height: 37px;
         text-align: center;
         background: #56a9e8;
         border: 1px solid #6db7ef;
      }

      p:before {
         content: "______________";
         color: #6db7ef;
         position: absolute;
         top: 0;
         left: -102px;
      }

      p:after {
         content: "______________";
         color: #6db7ef;
         position: absolute;
         top: 0;
         right: -102px;
      }

      .survey-close-icon {
         position: absolute;
         top: 8px;
         left: 21rem;
         font-size: 1.2rem;
         color: #fff;
         cursor: pointer;
         opacity: 0.7;
      }

      .header-area {
         padding-bottom: 10px;
         // padding-bottom: 2.3rem;
         font-family: "Open Sans";
         font-weight: 600;
         line-height: 1.35;
         letter-spacing: normal;
         text-align: left;
         //position: relative;

         .header-label {
            font-size: 20px;
            // font-size: 1.7rem;
            color: #fff;
         }
         .sub-header-label {
            font-size: 1rem;
            color: #fff;
         }
      }

      .body-wrapper {
         padding-bottom: 2rem;
         .element-box {
            display: flex;
            flex-direction: column;
            padding-bottom: 2rem;
            margin: 0;
            font-family: "Open Sans";
            font-weight: 600;
            font-size: 0.8rem;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.35;
            letter-spacing: normal;
            text-align: left;

            .invalid-feedback {
               position: absolute;
               bottom: 12px;
            }

            .label {
               margin: 0;
               font-size: 0.8rem;
               color: #fff;
            }
            .required:after {
               // content:"*";
               //color: red;
            }
            .input-area {
               height: auto;
               padding: 7px 0;
               font-size: 0.8rem;
               color: #fff;
               background-color: transparent !important;
               border: 0;
               border-radius: 0;
               border-bottom: 1px solid;
               border-bottom-color: #fff;
               transition: 0.2s ease all;
               &::placeholder {
                  color: #dbdbdb;
               }
               &:focus,
               &:hover {
                  border-bottom-color: #fff;
                  transition: all 0.3s;
                  box-shadow: none !important;
               }
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            textarea:-webkit-autofill,
            textarea:-webkit-autofill:hover,
            textarea:-webkit-autofill:focus,
            select:-webkit-autofill,
            select:-webkit-autofill:hover,
            select:-webkit-autofill:focus {
               // -webkit-text-fill-color: transparent;
               // -webkit-box-shadow: 0 0 0 30px white inset !important;
               -webkit-animation-name: autofill;
               -webkit-animation-fill-mode: both;
            }

            .hyper-link {
               position: absolute;
               bottom: 8px;
               right: 0;
            }
            .invalid-feedback {
               font-size: 0.8rem;
               color: #a5685f;
            }
            .are-you-other {
               position: absolute;
               top: 17%;
               width: 80%;
               z-index: 2;
            }
            .form-invalid-field {
               font-size: 0.8rem;
               position: absolute;
               bottom: 12px;
               color: #a5685f;
            }

            .for-pwd-hyper-link {
               position: absolute;
               bottom: 15px;
               right: 0;
               font-size: 0.8rem;
            }

            .password-errormsg {
               font-size: 0.8rem;
               color: #a5685f;
            }
         }

         .country-wrapper {
            max-height: 350px;
            overflow-y: scroll;
         }
      }

      .was-validated .form-control:invalid,
      .form-control.is-invalid {
         background-image: none !important;
      }
   }
}

.profile-success {
   color: #fff;

   .form-group {
      margin-bottom: 0px;
      margin-top: 1rem;
   }

   .secondary-btn {
      width: 40% !important;
   }
}

.cta-wrapper {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   .btn {
      width: 100%;
      padding: 7px;
      margin-bottom: 10px;
      font-weight: bold;
      font-size: 0.8rem;
      line-height: 2;
      border: 1px solid transparent;
      border-radius: 30px;
      text-transform: uppercase;
      transition: all 0.3s;
   }
   .primary-btn {
      background-color: #56a9e8;
      color: #fff;
      &:hover,
      &:focus,
      &:active {
         // background-color: #fff;
         // color: #56a9e8;
         // border: 1px solid #56a9e8;
         box-shadow: 0 5px 15px rgba(86, 169, 232, 0.21);
      }
   }
   .secondary-btn {
      background-color: #fff;
      color: #56a9e8;
      border: 1px solid #56a9e8;
      &:hover,
      &:focus,
      &:active {
         // background-color: #56a9e8;
         // color: #fff;
         box-shadow: 0 5px 15px rgba(86, 169, 232, 0.21);
      }
   }
}

.thank-you-button {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;

   .btn {
      // width: 65%;
      min-width: 65%;
      padding: 7px;
      margin-bottom: 10px;
      font-weight: bold;
      font-size: 0.8rem;
      line-height: 2;
      border: 1px solid transparent;
      border-radius: 30px;
      text-transform: uppercase;
      transition: all 0.3s;
   }

   .primary-btn {
      background-color: #56a9e8;
      color: #fff;
      &:hover,
      &:focus,
      &:active {
         box-shadow: 0 5px 15px rgba(86, 169, 232, 0.21);
      }
   }
}

@include respond(phone) {
   .register-magnifi-auth-design {
      margin-top: 0px !important;
   }

   .left-side-panel-container {
      .carousel-panel {
         display: none !important;
      }
   }

   .magnifi-auth-page-wrapper .left-side-panel-container-login {
      margin-bottom: 40px;
      //  height: 66px !important;
      // padding: 2rem 2.3rem !important;
      justify-content: left !important;
   }

   .right-side-panel-container {
      height: 100% !important;
      background-color: #fff;
      .magnifi-auth-design-container {
         width: 100% !important;
         height: 100% !important;
         min-width: 300px !important;
         max-width: 350px !important;
         padding-left: 1rem !important;
         padding-right: 1rem !important;
         overflow: hidden;
         // overflow-y: visible;
      }
      .register-magnifi-opt-2 .cta-wrapper .btn {
         font-size: 0.8rem !important;
      }
   }

   .register-page-height {
      height: auto !important;
      background-color: #fff;
   }

   // .magnifi-auth-design-container {
   //   .header-area{
   //     .header-label{
   //       font-size: 18px !important;
   //       color: #56a9e8;
   //     }
   //   }
   // }

   .back-to-page {
      top: 1rem !important;
      left: 2rem !important;
      font-size: 1rem !important;
   }

   .back-to-result {
      position: absolute;
      color: #979797;
      font-size: 12px;
      top: 17px !important;
      font-weight: 600;
      right: 29px !important;
      text-decoration: underline !important;
   }

   .survey-close-icon {
      position: absolute;
      top: 8px;
      left: 19rem !important;
      font-size: 1.5rem;
      color: #fff;
      cursor: pointer;
      opacity: 0.7;
   }
}

.acc-access-option-wrapper {
   margin-bottom: 1rem;
   font-size: 0.7rem;
   font-weight: 600;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.35;
   letter-spacing: normal;
   text-align: left;
   color: #979797;
}

.or-partition {
   margin: 1rem;
   font-size: 1rem;
   font-weight: 600;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.33;
   letter-spacing: normal;
   text-align: left;
   color: #a2a2a2;
}

.pos-rel {
   position: relative;
}

.credited-investor {
   padding-bottom: 1.75rem !important;
}

.tnc_hyper-link {
   width: max-content;
   font-size: 0.8rem;
   color: #56a9e8;
   &:hover,
   &:focus {
      color: #56a9e8;
   }
}

.hyper-link {
   width: max-content;
   font-size: 0.7rem;
   color: #56a9e8;
   &:hover,
   &:focus {
      color: #56a9e8;
   }
}

.search-result-login-wrapClassName {
   .search-result-login-modalClassName {
      width: 75%;
      height: 100%;
      margin-left: auto !important;
      margin-right: 0 !important;
      right: 0;

      @media (max-width: 1024px) {
         width: 63%;
      }
   }
}

@media (max-width: 1024px) {
   .advisor_search_card {
      // margin-top: 40%;
      .trending-searches {
         padding: 10px;
         .search_card_trending {
            padding: 8px;
            background: #fff;
            margin-right: 20px;
            margin-bottom: 10px;
            display: inline-block;
            border-radius: 5px;
            font-size: 12px;
            color: #5eade9;
            font-size: 14px;
            font-weight: 350;
         }
         .inner-container {
            border: 0;
         }
      }
   }
}

.phone_search {
   .fa-sm {
      font-size: 1.8rem;
   }
   .react-autosuggest__input {
      text-indent: 4px !important;
      font-size: 14px !important;
      padding: 10px 70px 10px 44px !important;
   }

   @media (max-width: 768px) {
      .close-icon-section {
         top: 15px !important;
      }
   }
}

.no-scroll::-webkit-scrollbar {
   display: none !important;
   width: 0 !important;
}

.elementPenal {
   // display: inline-flex;
   display: flex;
   flex-direction: column;
   position: relative;
   .element:nth-child(1) {
      order: 2;
   }
   .element:nth-child(2) {
      order: 3;
   }
   .element:nth-child(3) {
      order: 4;
   }
   .element:nth-child(4) {
      order: 5;
   }
   .sponsors-insight {
      order: 1 !important;
   }
}

.custom-scroll {
   overflow: hidden;
   overflow-y: auto !important;

   &::-webkit-scrollbar {
      width: 4px !important;
      border-radius: 6px;
      background-color: transparent;
   }

   &::-webkit-scrollbar-thumb {
      width: 4px !important;
      border-radius: 6px;
      background-color: transparent;
   }
}

.custom-scroll:hover {
   &::-webkit-scrollbar-thumb {
      background-color: #dbdbdb !important;
   }
}

@include respond(tab) {
   .register-magnifi-opt-2 .cta-wrapper .btn {
      font-size: 0.8rem !important;
   }

   .register-magnifi-auth-design {
      margin-top: 63px;
   }

   .back-to-page {
      top: 1rem !important;
      left: 2rem !important;
      font-size: 1rem !important;
   }

   .back-to-result {
      position: absolute;
      color: #979797;
      font-size: 12px;
      top: 17px !important;
      font-weight: 600;
      right: 29px !important;
   }

   .survey-close-icon {
      left: 20rem !important;
   }
}
.col-grey-top {
   .tooltip-inner {
      background-color: #4a4a4a !important;
   }
   .arrow:before {
      border-top-color: #4a4a4a !important;
   }
}

.col-grey-left {
   .tooltip-inner {
      background-color: #4a4a4a !important;
   }
   .arrow:before {
      border-left-color: #4a4a4a !important;
   }
}

.col-grey {
   .tooltip-inner {
      background-color: #4a4a4a !important;
   }
   .arrow:before {
      border-right-color: #4a4a4a !important;
   }
}
.col-grey-bottom {
   .tooltip-inner {
      background-color: #4a4a4a !important;
   }
   .arrow:before {
      border-bottom-color: #4a4a4a !important;
   }
}

.universe-container {
   // margin-top: -15px;
   .user-criteria-wrap {
      width: 100%;
      .universe-section__item {
         width: 100% !important;
      }
   }

   .universe-section {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding: 0 2px;
      // margin-top: 10px;

      .switch-wrapper {
         display: flex;
         .ant-switch-small {
            min-width: 20px !important;
            height: 8px;
         }
         .ant-switch-checked {
            &:after {
               top: -4px !important;
               width: 14px !important;
               height: 14px !important;
            }
         }
         .ant-switch::after {
            top: -4px !important;
            width: 14px !important;
            height: 14px !important;
         }
      }

      &__item {
         min-width: 80px;
         margin: 5px 0;
         // margin-bottom: 5px;
         // margin-right: 10px;
         // position: relative;
         // display: flex;
         // align-items: center;

         .check-label {
            font-size: 12px;
            // position: absolute;
            // left: 34px;
            // bottom: -5px;
            // width: 70px;

            #mfu,
            #etf {
               font-size: 10px;
            }
         }

         .custom-check-label {
            // width: 100px !important;
            // bottom: -5px !important;
            // right: -110px !important;
         }

         .switch {
            width: 25px;
            height: 8px;
            display: inline-block;
            position: relative;
            margin: 0;
            // background-color: #4A4A4A;
         }

         .switch input {
            display: none;
         }

         .slider {
            background-color: #4a4a4a;
            bottom: 0;
            cursor: pointer;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            transition: 0.4s;
         }

         .slider:before {
            background-color: #dbdbdb;
            bottom: -3px;
            content: "";
            height: 14px;
            left: -1px;
            position: absolute;
            transition: 0.4s;
            width: 14px;
         }

         input:checked + .slider {
            background-color: #4a4a4a;
            // background-color: #56a9e8 !important;
         }

         input:checked + .slider:before {
            transform: translateX(12px);
            background-color: #56a9e8;
         }

         .slider.round {
            border-radius: 34px;
         }

         .slider.round:before {
            border-radius: 50%;
         }
      }
   }

   .check-error {
      font-size: 12px;
   }
}

.pt-3rem {
   padding-top: 3rem !important;
}

@media only screen and (max-width: 430px) {
   .universe-container {
      .universe-section {
         width: 100%;
      }
   }
}

@media only screen and (width: 375px) {
   .survey-close-icon {
      right: 7px !important;
   }
}

@media only screen and (min-width: 1024px) and (max-width: 1036px) {
   .survey-close-icon {
      left: 20rem !important;
   }
}

@media only screen and (min-width: 600px) and (max-width: 805px) {
   .survey-close-icon {
      left: 20rem !important;
   }
}

@media only screen and (min-width: 770px) and (max-width: 800px) {
   //770
   .survey-close-icon {
      left: 20rem !important;
   }
}

@media only screen and (min-width: 803px) and (max-width: 988px) {
   .survey-close-icon {
      left: 21rem !important;
   }
}

@media only screen and (min-width: 407px) and (max-width: 599px) {
   .survey-close-icon {
      left: 21rem !important;
   }
}

@media only screen and (max-width: 406px) and (min-width: 386px) {
   .survey-close-icon {
      left: 20rem !important;
   }
}

@media only screen and (max-width: 385px) and (min-width: 371px) {
   .survey-close-icon {
      top: 7px;
      left: 19.5rem !important;
   }
}

@media only screen and (max-width: 370px) {
   .survey-close-icon {
      top: 7px;
      left: 18.3rem !important;
   }
}

@include respond(phone) {
   .align-self-stretch {
      padding-left: 24px !important;
   }
}

// @media only screen and (max-width: 1024px) and (min-width:768px){
//   .register-magnifi-opt-2 .register-magnifi-auth-design {
//     padding-top: 5rem !important;
//   }

//   .register-magnifi-opt-2.magnifi-auth-page-wrapper .left-side-panel-container {
//     padding-top: 270px !important;
//   }
// }

@-webkit-keyframes spinner-border {
   to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
   }
}

@keyframes spinner-border {
   to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
   }
}

.spinner-border {
   display: inline-block;
   width: 2rem;
   height: 2rem;
   vertical-align: text-bottom;
   border: 0.25em solid currentColor;
   border-right-color: transparent;
   border-radius: 50%;
   -webkit-animation: spinner-border 0.75s linear infinite;
   animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
   width: 1rem;
   height: 1rem;
   border-width: 0.2em;
}

.not_logged_in {
   position: absolute !important;
   right: 100px;
   padding: 7px 17px !important;
   line-height: normal !important;
   font-family: "Open Sans";
   font-size: 14px !important;
   font-weight: 800;
   font-stretch: normal;
   font-style: normal;
   letter-spacing: normal;
   text-align: center;
   color: #5eade9 !important;
   border: 1px solid #5eade9;
   background-color: #fff;
   border-radius: 3px;
}

.ant-message {
   .ant-message-notice {
      .ant-message-custom-content {
         display: flex;
         align-items: center;
      }
   }
}

.magnifi-password-requirement-container {
   font-family: $primary-font;
   font-weight: 600;
   font-size: 0.8rem;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.35;
   letter-spacing: normal;
   text-align: left;
   .magnifi-password-requirement-label {
      margin: 4px 0;
      font-size: 14px;
      color: $label-black-color;
   }
   .magnifi-password-requirement-wrapper,
   ul {
      padding-left: 18px;
      li {
         margin: 4px 0;
         font-weight: normal;
         color: $label-black-color-2;
      }
   }
}
