@media only screen and (min-width: 1024px) and (max-width: 1279px) {
   .enhancer-content-wrapper {
      .dashboard-container {
         .sidebar-container {
            .sideBarWrapper {
               .element {
                  .label {
                     font-size: 1rem !important;
                  }
                  .sub-label {
                     font-size: 0.7rem !important;
                  }
               }
               .sidebar-dropdown-wrapper {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  .portfolio-class-dropdown-text {
                     font-size: 12px !important;
                     .anticon-caret-down {
                        font-size: 10px !important;
                     }
                  }
               }
               .discover-dropdown {
                  .multiple-calculator {
                     .view-btn {
                        font-family: Roboto !important;
                        font-size: 12px !important;
                        color: inherit;
                        .anticon-caret-down {
                           font-size: 10px !important;
                        }
                     }
                  }
               }
               // Universe Container
               .universe-container {
                  // margin-bottom: .5rem;
                  .enhancer-positions-universe-wrapper {
                  }
                  .enhancer-universe-divider-wrapper {
                     position: relative;
                     .ant-row {
                        margin: 7px 0px;
                     }
                     .enhancer-universe-divider {
                        height: 0 !important;
                        &:before {
                           content: unset !important;
                        }
                     }
                  }
                  .enhancer-portfolio-universe-wrapper {
                  }
                  .universe-section {
                     justify-content: unset !important;
                     &__item {
                        // width: 80px !important;
                        // font-size: 10px !important;
                        position: relative;
                        padding-right: 10px;
                        .check-label {
                           font-size: 12px !important;
                        }
                        .uni-tooltp {
                           font-size: 10px !important;
                           // right: 25px !important;
                           bottom: -1px !important;
                        }
                        .switch {
                           height: 6px !important;
                           width: 20px !important;
                        }
                        .slider:before {
                           height: 12px !important;
                           width: 12px !important;
                        }
                     }
                  }
               }
            }
         }
      }
   }
}

@media only screen and (min-width: 1280px) and (max-width: 1330px) {
   .enhancer-content-wrapper {
      .dashboard-container {
         .sidebar-container {
            .sideBarWrapper {
               .element {
                  .label {
                     font-size: 0.95rem !important;
                  }
                  .sub-label {
                     font-size: 0.65rem !important;
                  }
               }
               .sidebar-dropdown-wrapper {
                  .portfolio-class-dropdown-text {
                     font-size: 0.75rem !important;
                     .anticon-caret-down {
                        font-size: 10px !important;
                     }
                  }
               }
               .discover-dropdown {
                  .multiple-calculator {
                     .view-btn {
                        font-family: Roboto !important;
                        font-size: 0.75rem !important;
                        color: inherit;
                        .anticon-caret-down {
                           font-size: 10px !important;
                        }
                     }
                  }
               }
               // Universe Container
               .universe-container {
                  // margin-bottom: .5rem;
                  .enhancer-positions-universe-wrapper {
                     width: 45% !important;
                     min-width: 45% !important;
                     max-width: 45% !important;
                  }
                  .enhancer-universe-divider-wrapper {
                     position: relative;
                     width: 9%;
                     min-width: 9%;
                     max-width: 9%;
                     padding: 0 5px;
                     .ant-row {
                        margin: 0px 0px;
                     }
                     .enhancer-universe-divider {
                        height: 40px;
                        &:before {
                           top: 30px !important;
                           height: 32px !important;
                        }
                     }
                  }
                  .enhancer-portfolio-universe-wrapper {
                     width: 47% !important;
                     min-width: 46% !important;
                     max-width: 46% !important;
                     padding-left: 0 !important;
                     .label {
                        padding-left: 5px;
                     }
                  }
                  .universe-section {
                     &__item {
                        font-size: 10px !important;
                        position: relative;
                        .check-label {
                           font-size: 9px !important;
                        }
                     }
                  }
               }
            }
         }
      }
   }
}

@media only screen and (min-width: 1331px) and (max-width: 1439px) {
   .enhancer-content-wrapper {
      .dashboard-container {
         .sidebar-container {
            .sideBarWrapper {
               .element {
                  .label {
                     font-size: 1rem !important;
                  }
                  .sub-label {
                     font-size: 0.65rem !important;
                  }
               }
               .sidebar-dropdown-wrapper {
                  .portfolio-class-dropdown-text {
                     font-size: 0.75rem !important;
                     .anticon-caret-down {
                        font-size: 10px !important;
                     }
                  }
               }
               .discover-dropdown {
                  .multiple-calculator {
                     .view-btn {
                        font-family: Roboto !important;
                        font-size: 0.75rem !important;
                        color: inherit;
                        .anticon-caret-down {
                           font-size: 10px !important;
                        }
                     }
                  }
               }
               // Universe Container
               .universe-container {
                  // margin-bottom: .5rem;
                  .enhancer-positions-universe-wrapper {
                     width: 45% !important;
                     min-width: 45% !important;
                     max-width: 45% !important;
                  }
                  .enhancer-universe-divider-wrapper {
                     position: relative;
                     width: 6%;
                     min-width: 6%;
                     max-width: 6%;
                     padding: 0 5px;
                     .ant-row {
                        margin: 0px 0px;
                     }
                     .enhancer-universe-divider {
                        height: 40px;
                        &:before {
                           height: 32px !important;
                        }
                     }
                  }
                  .enhancer-portfolio-universe-wrapper {
                     width: 49% !important;
                     min-width: 49% !important;
                     max-width: 49% !important;
                     padding-left: 10px;
                     .label {
                        padding-left: 5px;
                     }
                  }
                  .universe-section {
                     &__item {
                        font-size: 10px !important;
                        position: relative;
                        // padding-right: 10px;
                        .check-label {
                           font-size: 10px !important;
                        }
                        .uni-tooltp {
                           font-size: 10px !important;
                           bottom: -1px !important;
                        }
                        .switch {
                           height: 6px !important;
                           width: 20px !important;
                        }
                        .slider:before {
                           height: 12px !important;
                           width: 12px !important;
                        }
                     }
                  }
               }
            }
         }
      }
   }
}

@media only screen and (min-width: 1440px) and (max-width: 1919px) {
   .enhancer-content-wrapper {
      .dashboard-container {
         .sidebar-container {
            .sideBarWrapper {
               .element {
                  .label {
                     // font-size: 1rem !important;
                  }
                  .sub-label {
                     // font-size: 0.7rem !important;
                  }
               }
               // Universe Container
               .universe-container {
                  // margin-bottom: .5rem;
                  .enhancer-positions-universe-wrapper {
                  }
                  .enhancer-universe-divider-wrapper {
                     position: relative;
                     padding: 0 4px;
                     .label {
                        // padding-left: 4px;
                     }
                     .ant-row {
                        margin: 0px 0px;
                     }
                     .enhancer-universe-divider {
                        // height: 0 !important;
                        &:before {
                           // content: unset !important;
                        }
                     }
                  }
                  .enhancer-portfolio-universe-wrapper {
                  }
                  .universe-section {
                     &__item {
                        font-size: 10px !important;
                        position: relative;
                        padding-right: 10px;
                        .check-label {
                           font-size: 9px !important;
                        }
                        .uni-tooltp {
                           font-size: 10px !important;
                           bottom: -1px !important;
                        }
                        .switch {
                           height: 6px !important;
                           width: 20px !important;
                        }
                        .slider:before {
                           height: 12px !important;
                           width: 12px !important;
                        }
                     }
                  }
               }
            }
         }
      }
   }
}

@media only screen and (min-width: 1920px) {
   .enhancer-content-wrapper {
      .dashboard-container {
         .sidebar-container {
            .sideBarWrapper {
               .element {
                  .label {
                     // font-size: 1rem !important;
                  }
                  .sub-label {
                     font-size: 0.89rem !important;
                  }
               }
               .sidebar-dropdown-wrapper {
                  .portfolio-class-dropdown-text {
                     font-size: 1rem !important;
                     .anticon-caret-down {
                        font-size: 12px !important;
                        top: 1px;
                        right: -16px;
                     }
                  }
               }
               .discover-dropdown {
                  .multiple-calculator {
                     .view-btn {
                        font-size: 1rem !important;
                        .anticon-caret-down {
                           font-size: 12px !important;
                           top: -1px;
                           right: -9px;
                        }
                     }
                  }
               }
               // Universe Container
               .universe-container {
                  // margin-bottom: .5rem;
                  .enhancer-positions-universe-wrapper {
                  }
                  .enhancer-universe-divider-wrapper {
                     position: relative;
                     padding: 0 4px;
                     .label {
                        // padding-left: 4px;
                     }
                     .ant-row {
                        margin: 0px 0px;
                     }
                     .enhancer-universe-divider {
                        height: 55px;
                        &:before {
                           top: 36px !important;
                           height: 55px;
                           margin: 0 8px;
                        }
                     }
                  }
                  .enhancer-portfolio-universe-wrapper {
                  }
                  .universe-section {
                     &__item {
                        // width: 80px !important;
                        font-size: 10px !important;
                        position: relative;
                        padding-right: 10px;
                        .check-label {
                           font-size: 0.89rem !important;
                        }
                        .uni-tooltp {
                           // font-size: 10px !important;
                           // right: 25px !important;
                           // bottom: -1px !important;
                        }
                        .switch {
                           // height: 6px !important;
                           // width: 20px !important;
                        }
                        .slider:before {
                           // height: 12px !important;
                           // width: 12px !important;
                        }
                     }
                  }
               }
               .sidebar-scroll-content {
                  // height: calc(100vh - (310px)) !important;
               }
            }
         }
      }
   }
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
   .unidentified_ticker_suggestion_text {
      font-size: 15px !important;
   }
   .dashboard-container {
      .sidebar-container {
         .sideBarWrapper {
            padding: 20px 5px;
            .loader {
               font-size: 8px;
            }
            .element {
               padding-bottom: 20px;

               .search-header-label {
                  padding-top: 0;
                  font-size: 15px !important;
                  line-height: 1.8;
               }
               .label {
                  font-size: 15px !important;
               }
               .sub-label {
                  font-size: 0.49rem !important;
               }
               .searchInput {
                  input {
                     font-size: 12px !important;
                  }
                  .search-icon {
                     font-size: 17px !important;
                     right: 8px;
                  }
               }
            }
            // .universe-section__item {
            //   // width: 70px;
            //   .check-label{
            //     font-size: 12px !important;
            //   }
            // }
         }
      }
   }
}

@media only screen and (min-width: 1920px) {
   .unidentified_ticker_suggestion_text {
      font-size: 22px !important;
   }
   .dashboard-container {
      .sidebar-container {
         .sideBarWrapper {
            // padding: 20px 5px;
            .loader {
               // font-size: 8px;
            }
            .element {
               // padding-bottom: 20px;

               .search-header-label {
                  // padding-top: 0;
                  font-size: 28px !important;
                  // line-height: 1.8;
               }
               .label {
                  font-size: 22px !important;
               }
               .sub-label {
                  font-size: 0.89rem !important;
               }
               .searchInput {
                  input {
                     // font-size: 12px !important;
                  }
                  .search-icon {
                     // font-size: 17px !important;
                     // right: 8px;
                  }
               }
               .tagWrapper {
                  // max-height: 98px !important;
                  // font-size: 13px !important;
                  .tag {
                     // padding: 4px 12px !important;
                     font-size: 14px !important;
                  }
               }
               .popTheme-wrapper {
                  &__item {
                     & img {
                     }
                  }

                  &__title {
                     font-size: 18px !important;
                  }
               }
            }
         }
      }
   }
}

@media only screen and (min-height: 500px) and (max-height: 700px) {
   .enhancer-onboard-screen-wrapper {
      padding: 3rem 2rem !important;
      .content-body {
         .buttons-items-wrapper {
         }
      }
   }
}
