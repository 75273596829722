$c1: #56a9e8;
$c2: #4a4a4a;
$c3: #bababb;
$c4: #1e5f91;
$c5: #f7f7f7;
$c6: #edf8fc; // light blue for bg
$c7: #918a1e;

$pg-px: 12mm;

.portfolio-reports {
   .index-sub-heading-text {
      width: 100%;
      margin: 0;
      font-weight: bold;
   }

   .index-p-text {
      width: 100%;
      margin: 0;
   }

   .report-result-container {
      .odometer-chart {
         min-width: 140px;
      }
      .fz-9 {
         font-size: 9px !important;
      }
      .text-overflow-ellipse-line-2 {
         padding: 1px 1px 2px 1px;
         overflow: hidden !important;
         text-overflow: ellipsis !important;
         -webkit-line-clamp: 2 !important;
         display: -webkit-box !important;
         -webkit-box-orient: vertical !important;
      }

      .report-result-header {
         padding: 0 0 3mm 0;
         margin: 0 0mm 3mm 0mm;
         border-bottom: 1px solid #e9ecef;
         .header-label {
            font-size: 0.8rem !important;
            color: #202529 !important;
            text-align: center;
         }
      }
      .report-result-content {
         .item-card {
            margin-bottom: 3mm;
            border: 1px solid #e9ecef;

            .feesReturnRiskWrapper {
               width: 100%;
               .search-fees-return-stats-padding {
                  padding: 0;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  .fassf {
                     width: 28px;
                     height: 0;
                     line-height: normal;
                     font-size: 10px;
                  }
                  .fees-flag-color,
                  .returns-flag-color {
                     margin-top: -8px;
                  }
               }
               .search-risk-stats-padding {
                  padding: 0;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  .risk-flag-color {
                     margin-top: -8px;
                  }
               }
               .search-risk-stats-highlighting {
                  .fees-flag-color,
                  .returns-flag-color,
                  .risk-flag-color {
                     color: $tag-blue;
                  }
               }
            }
            .card-name-wrapper {
               .price-ticker-col {
                  min-width: max-content;
                  max-width: 70px;
               }
            }
         }
      }
   }

   .page {
      .header {
         min-height: 65px;
         margin-bottom: 4mm;
      }
   }
}

@media print {
   .portfolio-reports {
      .page {
         .content {
            flex: 1;
            padding: 0mm $pg-px;
         }
      }
   }
}
