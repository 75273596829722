@import "../../node_modules/bootstrap/scss/variables";

// Color variables

/*
$custom:    #02c0ce;

$primary:   #2d7bf4;
$secondary: #9a9a9a;
$success:   #0acf97;
$info:      #4eb7eb;
$warning:   #f9bc0b;
$danger:    #f1556c;
$purple:    #777edd;
$pink:      #ff679b;
$muted:     #98a6ad;
$light:     #e3eaef;
$light3:    #f3f3f3;
$light7:    #797979;
$dark:      #313a46;
*/

$accent-blue: #1b8be0;

$ssf-blue: #57869c;
$ssf-blue-d1: #0084cf;
$ssf-blue-d2: #005383;
$ssf-blue-d3: #05487d;
$ssf-blue-d4: #002a44;
$ssf-blue-l1: #e9f5fb;
$ssf-blue-l2: #e4f1f2;
$ssf-blue-l3: #eff5fb;
$ssf-blue-l4: #f4f9fd;
$ssf-blue-2: #2d9cdb;
$ssf-blue-50: rgba($gray-600, 0.1);
$charcoal-black: #494949;
$filter-bg-color: #dbdbdb;

$ssf-green: #6a9c65;
$ssf-green-d1: #006400;
$ssf-green-l1: #547f79;
$ssf-green-l1: #1a7166;
$ssf-green-d2: #807f7f;
$ssf-red-d1: #8b0000;
$ssf-red-d2: #6e0303;
$ssf-orange: #f77a08;
$ssf-orange-l1: #ef961e;
$ssf-orange-l2: #fff4ec;
$ssf-orange-d1: #e26b02;
$ssf-purple: #6540a5;
$tag-blue: #55a9e8;

$ssf-gray: #495057;
$ssf-gray-l1: #f3f3f3;
$ssf-gray-l2: #f3f7f2;
$ssf-gray-l3: #f7f7f7;
$ssf-gray-l4: #fffef8;
$ssf-gray-l5: #efefef; //#F9F9F9;
$ssf-gray-l6: #fbfbfb;
$ssf-gray-l7: #e6e6e6;
$ssf-gray-l7: #e5e5e5; //#ddd
$ssf-gray-l8: #4c5053;
$ssf-gray-l9: #f1f1f1;
$ssf-gray-20: #7e7e7e;

$sfmodal-header: #4a4a4a;
$sfmodal-tab: #6c6c6c;
$sfmodal-tab-active: #f7f7f7;
$sfmodal-text-blue: #2173ad;
