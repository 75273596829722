.login-container {
   display: flex;
   justify-content: center;
   align-items: center;
   // height: 100vh;
}

.sign-in-modal {
   max-width: 442px;

   @media (max-width: 1024px) {
      justify-content: center;
   }

   @media (max-width: 575px) {
      margin: 0 auto;
   }
}

.sign-in {
   width: 100%;
   max-width: 280px;
   margin: 0 auto;
}

.sign-in__caption {
   color: #333333;
   text-align: center;
   font-weight: $font-weight-medium;
   font-size: 14px;
}

.sign-in-securities {
   top: 25%;
   position: absolute;
   left: 45%;
   .button-text {
      padding-left: 10px;
   }
}

.sign-in__breadcrumb {
   color: #306498;

   .breadcrumb {
      background-color: transparent;
      justify-content: center;
      padding: 0;
   }

   .breadcrumb-item + .breadcrumb-item::before {
      content: "|";
   }
}

.sign-in__breadcrumb-link {
   color: #306498;
   font-size: 14px;
   font-weight: $font-weight-medium;
}

.sign-in__input-field {
   height: auto;
   padding: 10px 4px 4px;
   font-size: 16px;
   color: black !important;

   background-color: #eeeff0 !important;
   border: 0;
   border-radius: 5px 5px 0px 0px !important;
   border-bottom: 1px solid;
   border-bottom-color: #1e5f91 !important;
   -webkit-transition: 0.2s ease all;
   transition: 0.2s ease all;
}
.input-area {
   padding: 10px 4px !important;
}

.sign-in__input-email {
   border-top-left-radius: 4px;
   border-top-right-radius: 4px;
}

.sign-in__input-password {
   border-bottom-left-radius: 4px;
   border-bottom-right-radius: 4px;
}

.sign-in__submit {
   // font-family: 'Roboto-Bold';
   font-weight: $font-weight-bold;
   background: $primary-color;
   border: 0;

   &:hover {
      background: $primary-color-hover;
   }

   &:not(:disabled):not(.disabled):active,
   .disabled {
      background: $primary-color-hover;
   }

   &.disabled,
   &:disabled {
      background: $primary-color-hover;
   }
}

.sign-in__googleBtn {
   padding-top: 0px;
   border: 0;
   outline: none !important;
   box-shadow: none !important;

   &:hover {
      border: 0;
      outline: none !important;
      box-shadow: none !important;
   }

   &:focus {
      border: 0;
      outline: none !important;
      box-shadow: none !important;
   }

   &:not(:disabled):not(.disabled):active,
   .disabled {
      border: 0;
      outline: none !important;
      box-shadow: none !important;
   }

   &.disabled,
   &:disabled {
      border: 0;
      outline: none !important;
      box-shadow: none !important;
   }
}

.sign-in__linkedIn {
   background: transparent;
   border: 1px solid #0075b8;
   color: #0075b8;

   &:hover {
      background: transparent;
      color: #095480;
   }

   &:not(:disabled):not(.disabled):active,
   .disabled {
      background: transparent;
      color: #095480;
   }

   &.disabled,
   &:disabled {
      background: transparent;
   }

   .fa-linkedin {
      color: #fff;
      background: #0075b8;
      margin-right: 10px;
      padding: 3px;
      font-size: 16px;
   }
}

.sign-in__google-plus {
   background: transparent;
   border: 1px solid #e13731;
   color: #e13731;
   font-weight: $font-weight-regular;

   &:hover {
      background: transparent;
      border: 1px solid #e13731;
      color: #af1d18;
   }

   &:not(:disabled):not(.disabled):active,
   .disabled {
      background: transparent;
      color: #af1d18;
   }

   &.disabled,
   &:disabled {
      background: transparent;
   }

   .fa-google-plus-square {
      color: #e13731;
      margin-right: 10px;
      font-size: 22px;
   }
}

.sign-in__google-plus-original {
   display: none !important;
}

.renderText_wrapper {
   font-family: Roboto;
   .renderText_firstCol {
      margin: 35px 0px 20px;
      text-align: center;
      .renderText_firstCol_desc {
         font-size: 16px !important;
         font-weight: 500;
         line-height: 20px;
         margin: 8px 0px 0px;
      }
   }
   .renderText_secondCol {
      text-align: center;
      margin: 20px 0px;
      .renderText_secondCol_heading {
         font-size: 20px !important;
         font-weight: 500;
         line-height: 25px;
         margin-bottom: 50px;
      }
      .renderText_secondCol_desc {
         font-size: 16px !important;
         font-weight: 400;
         line-height: 20px;
         margin: 0;
      }
   }
}
