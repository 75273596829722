.user-popover {
   border-radius: 0;
   // margin-top: 14px;
   top: -7px !important;
   border-bottom: 1px solid rgba(205, 212, 219, 0.5);
   min-width: 352px;
   z-index: 9999999;
   .popover-body {
      padding: 0 0.75rem;
   }
}

#user-popover {
   cursor: pointer;
   color: red;
}

.user-menu {
   list-style-type: none;

   padding: 0;
   margin: 0px -0.75rem;
   color: #202529;
}

.user-menu__item {
   display: flex;
   align-items: center;
   border-bottom: 1px solid rgba(205, 212, 219, 0.5);
   min-height: 44px;

   &:last-child {
      border-bottom: none;
   }

   .user-menu__link {
      font-weight: $font-weight-regular;
      font-size: 16px;
      color: #202529;
      padding: 11px 20px;
      width: 100%;
   }

   i {
      font-size: 20px;
   }
}

.user-menu__item--user-info {
   padding-left: 12px;
   font-weight: 400;
   min-height: 60px;
   color: #202529;
}

.user-menu__name-badge {
   color: white;
   background-color: black;
   font-size: 14px;
   border-radius: 50%;
   line-height: 22px;
   padding: 9px 9px;
   height: 40px;
   width: 40px;
   display: inline-block;
   .notification-count {
      position: absolute;
      top: -20px;
      bottom: 0;
      right: -5px;
      cursor: pointer;
      .ant-badge-count {
         padding: 2px 0px;
         height: 22px;
         width: 22px;
         border-radius: 50%;
         font-size: 0.7rem;
         text-align: center;
         color: #fff;
         background: #56a9e8;
         background-color: #56a9e8;
      }
   }
   // line-height: 40px;
}
.alert-notification {
   position: absolute;
   top: -12px;
   bottom: 0;
   right: 7px;
   cursor: pointer;
   .ant-badge-count {
      padding: 2px 0px;
      height: 22px;
      width: 22px;
      border-radius: 50%;
      font-size: 0.7rem;
      text-align: center;
      color: #fff;
      background: #56a9e8;
      background-color: #56a9e8;
   }
}

.user-menu__user-name {
   padding-left: 15px;
   font-size: 16px;
}

.user-tooltip {
   position: relative;
   top: -17px;
   bottom: -36px;

   .card-user-class-icon {
      position: absolute;
      top: 7px;
      right: -28px;
      width: auto;
      height: 18px;
   }
}

.user-profile {
   cursor: pointer;
   a {
      text-align: center;
      align-items: center;
      .fa-user {
         font-size: 1.3rem;
      }
   }
   & > .dropdown-menu {
      &:after {
         position: absolute;
         display: block;
         content: "";
         border-color: transparent;
         border-style: solid;
         border-width: 0 0.5rem 0.5rem;
         top: -7px;
         border-bottom-color: #fff;
         right: 14px;
      }
      &:before {
         position: absolute;
         display: block;
         content: "";
         border-color: transparent;
         border-style: solid;
         border-width: 0 0.5rem 0.5rem;
         top: -8px;
         border-bottom-color: rgba(0, 0, 0, 0.25);
         right: 14px;
      }
      border-radius: 0px !important;
      min-width: 260px;
      left: auto !important;
      right: -7px;
      top: 11px !important;
      transform: translate3d(0px, 22px, 0px) !important;
      padding: 8px 0px 0px;

      .dropdown-item {
         background-color: initial;
      }

      h6.dropdown-header {
         height: 40px;
      }
   }
   .nav-sub-menu {
      .dropdown-menu {
         border-radius: 0px !important;
         min-width: 260px;
         transform: translate3d(-270px, -2px, 0px) !important;
         padding: 0px 0px;
      }
      .sub-menu-dd {
         .user-menu__link {
            padding: 13px 15px;
         }
      }
   }
   .spaced-icon {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      align-self: center;
      .file-opt {
         img {
            padding-right: 10px;
         }
         &:hover {
            cursor: pointer;
         }
      }
      .chev {
         font-size: 13px;
      }
   }
}

@include respond(phone) {
   .user-profile {
      .dropdown-menu {
         top: 18px !important;
      }
   }
}
.navbar-rhs {
   display: flex;
   align-items: center;
   text-align: center;
   .btn-register {
      background: #101828;
      border-radius: 8px;
      color: white;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      padding: 8px 15px;
      display: flex;
      align-items: center;
      text-align: center;
   }
   .btn-sign-in {
      color: #101828;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
   }
}
