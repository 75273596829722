.subheading {
   font-size: $font-lg-px;
   font-weight: $font-weight-light;
   color: $label-black-color-3;
}

.main-heading {
   margin-top: 20px;
}

.community-wrapper {
   //background: #ffffff;
   padding: 7rem 0rem 1.5rem;
   min-width: 994px;
   width: 78%;
   max-width: 1200px;
   height: 75vh;
   @media (min-width: 1800px) {
      height: 85vh;
   }
   .community-col-wrapper {
      margin-left: 10%;
   }
}

.details-heading {
   font-size: $font-lg-px !important;
   font-weight: $font-weight-medium;
   color: $black;
}
.details-subheading {
   font-size: $font-weight-600;
   font-weight: $font-weight-regular;
   font-family: Open Sans;
   color: $black;
}

.details-divider {
   border-left: 0.5px solid $border-color-4 !important;
   height: 16em !important;
   margin: 0px !important;
}

.details-button {
   border: 1px solid $primary-color !important;
   border-radius: 30px !important;
   width: 180px;
   height: 40px !important;
   color: $primary-color !important;
   font-size: $font-xs-m-px;
   font-weight: $font-weight-600 !important;

   &:hover {
      background-color: $primary-color !important;
      color: white !important;
   }
}
.details-button-primary {
   background: $primary-color !important;
   background-color: $primary-color !important;
   border: 1px solid $primary-color !important;
   border-radius: 30px !important;
   width: 180px;
   height: 40px !important;
   color: White !important;
   font-size: $font-xs-m-px;
   font-weight: $font-weight-600 !important;

   &:hover {
      background-color: white !important;
      color: $primary-color !important;
   }
}
.disabled {
   &:hover {
      background-color: #56a9e8 !important;
      color: white !important;
   }
}

.download-button {
   a {
      color: $primary-color !important;
      font-size: 14px !important;
      font-weight: 400 !important;
   }
}
