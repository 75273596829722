.totum-risk-bar-container {
   width: 100%;
   height: 100%;
   // min-height: 86px;
   display: flex;
   align-items: center;
   .totum-risk-bar-loader {
      width: 400px;
      display: flex;
      align-items: center;
      justify-content: center;
   }
}

.totum-questionnaire-coming-soon {
   height: 255px;
   display: flex;
   align-items: center;
   justify-content: center;
   font-family: $primary-font;
   font-size: $font-size-16px;
   font-weight: bold;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.56;
   letter-spacing: normal;
   text-align: center;
}

.totum-questionnaire-email-preview-modal {
   .ant-modal {
      width: 720px;
      max-width: 720px;
      .ant-modal-content {
         padding: 0 !important;
         border-radius: 22px !important;
         .ant-modal-close {
            .ant-modal-close-x {
               .cp-modal-close {
                  color: #fff;
               }
            }
         }
         .ant-modal-header {
            background-color: #66b0e9;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            .ant-modal-title {
               .title-head {
                  font-family: $secondary-font;
                  font-size: $font-size-20px !important;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.2;
                  letter-spacing: 0.48px;
                  text-align: center;
                  color: #fff;
               }
            }
         }
         .ant-modal-body {
            padding: 0 40px 30px 40px;
            .totum-questionnaire-email-body-container {
               .totum-questionnaire-email-body-wrapper {
                  margin: 24px;
                  padding: 24px;
                  border: solid 1px $border-color-2;
                  border-radius: 20px;
                  textarea {
                     padding: 0;
                     color: $label-black-color;
                     cursor: default;
                     resize: none;
                     border: 0;
                     background: transparent;
                  }
                  textarea.show-text-area {
                     padding: 4px 10px;
                     border: 1px solid $disabled-text-color-2;
                  }
                  .magnifi-logo {
                  }
                  .email-head-text {
                     margin-bottom: 24px;
                     font-family: $secondary-font;
                     font-size: $font-size-18px;
                     font-weight: 500;
                     font-stretch: normal;
                     font-style: normal;
                     line-height: 1.17;
                     letter-spacing: 0.43px;
                     color: $label-black-color;
                  }
                  .email-preview-button {
                     width: 265px;
                     padding: 9px 48px 8px 47px;
                     margin-bottom: 24px;
                     font-family: $secondary-font;
                     font-size: $font-size-16px;
                     font-weight: normal;
                     font-stretch: normal;
                     font-style: normal;
                     letter-spacing: normal;
                     text-align: center;
                     text-transform: uppercase;
                     color: #fff;
                     border-radius: 21px;
                     border: solid 1px $dark-blue-color;
                     background-color: $dark-blue-color;
                  }
                  .email-disclaimer-wrapper {
                     font-family: $primary-font;
                     font-size: $font-size-12px;
                     font-weight: normal;
                     font-stretch: normal;
                     font-style: normal;
                     line-height: 1.42;
                     letter-spacing: 0.29px;
                     color: $label-black-color;
                     .light-blue {
                        color: $light-blue-color;
                     }
                     .dash {
                     }
                     .copy-right-icon {
                        margin: 0;
                        vertical-align: 0;
                        line-height: 0;
                     }
                  }
                  .ant-form-item-has-error .ant-input {
                     border-color: $error-color;
                     padding: 4px 10px;
                     border: 1px solid $error-color;
                  }
                  .totum-questionnaire-form-footer-wrapper {
                     display: flex;
                     align-items: flex-end;
                     justify-content: flex-start;
                     .text {
                        padding-right: 5px;
                        font-family: $primary-font;
                        font-size: $font-size-20px;
                        line-height: 1;
                        color: #33b5d0;
                     }
                     img {
                        width: auto;
                        height: 24px;
                     }
                  }
               }
            }
            .totum-questionnaire-email-cta-container {
               display: flex;
               align-items: center;
               justify-content: center;
               .ant-form-item {
                  margin: 0;
               }
               button {
                  width: 190px;
                  height: 39px;
                  margin: 0 20px 0 0;
                  font-family: $secondary-font;
                  font-size: $font-size-16px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  letter-spacing: normal;
                  text-align: center;
                  border-radius: 21px;
               }
               .edit-button {
                  color: $dark-blue-color;
                  border: solid 1px $dark-blue-color;
               }
               .ant-btn-primary {
                  border: solid 1px $dark-blue-color;
                  background: $dark-blue-color;
                  border-color: $dark-blue-color;
                  background-color: $dark-blue-color;
               }
            }
            .totum-questionnaire-resend-email-wrapper {
               padding-top: 24px;
               .title-head {
               }
               .message-text {
                  font-family: $secondary-font;
                  font-size: $font-size-20px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.17;
                  letter-spacing: 0.43px;
                  text-align: center;
                  color: $label-black-color;
               }
            }
         }
      }
   }
   .show-text-area {
      resize: auto;
   }
   .totum-questionnaire-form-footer-wrapper {
      display: flex;
      justify-content: center;
      .text {
         padding-right: 5px;
         font-family: $primary-font;
         font-size: 11px;
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         line-height: 2.27;
         letter-spacing: normal;
         text-align: left;
         color: #33b5d0;
      }
      img {
         width: auto;
         height: 12px;
      }
   }
}

.totum-questionnaire-form-modal {
   .ant-modal-content {
      padding: 24px !important;
      border-radius: 22px !important;
   }
   .ant-modal-header {
      padding-top: 10px !important;
   }
   .totum-questionnaire-form-tab-container {
      padding: 0 20px;
      .ant-tabs-bar {
         .ant-tabs-nav-container {
            .ant-tabs-nav-wrap {
               .ant-tabs-nav {
                  width: 98%;
                  margin: 0 auto;
                  .ant-tabs-tab {
                     width: calc(100% / 2);
                     margin: 0;
                     font-family: $secondary-font;
                     font-size: 16px;
                     font-weight: normal;
                     font-stretch: normal;
                     font-style: normal;
                     line-height: 1.56;
                     letter-spacing: normal;
                     text-align: center;
                     color: #b2b2b2;
                  }
                  .ant-tabs-tab-active {
                     font-weight: 500;
                     color: $dark-blue-color;
                  }
                  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
                     background-color: $dark-blue-color;
                  }
               }
            }
         }
      }
      .totum-questionnaire-form-tabpane-wrapper {
      }
      .label-text {
         margin-bottom: 15px;
         font-family: $primary-font;
         font-size: $font-size-16px;
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.56;
         letter-spacing: normal;
         text-align: center;
         color: $label-black-color;
      }
   }
   .totum-questionnaire-form-footer-wrapper {
      display: flex;
      justify-content: center;
      .text {
         padding-right: 5px;
         font-family: $primary-font;
         font-size: 11px;
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         line-height: 2.27;
         letter-spacing: normal;
         text-align: left;
         color: #33b5d0;
      }
      img {
         width: auto;
         height: 12px;
      }
   }
}

.totum-questionnaire-form-container {
   .totum-questionnaire-form-row {
      .totum-questionnaire-form-col {
         .ant-input {
            height: 56px;
         }
      }
      .totum-questionnaire-form-cta-wrap {
         width: 100%;
         text-align: center;
         .ant-btn {
            height: 40px;
            margin: 0 auto;
            padding: 5px 45px;
            background-color: $dark-blue-color;
            border-color: $dark-blue-color;
         }
      }
   }
}

.totum-score-meter-wrapper {
   width: 100%;
   display: flex;
   align-items: center;
   // justify-content: center;
   .totum-logo-area {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      // justify-content: center;
      .totum-resend-link {
         height: auto;
         padding: 0;
         margin: 0;
         font-size: 11px;
         color: $blue-color;
         border: 0;
         box-shadow: none;
         background: transparent;
         span {
            text-decoration: underline;
         }
         .ant-btn-loading-icon {
            padding-right: 4px;
            line-height: 1;
            .anticon {
               padding: 0;
            }
         }
         &:before {
            box-shadow: none;
            background: transparent;
         }
         &:after {
            box-shadow: none;
            background: transparent;
         }
      }
      .text {
         padding-right: 5px;
         font-family: $primary-font;
         font-size: 9px;
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         line-height: 2.27;
         letter-spacing: normal;
         text-align: left;
         color: #33b5d0;
      }
      img {
         width: auto;
         height: 10px;
      }
   }
}

.score-meter-range-track-container {
   width: 100%;
   max-width: 400px;
   height: 45px;
   display: flex;
   cursor: pointer;

   .score-meter-range-track-wrapper {
      position: relative;
      height: 13px;
      width: 100%;
      align-self: center;
      border-radius: 4px;
      .score-meter-range-track-min {
         position: absolute;
         top: -2px;
         left: 5px;
         z-index: 0;
         font-size: 11px;
         color: #fff;
      }
      .score-meter-range-track-max {
         position: absolute;
         top: -2px;
         right: 5px;
         z-index: 0;
         font-size: 11px;
         color: #fff;
      }
   }
}

.score-meter-range-thumb-container {
   height: 38px;
   width: 38px;
   border-radius: 50%;
   background-color: #ccc;
   display: flex;
   justify-content: center;
   align-items: center;
   .score-meter-range-thumb-wrapper {
      position: absolute;
      color: rgb(105, 105, 105);
      // font-weight: 600;
      font-size: 11px;
      font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
      padding: 0px 4px;
      border-radius: 4px;
      white-space: nowrap;
   }
   .current-enhanced {
      bottom: -18px;
      background-color: #f7f7f7;
   }
   .not-current-enhanced {
      top: -18px;
      background-color: #ededed;
   }
}

.cursor-pointer {
   pointer-events: visible !important;
   cursor: pointer !important;
}

.score-meter-range-thumb-circle-progress {
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
}

.score-meter-range-thumb-circle-text {
   z-index: 2;
   height: 28px;
   width: 28px;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 13px;
   font-weight: 600;
   background-color: #fff;
   border-radius: 50%;
}

.score-meter-range-thumb-circle-vertical-bar {
   position: relative;
   width: 4px;
   height: 25px;
   background-color: $dark-blue-color;

   .score-meter-range-thumb-circle-vertical-bar-funnel {
      position: absolute;
      top: -5px;
      left: -5px;
      font-size: 14px;
      color: $dark-blue-color;
      .fa-caret-down {
      }
   }
}

.primary-cta-totum {
   font-size: 14px !important;
   font-weight: 600 !important;
   border: 1px solid #1e5f91 !important;
   border-radius: 50px !important;
   height: 34px !important;
   color: #1e5f91 !important;
   background-color: white !important;
   text-transform: uppercase !important;
   line-height: normal !important;
}

.primary-cta-totum.disabled {
   color: rgb(204, 204, 204) !important;
   border: 1px solid rgb(204, 204, 204) !important;
}

.sent-invite-modal {
   .send-invite-close {
      position: absolute;
      top: 8px;
      right: 8px;
      font-size: 20px;
      color: #1e5f91;
      &:hover {
         cursor: pointer;
      }
   }

   .modal-content {
      border-radius: 0.7rem !important;
   }

   .modal-header {
      border: none;
   }

   .modal-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      justify-content: space-between;
      min-height: 170px;
      padding-bottom: 20px;

      .link {
         width: 100%;
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;
      }

      input {
         background: white;
      }

      .in-valid-field {
         border-color: $error-color !important;
      }

      .send {
         width: 100%;
         border: none;
         background: #1e5f91;
         color: white;
         border-radius: 30px;
         padding: 0.5em 0;
         cursor: pointer;
      }
   }

   .modal-footer {
      border: none;
   }

   .modal-title {
      width: 100%;
      text-align: center;
      margin: 10px 18px;
   }

   .heading {
      font-size: 1.4rem;
      color: #1e5f91;
      text-align: center;
      position: relative;
   }

   .icon_in_heading {
      padding-left: 10px;
      position: absolute;
      right: 0;
      top: 35px;
      height: 15px;
   }

   .tabs {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;

      button {
         width: 50%;
         border: none;
         background: white;
         cursor: pointer;
         font-weight: 500;
         padding: 0.5em 0;

         color: #cfd3d7;
         border-bottom: 3px solid #cfd3d7;
      }

      button.selected {
         color: #1e5f91;
         border-bottom: 3px solid #1e5f91;
      }
   }
}

.send-invite-backdrop {
   display: block !important;
   opacity: 0.5 !important;
   background: black !important;
}

.low_high_span {
   font-size: 9px;
   max-width: 30px;
}
