.overlay-wrapper {
   .overlay-container {
      position: fixed;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.75);
      z-index: 1031;
      text-align: center;
      &.overlay-items {
         top: 145px;
      }
   }
}
