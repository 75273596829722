.register-modal {
   max-width: 546px;

   .ssf-modal__body {
      width: 312px;
      margin: 0 auto;
   }

   .form-group {
      margin-bottom: 5px;

      label {
         font-weight: $font-weight-medium;
         font-size: 14px;
         color: #202529;
         margin-bottom: 2px;
         padding-left: 3px;
      }
   }
}

.register-form__caption {
   color: #202529;
   text-align: center;
   font-weight: $font-weight-medium;
   font-size: 14px;
}

.register-form__term-cond-label {
   font-weight: $font-weight-regular;
   font-size: 14px;
   color: #202529;
}

.register-form__term-cond-link {
   color: $primary-color;
   font-weight: $font-weight-medium;

   &:hover {
      color: $primary-color;
   }
}

.register-form__checkbox {
   width: 13px;
   font-size: 16px;
   margin-top: 2px;
}

.register-complete-modal {
   width: 356px;
   height: 264px;

   .ssf-modal__body {
      text-align: center;
   }

   .ssf-btn-primary {
      width: 89px;
   }
}

.register-complete-modal__circle-tick-icon {
   text-align: center;
   font-size: 80px;
   color: #00ab34;
}

.register-complete-modal__caption {
   font-weight: $font-weight-medium;
   font-size: 14px;
   color: #333333;
   margin-bottom: 15px;
   line-height: 20px;
}
.register-complete-modal__desc {
   font-weight: $font-weight-regular;
   font-size: 12px;
   color: #151515;
   line-height: 20px;
}

.magnifi-auth-page-wrapper {
   .right-side-panel-container {
      .calendar-invite-screen-wrapper {
         display: block !important;
         height: max-content !important;
         padding: 6rem 13%;
         margin: auto;
      }
   }
}
