@import "./EnhancePortfolio/index";

.enhancer-model-portfolio-container {
   height: calc(100vh - 128px);
   .primary-cta-button {
      position: relative;
      font-size: 14px;
      font-weight: 600;
      color: #1e5f91 !important;
      background-color: #ddeefa !important;
      border: 0;
      border-radius: 50px;
      height: 34px;
      color: #fff !important;
      background-color: #1e5f91 !important;
      text-transform: uppercase;
      line-height: normal;
      .anticon {
         position: absolute;
         top: 8px;
         vertical-align: 0;
         line-height: normal;
      }
   }
}
