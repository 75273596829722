$color: #1e5f91;
$active-color: #1e5f91;
$in-active-color: #dbdbdb;
$white-color: #ffffff;
$black-color: #4a4a4a;
$bg-hover-color: #dff1ff;

.body_paddingTp {
   padding-top: 60px !important;
}

@media only screen and (min-width: 320px) and (max-width: 1023px) {
   .clear-icon {
      line-height: 35px;
      width: 15px;
      text-align: center;
      right: 35px;
   }
}

@media only screen and (min-width: 1440px) {
   .body_paddingTp {
      padding-top: 60px !important;
   }
   .onBoardScreenWrapper {
      .page-head-wrapper {
         .page-description {
            // white-space: nowrap;
         }
      }
   }
}

@media only screen and (min-width: 1024px) and (max-width: 1036px) {
   .body_paddingTp {
      padding-top: 60px !important;
   }
   .portfolioMenuListWrapper {
      .ant-dropdown-menu {
         .ant-dropdown-menu-item {
            // max-width: 300px;
            overflow: hidden;
            text-overflow: ellipsis;
         }
      }
   }

   // .market-place-dropdown {
   //   .ant-dropdown-menu {
   //     position: absolute;
   //     margin-left: 40px;
   //     .ant-dropdown-menu-item {
   //       // max-width: 300px;
   //       overflow: hidden;
   //       text-overflow: ellipsis;
   //     }
   //   }
   // }
   .brokerListWrapper {
      .ant-dropdown {
         .ant-dropdown-menu {
            .ant-dropdown-menu-item {
               // max-width: 300px;
               overflow: hidden;
               text-overflow: ellipsis;
            }
         }
      }
   }
   .onBoardScreenWrapper {
      max-width: 1200px;
      margin: 0 auto;
      padding: 1rem 2rem;

      .content_title {
         font-size: 20px !important;
         line-height: 1.23;
         letter-spacing: -0.36px;
         margin-bottom: 3rem;
      }

      .stepsSection {
         width: 82%;
         margin: 5px auto;

         .ant-steps-item {
            &:first-child {
               max-width: 355px;
            }
            &:nth-child(2) {
               max-width: 418px;
            }
         }

         .ant-steps-item-title::after {
            top: 11px !important;
            width: 150px !important;
         }

         .ant-steps-item-active {
            .ant-steps-item-content {
               .ant-steps-item-title {
                  font-size: 0.75rem !important;
                  line-height: 22px;
                  &:after {
                     width: 150px;
                  }
               }
            }
            .indexIconWrapper {
               // background: $active-color;
               width: 22px;
               min-width: 22px;
               height: 22px;
               line-height: 22px;
               font-size: 12px;
            }
         }

         .ant-steps-item-finish {
            .ant-steps-item-container {
               .ant-steps-item-icon {
                  width: 22px;
                  min-width: 22px;
                  height: 22px;
                  font-size: 24px;
                  line-height: 32px;
               }
               .ant-steps-item-content {
                  .ant-steps-item-title {
                     font-size: 0.75rem !important;
                     line-height: 22px;

                     &:after {
                        // background-color: $active-color;
                     }
                  }
               }
            }
            .indexIconWrapper {
               // background: $active-color;
               width: 22px;
               min-width: 22px;
               height: 22px;
               line-height: 22px;
               font-size: 12px;
            }
         }

         .ant-steps-item-wait {
            .ant-steps-item-container {
               .ant-steps-item-icon {
                  width: 22px;
                  height: 22px;
                  font-size: 24px;
                  line-height: 32px;
               }
               .ant-steps-item-content {
                  .ant-steps-item-title {
                     font-size: 0.75rem !important;
                     line-height: 22px;
                  }
               }
            }
            .indexIconWrapper {
               width: 22px;
               min-width: 22px;
               height: 22px;
               line-height: 22px;
               font-size: 12px;
               font-weight: normal;
            }
         }
      }
      .onBoardScreen_contentWrapper {
         // margin: 3rem 0 10px;
         // padding: 10px;
         // border-radius: 10px;

         .MCardWrapper {
            height: auto;
            .label_tag {
               font-size: 10px !important;
            }
         }

         .ant-col {
            &:last-child .block {
               border: 0;
            }
         }

         .block {
            margin: 1rem;
            padding-right: 1rem;
            //border-right: 1px solid $white-color;
            // align-items: center;
            justify-content: center;

            .imgBlock {
               height: 25px !important;
               img {
                  height: 100%;
                  width: auto;
               }
            }
            .detailsBlock {
               .head {
                  font-size: 12px !important;
               }
               .sub-head {
                  font-size: 10px !important;

                  .anticon-down {
                     vertical-align: 0;
                  }
               }
               .underline {
               }
            }
         }
      }
   }
}

@media only screen and (min-width: 1440px) and (max-width: 1919px) {
   .portfolioMenuListWrapper {
      .ant-dropdown-menu {
         .ant-dropdown-menu-item {
            // max-width: 300px;
            overflow: hidden;
            text-overflow: ellipsis;
         }
      }
   }
   .brokerListWrapper {
      .ant-dropdown {
         .ant-dropdown-menu {
            .ant-dropdown-menu-item {
               // max-width: 300px;
               overflow: hidden;
               text-overflow: ellipsis;
            }
         }
      }
   }
   .onBoardScreenWrapper {
      // max-width: 1200px;
      // margin: 0 auto;
      // padding: 1rem 2rem;

      .content_title {
         // font-size: 20px !important;
         line-height: 1.23;
         letter-spacing: -0.36px;
         margin-bottom: 3rem;
      }

      .stepsSection {
         width: 100% !important;
         margin: 5px auto;

         .ant-steps-item {
            &:first-child {
               max-width: 295px !important;
            }
            &:nth-child(2) {
               max-width: 418px;
            }
         }

         .ant-steps-item-title::after {
            // top: 11px !important;
            width: 150px !important;
         }

         .ant-steps-item-active {
            .ant-steps-item-content {
               .ant-steps-item-title {
                  // font-size: 0.75rem !important;
                  // line-height: 22px;
                  &:after {
                     width: 150px;
                  }
               }
            }
            .indexIconWrapper {
               // background: $active-color;
               // width: 22px;
               // min-width: 22px;
               // height: 22px;
               // line-height: 22px;
               // font-size: 12px;
            }
         }

         .ant-steps-item-finish {
            .ant-steps-item-container {
               .ant-steps-item-icon {
                  // width: 22px;
                  // min-width: 22px;
                  // height: 22px;
                  // font-size: 24px;
                  // line-height: 32px;
               }
               .ant-steps-item-content {
                  .ant-steps-item-title {
                     // font-size: 0.75rem !important;
                     // line-height: 22px;

                     &:after {
                        // background-color: $active-color;
                     }
                  }
               }
            }
            .indexIconWrapper {
               // background: $active-color;
               // width: 22px;
               // min-width: 22px;
               // height: 22px;
               // line-height: 22px;
               // font-size: 12px;
            }
         }

         .ant-steps-item-wait {
            .ant-steps-item-container {
               .ant-steps-item-icon {
                  // width: 22px;
                  // height: 22px;
                  // font-size: 24px;
                  // line-height: 32px;
               }
               .ant-steps-item-content {
                  .ant-steps-item-title {
                     // font-size: 0.75rem !important;
                     // line-height: 22px;
                  }
               }
            }
            .indexIconWrapper {
               // width: 22px;
               // min-width: 22px;
               // height: 22px;
               // line-height: 22px;
               // font-size: 12px;
               // font-weight: normal;
            }
         }
      }
      .onBoardScreen_contentWrapper {
         // margin: 3rem 0 10px;
         // padding: 10px;
         // border-radius: 10px;

         .MCardWrapper {
            height: auto;
            .label_tag {
               // font-size: 10px !important;
            }
         }

         .ant-col {
            &:last-child .block {
               border: 0;
            }
         }

         .block {
            margin: 1rem;
            //padding-right: 1rem;
            //border-right: 1px solid $white-color;
            // align-items: center;
            justify-content: center;

            .imgBlock {
               // height: 33px !important;
               img {
                  height: 100%;
                  width: auto;
               }
            }
            .detailsBlock {
               .head {
                  // font-size: 10px !important;
               }
               .sub-head {
                  // font-size: 10px !important;

                  .anticon-down {
                     vertical-align: 0;
                  }
               }
               .underline {
               }
            }
         }
      }
   }
}

.ant-dropdown-menu {
   overflow: hidden;
   overflow-y: auto !important;

   &::-webkit-scrollbar {
      width: 4px !important;
      border-radius: 6px;
      background-color: transparent;
   }

   &::-webkit-scrollbar-thumb {
      width: 4px !important;
      border-radius: 6px;
      background-color: $in-active-color;
   }
}

.data-spinner-wrapper {
   position: absolute;
   width: 100%;
   align-items: center;
   display: flex;
   height: 100%;
   justify-content: center;
   z-index: 99;
   opacity: 0.4;
   background-color: #060606;
   i {
      color: #fff !important;
   }
}

.no-ticker-exist-msg-wrapper {
   min-height: 212px;
   min-width: 535px;
   display: flex;
   justify-content: center;
   align-items: center;
   .cta-wrapper .btn {
      margin: 0 !important;
   }
}

.portfolioMenuListWrapper {
   .ant-dropdown-menu-item:hover,
   .ant-dropdown-menu-submenu-title:hover {
      background-color: transparent !important;
   }
   .ant-dropdown-menu {
      border-radius: 10px !important;
      max-height: 200px;
      .ant-dropdown-menu-item {
         padding: 0 !important;
         font-family: "Open Sans";
         font-size: 12px;
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         letter-spacing: normal;
         text-align: left;
         color: $black-color !important;
         font-size: 14px;
         display: flex;
         flex-direction: row;
         margin-bottom: 2px;

         .upload_btn {
            width: 100%;
            position: relative;
            padding: 5px 15px;
            color: $active-color;
            font-weight: 600;
            &:hover {
               background-color: #dff1ff !important;
            }
         }

         .portfolio_name-label {
            width: 100%;
            position: relative;
            // margin-right: 15px;
            // margin-top: 4px;
            // border-right: 1px solid #dbdbdb !important;
            padding: 5px 15px;
            &:hover {
               background-color: #dff1ff !important;
            }
         }
         .delete-icon {
            // position: absolute;
            // right: -3px;
            // z-index: 2;
            padding: 5px 12px;
            color: $in-active-color;

            &:hover {
               // color: $black-color;
               color: #a5685f;
               background: #a5685f26;
            }
         }
      }
   }
}

.ant-dropdown-menu-item {
   font-family: "Open Sans";
   font-size: 12px;
   font-weight: normal;
   font-stretch: normal;
   font-style: normal;
   letter-spacing: normal;
   text-align: left;
   color: $black-color !important;
   font-size: 14px;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
   background-color: $bg-hover-color !important;
}

.brokerListWrapper {
   .ant-dropdown-menu-item:hover,
   .ant-dropdown-menu-submenu-title:hover {
      background-color: $bg-hover-color !important;
   }
   .ant-dropdown {
      .ant-dropdown-menu {
         .ant-dropdown-menu-item {
            font-family: "Open Sans";
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: $black-color !important;
            font-size: 14px;
         }
      }
   }
}

.onBoardScreenWrapper {
   width: 100%;
   height: 100%;
   width: 68%;
   max-width: 1200px;
   margin: 0 auto;
   display: flex;
   flex-direction: column;
   // justify-content: center;
   padding: 1rem 2rem;
   padding: 6rem 2rem 6rem;
   // justify-content: flex-end;
   // padding: 1rem 2rem 3rem;
   .video-block {
      margin-bottom: 3rem;
      iframe {
         border: none;
         width: 100%;
         height: 100%;
      }
   }
   .page-head-wrapper {
      margin-bottom: 3rem;
      .head-label {
         margin: 0;
         font-family: Roboto;
         font-size: 3.75rem !important;
         font-weight: 300;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.18;
         letter-spacing: normal;
         text-align: left;
         color: #4a4a4a;
      }
      .page-description {
         // width: 80%;
         // max-width: 490px;
         padding: 5px;
         font-family: "Open Sans";
         font-size: 20px;
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.38;
         letter-spacing: normal;
         text-align: left;
         // white-space: nowrap;
      }
   }

   .content_title {
      font-family: Roboto;
      font-size: 30px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: normal;
      text-align: left;
      color: #4a4a4a;
      margin-bottom: 30px;
      margin-bottom: 10px;
   }

   .stepsSection {
      width: 88%;
      margin: 5px auto;

      .ant-steps-item {
         &:first-child {
            max-width: 302px;
         }
         &:nth-child(2) {
            max-width: 380px;
         }
      }

      .ant-steps-item-title::after {
         width: 150px !important;
      }

      .ant-steps-item-active {
         .ant-steps-item-content {
            .ant-steps-item-title {
               font-family: Roboto;
               font-size: 20px;
               font-weight: 500;
               font-stretch: normal;
               font-style: normal;
               color: $active-color;
               &:after {
                  width: 150px;
               }
            }
         }
         .indexIconWrapper {
            background: $active-color;
         }
      }

      .ant-steps-item-finish {
         .ant-steps-item-container {
            .ant-steps-item-icon {
            }
            .ant-steps-item-content {
               .ant-steps-item-title {
                  font-family: Roboto;
                  font-size: 20px;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  color: $active-color;

                  &:after {
                     background-color: $active-color;
                  }
               }
            }
         }
         .indexIconWrapper {
            background: $active-color;
         }
      }

      .ant-steps-item-wait {
         .ant-steps-item-container {
            .ant-steps-item-icon {
            }
            .ant-steps-item-content {
               .ant-steps-item-title {
                  font-family: Roboto;
                  font-size: 20px;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  color: $in-active-color;
               }
            }
         }
         .indexIconWrapper {
            font-weight: normal;
         }
      }
   }
   .onBoardScreen_contentWrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      // align-items: center;
      margin: 0px 0 10px;
      // padding: 10px;
      border-radius: 10px;

      .ant-col {
         &:last-child .block {
            border: 0;
         }
      }

      .disabled {
         border-color: #d6d6d6 !important;
         cursor: not-allowed !important;
      }

      .block {
         position: relative;
         width: 222px;
         height: 45px;
         display: flex;
         align-items: center;
         justify-content: space-between;
         margin: 0 0 6px 0;
         border: 2px solid #56a9e8;
         border-radius: 50px;
         // background-color: #fff;
         padding-left: 14px;
         padding: 2px 24px;
         .imgBlock {
            position: absolute;
            width: 32px;
            height: 25px;
            img {
               width: 32px;
               height: 25px;
            }
         }
         .detailsBlock {
            width: 100%;
            padding-left: 42px;
            .head {
               position: relative;
               height: 100%;
               font-family: Roboto;
               font-size: 15px;
               font-weight: bold;
               font-stretch: normal;
               font-style: normal;
               line-height: 1.14;
               letter-spacing: normal;
               text-align: left;
               color: #5eade9;
               // line-height: 1;
               cursor: pointer;
               span {
                  position: absolute;
                  right: -7px;
                  // bottom: 2px !important;
                  font-size: 17px;
                  font-size: 14px;
                  /* white-space: nowrap; */
                  vertical-align: 0;
                  line-height: 1.12;
               }
            }
            .sub-head {
               font-family: Roboto;
               font-stretch: normal;
               font-style: normal;
               text-align: left;
               color: #56a9e8;
               font-size: 11px;
               line-height: 1;
               .anticon-down {
                  vertical-align: 0;
               }
            }
            .underline {
               cursor: pointer;
               text-decoration: underline;
            }
         }
      }

      .OR-separator {
         margin: 1rem 0 0;
         font-family: Roboto;
         font-size: 16px;
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.19;
         letter-spacing: -0.19px;
         color: #bababb;
      }
   }
}

.onBoardScreenWrapper .onBoardScreen_contentWrapper .upload-block {
   padding-right: 0rem !important;
}

.onBoardScreenWrapper .onBoardScreen_contentWrapper .linked-broker-block {
   padding-right: 31px !important;
}

.onBoardScreenWrapper .onBoardScreen_contentWrapper .watchlist-block {
   padding-left: 11px !important;
   padding-right: 0px !important;
}

.onBoardScreenWrapper .onBoardScreen_contentWrapper .marketplace-block {
   padding-left: 0px !important;
}

.onBoardScreenWrapper .onBoardScreen_contentWrapper .MCardWrapper {
   padding-right: 20px !important;
}
