@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.padding-3 {
   padding: 3rem;
}
.referral-code-container {
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   // background: #fff;
   .header {
      width: 100%;
      padding: 1rem;
      border-bottom: 1px solid #e6e6e6;
   }
   .content-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1rem;

      .sub-referal-text {
         font-size: 0.8rem;
         color: #979797;
      }
      .sub-email-text {
         color: #56a9e8;
         font-weight: bold;
         font-size: 16px;
      }
      .secondary-btn {
         margin-top: -15px;
         padding: 6px 15px;
         font-size: 10px;
         border-radius: 30px;
         line-height: 1.4;
         background: $sfmodal-tab-active;
         border-color: $sfmodal-tab-active;
         color: #55a9e8;
         cursor: pointer;
      }
   }

   tbody {
      tr {
         &:last-child {
            td {
               padding-bottom: 1rem !important;
            }
         }
      }
   }
   .referral-code-input {
      width: 70%;
      min-width: max-content;
      max-width: unset;
      border: 1px dotted #e6e6e6;
      padding: 1.5rem;
      border-radius: 5px;
      pointer-events: none;
   }
}

.failed-referral-message-block {
   width: 100%;
   padding: 3rem;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   background: #fff;
   i {
      font-size: 3rem;
      margin-bottom: 1rem;
      color: #55a9e8;
   }
}

.modal-header-title-body {
   display: flex;
   justify-content: center;
   border: 0;
   line-height: 1;
   position: relative;
   border-top-left-radius: 0.3rem;
   border-top-right-radius: 0.3rem;
   padding: 0;
   cursor: pointer;
   .modal-title {
      width: 100%;
      .modal-header-title-container {
         padding: 1rem;
         text-align: center;
      }
   }
}

.sfm-tab-color {
   background-color: #6c6c6c !important;
}

.close-icon-box {
   padding-left: 10px !important;
   font-size: 20px;
   font-weight: normal;
   line-height: 1;
   color: $tag-blue;
   cursor: pointer;
}

.card-item {
   background-color: #fff !important;
   padding: 0.25rem !important;
   box-shadow: none !important;
   .card {
      // min-height: 105px;
      // padding-top: 8px;
      background-color: #efefef !important;
      border: 0px !important;
      .card-name {
         border: 0;
      }
      .progress-wrapper {
         display: flex;
         flex-direction: row;
         // align-items: center;
         .progress-box {
            flex: 1;
            overflow: hidden;
            .score {
               border-radius: 15px;
            }
            .progress-bar {
               border-radius: 15px;
            }
         }
      }
      .primary-btn {
         padding: 5px 15px;
         font-weight: normal;
         font-size: 13px;
         line-height: 1.4;
         color: $ssf-orange-d1;
         text-transform: capitalize;
         border-radius: 30px;
         border-color: $ssf-orange-d1;
         background: transparent;
         transition: all 0.3s;
         &:hover,
         &:focus {
            color: #fff;
            background: $ssf-orange-d1;
         }
      }
   }
   .failed-criteria {
      display: flex;
      align-items: center;
      margin-left: 10px;
      color: #a5685f;
      width: 100%;
      overflow: hidden;
      padding-right: 20px;
      .ban-icon-box {
         font-size: 15px !important;
         margin-right: 5px;
      }
      .failed-criteria-text {
         white-space: nowrap;
         text-overflow: ellipsis;
         // overflow: hidden;
      }
   }
}

.sfm-modal-footer {
   background-color: #4a4a4a !important;
   border-radius: 0;
   .footer-delete-icon {
      padding: 5px !important;
   }

   .secondary-btn {
      padding: 6px 15px !important;
      font-size: 12px !important;
      border-radius: 30px;
      line-height: 1.4;
      background: #f7f7f7;
      border-color: #e26b02;
      color: #e26b02;
   }
   .disabled,
   .disabled:hover {
      background-color: #ece9e4 !important;
      color: #e26b02 !important;
   }
   .primary-btn {
      padding: 6px 15px !important;
      font-size: 12px !important;
      border-radius: 30px;
      line-height: 1.4;
   }
}

.feature-modal-body {
   font-size: 0.875rem !important;
}
.feature-modal-footer {
   background-color: hsl(207, 8%, 23%) !important;
   height: 40px !important;
   padding: 3px !important;
}
.premium-msg-modal.configure-weight-modal,
.delete-confirmation-modal {
   max-width: 995px !important;
   .modal-content {
      background-color: #f7f7f7;
      color: #1e5f91;
      bottom: 17px;
   }
   .variable-wrapper {
      color: #1e5f91;
   }
   .premium-feature-process-modal-container {
      padding: 50px 35px;
      padding-top: 20px;
      .premium-feature-completed-close {
         color: #4a4a4a;
      }
      .header-wrapper h1 {
         color: #1e5f91;
      }
   }
   .modal-addtional-text {
      font-weight: normal;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.35;
      letter-spacing: normal;
      text-align: left;
      margin-bottom: 10px;
      color: #000;
      .screener-text {
         margin-top: 15px;
         color: #1e5f91;
      }
   }
   .btn-update,
   .btn-reset,
   .btn_later,
   .btn_link_now,
   .btn-update:hover,
   .btn-reset:hover .btn_link_now:hover .btn_later:hover {
      font-weight: 600;
      width: 175px;
      padding: 0.45rem !important;
      border-radius: 25px !important;
      font-size: 13px;
      font-weight: normal;
      text-decoration: none;
   }
   .btn-reset,
   .btn-reset:active {
      border: solid 1px #1e5f91;
      background-color: #ffffff;
      text-decoration: none;
      margin-right: 10px;
   }
   .btn_later,
   .btn_later:active,
   .btn_later:focus {
      border: solid 1px #56a9e8 !important;
      background-color: #ffffff !important;
      text-decoration: none !important;
   }
   .btn_link_now,
   .btn_link_now:active,
   .btn_link_now:focus {
      color: #ffffff !important;
      width: 80% !important;
      background-color: #56a9e8 !important;
      border: solid 1px #56a9e8 !important;
   }
   .btn-update,
   .btn-update:active {
      color: #ffffff;
      background-color: #1e5f91;
   }
   .slidex-container {
      position: relative;
      height: 17px;
      font-size: 12px;
      width: 100%;
      color: #1e5f91;
      font-weight: bold;
      .min-value,
      .max-value {
         position: absolute;
         bottom: -10px;
      }
      .min-value {
         left: 0px;
      }
      .max-value {
         right: 0px;
      }
   }
   .editor-pen {
      color: #1e5f91;
      //font-size: 14px;
      cursor: pointer;
      //transform: translate(3px, 0px) scaleX(-1);
   }
}

.delete-confirmation-modal {
   .delete-content {
      text-align: center;
      font-size: 18px;
      margin-bottom: 20px;
   }
}

.weight-active {
   display: block;
}
.weight-deactive {
   display: none;
}

.weight-overlay {
   max-width: 546px;
   margin: 0 auto;
   border-radius: 5px;
}

.configure-weights-table {
   .configure-footer {
      border-radius: 0;
   }
   .configure-weights-header {
      background-color: $sfmodal-tab-active !important;
      color: $ssf-blue-d3;
   }

   .configure-referral-header {
      color: $ssf-blue-d3;
   }

   .weight-cell-column {
      position: relative;
      padding: 0px !important;
      border: 0px;
      padding-top: 10px !important;
      .weight-cell-container {
         position: relative;
         .right-line {
            position: absolute;
            height: 100%;
            width: 1px;
            background-color: #f7f7f7;
            right: 0px;
            z-index: 10;
         }
         .bottom-line {
            position: absolute;
            height: 1px;
            width: 100%;
            background-color: #f7f7f7;
            bottom: 0px;
            z-index: 10;
         }
      }
      .weight-cell-wrapper {
         max-height: 350px;
         overflow: auto;
         flex-wrap: wrap;
         .weight-cell-item {
            width: 25%;
            position: relative;
            padding: 20px !important;
            border-right: 1px solid #e3e3e3;
            border-bottom: 1px solid #e3e3e3;
            &:first-child {
               border-top: 0px;
            }
            .name {
               display: flex;
               flex-direction: column;
               font-size: 14px;
               .variable-row {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: center;
                  height: 25px;
                  .column-1 {
                     .rc-slider-disabled {
                        background-color: transparent;
                     }
                     width: 80%;
                  }
                  .column-2 {
                     width: 20%;
                     text-align: right;
                  }
               }
            }
            .value {
               color: #1e5f91 !important;
               width: 25px;
               // border: 0px;
               text-align: right;
               font-size: 12px;
               padding: 0px 2px 0px 0px;
               font-weight: bold !important;
               float: right;
               background-color: transparent;
               margin-top: 0px;
               &:disabled {
                  border: 0px;
                  font-weight: normal;
               }
            }
            &.weight-screener-item {
               .name {
                  .variable-row {
                     justify-content: left;
                     .column-1 {
                        width: 100%;
                        .variable-wrapper {
                           justify-content: left;
                           i {
                              margin-left: 10px;
                           }
                           .screener-checkbox-wrapper {
                              margin-right: 10px;
                           }
                        }
                        .screener-input-wrapper {
                           display: flex;
                           align-items: center;
                           margin-top: 5px;
                           .criteria-label {
                              width: 16px;
                           }
                           .criteria-value-input {
                              input {
                                 width: 65px;
                                 height: 20px;
                                 border-radius: 5px;
                                 margin-left: 10px;
                                 margin-right: 5px;
                                 &.error-highlight {
                                    border-color: #a5685f !important;
                                 }
                              }
                              .ant-select {
                                 .ant-select-selector {
                                    display: flex;
                                    align-items: center;
                                    width: 65px;
                                    height: 20px;
                                    border-radius: 5px;
                                    margin-left: 10px;
                                    margin-right: 5px;
                                    padding: 0 8px;
                                    &.error-highlight {
                                       border-color: #a5685f !important;
                                    }
                                 }
                              }
                           }
                        }
                        .screener-error {
                           color: #a5685f;
                           font-size: 10px;
                           position: absolute;
                           bottom: -2px;
                        }
                     }
                  }
               }
            }
         }
      }
      &.footer {
         .btn-group {
            margin-top: 20px;
         }
      }
      .cell-header-section {
         display: flex;
         flex-direction: row;
         align-items: center;
         .weights-pie-chart-wrapper {
            width: 330px;
            height: 200px;
            position: relative;
            &.adjustAlign {
               top: -20px;
            }
            .equalize-weight {
               position: absolute;
               bottom: -10px;
               width: 100%;
               text-align: center;
               color: #1e5f91;
               font-weight: 500;
               text-decoration: underline;
               &:hover {
                  cursor: pointer;
               }
            }
            .recharts-wrapper {
               max-height: initial;
            }
         }
      }
   }
   .configure-weights-footer-button {
      width: 83px;
   }
   tbody tr {
      //border-bottom: 1px solid $ssf-gray-l5;
   }
   tbody tr td,
   thead tr th {
      padding-left: 20px !important;
      padding-right: 20px !important;
      line-height: 2.5;
   }
   tfoot tr {
      // background-color: #353a3e!important;
   }

   tbody tr td {
      font-size: 0.875rem !important;
      padding-top: 5px !important;
      padding-bottom: 5px !important;
      .form-control {
         text-align: right;
         height: calc(2.25rem - 19px) !important;
         margin-top: 5px;
         width: 4rem;
      }
   }
   .sub-text {
      font-size: 65% !important;
      padding: 10px 30px !important;
      color: #979797;
   }

   .sub-email-text {
      color: #56a9e8;
      font-weight: bold;
      font-size: 16px !important;
   }

   .refer-tick {
      color: #56a9e8;
   }

   .font-check-icon {
      font-size: 1.5em;
   }

   .sub-ref-text {
      color: $ssf-blue-d3;
      font-weight: bold;
      font-size: 16px !important;
   }

   .show-more-button {
      padding-left: 0px;
      padding-right: 0px;
      color: #e26b02;
      background-color: transparent;
      border: 0px;
      font-size: 12px;

      &:focus {
         outline: none;
      }
   }
   .tooltip-icon {
      font-size: x-small;
   }
   .variable-name {
      line-height: 1.5;
      font-weight: bold;
      .variable-wrapper {
         display: flex;
         flex-direction: row;
         align-items: center;
         justify-content: space-between;
         span {
            line-height: 1;
            word-break: break-word;
         }
         i {
            margin-left: 5px;
         }
      }
   }

   .secondary-btn {
      padding: 6px 15px !important;
      font-size: 11px !important;
      border-radius: 30px;
      line-height: 1.4;
      background: #f7f7f7;
      border-color: #e26b02;
      color: #e26b02;
   }

   .copy-btn {
      padding: 6px 15px !important;
      font-size: 10px !important;
      border-radius: 30px;
      line-height: 1.4;
      background: $sfmodal-tab-active;
      border-color: $sfmodal-tab-active;
      color: #55a9e8;
   }

   .refer-name {
      color: #979797;
   }

   .refer-date {
      color: #979797;
   }

   .refer-date {
      color: #55a9e8;
      font-size: 10px;
   }
}

.selector-name-wrapper {
   display: flex;
   flex-direction: row;
   align-items: center;
   .selector-name {
      display: flex;
      flex-direction: row;
      align-items: center;
      min-width: 225px;
      width: 225px;
      font-size: 18px;
      font-weight: 600;
      color: #1e5f91;
      justify-content: space-between;
      i {
         margin-left: 0px;
      }
      .ant-input {
         border: none;
         background: none;
         padding-left: 0px;
         font-size: 20px;
         font-weight: bold;
         color: #1e5f91;
         min-width: 210px;
         width: 210px;
         text-overflow: initial;
      }
      .ant-input,
      .ant-input::-webkit-input-placeholder,
      .ant-input:-moz-placeholder,
      .ant-input::-moz-placeholder,
      .ant-input:-ms-input-placeholder,
      .ant-input::placeholder {
         font-size: 20px !important;
         font-weight: bold !important;
         color: #1e5f91 !important;
      }
   }
   .selector-error {
      font-size: 13px;
      color: #a5685f;
      margin-left: 20px;
   }
   .name-text {
      padding: 10px 0px;
   }
}

.create-custom-selector {
   .add-attribute {
      width: 400px;
      .search-input {
         position: relative;
         z-index: 21;
         .ant-input-affix-wrapper {
            border-radius: 50px;
            align-items: center;
            border: 1px solid #1e5f91;
            height: 40px;
            padding: 4px 20px;
            .ant-input-prefix {
               display: flex;
               margin-right: 7px;
               font-size: 18px;
               color: #1e5f91;
            }
            .ant-input-suffix {
               display: flex;
               align-items: center;
               color: #1e5f91;
               .anticon-search {
                  font-size: 20px;
               }
               .ant-input-clear-icon {
                  margin: 0px 10px 0px 4px;
               }
            }
            .ant-input {
               border: none;
               background: none;
               padding-left: 0px;
               font-size: 16px;
               // font-weight: 700;
               color: #1e5f91;
            }
            .ant-input,
            .ant-input::-webkit-input-placeholder,
            .ant-input:-moz-placeholder,
            .ant-input::-moz-placeholder,
            .ant-input:-ms-input-placeholder,
            .ant-input::placeholder {
               font-size: 16px !important;
               // font-weight: 700 !important;
               color: #1e5f91 !important;
            }
         }
      }
   }
}

.create-selector-dropdown {
   .search-results {
      z-index: 20;
      // max-height: 250px;
      overflow: hidden;
      background: #ffffff;
      border-radius: 20px;
      padding: 5px 0px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      hr {
         margin: 0px 0px;
      }
      .selected-header {
         display: flex;
         justify-content: space-between;
         line-height: 1;
         padding: 10px 15px 5px;
         .label {
            font-weight: 600;
            color: #1e5f91;
         }
         .allot-weight {
            color: #1e5f91;
            text-decoration: underline;
            cursor: pointer;
         }
      }
      .ant-menu-submenu-arrow {
         padding-right: 12px;
      }
      .results-content,
      .selected-attrs {
         max-height: 120px;
         overflow-y: auto;
         padding: 0px;
         .result-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px 15px;
            &:hover {
               background: #dff1ff;
               cursor: pointer;
            }
            .attr {
               display: flex;
               align-items: center;
               font-size: 13px;
            }
            .attr-icon {
               margin-right: 5px;
            }
            i {
               font-size: 8px;
               color: #ccc;
            }
            &.single-result-item {
               justify-content: space-between;
            }
         }
      }
      .ant-menu-submenu-title,
      .ant-menu-item-only-child {
         padding: 0px !important;
         height: 30px !important;
         line-height: 30px !important;
         margin: 0px !important;
      }
      .selected-attrs {
         .result-item {
            padding: 3px 15px;
         }
      }
   }
}

.level2-dropdown {
   margin-left: 30px !important;
   box-shadow: none !important;
   .result-item {
      display: flex;
      justify-content: space-between;
      i {
         font-size: 8px;
         color: #ccc;
      }
      .attr-icon {
         display: flex;
         align-items: center;
      }
   }
   .ant-menu-item-only-child {
      height: 30px !important;
      line-height: 30px !important;
      margin-bottom: 0px !important;
      margin-top: 0px !important;
   }
   .ant-menu-item-active {
      background: #dff1ff !important;
   }
}

.onboard-selector {
   text-align: center;
   padding-top: 35px;
   padding-bottom: 15px;
   h1 {
      font-size: 1.7rem !important;
      font-weight: 600 !important;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.31;
      letter-spacing: normal;
      color: #1e5f91 !important;
      font-family: "Roboto";
      margin-bottom: 5px;
   }
   .create-custom-selector {
      display: inline-block;
   }
   .selector-name-center {
      margin: 0 auto;
   }
   .selector-name-wrapper {
      display: inline-block;
      .selector-name {
         margin: 0 auto;
         .ant-input,
         .ant-input::-webkit-input-placeholder,
         .ant-input:-moz-placeholder,
         .ant-input::-moz-placeholder,
         .ant-input:-ms-input-placeholder,
         .ant-input::placeholder {
            font-size: 20px !important;
            font-weight: 600 !important;
            color: #1e5f91 !important;
         }
      }
   }
}

.editable-header-text {
   display: inline-block;
   h1 {
      float: left;
      margin-right: 15px;
      margin-bottom: 5px;
   }
}

.selector-input-desc {
   display: block;
   font-size: 15px !important;
   font-family: "Roboto" !important;
   color: #4a4a4a;
   margin-top: 5px;
   margin-bottom: 10px !important;
}

.weight-desc {
   display: block;
   margin-top: 10px;
}

.gen-selector-errors {
   font-size: 13px;
   color: #a5685f;
   text-align: center;
   position: absolute;
   width: 100%;
}

.delete-selector-success,
.delete-selector-failure {
   .ant-notification-notice-message {
      margin-bottom: 0px;
   }
   .ant-notification-notice-close {
      top: 15px !important;
   }
}

.delete-selector-success {
   background-color: #d4edda !important;
   color: #155724;
}

.delete-selector-failure {
   background-color: #f8d7da !important;
   color: #721c24 !important;
}

.ant-notification {
   z-index: 1051 !important;
}

.configure-weight-modal {
   .results-content,
   .selected-attrs,
   .weight-cell-wrapper {
      &::-webkit-scrollbar {
         width: 14px !important;
         background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
         border-radius: 25px;
         background-color: #d2d2d2;
         border: 5px solid transparent;
         background-clip: content-box;
      }
   }
}

.w-95 {
   width: 95% !important;
}
.blur-effect {
   filter: blur(4px);
}

.h-100 {
   height: 100%;
}

.h-300 {
   height: 300px;
}

.h-335 {
   // height: 335px;
   height: calc(100% - 8px);
}

.h-350 {
   // height: 353px;
   height: calc(100% - 90px);
}

.h-405 {
   // height: 405px;
   height: calc(100% - 27px);
}

.h-490 {
   height: 490px;
   overflow: hidden;
}

.h-415 {
   height: 415px;
}

.h-425 {
   // height: 425px;
   height: 100%;
}

.custom-scroll:hover {
   &::-webkit-scrollbar-thumb {
      background-color: #999 !important;
   }
}

.modal-body-ssf::-webkit-scrollbar {
   display: none !important;
   width: 0 !important;
}

.no-scroll::-webkit-scrollbar {
   display: none !important;
   width: 0 !important;
}

.custom-scroll {
   overflow: hidden;
   overflow-y: auto !important;

   &::-webkit-scrollbar {
      width: 4px !important;
      border-radius: 6px;
      background-color: transparent;
   }

   &::-webkit-scrollbar-thumb {
      width: 4px !important;
      border-radius: 6px;
      background-color: transparent;
   }
}

.container-fluid {
   .chart-wrapper {
      height: 165px;
      .media-fund-detail-wrapper {
      }
   }
}

.funds-listing-warapper {
   .slick-slider {
      padding-top: 2px;
      .slick-list {
         .slick-track {
            height: 100% !important;
         }
      }
      .slick-prev {
         left: 5rem;
         top: unset;
         color: red !important;
         bottom: -24px !important;
         transform: rotate(90deg);
         z-index: 99;
         &:before {
            color: #4a4a4a;
            // content: '';
         }
      }
      .slick-next {
         right: 5rem !important;
         bottom: -24px !important;
         top: unset !important;
         transform: rotate(90deg);
         z-index: 99;
         &:before {
            // content: '';
            color: #4a4a4a;
         }
      }
   }
   .ps__rail-x {
      display: none !important;
   }
   .ps__rail-y {
      display: none !important;
   }
}

.relink-broker {
   text-decoration: underline;
}

.sel-dropdown {
   .multiple-calculator {
      .ant-dropdown {
         padding: 7px 0;
         background-color: #fff;
         background-clip: padding-box;
         border: 1px solid rgba(0, 0, 0, 0.15);
         border-radius: 10px !important;
         .ant-menu-item-selected {
            .selector-name {
               color: rgba(0, 0, 0, 0.85) !important;
               font-weight: 600;
            }
         }
         .ant-menu {
            margin: 0;
            border: 0;
         }
         .ant-menu .ant-menu-item {
            position: relative;
            display: flex;
            align-items: center;
            height: 20px;
            line-height: 20px;
            // height: 25px;
            // line-height: 25px;
            margin: 0;
            padding-left: 8px;
            padding-right: 8px;
            font-size: 12px;
            text-align: left;
            .selector-radio {
               display: flex;
               align-items: center;
               font-size: 12px;
            }
            .selector-name {
               width: 100%;
               max-width: 140px !important;
               padding-right: 12px;
               font-family: "Open Sans" !important;
               font-size: 12px;
               overflow: hidden;
               text-overflow: ellipsis;
               line-height: 1.3;
            }
            .delete-selector {
               position: absolute;
               right: 9px;
               line-height: 1;
               i {
                  font-size: 11px;
                  color: #bababb;
                  margin-left: 5px;
                  font-weight: normal;
                  cursor: pointer;
                  &:hover {
                     color: #a5685f;
                  }
               }
            }
            .ant-radio-wrapper {
               // width: 140px !important;
               // height: 100%;
               height: 12px;
               font-size: 12px !important;
               color: inherit;
               font: inherit;
               margin-right: 0px;
               text-align: left;
               cursor: inherit;
               span {
                  padding-right: 0px;
                  padding-left: 0px;
                  // line-height: 18px;
               }
               .ant-radio {
                  width: 11px;
                  height: 11px;
                  margin-right: 5px;
               }
               .ant-radio-input {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
               }
               .ant-radio-inner {
                  width: 11px;
                  height: 11px;
                  border-radius: 50%;
                  &::after {
                     top: 2px;
                     left: 2px;
                     width: 5px;
                     height: 5px;
                  }
               }
            }
         }
      }
      .view-btn {
         position: relative;
         padding: 0px 5px;
         color: rgba(0, 0, 0, 0.85) !important;
         font-weight: 500;
         font-size: 16px !important;
         font-family: inherit !important;
         text-decoration: underline;
         .down-arrow {
            position: absolute;
            top: 10%;
            right: -9px;
            font-size: 12px;
            vertical-align: 0;
         }
      }
      .set-default-label {
         display: flex;
         align-items: center;
         padding-left: 8px;
         margin-bottom: 3px;
         color: #1e5f91;
         font-family: "Open Sans" !important;
         font-weight: 600;
         i {
            font-weight: 500;
         }
         span {
            &:first-child {
               margin-right: 10px;
            }
         }
      }
      .dropdown-menu {
         min-width: initial;
         // .ant-radio-wrapper{
         //   display: flex;
         //   align-items: center;
         //   font-size: 12px !important;
         //   color: inherit !important;
         //   font: inherit;
         //   margin-right: 0px;
         //   span{
         //     padding-right: 0px;
         //     padding-left: 0px;
         //   }
         //   // .ant-radio{
         //   //   padding-right: 5px;
         //   // }
         //   // .ant-radio-input{
         //   //   width: inherit;
         //   // }
         //   .ant-radio-inner{
         //     width: 11px;
         //     height: 11px;
         //     &::after{
         //       top: 2px;
         //       left: 2px;
         //       width: 5px;
         //       height: 5px;
         //     }
         //   }
         // }
      }
      .dropdown-item.disabled,
      .dropdown-item:disabled {
         color: darkgrey;
      }
      // .selector-options{
      //   display: flex;
      //   flex-direction: row;
      //   justify-content: space-between;
      //   align-items: center;
      //   // padding-right: 10px;
      //   .selector-content{
      //     display: flex;
      //     align-items: center;
      //     font-size: 12px;
      //     width: 100%;
      //     overflow: hidden;
      //   }
      //   .selector-name{
      //     width: 115px;
      //     overflow: hidden;
      //     text-overflow: ellipsis;
      //   }
      //   .delete-selector{
      //     display: flex;
      //     i{
      //       font-size: 11px;
      //       color: #bababb;
      //       margin-left: 5px;
      //       font-weight: normal;
      //       &:hover{
      //         color: #a5685f;
      //       }
      //     }
      //   }
      // }
      .selector-scroll {
         max-height: 220px;
         overflow-x: hidden;
         overflow-y: auto;
         &::-webkit-scrollbar {
            width: 15px !important;
            background-color: transparent;
            border-left: 1px solid #fff;
         }
         &::-webkit-scrollbar-thumb {
            border-radius: 25px;
            background-color: #d2d2d2;
            border: 5px solid transparent;
            background-clip: content-box;
         }
      }
   }
   .modal-trigger-btn {
      position: absolute;
      right: 15px;
      cursor: pointer;
   }
}

.discover-dropdown {
   display: flex;
   .multiple-calculator {
      display: flex;
      align-items: center;
      .font-color-charcoal {
         color: #494949 !important;
      }
      .view-btn {
         display: flex;
         font-size: inherit !important;
         font-weight: normal;
         text-decoration: none;
         padding: 0px 8px 0px 4px;
         margin: 0px !important;
         align-items: center;
         line-height: 1;
         .down-arrow {
            position: absolute;
            top: 0;
            right: -7px;
            height: 100%;
            font-size: 12px;
            line-height: 1.4;
            vertical-align: 0;
         }
      }
   }
   .settings-gear {
      position: relative;
      .modal-trigger-btn {
         position: absolute;
         right: -30px;
         height: 100%;
         font-size: 11px;
         cursor: pointer;
         line-height: 1;
      }
   }
   .selector-options {
      font-family: "Open Sans" !important;
   }
}

@media only screen and (max-width: 769px) {
   .configure-weights-table {
      .weight-cell-wrapper {
         max-height: 500px !important;
      }
      .weight-cell-item {
         width: 33.33% !important;
         .name {
            .variable-row {
               .column-1 {
                  .rc-slider {
                     width: 85%;
                  }
                  .variable-wrapper {
                     .attr-name {
                        font-size: 12px;
                     }
                  }
               }
            }
         }
      }
   }
}

@media only screen and (max-width: 426px) {
   .weight-cell-column {
      .btn-group {
         display: flex;
         flex-direction: row;
         justify-content: center !important;
      }
      .cell-header-section {
         flex-direction: column !important;
         .weights-pie-chart-wrapper {
            margin: -30px;
         }
      }
   }
   .premium-msg-modal.configure-weight-modal {
      .premium-feature-process-modal-container {
         padding: 15px !important;
         padding-top: 30px !important;
         .header-wrapper h1 {
            font-size: 23px !important;
            margin-bottom: 10px !important;
         }
         .modal-addtional-text {
            font-size: 12px !important;
         }
         .btn-reset,
         .btn-update {
            max-width: 150px !important;
         }
         .modal-addtional-text {
            margin-bottom: 15px !important;
         }
      }
      .configure-weights-table {
         .weight-cell-wrapper {
            max-height: 190px !important;
            .weight-cell-item {
               width: 50% !important;
            }
         }
      }
   }
}

@media only screen and (max-width: 321px) {
   .configure-weights-table {
      .weight-cell-wrapper {
         max-height: 330px !important;
         .weight-cell-item {
            width: 80% !important;
         }
      }
   }
}

.d-flex-datepicker {
   display: flex;
   flex-direction: row;
   .ant-picker-range {
      border-radius: 30px;
      height: 20px;
      margin-top: 3px;
      margin-left: 3px;
      border-color: #dbdbdb;
      .ant-picker-input {
         width: 40px;
         input {
            font-size: 10px;
            line-height: 10px;
         }
      }
      .ant-picker-range-separator {
         padding: 0 3px 0 0px;
         .ant-picker-separator {
            display: flex;
            align-items: center;
            align-self: center;
            padding: 0;
            justify-content: center;
         }
      }
      .ant-picker-suffix {
         display: flex;
      }
      &:hover {
         border-color: #dbdbdb;
         box-shadow: none;
      }
      &.ant-picker-focused {
         border-color: #dbdbdb;
         box-shadow: none;
      }
      .ant-picker-clear {
         margin-top: -3px;
      }
   }
}

.dropdown-datepicker {
   .spaced-icon {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: center;
      i {
         font-size: 9px;
      }
   }
   .custom-timeline {
      padding: 3px;
      left: -10px !important;
      .dropdown-item {
         .active,
         &:active {
            color: #212529 !important;
            background: inherit !important;
         }
      }
      .date-select {
         .select-label {
            display: inline-block;
            min-width: 35px;
         }
         span {
            &:first-child {
               padding-left: 0px;
            }
            padding-left: 10px;
         }
         select {
            border: none;
            text-decoration: underline;
            background: none;
         }
      }
   }
}
.dropdown-menu-center {
   left: 50% !important;
   right: auto !important;
   top: 18px !important;
   text-align: center !important;
   transform: translate(-50%, 0) !important;
}

.custom-combobox {
   position: relative;
   .custom-combo-input {
      input[type="text"] {
         width: 45px;
         height: 20px;
         border: none;
         background: none;
         border-bottom: 1px solid #c1c1c1;
         font-weight: 500;
         font-size: 12px !important;
         padding-left: 2px;
         padding-right: 2px;
         margin-right: 6px;
         text-align: left;
      }
      i {
         font-size: 10px;
         color: #c1c1c1;
      }
   }
   .dropdown-toggle {
      &::after {
         display: flex;
         align-items: center;
         align-self: center;
         margin-top: -3px;
         margin-left: 2px;
         margin-right: 4px;
      }
   }
}

.custom-checkbox-antd {
   .ant-checkbox-inner {
      border-radius: 4px;
   }
   .ant-checkbox-checked {
      .ant-checkbox-inner {
         background-color: #1e5f91 !important;
         border-color: #1e5f91 !important;
         &::after {
            border-color: #ffffff !important;
         }
      }
   }
   &.no-pointer {
      .ant-checkbox-input {
         cursor: auto !important;
      }
   }
}

.configure-screener-wrapper {
   .cell-header-section {
      align-items: start !important;
      justify-content: space-between;
      .modal-addtional-text {
         margin-top: 30px;
      }
      .weights-pie-chart-wrapper {
         width: 200px !important;
      }
   }
}

.text_21_rem .premium-feature-process-modal-container .header-wrapper h1 {
   font-size: 2.1rem !important;
}
