.spinner-wrapper {
   position: fixed;
   z-index: 1100; // modal's z-index  - 1050
   display: flex;
   top: 0;
   left: 0;
   height: 100%;
   width: 100%;
   align-items: center;
   justify-content: center;
   background-color: rgba(255, 255, 255, 0.2);
}
