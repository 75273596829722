$color: #1e5f91;
$active-color: #1e5f91;
$in-active-color: #dbdbdb;
$white-color: #ffffff;
$black-color: #4a4a4a;
$body-bg-color: #f7f7f7;

.timelineWrapper {
   height: 100%;
   height: calc(100vh - 100px);
   margin-right: -5px;
   padding: 0 !important;
   .collapseWrapper {
      .headerWrap {
         display: flex;
         align-items: center;
      }
      .indexIconWrapper {
         width: 32px;
         height: 32px;
         margin-right: 10px;
         font-family: Roboto;
         font-size: 16px;
         font-weight: normal;
         letter-spacing: normal;
         color: $white-color;
         background: $active-color;
         border-radius: 50%;
         line-height: 26px;
         padding: 4px 4px;
         text-align: center;
      }
      .indexText {
         position: relative;
         padding-right: 20px;
         font-family: Roboto;
         font-weight: 500;
         font-stretch: normal;
         font-style: normal;
         // line-height: 1;
         color: #1e5f91;

         .edit-icon {
            position: absolute;
            top: 0px;
            right: -4px;
            font-size: 11px;
            line-height: 25px;
            @media only screen and (min-width: 320px) and (max-width: 1439px) {
               top: 0px !important;
               right: 0 !important;
            }
         }

         .node-head {
            @media only screen and (min-width: 320px) and (max-width: 1439px) {
               font-size: 0.8rem !important;
               > span {
                  font-size: 0.8rem !important;
               }
            }
         }
      }
      .third-collpase {
         .ant-collapse-content-inactive {
            display: block !important;
         }
      }
   }
   .ant-collapse {
      background: transparent;
      border: 0;
      .ant-collapse-item {
         .ant-collapse-arrow {
            top: 45% !important;
            // color: $in-active-color !important;
            vertical-align: 0 !important;
         }
         .ant-collapse-header[aria-expanded="true"] .ant-collapse-arrow svg {
            transform: rotate(270deg) !important;
         }

         .ant-collapse-header[aria-expanded="false"] .ant-collapse-arrow svg {
            transform: rotate(90deg) !important;
         }

         &:first-child {
            .ant-collapse-header {
               padding-top: 0 !important;
            }
         }
         &:last-child {
            .ant-collapse-content {
               .ant-collapse-content-box {
                  padding-bottom: 0 !important;
                  .collapse-element {
                     &:last-child {
                        padding-bottom: 0 !important;
                        .node {
                           &:after {
                              content: "";
                              position: absolute;
                              top: 14px;
                              left: -27px;
                              height: 21px;
                              background: $body-bg-color !important;
                              border: 1px solid $body-bg-color !important;
                           }
                        }
                     }
                  }
               }
            }
         }
      }
      .ant-collapse-header {
         padding: 5px 0 !important;
      }
      .ant-collapse-item-disabled {
         .ant-collapse-header {
            .headerWrap {
               .indexIconWrapper {
                  background: $in-active-color;
               }
               .indexText {
                  color: $in-active-color;
               }
            }
         }
      }
      .ant-collapse-content {
         border: 0;
         background: transparent;
         .ant-collapse-content-box {
            position: relative;
            margin-left: 15px;
            // padding: 3px 25px 52px;
            padding: 3px 25px 25px;
            border-left: 1px solid $in-active-color;
            @media only screen and (min-width: 320px) and (max-width: 1439px) {
               padding: 3px 25px 10px !important;
            }
            .collapse-element {
               position: relative;
               font-family: Roboto;
               font-stretch: normal;
               font-style: normal;
               text-align: left;
               color: $black-color;
               .node-head {
                  font-family: Roboto;
                  font-size: 13px;
                  font-weight: 500;
               }
               .not-allowed {
                  color: #dbdbdb;
                  cursor: not-allowed;
               }
               .node {
                  &:before {
                     content: "";
                     position: absolute;
                     top: 4px;
                     left: -30.52px;
                     width: 10px;
                     height: 10px;
                     background: transparent;
                     border: 1px solid transparent;
                     border-radius: 15px;
                  }
               }
               .text-body {
                  margin: 5px 0;
                  font-family: Roboto;
                  font-size: 12px;
                  .checkbox-category-head-label {
                     font-family: "Roboto";
                     font-weight: 500;
                     font-stretch: normal;
                     font-style: normal;
                     letter-spacing: normal;
                     text-align: left;
                     color: #4a4a4a;
                     @media only screen and (min-width: 320px) and (max-width: 1439px) {
                        font-size: 0.75rem !important;
                     }
                     .dropdown-span {
                        border-bottom: 1px solid #4a4a4a;
                        margin: 0 4px 0 2px;
                        .ant-dropdown-trigger {
                           padding: 0;
                           font-weight: 500;
                        }
                        .ant-dropdown {
                           box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
                           border-radius: 10px !important;
                           width: 150px;
                           ul {
                              margin-top: -2px !important;
                              border-radius: 10px !important;
                              padding: 0 !important;
                              .ant-dropdown-menu-item-selected {
                                 background-color: rgba(
                                    197,
                                    197,
                                    197,
                                    0.6
                                 ) !important;
                              }
                              li {
                                 font-size: 12px;
                                 font-weight: normal;
                                 font-stretch: normal;
                                 font-style: normal;
                                 line-height: 1.42;
                                 letter-spacing: normal;
                                 text-align: left;
                                 color: #4a4a4a;
                                 @media only screen and (min-width: 320px) and (max-width: 1439px) {
                                    font-size: 0.7rem !important;
                                 }
                                 &:last-child {
                                    border-top: 1px solid
                                       rgba(197, 197, 197, 0.4);
                                 }
                              }
                           }
                           .ant-dropdown-menu-item-divider,
                           .ant-dropdown-menu-submenu-title-divider {
                              margin: 0;
                           }
                        }
                        .anticon {
                           font-size: 11px;
                           vertical-align: 0.1em;
                        }
                     }
                  }
                  .ant-radio-group {
                     width: 100%;
                     flex-direction: column;
                     .radio-col {
                        margin-bottom: 15px;
                        font-size: 15px;
                        @media only screen and (min-width: 320px) and (max-width: 1439px) {
                           margin-bottom: 5px;
                           font-size: 0.75rem !important;
                        }
                     }
                     .mg-b-10 {
                        @media only screen and (min-width: 320px) and (max-width: 1439px) {
                           margin-bottom: 10px !important;
                        }
                     }
                     .ant-radio-wrapper {
                        font-size: 15px;
                        white-space: break-spaces;
                        @media only screen and (min-width: 320px) and (max-width: 1439px) {
                           font-size: 0.75rem !important;
                           .ant-radio-inner {
                              width: 13px;
                              height: 13px;
                              &:after {
                                 width: 9px;
                                 height: 9px;
                              }
                           }
                        }
                        .ant-radio-input {
                           width: auto;
                        }
                     }
                     .ant-radio-wrapper-checked {
                        .ant-radio-checked {
                           border-radius: 50%;
                           .ant-radio-input {
                              border-radius: 50%;
                           }
                           .ant-radio-inner {
                              border-radius: 50%;
                              top: 0;
                              left: 0;
                              width: 16px;
                              height: 16px;
                              &:after {
                                 top: 1px;
                                 left: 1px;
                                 width: 12px;
                                 height: 12px;
                                 background-color: #1790ff;
                              }
                           }
                           @media only screen and (min-width: 320px) and (max-width: 1439px) {
                              .ant-radio-inner {
                                 width: 13px;
                                 height: 13px;
                                 &:after {
                                    width: 9px;
                                    height: 9px;
                                 }
                              }
                           }
                        }
                     }
                     .uni-tooltp {
                        z-index: 9;
                     }
                  }
                  .ant-checkbox-wrapper {
                     font-size: 12px;
                  }
               }
            }
         }
      }
   }
}

.check-bold {
   font-weight: 600;
   .ant-checkbox-disabled + span {
      color: #4a4a4a;
   }

   .ant-checkbox-disabled .ant-checkbox-inner {
      background-color: #d9d9d9;
   }
}

.two-edit {
   margin-left: 10px;
   position: absolute;
   top: 10px;
   font-size: 11px;

   @media only screen and (min-width: 320px) and (max-width: 1439px) {
      // top: 26px;
   }
}
.enhance-custom-query-search-bar {
   input {
      @media only screen and (max-width: 1390px) {
         font-size: 12px !important;
      }
   }
}

.margin-searchBox {
   margin: 0px 0 20px 0;
}
