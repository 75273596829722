.search-wrapper {
   // margin-bottom: 15px;
   justify-content: center;
   .search-box {
      input {
         width: 100%;
         height: 45px;
         padding-right: 40px;
         font-size: 18px;
         opacity: 0.74;
         font-weight: normal;
         font-family: $secondary-font !important;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.35;
         letter-spacing: -0.24px;
         text-align: left;
         color: $label-black-color !important;
         border: 2px solid #145b8a;
         padding-left: 25px;
         background-color: #f5f7f9 !important;
      }
      .search-icon {
         left: 10px;
         font-size: 17px;
         font-weight: 500;
         font-stretch: normal;
         font-style: normal;
         border: unset;
         border-radius: unset;
         line-height: 1.35;
         letter-spacing: -0.24px;
         color: #145b8a !important;
         width: 27px;
         height: 27px;
         align-items: center;
         justify-content: center;
         display: flex;
         opacity: 0.74;
      }
      .rounded {
         border-radius: 26px !important;
      }
      ::placeholder {
         color: $label-black-color !important;
         opacity: 1; /* Firefox */
         text-align: center;
      }
      :-ms-input-placeholder {
         /* Internet Explorer 10-11 */
         color: $label-black-color !important;
         opacity: 1;
         text-align: center;
      }
      ::-ms-input-placeholder {
         /* Microsoft Edge */
         color: $label-black-color !important;
         opacity: 1;
         text-align: center;
      }
   }
   .clear-icon {
      right: 20px;
      line-height: 18px;
      background-color: #cbcbcb !important; //#ebebeb!important;
      border: 1px solid #f5f7f9;
      border-radius: 50%;
      color: white !important;
   }
}

.type-writer {
   position: absolute;
   left: 0;
   right: 0;
   margin: 0 auto !important;
   width: max-content !important;
   min-width: auto !important;
   max-width: max-content !important;
   // margin: 0px 28px !important;
   padding-right: 3px;
   line-height: 1.5;
   font-size: 18px !important;
   font-family: $secondary-font;
   font-weight: normal !important;
   color: $label-black-color !important;
}

.type-writer {
   border-right: solid 1px rgba(0, 255, 0, 0.75);
   white-space: nowrap;
   overflow: hidden;
}

/* Animation */
.type-writer {
   // animation: animated-text 4s steps(29,end) 1s 1 normal both,
   //            animated-cursor 800ms steps(29,end) infinite;
   animation: animated-cursor 800ms steps(29, end) infinite;
}

/* text animation */

@keyframes animated-text {
   from {
      width: 0;
   }
   to {
      width: 230px;
   }
}

/* cursor animations */

@keyframes animated-cursor {
   from {
      border-right-color: #56a9e8;
   }
   to {
      border-right-color: transparent;
   }
}
