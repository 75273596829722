@import "./select-view";
@import "./Registrations/index";
@import "./PremiumFeature/index";
@import "./IndexIcon/index";
@import "./Charts/index";
@import "./CalculatorSlider/index";
@import "./CardLoader/index";
@import "./NoFunds/index";
@import "./MDrawer/index";
@import "./TopFeeds/index";
@import "./TotumSection/index";

.no-result-wrapper {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 430px;
   margin: auto;
   height: 100%;
   font-family: "Roboto";
   // margin-top: -50px;

   @include respond(phone) {
      margin-top: -70px;
   }

   .dot-no-result {
      position: absolute;
      top: 48%;
      transform: translateY(-50%);
      margin-left: -15px;
   }

   .h3 {
      font-size: 36px !important;
      margin-bottom: 0px;

      @media only screen and (max-width: 1280px) and (min-width: 1024px) {
         font-size: 30px !important;
      }

      @include respond(tab) {
         font-size: 30px !important;
      }

      @include respond(phone) {
         font-size: 20px !important;
      }
   }

   .sub-head {
      font-size: 30px !important;
      font-weight: 300;

      @media only screen and (max-width: 1280px) and (min-width: 1024px) {
         font-size: 24px !important;
      }

      @include respond(tab) {
         font-size: 24px !important;
      }

      @include respond(phone) {
         font-size: 15px !important;
      }
   }

   .mg-btm-no-result {
      margin-bottom: 1.5rem;

      @include respond(phone) {
         margin-bottom: 0.5rem;
      }

      img {
         @media only screen and (max-width: 1280px) and (min-width: 1024px) {
            width: 100px;
         }

         @include respond(tab) {
            width: 90px;
         }

         @include respond(phone) {
            width: 60px;
         }
      }
   }

   .col-grey {
      color: #4a4a4a;
   }

   .specific-query {
      display: flex;
      justify-content: center;

      ul {
         width: 30%;
         list-style-type: none;
         text-align: left;
         // padding: 0px;

         &:first-child {
            padding-left: 30px;

            @include respond(phone) {
               padding-left: 40px;
            }
         }

         &:last-child {
            padding-left: 50px;

            @include respond(phone) {
               padding-left: 40px;
            }
         }

         @media only screen and (max-width: 1280px) and (min-width: 1024px) {
            width: 35%;
         }

         @include respond(tab) {
            width: 35%;
         }

         @include respond(phone) {
            width: 50%;
         }

         li {
            position: relative;
            margin: 40px 12px;

            &:first-child {
               margin-top: 0px;
            }

            @include respond(phone) {
               margin: 32px 0px;
            }
         }
      }

      .query-name {
         display: inline-block;
         font-size: 28px;
         color: #56a9e8;
         cursor: pointer;
         font-weight: 500;

         // &::before {
         //   content: '\2022  ';
         //   color: #4a4a4a
         // }

         @media only screen and (max-width: 1280px) and (min-width: 1024px) {
            font-size: 22px !important;
         }

         @include respond(tab) {
            font-size: 24px !important;
         }

         @include respond(phone) {
            font-size: 18px !important;
         }
      }

      .eg-query {
         position: absolute;
         bottom: -18px;
         left: 2px;
         font-size: 14px;
         font-weight: 300;

         @media only screen and (max-width: 1280px) and (min-width: 1024px) {
            font-size: 12px !important;
         }

         @include respond(tab) {
            font-size: 12px !important;
         }

         @include respond(phone) {
            font-size: 10px !important;
         }
      }

      .other {
         bottom: -25px;
      }

      #other {
         width: 80%;
         height: auto;
         padding: 7px 0;
         font-size: 14px;
         color: #4a4a4a;
         border: 0;
         border-radius: 0;
         border-bottom: 1px solid #dbdbdb;
         -webkit-transition: all 0.2s ease;
         transition: all 0.2s ease;
         background-color: transparent !important;

         @media only screen and (max-width: 1280px) and (min-width: 1024px) {
            width: 60%;
            font-size: 12px;
         }

         @include respond(tab) {
            width: 60%;
         }

         @include respond(phone) {
            width: 65%;
         }
      }

      .other-error {
         position: absolute;
         bottom: -20px;
         left: 0;
         color: #a5685f;
      }

      .no-query-btn {
         display: flex;
         align-items: center;
         justify-content: center;
         position: absolute;
         bottom: 0;
         right: 1px;
         background-color: transparent;
         box-shadow: none;
         border-width: 0;
         border: 1px solid #dbdbdb;
         cursor: pointer;
         width: 26px;
         height: 26px;

         @media only screen and (max-width: 1280px) and (min-width: 1024px) {
            width: 22px;
            height: 22px;
            right: 30px;
         }

         @include respond(tab) {
            width: 22px;
            height: 22px;
            right: 30px;
         }

         @include respond(phone) {
            right: 5px;
         }

         .fa-chevron-right {
            color: #56a9e8;
         }
      }
   }
}
