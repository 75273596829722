.cd-sm-light-modal-backdrop {
   display: block !important;
}
.cd-sm-light {
   max-width: 600px !important;
   .modal-content {
      border-radius: 20px;
   }
   .modal-body {
      padding: 20px;
   }
}

.total-label {
   font-weight: normal;
   font-family: $secondary-font;
   color: #5488c9;
   margin-top: 13px;
}
.account-performance {
   .ant-tag-checkable:active {
      background-color: unset;
   }
   .ant-tag-checkable-checked {
      background-color: #c9e1f5;
      width: 36px;
      text-align: center;
      border-radius: 10px;
   }

   .ant-tag {
      color: #5488c9;
      font-weight: normal;
      font-family: $secondary-font;
      margin-left: 36px;
   }
}

.custom-tooltip {
   padding: 10px;
   background-color: rgb(255, 255, 255);
   border: 1px solid rgb(204, 204, 204);
   white-space: nowrap;
}
.client-detail-section-name {
   // General Styles
   background: #f5f7f9;
   padding-left: 15px;
   padding-right: 15px;
   //height: 100%;
   .container-scroll {
      overflow-y: auto;
      height: calc(100% - 43px);
      &::-webkit-scrollbar {
         width: 15px !important;
         background-color: transparent;
         border-left: 1px solid #fff;
      }
      &::-webkit-scrollbar-thumb {
         border-radius: 20px;
         background-color: #d2d2d2;
         border: 5px solid transparent;
         background-clip: content-box;
      }
      .ant-tabs-tabpane {
         .cl-holdings-wrapper {
            .fWHyCt {
               .rdt_Table {
                  display: inline-table;
               }
               &::-webkit-scrollbar {
                  width: 20px !important;
                  background-color: transparent;
                  border-left: 1px solid #fff;
               }
               &::-webkit-scrollbar-thumb {
                  border-radius: 25px;
                  background-color: #d2d2d2;
                  border: 7px solid transparent;
                  background-clip: content-box;
               }
            }
         }
      }
   }
   .client-header {
      padding-top: 8px;
      padding-bottom: 2px;
      border-bottom: 1px solid #cbcbcb;
      display: flex;
      justify-content: space-between;
      &__left-section,
      &__right-section {
         display: flex;
      }
      span {
         flex-grow: 1;
         h3 {
            font-family: $primary-font;
            font-weight: 600;
            font-stretch: normal;
            color: $label-black-color;
         }
      }
      .ant-btn-group > .ant-btn:last-child {
         //border:unset;
         background-color: unset;
         border: 1px solid #f4faff;
      }
      .ant-btn-group > .ant-btn:last-child:hover {
         //width: 27px;
         //height: 27px;
         background-color: #f4faff;
         border: 1px solid #ebebeb;
         border-radius: 50%;
         padding: 0px 0px;
      }
      .ant-btn-icon-only > * {
         font-size: 18px;
      }
      .client-header-icon {
         margin-left: 10px;
         cursor: pointer;
      }
   }
   .text-wrap-ellip {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
   }
   .cl-dt-header {
      // h3{
      //   color: #4a4a4a;
      //   padding-bottom: 5px;
      //   margin-bottom: 0px;
      //   font-family: $primary-font;
      //   font-size: $font-size-30px !important;
      //   font-weight: normal;
      // }
      // p{
      //   font-size: $font-size-22px !important;
      //   color: $dark-blue-color;
      //   font-weight: normal;
      // }
      // padding: 10px 0px 15px 0px;
   }
   // Tab customization starts here
   .ant-tabs {
      .ant-tabs-bar {
         margin: unset;
      }
      .ant-tabs-nav-container {
         .ant-tabs-nav-wrap {
            .ant-tabs-tab:hover {
               color: #5488c9;
            }
            .ant-tabs-tab-active {
               color: #5488c9;
            }
            .ant-tabs-ink-bar {
               background-color: #5488c9;
            }
         }
      }
   }
   // .ant-tabs {
   //   margin-top: 10px;
   //   &-nav {
   //     & > div{
   //       display: flex;
   //       width: 100%;
   //       border-bottom: 1px solid $other-grey-color-2;
   //     }
   //     display: flex;
   //     .ant-tabs-tab {
   //       flex-grow: 1;
   //       margin-right: 0px;
   //       width: 100%;
   //       text-align: center;
   //       color: $other-grey-color-2;
   //       font-weight: 500;
   //       font-family: $secondary-font !important;
   //       border: 1px solid $other-grey-color-2;
   //       padding: 5px 0px;
   //       &.ant-tabs-tab-active{
   //         color: $dark-blue-color;
   //         border: 1px solid $dark-blue-color !important;
   //       }
   //     }
   //   }
   //   .ant-tabs-bar{
   //     margin-bottom: 0px;
   //     border-bottom: 1px solid #fff;
   //   }
   //   .ant-tabs-ink-bar{
   //     background-color: $dark-blue-color;
   //   }
   //   .ant-tabs-top-content{
   //     height: 300px;
   //   }
   // }
   // Tab customization ends here
   .account-details-wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-evenly;
      .pf-val {
         span {
            font-size: $font-size-16px;
            font-family: $primary-font !important;
            font-weight: 600;
            color: $label-black-color;
            &:last-child {
               font-size: $font-size-26px;
               color: $dark-blue-color;
               font-weight: normal;
               padding-left: 10px;
            }
         }
      }
      .model-selection {
         font-family: $secondary-font;
         font-size: 18px;
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         line-height: 2;
         letter-spacing: normal;
         text-align: left;
         color: #5488c9;
      }
      .allocation-pending {
         font-family: $primary-font;
         font-size: 16px;
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.38;
         letter-spacing: normal;
         text-align: left;
         color: #4a4a4a;
         .img-pointer {
            padding-left: 4px;
            cursor: pointer;
         }
         .img-pointer-not-allowed {
            padding-left: 4px;
            cursor: not-allowed;
         }
      }
   }

   .account-benchmark {
      display: flex;
      justify-content: space-between;
      .img-pointer {
         cursor: pointer;
      }
      .img-pointer-not-allowed {
         cursor: not-allowed;
      }
   }
   .account-benchmark-tab {
      display: flex;
      width: 36%;
      border-radius: 18px;
      border: solid 1px #5488c9;
      background-color: #fff;
      .ant-select {
         color: #5488c9;
         height: 30px;
         .ant-select-selector {
            .ant-select-selection-item {
               text-align: left;
            }
         }
         .ant-select-arrow {
            color: #5488c9;
         }
      }
   }

   // Holdings Details
   .dynamic-column-container {
      display: flex;
      flex-flow: row;
      .highlight-ticker {
         padding-left: 4px;
         padding-right: 4px;
      }
   }
   .show-more-section {
      .show-more {
         font-family: $primary-font;
         font-size: 10px;
         font-stretch: normal;
         font-weight: normal;
         color: #2c70a5;
      }
      .show-more:hover {
         cursor: pointer;
      }
      // .dynamic-column-pagination{
      //   float:right;
      //   .left-arrow{
      //     padding-right: 10px;
      //   }
      //   .ant-btn-icon-only{
      //     width: 30px;
      //     height: 30px;
      //     font-size: 10px;
      //     border-radius: 4px;
      //   }
      //   .ant-btn-primary{
      //     color: unset;
      //     border: solid 1px #5488c9;
      //     background-color: rgba(244, 250, 255, 0);
      //   }
      //   .ant-btn-primary:hover{
      //     color: unset;
      //     border-color: unset;
      //   }
      //   .ant-btn:hover{
      //     color: unset;
      //     border-color: unset;
      //     background-color: unset;
      //   }

      // }
   }
   .gn-tbl-wpr {
      .rdt_Table {
         background-color: #f5f7f9;
         .rdt_TableHead {
            min-height: 32px;
            .highlight-ticker {
               text-align: center;
               flex-grow: 1;
               width: 85px;
               text-overflow: ellipsis;
               overflow: hidden;
               white-space: nowrap;
               cursor: pointer;
            }
            .highlight {
               padding: 6px 20px;
               line-height: 1;
               margin-left: unset;
               font-size: 14px;
               font-family: "Roboto";
               font-weight: 500;
               margin-right: unset;
               background: #064676;
               padding: 4px 8px;
               border-radius: 30px;
               color: #ffffff;
            }
         }
         .rdt_TableBody {
            display: inline-table;
            width: 100%;
            height: 100%;
            .rdt_TableRow {
               .rdt_TableCell:first-child {
                  text-align: left;
               }
            }
         }
         .rdt_TableHead,
         .rdt_TableBody {
            .rdt_TableRow {
               min-height: 38px;
            }
            .rdt_TableHeadRow,
            .rdt_TableRow {
               background-color: #f5f7f9;
            }
         }
      }
      &.tbl-content {
         display: flex;
         flex: 1;
         overflow: hidden;
         height: 100%;
         padding-top: 15px;
         & > div {
            overflow: auto;
            & > div {
               display: flex;
            }
         }
      }
      .rdt_TableBody,
      .ant-tabs-tabpane {
         //overflow-y: auto !important;
         max-height: unset;
         &::-webkit-scrollbar {
            width: 20px !important;
            background-color: transparent;
            border-left: 1px solid #fff;
         }
         &::-webkit-scrollbar-thumb {
            border-radius: 25px;
            background-color: #d2d2d2;
            border: 7px solid transparent;
            background-clip: content-box;
         }
      }
      .rdt_TableCol:first-child {
         padding-left: 5px;
      }

      .rdt_TableCol:nth-child(3) {
         padding-left: 20px;
      }
      .rdt_TableCol:nth-child(4) {
         padding-left: 20px;
      }
      .rdt_TableCol:last-child {
         padding-left: 10px;
      }
      .rdt_TableCol {
         font-family: $primary-font !important;
         font-weight: 600;
         color: $label-black-color;
         font-stretch: normal;
         font-size: $font-size-16px;
         padding-left: 15px;
      }

      .rdt_TableCell {
         min-height: inherit;
         font-family: $primary-font !important;
         font-weight: normal;
         color: $label-black-color;
         font-stretch: normal;
         font-size: $font-size-14px;
         text-align: center;
      }
   }
}
.ant-dropdown-menu-item {
   span {
      padding-left: 4px;
   }
}

.recharts-text.recharts-cartesian-axis-tick-value {
   font-size: 10px;
}
