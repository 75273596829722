@media only screen and (min-width: 1024px) and (max-width: 1210px) {
   .build-portfolio-onboard-screen-wrapper {
      .content-body {
         .buttons-items-wrapper {
            height: 200px;
         }
      }
   }
}

@media only screen and (min-width: 1920px) {
}
