.multi-tab-card-container {
   width: 100%;
   margin-bottom: 7px;
   .first-option {
   }
   .second-option {
      line-height: 36px;
   }
   .multi-tab-card-head-wrapper {
      width: 100%;
      margin-bottom: 10px;
   }
   .multi-tab-card-body-wrapper {
      width: 100%;
      display: flex;
   }
}
