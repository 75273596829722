$btn-color: #1e5f91;
$btn-bg-color: #ddeefa;
$text-color: #4a4a4a;

.m-drawer-wrapper {
   z-index: 1;
   box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
   .ant-drawer-header {
      padding: 50px 35px 10px 35px;
      font-family: $secondary-font;
      font-weight: 500;
      letter-spacing: -0.24px;
      text-align: left;
      color: $text-color;
      border: 0;
      .ant-drawer-title {
         .m-drawer-header-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-family: $secondary-font;
            font-size: 36px;
            line-height: normal;
            letter-spacing: 0.01em;
            .m-drawer-header-title {
               font-weight: 500;
            }
            .m-drawer-close-icon {
               font-size: $font-size-30px;
               font-weight: 300 !important;
               cursor: pointer;
            }
         }
         .m-drawer-header-subheading {
            font-size: $font-sm-s;
            color: $black;
            opacity: 0.6;
            margin-top: 10px !important;
         }
      }
   }

   .ant-drawer-close {
      font-weight: 800 !important;
      color: black;
   }
   .ant-drawer-content-wrapper {
      // width: calc(33vw) !important;
      // max-width: 545px !important;
      // @media only screen and (min-width: 1024px) and (max-width: 1280px) {
      //   // padding-bottom: 2rem !important;
      //   width: calc(38vw) !important;
      // }
      .ant-drawer-body {
         position: relative;
         padding: 0;
         .spinner-wrapper {
            position: absolute;
         }
      }
      .m-drawer-body-wrapper {
      }
      .m-drawer-body-wrapper.custom-scroll {
         height: calc(100vh - 260px) !important;
         padding: 5px !important;
         padding-right: 1rem !important;
         margin-right: -16px;
         font-size: 14px;
         // @media only screen and (min-width: 1024px) and (max-width: 1280px) {
         //   // padding-bottom: 2rem !important;
         // }
         // @include respond(tab) {
         //   // padding-bottom: 2rem !important;
         // }
      }
   }

   .mg-btm-0 {
      margin-bottom: 0.5rem !important;
   }

   hr {
      margin-top: 0;
   }

   .row {
      margin-top: 10px;
      margin-bottom: 10px;
   }
   .row:first-child {
      margin-bottom: 0;
   }

   .sub-head-label {
      font-size: 14px;
      color: #1e5f91;
   }

   .default-button,
   .secondary-button,
   .ant-btn-default {
      padding: 4px 25px;
      font-family: $primary-font;
      font-style: normal;
      font-weight: 600;
      font-size: $font-size-12px;
      color: $primary-color;
      border-radius: 30px;
      border-color: $primary-color;
      i {
         margin-right: 5px;
      }
      span {
         padding-left: 3px;
      }
      &:hover,
      &:active,
      &:focus {
         color: $primary-color;
         border-color: $primary-color;
      }
   }

   .primary-button,
   .ant-btn-primary {
      padding: 4px 25px;
      font-family: $primary-font;
      font-style: normal;
      font-weight: 600;
      font-size: $font-size-12px;
      border-radius: 30px;
      border-color: $total-blue-text;
      background: $total-blue-text;
      background-color: $total-blue-text;
      i {
         margin-right: 5px;
      }
      span {
         padding-left: 3px;
      }
      &:hover,
      &:active,
      &:focus {
         border-color: $total-blue-text;
         background: $total-blue-text;
         background-color: $total-blue-text;
      }
   }

   .ant-drawer-body {
      &::-webkit-scrollbar {
         width: 4px !important;
         border-radius: 6px;
         background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
         width: 4px !important;
         border-radius: 6px;
         background-color: transparent;
      }

      &:hover {
         &::-webkit-scrollbar-thumb {
            background-color: #dbdbdb !important;
         }
      }
   }
}
