$background: #f7f7f7;
$border: #b7b7b7;
$text-color: #4a4a4a;
$text-color-highlighted: #56a9e8;
$fontRoboto: "Roboto";
$fontOpenSans: "Open Sans";
$error: #a5685f;
.settingsContainer {
   display: flex;
   flex-direction: column;
}

.header {
   position: sticky;
   top: 0;
}
.inActiveSettings {
   position: relative;
   pointer-events: none;
}
.inActiveSettings::before {
   position: absolute;
   content: "";
   height: 100%;
   width: 100%;
   background: #3131312b;
   z-index: 2;
}
.container {
   display: flex;
   margin: auto;
   margin-top: unset;
   margin-bottom: unset;
   // margin-top: 40px;
   max-width: 850px;
   width: 100%;
   // margin-bottom: 100px;
}
.leftNavigation {
   width: 22%;
   display: flex;
   // padding-left: 75px;
   //padding-top: 66px;
   flex-direction: column;
   .menuItem {
      padding: 4px 0;
      font-size: 24px;
      color: $text-color;
      font-family: $fontRoboto;
      text-decoration: none;
      line-height: 35px;
      &:hover {
         color: #55a9e8;
         text-decoration: none;
      }
      &:active {
         color: $text-color;
         text-decoration: none;
      }
      &.active {
         color: #55a9e8;
      }
   }
}
.rightPages {
   width: 78%;
   margin: 40px 0;
   // margin-bottom: 40px;
   display: flex;
   flex-direction: column;
}
.name {
   font-family: $fontRoboto;
   font-size: 30px;
   color: $text-color;
   text-transform: capitalize;
   position: relative;
   input {
      height: 48px;
   }
   i {
      position: absolute;
      font-size: 17px;
      cursor: pointer;
      right: 0;
      top: 20px;
      color: #53a6e0;
   }
}

.accStatus {
   font-size: 14px;
   margin: 0px;
   position: relative;
   top: -6px;
   font-weight: 600;
   opacity: 0.8;
}
.editInput {
   margin-right: 10px;
   background: TRANSPARENT !important;
   border: 0 !important;
   border-bottom: 1px solid #d5d5d5 !important;
   border-radius: 0px !important;
   &:focus {
      box-shadow: none !important;
      outline: 0px !important;
      border-bottom-color: #4a4a4a !important;
   }
}
.nameEditBox {
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
   width: 100%;

   .key {
      text-align: left;
      color: $text-color !important;
      margin-bottom: 0;
      font-family: $fontRoboto;
   }

   .name {
      padding-left: 28px;
      padding-right: 28px;
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
   }

   i {
      color: #5eade9;
      font-size: 12px;
   }
}

.footerContainer {
   padding: 10px 50px !important;
   background: $background !important;
   box-shadow: 0px 0 10px #c7c0c0;
   position: fixed;
   bottom: 0px;
   width: 100%;
   text-align: center;
   font-family: $fontOpenSans;
   img {
      padding-right: 10px;
      width: 40px;
   }
}
