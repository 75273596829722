.spoof-body-container {
   width: 100%;
   .spoof-body-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #d4d4d4;
      overflow: hidden;
      img {
         width: 100%;
         height: 100%;
         // filter: blur(3px);
      }

      @include respond(phone) {
         img {
            // width: 100%;
            // height: 100%;
            opacity: 0;
            height: 100vh;
            // filter: blur(3px);
         }
      }

      .overlay-button {
         position: absolute;
         top: 40%;
         left: 0;
         right: 0;
         margin: 0 auto;
         text-align: center;
         .spoof-login-button {
            padding: 0;
            .link {
               padding: 5px 10px;
               line-height: 2.5;
               color: #fff;
            }
            span {
               padding-left: 5px;
            }
         }
      }
   }
}
