@import "./MDrawer/index";
@import "./PortfolioTable/index";

$gutter: 15px;

.item-card-skeleton {
   height: 94px;
   padding: 1.8rem 0;
   margin-bottom: 10px;
   align-items: center;
   background: #ffffff;
   width: inherit;
   .ant-col {
      padding-left: 2rem;
      &:first-child {
         padding-left: 2rem;
      }
      &:last-child {
         padding: 0 2rem;
      }
   }
}

.chart-skeleton {
   width: 200px;
   height: 200px;
   margin: 0 auto;
   padding: 25px;
   .ant-col {
      width: 100%;
      height: 100%;
      .ant-skeleton {
         width: 100%;
         height: 100%;
         .ant-skeleton-avatar {
            width: 100%;
            height: 100%;
         }
      }
   }
}

.vertical-item-card-skeleton {
   height: 94px;
   padding: 0;
   margin-bottom: 10px;
   align-items: center;
   background: #ffffff;
   .ant-col {
      padding-left: 2rem;
      &:first-child {
         padding-left: 2rem;
      }
      &:last-child {
         margin: 10px 0;
         padding: 0 2rem;
      }
   }
}

.allocation-details-card {
   padding: 1rem;
   background: #ffffff;
   color: $black;
   margin-bottom: 24px;
   .allocation-chart-wrapper {
      height: 250px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: $gutter;
   }
   .allocation-chart-details-wrapper {
      padding-bottom: $gutter;
      label {
         margin: 0;
         font-family: $secondary-font;
         font-style: normal;
         font-weight: 700;
         font-size: $font-size-16px;
      }
      .ant-row {
         font-family: $primary-font;
         font-style: normal;
         font-weight: 400;
         font-size: $font-size-12px;
      }
      &:last-child {
         padding: 0;
      }
   }
   .custom-scroll {
      margin-top: 9px !important;
      margin-bottom: 9px !important;
      padding-right: 4px !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
   }
}
