.rdt_Table {
   .rdt_TableHead {
      min-height: 51px;
   }
}
@media only screen and (min-width: 1024px) and (max-width: 1279px) {
   .model-selector-wrapper {
      .tbl-wrapper {
         .model-panel {
         }
         .ant-tabs {
         }
         .tab-holdings {
            .ant-tabs-content {
            }
         }
         .ant-tabs-content {
         }
         .ant-tabs-tabpane {
         }
      }
      .rdt_TableRow {
         font-family: $primary-font;
         font-size: $font-size-11px !important;
         color: #4a4a4a;
         .tbl-edit {
            color: $dark-blue-color;
         }
      }
      .rdt_TableCol {
         font-size: $font-size-12px !important;
      }
      .rdt_TableHeadRow {
         .rdt_TableCol {
            font-size: $font-size-12px !important;
         }
         // border: 0px;
      }
      .rdt_TableBody,
      .ant-tabs-tabpane {
         &::-webkit-scrollbar-thumb {
         }
      }
      .tbl-content {
         & > div {
            & > div {
            }
         }
      }
      .sub-head {
      }
      .tbl-edit {
      }
      .model-panel {
         .model-header {
            h4 {
               .fa-redo {
               }
            }
            &.ma {
            }
            &.sm {
               background: #6ab0eb;
            }
         }
      }
      .ant-tabs {
         background: #e1effa;
         &-nav {
            & > div {
            }
            .ant-tabs-tab {
               height: 49px;
               line-height: 25px;
               font-size: $font-size-12px !important;
               &.ant-tabs-tab-active {
               }
            }
         }
         .ant-tabs-bar {
         }
         .ant-tabs-ink-bar {
         }
      }
      .risk-chart-wrapper {
         h4 {
            font-size: $font-size-17px !important;
         }
      }
      .returns-summary-wrapper {
         .returns-summary-item {
         }
      }
      // Model Search Results Styles
      .sr-wrapper {
         .ticker-wrapper,
         .sponsor-wrapper,
         .frr-wrapper {
            span {
               &:nth-child(2) {
               }
            }
         }
         .ticker-wrapper {
            span {
               font-size: $font-size-13px !important;
               &:last-child {
               }
            }
         }
         .sponsor-wrapper {
            span {
               &:last-child {
               }
            }
         }
         .frr-wrapper {
            span {
               &:last-child {
                  font-size: $font-size-11px !important;
               }
            }
         }
         .guage-chart-wrapper {
            .fz {
               font-size: 8px !important;
            }
         }
         .siw-1-pie-holder {
            .siw-1-pie-title {
               font-size: 8px;
            }
         }
         .siw-1-carouse-holder {
            .siw-1-pie-title {
               display: block;
            }
            .flipster__item__content {
               overflow: inherit;
            }
         }
         .frr-head-wrapper {
            .fassf {
            }
         }
         .rdt_TableCell {
            font-size: $font-size-11px !important;
            .cta-wrapper {
               button {
                  padding: 2px 5px !important;
                  font-size: $font-size-11px !important;
               }
            }
            .select-btn {
               padding: 8px 1vw;
               font-size: $font-size-11px !important;
            }
         }
      }
      // Allocation Not Found Wrapper
      .not-found-wrapper {
         img {
         }
         i {
            font-size: $font-size-30px !important;
         }
         span {
            font-size: $font-size-25px !important;
         }
      }
      .text-capitalize {
      }
   }

   .portfolio-tbl-wrapper {
      .rdt_TableHeadRow {
         .rdt_TableCol {
            font-size: 0.7rem !important;
         }
      }
      .rdt_TableCell {
         font-size: $font-size-11px !important;
         .cta-wrapper {
            button {
               padding: 2px 5px !important;
               font-size: $font-size-11px !important;
            }
         }
         .select-btn {
            padding: 8px 1vw;
            font-size: $font-size-11px !important;
         }
      }
   }
   .portfolio-tbl-wrapper {
      .rdt_TableRow {
         font-size: 0.62rem !important;
      }
   }
   .sub-head-text-wrap {
      label {
         font-size: $font-size-8px !important;
      }
   }

   .stats-wrapper {
      .sw-gen-style {
         padding-left: 20px;
      }
   }

   // New Antd table styles - client list table
   // .portfolio-tbl-wrapper {
   // 	.ant-custom-tbl-content{
   // 		.ant-table-thead{
   // 	      tr{
   // 	        th{
   // 	          font-size: 0.7rem;
   // 	        }
   // 	      }
   // 	    }
   // 		.ant-table-tbody{
   // 	      tr{
   // 	        td{
   // 	          font-size: $font-size-11px;
   // 	        }
   // 	      }
   // 	    }
   // 	}
   // 	.cta-wrapper{
   // 		.blue-btn{
   // 			padding: 1px 10px;
   // 			font-size: $font-size-11px;
   // 		}
   // 	}
   // }
}

@media only screen and (min-width: 1280px) and (max-width: 1439px) {
   .portfolio-tbl-wrapper {
      .rdt_TableRow {
         font-size: 0.62rem !important;
      }
      .sub-head-text-wrap {
         label {
            font-size: $font-size-8px !important;
         }
         img {
            width: auto;
            height: 12px !important;
         }
      }
      .rdt_TableHeadRow {
         .rdt_TableCol {
            font-size: 0.8rem !important;
         }
      }
   }
   .portfolio-tbl-wrapper {
      .rdt_TableCell {
         font-size: $font-size-11px !important;
         .cta-wrapper {
            button {
               padding: 2px 5px !important;
               font-size: $font-size-11px !important;
            }
         }
         .select-btn {
            padding: 8px 1vw;
            font-size: $font-size-11px !important;
         }
      }
   }

   // New Antd table styles - client list table
   // .portfolio-tbl-wrapper {
   // 	.ant-custom-tbl-content{
   // 		.ant-table-thead{
   // 	      tr{
   // 	        th{
   // 	          font-size: 0.8rem;
   // 	        }
   // 	      }
   // 	    }
   // 		.ant-table-tbody{
   // 	      tr{
   // 	        td{
   // 	          font-size: 0.62rem;
   // 	        }
   // 	      }
   // 	    }
   // 	}
   // 	.cta-wrapper{
   // 		.blue-btn{
   // 			padding: 1px 10px;
   // 			font-size: $font-size-11px;
   // 		}
   // 	}
   // }
}

@media only screen and (min-width: 1440px) and (max-width: 1919px) {
   .portfolio-tbl-wrapper {
      .rdt_TableRow {
         font-size: 0.7rem !important;
      }
      .rdt_TableHeadRow {
         .rdt_TableCol {
            font-size: 0.8rem !important;
         }
      }
      .sub-head-text-wrap {
         label {
            font-size: 12px !important;
         }
      }
      .rdt_TableCell {
         font-size: $font-size-11px !important;
         // .cta-wrapper {
         // 	button {
         // 		// padding: 2px 5px !important;
         // 		font-size: $font-size-11px !important;
         // 	}
         // }
         .select-btn {
            padding: 8px 1vw;
            font-size: $font-size-11px !important;
         }
      }
   }

   // New Antd table styles - client list table
   // .portfolio-tbl-wrapper {
   // 	.ant-custom-tbl-content{
   // 		.ant-table-thead{
   // 	      tr{
   // 	        th{
   // 	          font-size: 0.8rem;
   // 	        }
   // 	      }
   // 	    }
   // 		.ant-table-tbody{
   // 	      tr{
   // 	        td{
   // 	          font-size: $font-size-11px;
   // 	        }
   // 	      }
   // 	    }
   // 	}
   // }
}

@media only screen and (min-width: 1920px) {
}
