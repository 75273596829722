.downloadExcel-modalClassName {
   overflow-y: scroll;
}

.download-feature-process-modal-backdrop {
   display: block !important;
}
.download-csv {
   .download-csv-limit-wrap {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      .download-csv-limit-text {
         font-family: "Roboto";
         font-size: 16px;
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         line-height: 2;
         letter-spacing: normal;
         text-align: left;
         color: #494949;
      }
      .ant-input-number {
         border: 0;
         border-bottom: 1px solid #c9c9c9;
         background: transparent;
         box-shadow: none;
         .ant-input-number-handler-wrap {
            display: none !important;
            .ant-input-number-handler {
               display: none !important;
            }
         }
      }

      .download-csv-limit-input {
         padding: 0 5px;
         input {
            width: 60px;
            padding-top: 0;
            padding-bottom: 0;
            font-family: "Roboto";
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            color: rgb(30, 95, 145);
         }
      }
   }
}

.download-csv-limit-error {
   height: 14px;
   margin: 10px 0;
   text-align: center;
   line-height: 1;
   .download-csv-limit-error-text {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #a5685f;
   }
}

.download-msg-modal {
   max-width: 790.7px !important;

   .modal-content {
      border: 0;
      border-radius: 4px;
      width: 790.7px;
      height: auto;
      background-color: #f7f7f7;

      .wip {
         width: 316px;
         height: 334px;

         border-radius: 10px;
         background-color: #ebebeb;

         .option-var {
            width: 302px;
            height: 296px;
            //padding-top: 19px;
            padding-left: 20px;
            //padding-bottom: 19px;
            //  margin:4px, 4px;
            //  padding:4px;
            overflow-x: hidden;
            overflow-x: auto;
            text-align: justify;

            .draggable {
               font-family: Roboto;
               font-size: 16px;
               font-weight: normal;
               font-stretch: normal;
               font-style: normal;
               line-height: 2;
               letter-spacing: normal;
               text-align: left;
               //cursor: pointer;
               color: #494949;
               background-color: #ebebeb;
            }
         }

         .option-var::-webkit-scrollbar {
            width: 8px;
         }
         .option-var::-webkit-scrollbar-track {
            background-color: #ebebeb;
            -webkit-border-radius: 10px;
            //border-radius: 10px;
         }
         .option-var::-webkit-scrollbar-thumb {
            -webkit-border-radius: 10px;
            //border-radius: 10px;
            background: #c9c9c9;
         }
      }

      .droppable {
         width: 316px;
         height: 334px;
         border-radius: 10px;
         background-color: #ffffff;

         .select-var {
            width: 302px;
            height: 296px;
            //padding-top: 19px;
            padding-left: 20px;
            //padding-bottom: 19px;
            overflow-x: hidden;
            overflow-x: auto;
            text-align: justify;
            font-family: Roboto;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 2;
            letter-spacing: normal;
            text-align: left;
            color: #1e5f91;

            // .draggable {
            //   cursor: pointer;
            // }
         }

         .select-var::-webkit-scrollbar {
            width: 8px;
         }

         .select-var::-webkit-scrollbar-track {
            background-color: #ffffff;
            -webkit-border-radius: 10px;
            //border-radius: 10px;
         }
         .select-var::-webkit-scrollbar-thumb {
            -webkit-border-radius: 10px;
            //border-radius: 10px;
            background: #1e5f91;
         }
      }
   }
   .modal-body {
      padding-bottom: 2rem;
      padding-left: 2rem;
      padding-right: 2rem;
      padding-top: 39px;
   }
}

@include respond(tab) {
   .download-feature-process-modal {
      width: 80%;
   }
}

@include respond(phone) {
   .download-feature-process-modal {
      width: 95%;
   }
}

.download-feature-process-modal {
   max-width: 936px !important;

   .modal-content {
      border: 0;
      border-radius: 15px;
      background-color: #f7f7f7;
   }
   .modal-body {
      padding: 3.5rem;
   }
}

.fs-1 {
   font-size: 1rem !important;
}

.text-align-center {
   text-align: center !important;
}

.text-align-left {
   text-align: left !important;
}

.justify-content-left {
   justify-content: left !important;
}

.download-feature-process-modal-container {
   .download-feature-completed-close {
      position: absolute;
      right: 1rem;
      top: 1rem;
      z-index: 9;
      font-size: 1.5rem;
      color: #fff;
      cursor: pointer;
   }

   .download-feature-completed-check {
      font-size: 6rem;
      text-align: center;
      color: #fff;
   }

   .header-wrapper {
      h1 {
         font-family: "Roboto";
         font-size: 29px !important;
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.21;
         letter-spacing: normal;
         text-align: left;
         color: #1e5f91;
      }
      h2 {
         font-size: 13px !important;
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.38;
         letter-spacing: normal;
         color: #ffffff;
      }
   }
   .content-wrapper {
      font-size: 13px;
      font-weight: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;
      margin-bottom: 1.5rem;
   }
   .cta-wrapper {
      flex-direction: row;
      align-items: normal;
      .btn {
         width: 140px !important;
         font-size: 12px;
         font-weight: normal;
         padding: 13px 31px !important;
         line-height: 1;

         &:first-child {
            // margin-right: 20px;
         }
      }
   }
}

@include respond(phone) {
   .download-msg-modal {
      .modal-content {
      }
      .modal-body {
         padding: 1rem;
      }
   }

   .download-feature-process-modal {
      .modal-content {
      }
      .modal-body {
         padding: 1rem;
      }
   }

   .download-feature-process-modal-container {
      .download-feature-completed-close {
      }

      .download-feature-completed-check {
      }

      .header-wrapper {
         h1 {
            font-size: 2rem !important;
         }
         h2 {
            font-size: 13px !important;
         }
      }
      .content-wrapper {
      }
      .cta-wrapper {
         .btn {
            line-height: 1.2;

            &:first-child {
               margin-right: 0.8rem;
            }
         }
      }
   }
}
